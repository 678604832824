import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { scrollTop } from '../../shared/common-functions'
import swal from 'sweetalert'
import MainLoader from '../../shared/loaders/MainLoader'
import SmallLoaderComponent from '../../shared/loaders/SmallLoader'
import $ from 'jquery'
import { sortBy } from 'lodash'
import Select from 'react-select';
import {
	Row,
} from "shards-react"
class CreatePackage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			logOutRedirect: false,
			vat: 0,
			totalPrice: 0,

			infoNote: {}, // it contains all info notes

			userId: '',

			newPackage: {
				type: '',
				isAnnual: false,
				isMonthly: true,
				savingRatio: '',

				// link management
				linksPerMonth: window.i18n('cr_lk_pm'),
				tags: false,
				bulkLinkTagging: '',
				linkFiltering: false,
				customBackHalf: false,
				brandedLink: false,
				customDoms: false,

				// custom link
				brandedLinkLimit: '',
				customBackHalfLimit: '', // custom back half limit per month
				customLinkHistory: '',

				// branding
				customDomains: '',
				defaultDomains: false,

				// campaign management
				campaigns: false,
				campaignsTracking: false,
				campaignDataExport: false, // export of campaign data
				socialPosting: false,
				socialPlatforms: '',

				// data analytics
				linkHistory: '',
				dashboardView: false,
				analyticsDataExport: false,

				// data delivery
				integrations: '',



				// generate QR codes
				qrCodeGenerate: false,

				price: 0,
				api: ''

			},

			// disable toggles
			disabledToggles: {},

			packageSettings: {},
			links: [],
			bulkLineTaggingList: [],
			customLinkHistory: [],
			brandedLinkLimit: [],
			customBackHalfLimit: [],
			customDomains: [],
			socialPlatforms: [],
			linkHistory: [],
			integrations: [],
			api: [],
			errors: '',
			mainLoader: false,
			smallLoader: false,
			redirect: false,
			comingFromPricing: false,
			redirectToDashboard: false,
			id: "",
			// to store package settings by admin
			adminPackageSettings: {},
			linksPerMonthPrice: 0,
			bulkLinkTaggingPrice: 0,
			customLinkHistoryPrice: 0,
			brandedLinkLimitPrice: 0,
			customBackHalfLimitPrice: 0,
			customDomainsPrice: 0,
			socialPlatformsPrice: 0,
			linkHistoryPrice: 0,
			integrationsPrice: 0,
			apiPrice: 0
		}
	}

	componentDidMount() {
		scrollTop()

		if (this.props.history.location.state) {
			if (this.props.history.location.state.redirect)
				this.setState(() => ({ comingFromPricing: true, id: this.props.history.location.state.id }));
		}
		if (localStorage.getItem('LoginSession')) {
			const userId = JSON.parse(localStorage.getItem('LoginSession')).data._id;
			this.setState({ userId }, () => {
				this.getPckageSettings(null)
			})


		} else
			this.setState({ redirect: true })
	}

	// get package settings
	getPckageSettings(packageSettings) {
		this.setState({ mainLoader: true }, () => {
			let url = `${window.APIURL}package-settings/upsert`
			const country = localStorage.getItem('country')

			url += "?accessToken=" + localStorage.getItem('loginToken')

			if (country)
				url += `&country=${country}`


			fetch(url, {
				method: 'post',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ packageSettings })
			}).then((res) => {
				return res.json()
			}).then((result) => {
				if (result.sessionExpired) {
					this.setState({
						logOutRedirect: true
					})
					return;
				}
				const { data, status, vat } = result
				this.state.adminPackageSettings = data;
				if (status) {
					const disabledToggles = {}
					for (const [key, value] of Object.entries(data)) {
						const id = key.split('Price')[0]
						disabledToggles[id] = !value ? true : false
					}
					// disable plan type switch
					const { newPackage } = this.state

					// creating options array for links
					let links = [];
					if (result.data.links && result.data.links.length > 0) {
						result.data.links.forEach(eachLink => {
							links.push({
								value: { value: eachLink.end, price: eachLink.price },
								label: `0 - ${eachLink.end} (€${eachLink.price})`,
								price: eachLink.price
							})
						});
					}

					// creating options array for bulkLineTaggingList
					let bulkLineTaggingList = [];
					if (result.data.bulkLinkTagging && result.data.bulkLinkTagging.length > 0) {
						result.data.bulkLinkTagging.forEach(eachLink => {
							bulkLineTaggingList.push({
								value: { value: eachLink.end, price: eachLink.price },
								label: `0 - ${eachLink.end} (€${eachLink.price})`,
								price: eachLink.price

							})
						});
					}

					// creating options array for customLinkHistory
					let customLinkHistory = [];
					if (result.data.customLinkHistory && result.data.customLinkHistory.length > 0) {
						result.data.customLinkHistory.forEach(eachLink => {
							customLinkHistory.push({
								value: { value: eachLink.end, price: eachLink.price },
								label: `${eachLink.days} (€${eachLink.price})`,
								price: eachLink.price

							})
						});
					}

					// creating options array for brandedLinkLimit
					let brandedLinkLimit = [];
					if (result.data.brandedLinks && result.data.brandedLinks.length > 0) {
						result.data.brandedLinks.forEach(eachLink => {
							brandedLinkLimit.push({
								value: { value: eachLink.end, price: eachLink.price },
								label: `0 - ${eachLink.end} (€${eachLink.price})`,
								price: eachLink.price

							})
						});
					}

					// creating options array for customBackHalfLimit
					let customBackHalfLimit = [];
					if (result.data.customBackHalf && result.data.customBackHalf.length > 0) {
						result.data.customBackHalf.forEach(eachLink => {
							customBackHalfLimit.push({
								value: { value: eachLink.end, price: eachLink.price },
								label: `0 - ${eachLink.end} (€${eachLink.price})`,
								price: eachLink.price

							})
						});
					}

					// creating options array for customDomains
					let customDomains = [];
					if (result.data.customDomains && result.data.customDomains.length > 0) {
						result.data.customDomains.forEach(eachLink => {
							customDomains.push({
								value: { value: eachLink.end, price: eachLink.price },
								label: `0 - ${eachLink.end} (€${eachLink.price})`,
								price: eachLink.price

							})
						});
					}

					// creating options array for socialPlatforms
					let socialPlatforms = [];
					if (result.data.socialPlatforms && result.data.socialPlatforms.length > 0) {
						result.data.socialPlatforms.forEach(eachLink => {
							socialPlatforms.push({
								value: { value: eachLink.end, price: eachLink.price },
								label: `0 - ${eachLink.end} (€${eachLink.price})`,
								price: eachLink.price

							})
						});
					}

					// creating options array for linkHistory
					let linkHistory = [];
					if (result.data.linkHistory && result.data.linkHistory.length > 0) {
						result.data.linkHistory.forEach(eachLink => {
							linkHistory.push({
								value: { value: eachLink.days, price: eachLink.price },
								label: `${eachLink.days} (€${eachLink.price})`,
								price: eachLink.price

							})
						});
					}

					// creating options array for integrations
					let integrations = [];
					if (result.data.integrations && result.data.integrations.length > 0) {
						result.data.integrations.forEach(eachLink => {
							integrations.push({
								value: { value: eachLink.end, price: eachLink.price },
								label: `0 - ${eachLink.end} (€${eachLink.price})`,
								price: eachLink.price

							})
						});
					}

					// creating options array for apis
					let api = [];
					if (result.data.api && result.data.api.length > 0) {
						result.data.api.forEach(eachLink => {
							api.push({
								value: { value: eachLink.end, price: eachLink.price },
								label: `0 - ${eachLink.end} (€${eachLink.price})`,
								price: eachLink.price

							})
						});
					}

					disabledToggles.planType = (!data.isAnnual || !data.isMonthly) ? true : false
					if (disabledToggles.planType) {
						newPackage.isAnnual = data.isAnnual
						newPackage.isMonthly = data.isMonthly

						if (data.isAnnual && data.savingRatio)
							newPackage.savingRatio = data.savingRatio
					}

					this.setState({
						mainLoader: false, disabledToggles, packageSettings: data, newPackage, vat, links: links, bulkLineTaggingList: bulkLineTaggingList, customLinkHistory: customLinkHistory,
						brandedLinkLimit: brandedLinkLimit,
						customBackHalfLimit: customBackHalfLimit,
						customDomains: customDomains,
						socialPlatforms: socialPlatforms,
						linkHistory: linkHistory,
						integrations: integrations,
						api: api
					})
				} else
					swal('Error', 'Contact Admin', 'error')
			})
		})
	}

	handleKeyDown = e => {
		let keyCode = e.which
		if (!((keyCode > 95 && keyCode < 106)
			|| (keyCode > 47 && keyCode < 58)
			|| keyCode === 8)) {
			e.preventDefault()
		}
	}

	onChange = (e) => {
		let { name, value, id } = e.target
		const { newPackage, infoNote } = this.state
		newPackage[name] = value

		// get price
		const getPrice = $(`#${id}`).hasClass('noPrice') // calculate price for price fields only
		if (!getPrice) {
			// remove old price
			let classes = $(`#${id}`).attr('class')
			classes = classes.split(/\s+/)
			let oldPrice = classes.find((val) => { return /Price/.test(val) })
			if (oldPrice) {
				$(`#${id}`).removeClass(oldPrice)
				newPackage.price -= parseFloat(oldPrice.split('Price')[0])
			}

			// add new price
			if (value) {
				const price = this.getFieldPrice(id, value)
				if (price > -1) {
					newPackage.price += price
					$(`#${id}`).addClass(`${price}Price`)

					if (id === 'customBackHalfLimit')
						delete infoNote['customBackHalf']
					else delete infoNote[id]
				}
			} else if (!value)
				if (id === 'customBackHalfLimit')
					delete infoNote['customBackHalf']
				else delete infoNote[id]

			// get total price after tax
			this.getTotalPrice()
		}

		this.setState({ newPackage, infoNote })
	}

	onToggle(status, priceKey = '') {
		// const { newPackage, packageSettings } = this.state
		const { newPackage, adminPackageSettings } = this.state


		if (status === 'planType') {
			newPackage.isMonthly = !newPackage.isMonthly
			newPackage.isAnnual = !newPackage.isAnnual

			if (newPackage.isAnnual && (adminPackageSettings.savingRatio && adminPackageSettings.isAnnual))
				newPackage.savingRatio = adminPackageSettings.savingRatio
			else
				newPackage.savingRatio = 0

			// get total price after tax
			this.getTotalPrice()
		} else {
			const value = !newPackage[status]
			newPackage[status] = value

			// set price
			if (priceKey) {
				if (value)
					newPackage.price += adminPackageSettings[priceKey]
				else
					newPackage.price -= adminPackageSettings[priceKey]
			}
		}
		if (status === 'customDoms' && newPackage.customDoms === true) {
			newPackage.price += this.state.customDomainsPrice
		}

		if (status === 'brandedLink' && newPackage.brandedLink === true) {
			newPackage.price += this.state.brandedLinkLimitPrice

		}

		if (status === 'customBackHalf' && newPackage.customBackHalf === true) {
			newPackage.price += this.state.customBackHalfLimitPrice

		}

		if (status === 'socialPosting' && newPackage.socialPosting === true) {
			newPackage.price += this.state.socialPlatformsPrice

		}


		if (status === 'customDoms' && newPackage.customDoms === false) {
			newPackage.price -= this.state.customDomainsPrice
		}

		if (status === 'brandedLink' && newPackage.brandedLink === false) {
			newPackage.price -= this.state.brandedLinkLimitPrice

		}

		if (status === 'customBackHalf' && newPackage.customBackHalf === false) {
			if (newPackage.unlimitedCustomBackHalf === true)
				newPackage.price -= this.state.adminPackageSettings.unlimitedCustomBackHalfPrice
			else
				newPackage.price -= this.state.customBackHalfLimitPrice

			newPackage.unlimitedCustomBackHalf = false

		}

		if (status === 'socialPosting' && newPackage.socialPosting === false) {
			newPackage.price -= this.state.socialPlatformsPrice

		}



		this.setState({ newPackage }, this.getTotalPrice())
	}

	getFieldPrice(elem, value) {
		value = parseFloat(value)
		const inDays = $(`#${elem}`).hasClass('inDays')
		const { packageSettings } = this.state
		let data;

		// exceptional case
		if (elem === 'customBackHalfLimit')
			elem = 'customBackHalf'

		const packageElemData = sortBy(packageSettings[elem], inDays ? ['days'] : ['start', 'end'])

		if (!inDays) {
			data = packageElemData.find((val) => { return (val.start <= value && val.end >= value) })
		}
		else {
			data = packageElemData.find((val, index) => {
				if (index < (packageElemData.length - 1)) {
					const nexVal = packageElemData[index + 1]
					return (val.days >= value && value <= nexVal.days)
				} else return (val.days >= value)
			})
		}

		if (!data) {
			const { infoNote } = this.state
			const lastIndex = packageSettings[elem].length - 1
			if (lastIndex > -1) {
				const label = $(`label[for="${elem}"]`).html()
				infoNote[elem] = `${window.i18n('cr_msg_unallowed')}${label}${window.i18n('cr_msg_more')} ${packageSettings[elem][lastIndex][!inDays ? 'end' : 'days']}`
				this.setState({ infoNote })
			}
		} else
			return data.price
	}

	// get total price after tax
	getTotalPrice = () => {
		const { newPackage, vat } = this.state
		let totalPrice = 0, payment = newPackage.price

		if (newPackage.isAnnual)
			payment = (newPackage.price - (newPackage.price * (newPackage.savingRatio / 100))) * 12

		const tax = (vat / 100) * payment
		totalPrice = this.calcRound(payment + tax)
		// totalPrice = payment + tax

		this.setState({ totalPrice })
	}

	submit = (e) => {
		e.preventDefault()
		const { userId, newPackage, infoNote, totalPrice, adminPackageSettings } = this.state

		if (adminPackageSettings.isAnnual === false && newPackage.isAnnual === true) {
			scrollTop()
			this.setState({ errors: `${window.i18n('an_nt_al')}` });
			return
		}
		if (adminPackageSettings.isMonthly === false && newPackage.isMonthly === true) {
			scrollTop()
			this.setState({ errors: `${window.i18n('mo_nt_al')}` });
			return
		}
		// if (!newPackage.type) {
		// 	scrollTop()
		// 	this.setState({ errors: `${window.i18n('cr_msg_pckg')}` })
		// 	return
		// }
		if ((newPackage.isAnnual && newPackage.isMonthly) || (!newPackage.isAnnual && !newPackage.isMonthly)) {
			scrollTop()
			this.setState({ errors: `${window.i18n('cr_msg_anOmo')} - ${window.i18n('cr_msg_atleast')} ` })
			return
		}
		if ((!newPackage.unlimitedLinks && !newPackage.linksPerMonth) || (!newPackage.unlimitedLinks && newPackage.linksPerMonth === window.i18n('cr_lk_pm'))) {


			scrollTop()
			this.setState({ errors: `${window.i18n('cr_lk_valid')}` })
			return
		}
		if (newPackage.customLinkHistory) {
			if (parseInt(newPackage.customLinkHistory) < 1) {
				scrollTop()
				this.setState({ errors: `${window.i18n('cr_msg_cslink')}` })
				return
			}
		}
		if (!newPackage.linkHistory) {
			scrollTop()
			this.setState({ errors: `${window.i18n('cr_msg_linkhistory')}` })
			return
		}
		if (parseInt(newPackage.linkHistory) < 1) {
			scrollTop()
			this.setState({ errors: `${window.i18n('cr_msg_linkhist')}` })
			return
		}
		if (newPackage.customBackHalf) {
			// if ((newPackage.customBackHalfLimit && newPackage.unlimitedCustomBackHalf) || (!newPackage.customBackHalfLimit && !newPackage.unlimitedCustomBackHalf)) {
			if (!newPackage.customBackHalfLimit && !newPackage.unlimitedCustomBackHalf) {

				scrollTop()
				this.setState({ errors: `${window.i18n('cr_msg_cstback')}` })
				return
			}
			if (newPackage.customBackHalfLimit && parseInt(newPackage.customBackHalfLimit) < 1) {
				scrollTop()
				this.setState({ errors: `${window.i18n('cr_msg_cstback1')}` })
				return
			}
		}
		if (newPackage.brandedLink) {
			if (!newPackage.brandedLinkLimit) {
				scrollTop()
				this.setState({ errors: `${window.i18n('cr_msg_brlm')}` })
				return
			}
			if (parseInt(newPackage.brandedLinkLimit) < 1) {
				scrollTop()
				this.setState({ errors: `${window.i18n('cr_msg_brlm1')}` })
				return
			}
		}
		if (newPackage.customDoms) {
			if (!newPackage.customDomains) {
				scrollTop()
				this.setState({ errors: `${window.i18n('cr_msg_cstdm')}` })
				return
			}
			if (parseInt(newPackage.customDomains) < 1) {
				scrollTop()
				this.setState({ errors: `${window.i18n('cr_msg_cstdm1')}` })
				return
			}
		}
		if (newPackage.socialPosting) {
			if (!newPackage.socialPlatforms) {
				scrollTop()
				this.setState({ errors: `${window.i18n('cr_msg_sp')}` })
				return
			}
		}


		if (newPackage.api === '') {
			scrollTop()
			this.setState({ errors: `API ${window.i18n('cr_api_valid')}` })
			return
		}

		if (newPackage.integrations && parseInt(newPackage.integrations) < 1) {
			scrollTop()
			this.setState({ errors: `${window.i18n('cr_msg_int')}` })
			return
		}

		if (Object.keys(infoNote) > 0) {
			scrollTop()
			this.setState({ errors: `${window.i18n('cr_msg_look')}` })
			return
		}

		this.setState({ smallLoader: true, errors: '' }, () => {
			// assign user to payload
			newPackage.userId = userId

			// assign custom package
			newPackage.isCustomize = true

			// inactive new plan
			newPackage.active = false

			// new plan status 
			newPackage.isApproved = 0 // pending

			// set total price with tax
			// newPackage.price = totalPrice

			// this.calculateTotal(newPackage)

			// this.getTotalPrice()

			if (newPackage.unlimitedLinks) {
				newPackage.linksPerMonth = 'unlimited'
				delete newPackage.unlimitedLinks
			}

			if (newPackage.unlimitedCustomBackHalf) {
				newPackage.customBackHalfLimit = 'unlimited'
				delete newPackage.unlimitedCustomBackHalf
			}



			// newPackage.savingRatio = newPackage.savingRatio && packageSettings.isAnnual && packageSettings.savingRatio ? packageSettings.savingRatio : ''
			newPackage.brandedLinkLimit = newPackage.brandedLink ? newPackage.brandedLinkLimit : ''
			newPackage.customBackHalfLimit = newPackage.customBackHalf ? newPackage.customBackHalfLimit : ''
			newPackage.customDomains = newPackage.customDoms ? newPackage.customDomains : ''
			newPackage.isCreatedByUser = true

			// newPackage.price = totalPrice
			// return null;

			fetch(`${window.APIURL}subscription-package/plan-by-user?lang=${localStorage.getItem('language')}` + "&accessToken=" + localStorage.getItem('loginToken'), {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				// body: JSON.stringify(newPackage)
				body: JSON.stringify({
					type: newPackage.type,
					isAnnual: newPackage.isAnnual,
					isMonthly: newPackage.isMonthly,
					price: newPackage.price,
					savingRatio: newPackage.savingRatio,

					// link management
					linksPerMonth: newPackage.linksPerMonth.value,
					tags: newPackage.tags,
					bulkLinkTagging: newPackage.bulkLinkTagging.value,
					linkFiltering: newPackage.linkFiltering,
					customBackHalf: newPackage.customBackHalf,
					brandedLink: newPackage.brandedLink,
					customDoms: newPackage.customDoms,

					// custom link
					brandedLinkLimit: newPackage.brandedLinkLimit.value,
					customBackHalfLimit: newPackage.customBackHalfLimit.value, // custom back half limit per month
					customLinkHistory: newPackage.customLinkHistory.value,

					// branding
					customDomains: newPackage.customDomains.value,
					defaultDomains: newPackage.defaultDomains,

					// campaign management
					campaigns: newPackage.campaigns,
					campaignsTracking: newPackage.campaignsTracking,
					campaignDataExport: newPackage.campaignDataExport, // export of campaign data
					socialPosting: newPackage.socialPosting,
					socialPlatforms: newPackage.socialPlatforms.value,

					// data analytics
					linkHistory: newPackage.linkHistory.value,
					dashboardView: newPackage.dashboardView,
					analyticsDataExport: newPackage.analyticsDataExport,

					// data delivery
					integrations: newPackage.integrations.value,

					qrCodeGenerate: newPackage.qrCodeGenerate,
					userId: userId,

					isCustomize: true,

					active: false,

					isApproved: false,
					API: newPackage.api.value

				})
			}).then((res) => {
				return res.json();
			}).then((result) => {

				if (result.sessionExpired) {
					this.setState({
						logOutRedirect: true
					})
					return;
				}



				this.setState({ smallLoader: false })
				if (result.status) {
					swal('Success', `${window.i18n('cr_msg_reviewing')}`, 'success')
				} else if (!result.status) {
					swal('Error', result.message, 'error')
				} else {
					swal('Error', 'Contact Admin', 'error')
				}
			})
			this.setState({ smallLoader: false })

			this.setState({ redirectToDashboard: true })
		})

	}

	calculateTotal = (newPackage) => {
		const { adminPackageSettings } = this.state;
		if (newPackage.unlimitedCustomBackHalf === true) {
			delete newPackage.customBackHalfLimit
		}
		if (newPackage.unlimitedLinks === true) {
			delete newPackage.linksPerMonth

		}
		if (newPackage.tags === true) {
			newPackage.price = newPackage.price + adminPackageSettings.tagsPrice
		}
		if (newPackage.linkFiltering === true) {
			newPackage.price = newPackage.price + adminPackageSettings.linkFilteringPrice
		}
		if (newPackage.defaultDomains === true) {
			newPackage.price = newPackage.price + adminPackageSettings.defaultDomainsPrice
		}
		if (newPackage.campaigns === true) {
			newPackage.price = newPackage.price + adminPackageSettings.campaignsPrice
		}
		if (newPackage.campaignsTracking === true) {
			newPackage.price = newPackage.price + adminPackageSettings.campaignsTrackingPrice
		}
		if (newPackage.campaignDataExport === true) {
			newPackage.price = newPackage.price + adminPackageSettings.campaignDataExportPrice
		}
		if (newPackage.dashboardView === true) {
			newPackage.price = newPackage.price + adminPackageSettings.dashboardViewPrice
		}
		if (newPackage.analyticsDataExport === true) {
			newPackage.price = newPackage.price + adminPackageSettings.analyticsDataExportPrice
		}
		if (newPackage.qrCodeGenerate === true) {
			newPackage.price = newPackage.price + adminPackageSettings.qrCodePrice
		}




		for (const eachAttribute in newPackage) {
			// if (eachAttribute === "price") {
			// break;
			// }

			if (newPackage[eachAttribute].price) {
				newPackage.price = newPackage.price + newPackage[eachAttribute].price
				// this.setState({ price: price + eachAttribute.price })////
				// this.setState({ newPackage: { ...this.state.newPackage, price: this.state.newPackage.price + newPackage[eachAttribute].price } })
			}

			if (eachAttribute === "unlimitedCustomBackHalf") {
				newPackage.price = newPackage.price + adminPackageSettings.unlimitedCustomBackHalfPrice

			}
			if (eachAttribute === "unlimitedLinks") {
				newPackage.price = newPackage.price + adminPackageSettings.unlimitedLinksPrice

			}
			// }
		}


		const { vat } = this.state
		let totalPrice = 0, payment = newPackage.price

		if (newPackage.isAnnual)
			newPackage.price = (newPackage.price - (newPackage.price * (newPackage.savingRatio / 100))) * 12
		// payment = (newPackage.price - (newPackage.price * (newPackage.savingRatio / 100))) * 12


		// const tax = (vat / 100) * payment
		const tax = (vat / 100) * newPackage.price

		// totalPrice = this.calcRound(payment + tax)
		// newPackage.price = totalPrice
		newPackage.price = (Math.round((newPackage.price + tax) * 100) / 100).toFixed(2);
	}

	updatePriceWithToggles = (name, settingsKey) => {
		var { newPackage, adminPackageSettings } = this.state
		// if (name != "unlimitedCustomBackHalf")
		newPackage[name] = !newPackage[name];
		let priceToSubtract = 0;
		let priceToAdd = 0;
		if (name === "unlimitedLinks") {
			priceToSubtract = this.state.linksPerMonthPrice
		}
		if (name === "unlimitedCustomBackHalf") {
			priceToSubtract = this.state.customBackHalfLimitPrice
		}
		if (newPackage[name]) {
			this.setState({ newPackage: { ...newPackage, price: newPackage.price + adminPackageSettings[settingsKey] - priceToSubtract } }, () => this.getTotalPrice())
		}
		else {
			let newPriceToSubtract = 0;

			if (name === "unlimitedLinks") {
				priceToAdd = this.state.linksPerMonthPrice
			}
			if (name === "unlimitedCustomBackHalf") {
				priceToAdd = this.state.customBackHalfLimitPrice
			}

			this.setState({ newPackage: { ...newPackage, price: newPackage.price - adminPackageSettings[settingsKey] + priceToAdd - newPriceToSubtract } }, () => this.getTotalPrice())

		}
		// this.getTotalPrice()
	}

	updatePriceWithSelects = (price, key, objKey, value) => {
		for (const keys in value) {
		}
		let newPackage = { ...this.state.newPackage, [objKey]: value }
		// this.setState({
		// 	newPackage:newPackage1
		// })
		// var { newPackage } = this.state;
		var prevPrice = this.state[key];
		let newPrice = this.state.newPackage.price + price - prevPrice
		// this.state.newPackage.price = this.state.newPackage.price + price - prevPrice
		this.setState({ newPackage: { ...newPackage, price: newPrice } }, () => {
			this.getTotalPrice()
		})
		this.setState({
			[key]: price
		})
		// this.state[key] = price;
	}

	fixPriceBanner = () => {
		// debugger
		// TODO
		// var sticky = $('.net-amount-holder'),
		// 	scroll = $(window).scrollTop()
		// debugger
		// if (scroll >= 200) sticky.addClass('fixed')
		// else sticky.removeClass('fixed')
	}

	calcRound = (num) => {
		var with2Decimals = num.toString()?.match(/^-?\d+(?:\.\d{0,2})?/)[0]
		return with2Decimals
	}

	handleSelection = (e) => {
		// let temp = this.state.newPackage
		// temp.linksPerMonth = e.target.value
		// this.setState( { newPackage: temp} );
		// this.setState({
		// 	[e.target.name]: e.target.value
		//   });
		this.setState({
			newPackage: { ...this.state.newPackage, linksPerMonth: e.value }
		});

		// this.setState(prevState => ({
		// 	newPackage: {                   // object that we want to update
		// 		...prevState.newPackage,    // keep all other key-value pairs
		// 		linksPerMonth:  e.target.value       // update the value of specific key
		// 	}
		// }))
	}

	render() {
		const {
			id, mainLoader, smallLoader, redirect, errors, infoNote, userId, totalPrice,
			vat, newPackage, packageSettings, comingFromPricing,
			disabledToggles, redirectToDashboard
		} = this.state


		const { logOutRedirect } = this.state;
		if (logOutRedirect) {
			// return <Redirect to="/logout" />;
			window.location.href = "/web/logout-and-clear-session"
		}

		if (redirect && comingFromPricing === false)

			return <Redirect to={{
				pathname: "/userRegister",
				state: { redirect: true }
			}} />

		if (redirectToDashboard === true) {
			return <Redirect to={{
				pathname: "/dashboard",
			}} />
		}

		if (userId === "" || userId === undefined || userId === null) {
			this.setState({
				userId: id
			})
		}
		if (logOutRedirect) {
			window.location.href = "/web/logout-and-clear-session"
		}

		this.fixPriceBanner()

		return (
			<>
				{
					mainLoader ?
						<MainLoader />
						:
						<div className="create-custom-package content-section">
							<div className="container">
								<div className="row create-custom-row">
									<div className="col-md-12">
										<div className="admin-section--MAIN">
											<div className="net-amount-holder">
												<span>
													VAT: <strong>{vat}%</strong>&nbsp;
													{
														newPackage.isAnnual && packageSettings.savingRatio &&
														<span>Saving Ratio: <strong>{packageSettings.savingRatio}%</strong>&nbsp;</span>
													}
													NET: <strong>€{newPackage.price}</strong>
												</span>
												<p>
													<small className="subscription-payment--price-msg">{window.i18n('upgradPack_h')} <strong>€{totalPrice}</strong> {window.i18n(newPackage.isAnnual ? 'upgradPack_i' : 'upgradPack_j')}</small>
												</p>
											</div>
											<div className="col-12 mb-3 subscription-payment--layout">
												{/* && userId!=null && userId!=undefined */}
												<Link to={"/organization/subscriptions/" + userId} className="go-back link-dark td_ul">
													<span className="back_btn-icon" alt="back_btn-icon"><i className="fas fa-chevron-left"></i></span> {window.i18n('upgradPack_a')}
												</Link>
											</div>
											<div className="page-heading">
												<span className="account-detail--header">{window.i18n('cr_cs_pkg')}</span>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="package-detail-row pt-2 pb-2">
											{
												errors && <div className="alert alert-danger mt-4">{errors}</div>
											}
											<div className="row">
												<div className="col-md-3 col-12">
													<div className="form-group">
														<label className="" htmlFor="type">{window.i18n('cr_pkg_nm')}</label>
														<input
															className="text-capitalize form-control noPrice"
															type="text"
															id="type"
															name="type"
															value={newPackage.type}
															placeholder={window.i18n('cr_pkg_nm')}
															onChange={this.onChange}
															maxLength="100"
														/>
													</div>
												</div>
												<div className="col-md-3 col-12">
													<div className="form-group">
														<label className="d-none d-md-block">&nbsp;</label>
														<div className="radio-btns-holder d-flex">
															<div className="radio-btn-holder mr-5 d-flex align-items-center">
																<span className="d-block mr-2">{window.i18n('cr_pkg_mt')}</span>
																<label className="custom-rounded-toggle two-way-toggle">
																	<input
																		type="checkbox"
																		name="planType"
																		checked={newPackage.isAnnual}
																		disabled={disabledToggles.planType}
																		onChange={(e) =>
																			this.onToggle('planType')
																		}
																	/>
																	<span className="slider round"></span>
																</label>
																<span className="d-block ml-2">{window.i18n('cr_pkg_an')}</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<hr />
										{/* link management */}
										<div className="package-detail-row pt-2 pb-2">
											<h5 className="text-uppercase font-weight-bold">{window.i18n('price_F1H')}</h5>
											<div className="row">
												{/* links */}
												<div className="col-md-3">
													<div className="form-group">
														<label className="require" htmlFor="links">{window.i18n('cr_lk')}</label>
														{/* links per month select */}
														<div >
															<Select
																isDisabled={this.state.links.length === 0 || newPackage.unlimitedLinks === true}
																value={this.state.links.filter(selectedLink => this.state.newPackage.linksPerMonth === selectedLink.value)}
																onChange={(e) =>
																	//  {
																	// this.handleSelection(e)
																	this.updatePriceWithSelects(e.price, 'linksPerMonthPrice', 'linksPerMonth', e.value)


																	// }
																}
																placeholder={window.i18n('cr_lk')}
																classNamePrefix="react-select"
																isSearchable={false}
																options={this.state.links}
																isOptionDisabled={(option) => option.isdisabled}
																components={{
																	IndicatorSeparator: () => null
																}}
															/>
														</div>
														{/* links per month select */}

														{/* <input
															className="form-control"
															type="number"
															id="links"
															name="linksPerMonth"
															value={newPackage.linksPerMonth}
															placeholder={window.i18n('cr_lk_pm')}
															min="0"
															disabled={disabledToggles.links}
															onKeyDown={(e) => this.handleKeyDown(e)}
															onChange={this.onChange}
														/> */}
													</div>
													{infoNote.links && <small><strong className="color-theme">{window.i18n('cr_nt') + ": "}</strong> {infoNote.links}</small>}
												</div>
												{/* unlimited links */}

												{!disabledToggles.unlimitedLinks && <div className="col-md-3">
													<div className="form-group">
														<span className="d-block">{window.i18n('cr_un_l')}</span>
														<label className="custom-rounded-toggle">
															<input
																type="checkbox"
																id="unlimitedLinks"
																name="unlimitedLinks"
																value={newPackage.unlimitedLinks}
																disabled={disabledToggles.unlimitedLinks}
																onChange={(e) => this.updatePriceWithToggles('unlimitedLinks', 'unlimitedLinksPrice')}
															/>
															<span className="slider round"></span>
														</label>
													</div>

												</div>
												}
												{/* tags */}
												{!disabledToggles.tags && <div className="col-md-3">
													<div className="form-group">
														<span className="d-block">{window.i18n('price_F1D2')}</span>
														<label className="custom-rounded-toggle">
															<input
																type="checkbox"
																id="tags"
																name="tags"
																value={newPackage.tags}
																disabled={disabledToggles.tags}
																onChange={(e) => this.updatePriceWithToggles('tags', 'tagsPrice')}
															/>
															<span className="slider round"></span>
														</label>
													</div>
												</div>
												}
												{!this.state.bulkLineTaggingList.length === 0 && <div className="col-md-3">
													<div className="form-group">
														<label htmlFor="bulkLinkTagging">{window.i18n('cr_blk_tg')}</label>
														<div >
															<Select
																isDisabled={this.state.bulkLineTaggingList.length === 0}
																value={this.state.bulkLineTaggingList.filter(selectedLink => this.state.newPackage.bulkLinkTagging === selectedLink.value)}
																onChange={(e) =>

																	this.updatePriceWithSelects(e.price, 'bulkLinkTaggingPrice', 'bulkLinkTagging', e.value)
																}
																placeholder={window.i18n('cr_blk_tg')}
																classNamePrefix="react-select"
																isSearchable={false}
																options={this.state.bulkLineTaggingList}
																isOptionDisabled={(option) => option.isdisabled}
																components={{
																	IndicatorSeparator: () => null
																}}
															/>
														</div>
														{/* <input
															className="form-control"
															type="number"
															id="bulkLinkTagging"
															name="bulkLinkTagging"
															value={newPackage.bulkLinkTagging}
															placeholder="Number of links"
															disabled={disabledToggles.bulkLinkTagging}
															min="0"
															onKeyDown={(e) => this.handleKeyDown(e)}
															onChange={this.onChange}
														/> */}
													</div>
													{infoNote.bulkLinkTagging && <small><strong className="color-theme">{window.i18n('cr_nt') + ": "}</strong> {infoNote.bulkLinkTagging}</small>}
												</div>
												}
												{/* link filtering */}
												{!disabledToggles.linkFiltering && <div className="col-md-3">
													<div className="form-group">
														<span className="d-block">{window.i18n('price_F1D4')}</span>
														<label className="custom-rounded-toggle">
															<input
																type="checkbox"
																id="linkFiltering"
																name="linkFiltering"
																value={newPackage.linkFiltering}
																placeholder={window.i18n('price_F1D4')}
																disabled={disabledToggles.linkFiltering}
																onChange={(e) => this.updatePriceWithToggles('linkFiltering', 'linkFilteringPrice')}
															// onChange={(e) => this.onToggle('linkFiltering', 'linkFilteringPrice')}
															/>
															<span className="slider round"></span>
														</label>
													</div>
												</div>}
											</div>
										</div>
										<hr />
										{/* custom links */}
										{(this.state.customLinkHistory.length != 0 || this.state.brandedLinkLimit.length != 0 || newPackage.brandedLink ||
											this.state.customBackHalfLimit.length != 0 || newPackage.customBackHalf) && <div>
												<div className="package-detail-row pt-2 pb-2">
													<h5 className="text-uppercase font-weight-bold">{window.i18n('global_customLinks')}</h5>
													<div className="row">
														{/* custom link history */}
														{this.state.customLinkHistory.length != 0 && <div className="col-md-3">
															<div className="form-group">
																<label htmlFor="customLinkHistory">{window.i18n('price_F2D3') + "(" + window.i18n('cr_id') + ")"} </label>
																<Select
																	isDisabled={this.state.customLinkHistory.length === 0}
																	value={this.state.customLinkHistory.filter(selectedLink => this.state.newPackage.customLinkHistory === selectedLink.value)}
																	onChange={(e) =>
																		// {
																		// 	this.setState({
																		// 		newPackage: { ...this.state.newPackage, customLinkHistory: e.value }
																		// 	})
																		this.updatePriceWithSelects(e.price, 'customLinkHistoryPrice', 'customLinkHistory', e.value)

																		// }
																	}
																	placeholder={window.i18n('price_F2D3')}
																	classNamePrefix="react-select"
																	isSearchable={false}
																	options={this.state.customLinkHistory}
																	isOptionDisabled={(option) => option.isdisabled}
																	components={{
																		IndicatorSeparator: () => null
																	}}
																/>
																{/* <input
															className="form-control inDays"
															type="number"
															id="customLinkHistory"
															name="customLinkHistory"
															value={newPackage.customLinkHistory}
															placeholder="Upto 0 days"
															disabled={disabledToggles.customLinkHistory}
															min="0"
															onKeyDown={(e) => this.handleKeyDown(e)}
															onChange={this.onChange}
														/> */}
															</div>
															{infoNote.customLinkHistory && <small><strong className="color-theme">{window.i18n('cr_nt') + ": "}</strong> {infoNote.customLinkHistory}</small>}
														</div>
														}
														{/* branded links */}
													</div>
													{/* branded links limit*/}
													<div className="row">
														<div className="col-md-3">
															{this.state.brandedLinkLimit.length != 0 && <div className="form-group">
																<span className="d-block">{window.i18n('global_brandLinks')}</span>
																<label className="custom-rounded-toggle">
																	<input
																		type="checkbox"
																		id="brandedLink"
																		name="brandedLink"
																		value={newPackage.brandedLink}
																		disabled={this.state.brandedLinkLimit.length === 0}
																		// disabled={disabledToggles.brandedLinks}

																		onChange={(e) => this.onToggle('brandedLink')
																			// onChange={(e) => this.updatePriceWithToggles('brandedLink', 'linkFilteringPrice')
																		}

																	/>
																	<span className="slider round"></span>
																</label>
															</div>
															}	</div>
														{/* branded links limit */}
														{
															newPackage.brandedLink &&
															<div className="col-md-3">
																<div className="form-group">
																	<label htmlFor="brandedLinks" className="require">{window.i18n('cr_br_ll')}</label>
																	<div >
																		<Select
																			isDisabled={this.state.brandedLinkLimit.length === 0}

																			value={this.state.brandedLinkLimit.filter(selectedLink => this.state.newPackage.brandedLinkLimit === selectedLink.value)}
																			onChange={(e) =>
																				// {
																				// this.setState({
																				// 	newPackage: { ...this.state.newPackage, brandedLinkLimit: e.value }
																				// })
																				this.updatePriceWithSelects(e.price, 'brandedLinkLimitPrice', 'brandedLinkLimit', e.value)

																				// }
																			}
																			placeholder={window.i18n('cr_br_ll')}
																			classNamePrefix="react-select"
																			isSearchable={false}
																			options={this.state.brandedLinkLimit}
																			isOptionDisabled={(option) => option.isdisabled}
																			components={{
																				IndicatorSeparator: () => null
																			}}
																		/>
																	</div>

																	{/* <input
																className="form-control"
																type="number"
																id="brandedLinks"
																name="brandedLinkLimit"
																value={newPackage.brandedLinkLimit}
																placeholder={window.i18n('cr_br_ll')}
																disabled={disabledToggles.brandedLinks}
																min="0"
																onKeyDown={(e) => this.handleKeyDown(e)}
																onChange={this.onChange}
															/> */}
																</div>
																{infoNote.brandedLinks && <small><strong className="color-theme">{window.i18n('cr_nt') + ": "}</strong> {infoNote.brandedLinks}</small>}
															</div>
														}

													</div>
													<div className="row">

														{/* custom back half */}
														<div className="col-md-3">
															{this.state.customBackHalfLimit.length != 0 && <div className="form-group">
																<span className="d-block">{window.i18n('static_x3B1VL')}</span>
																<label className="custom-rounded-toggle">
																	<input
																		type="checkbox"
																		id="customBackHalf"
																		name="customBackHalf"
																		value={newPackage.customBackHalf}
																		// disabled={disabledToggles.customBackHalf}
																		disabled={this.state.customBackHalfLimit.length === 0}

																		onChange={(e) => this.onToggle('customBackHalf')}
																	/>
																	<span className="slider round"></span>
																</label>
															</div>
															}
														</div>
														{/* custom back half limit */}
														{
															newPackage.customBackHalf &&
															<div className="col-md-3">
																<div className="form-group">
																	<label htmlFor="customBackHalf" className="require">{window.i18n('cr_bkhf_lm')}</label>

																	<Select
																		isDisabled={this.state.customBackHalfLimit.length === 0 || this.state.newPackage.unlimitedCustomBackHalf === true}

																		value={this.state.customBackHalfLimit.filter(selectedLink => this.state.newPackage.customBackHalfLimit === selectedLink.value)}
																		onChange={(e) =>
																			//  {
																			// this.setState({
																			// 	newPackage: { ...this.state.newPackage, customBackHalfLimit: e.value }
																			// })
																			this.updatePriceWithSelects(e.price, 'customBackHalfLimitPrice', 'customBackHalfLimit', e.value)
																			// }
																		}
																		placeholder={window.i18n('cr_bkhf_lm')}
																		classNamePrefix="react-select"
																		isSearchable={false}
																		options={this.state.customBackHalfLimit}
																		isOptionDisabled={(option) => option.isdisabled}
																		components={{
																			IndicatorSeparator: () => null
																		}}
																	/>

																	{/* <input
																className="form-control"
																type="number"
																id="customBackHalfLimit"
																name="customBackHalfLimit"
																value={newPackage.customBackHalfLimit}
																placeholder="Custom Back Half Limit"
																disabled={disabledToggles.customBackHalf}
																min="0"
																onKeyDown={(e) => this.handleKeyDown(e)}
																onChange={this.onChange}
															/> */}
																</div>
																{infoNote.customBackHalf && <small><strong className="color-theme">{window.i18n('cr_nt') + ": "}</strong> {infoNote.customBackHalf}</small>}
															</div>
														}
														{/* unlimited custom back half */}
														{
															newPackage.customBackHalf &&
															<div className="col-md-3">
																<div className="form-group">
																	<span className="d-block">{window.i18n('cr_bkhf_un')}</span>
																	<label className="custom-rounded-toggle">
																		<input
																			type="checkbox"
																			id="unlimitedCustomBackHalf"
																			name="unlimitedCustomBackHalf"
																			value={newPackage.unlimitedCustomBackHalf}
																			disabled={disabledToggles.unlimitedCustomBackHalf}

																			onChange={(e) => {
																				// this.setState({ newPackage: {...newPackage, unlimitedCustomBackHalf: !this.state.newPackage.unlimitedCustomBackHalf } },()=>
																				this.updatePriceWithToggles('unlimitedCustomBackHalf', 'unlimitedCustomBackHalfPrice')

																				// )
																			}}
																		/>
																		<span className="slider round"></span>
																	</label>
																</div>
															</div>
														}
													</div>
												</div>
												<hr />
											</div>}
										{/* branding */}
										{this.state.customDomains.length != 0 && <div className="package-detail-row pt-2 pb-2">
											<h5 className="text-uppercase font-weight-bold">{window.i18n('price_F3H')}</h5>
											<div className="row">
												{/* custom domains */}
												<div className="col-md-3">
													<div className="form-group">
														<span className="d-block">{window.i18n('global_customDom')}</span>
														<label className="custom-rounded-toggle">
															<input
																type="checkbox"
																id="customDoms"
																name="customDoms"
																value={newPackage.customDoms}
																// disabled={disabledToggles.customDomains}
																disabled={this.state.customDomains.length === 0}
																// onChange={(e) => this.updatePriceWithToggles('customDoms')}

																onChange={(e) => this.onToggle('customDoms')}
															/>
															<span className="slider round"></span>
														</label>
													</div>

												</div>
												{/* custom domains limit */}
												{
													newPackage.customDoms &&
													<div className="col-md-3">
														<div className="form-group">
															<label htmlFor="customDomains" className="require">{window.i18n('cr_dm_lm')}</label>
															<Select
																isDisabled={this.state.customDomains.length === 0}

																value={this.state.customDomains.filter(selectedLink => this.state.newPackage.customDomains === selectedLink.value)}
																onChange={(e) =>
																	// {
																	// this.setState({
																	// 	newPackage: { ...this.state.newPackage, customDomains: e.value }
																	// })
																	this.updatePriceWithSelects(e.price, 'customDomainsPrice', 'customDomains', e.value)

																	// }
																}
																placeholder={window.i18n('cr_dm_lm')}
																classNamePrefix="react-select"
																isSearchable={false}
																options={this.state.customDomains}
																isOptionDisabled={(option) => option.isdisabled}
																components={{
																	IndicatorSeparator: () => null
																}}
															/>
															{/* <input
																className="form-control"
																type="number"
																id="customDomains"
																name="customDomains"
																value={newPackage.customDomains}
																placeholder={window.i18n('cr-dm-lm')}
																disabled={disabledToggles.customDomains}
																min="0"
																onKeyDown={(e) => this.handleKeyDown(e)}
																onChange={this.onChange}
															/> */}
														</div>
														{infoNote.customDomains && <small><strong className="color-theme">{window.i18n('cr_nt') + ": "}</strong> {infoNote.customDomains}</small>}
													</div>
												}

											</div>
										</div>
										}

										{/* Default Domains */}
										{!disabledToggles.defaultDomains && <div className="">
											<div className="form-group">
												<span className="d-block">{window.i18n('global_defaultDomains')}</span>
												<label className="custom-rounded-toggle">
													<input
														type="checkbox"
														id="defaultDomains"
														name="defaultDomains"
														value={newPackage.defaultDomains}
														disabled={disabledToggles.defaultDomains}
														onChange={(e) => this.updatePriceWithToggles('defaultDomains', 'defaultDomains')}
													/>
													<span className="slider round"></span>
												</label>
											</div>
										</div>
										}
										{this.state.customDomains.length != 0 && <hr />}
										{/* campaign management */}
										{(!disabledToggles.campaigns || !disabledToggles.campaignsTracking || !disabledToggles.campaignDataExport || this.state.socialPlatforms.length != 0) && <div>
											<div className="package-detail-row pt-2 pb-2">
												<h5 className="text-uppercase font-weight-bold">{window.i18n('price_F4H')}</h5>
												<div className="row">
													{/* campaigns */}
													{!disabledToggles.campaigns && <div className="col-md-3">
														<div className="form-group">
															<span className="d-block">{window.i18n('price_F4D1')}</span>
															<label className="custom-rounded-toggle">
																<input
																	type="checkbox"
																	id="campaigns"
																	name="campaigns"
																	value={newPackage.campaigns}
																	disabled={disabledToggles.campaigns}
																	// onChange={(e) => this.onToggle('campaigns', 'campaignsPrice')}
																	onChange={(e) => this.updatePriceWithToggles('campaigns', 'campaignsPrice')}

																/>
																<span className="slider round"></span>
															</label>
														</div>
													</div>
													}	{/* campaigns tracking */}
													{!disabledToggles.campaignsTracking &&
														<div className="col-md-3">
															<div className="form-group">
																<span className="d-block">{window.i18n('cr_cmp_tr')}</span>
																<label className="custom-rounded-toggle">
																	<input
																		type="checkbox"
																		id="campaignsTracking"
																		name="campaignsTracking"
																		value={newPackage.campaignsTracking}
																		disabled={disabledToggles.campaignsTracking}
																		// onChange={(e) => this.onToggle('campaignsTracking', 'campaignsTrackingPrice')}
																		onChange={(e) => this.updatePriceWithToggles('campaignsTracking', 'campaignsTrackingPrice')}

																	/>
																	<span className="slider round"></span>
																</label>
															</div>
														</div>
													}
													{/* export of campaign data */}
													<div className="col-md-3">
														{!disabledToggles.campaignDataExport && <div className="form-group">
															<span className="d-block">{window.i18n('price_F4D3')}</span>
															<label className="custom-rounded-toggle">
																<input
																	type="checkbox"
																	id="campaignDataExport"
																	name="campaignDataExport"
																	value={newPackage.campaignDataExport}
																	disabled={disabledToggles.campaignDataExport}
																	// onChange={(e) => this.onToggle('campaignDataExport', 'campaignDataExportPrice')}
																	onChange={(e) => this.updatePriceWithToggles('campaignDataExport', 'campaignDataExportPrice')}

																/>
																<span className="slider round"></span>
															</label>
														</div>
														}	</div>
												</div>
												<div className="row">
													{/* social platforms */}
													{this.state.socialPlatforms.length != 0 && <div className="col-md-3">
														<div className="form-group">
															<span className="d-block">{window.i18n('cr_sp')}</span>
															<label className="custom-rounded-toggle">
																<input
																	type="checkbox"
																	id="socialPosting"
																	name="socialPosting"
																	value={newPackage.socialPosting}
																	// disabled={disabledToggles.socialPlatforms}
																	disabled={this.state.socialPlatforms.length === 0}

																	onChange={(e) => this.onToggle('socialPosting')}

																/>
																<span className="slider round"></span>
															</label>
														</div>
													</div>}
													{/* social platforms limit */}
													{
														newPackage.socialPosting &&
														<div className="col-md-3">
															<div className="form-group">
																<label htmlFor="socialPlatforms" className="require">{window.i18n('cr_sp_lm')}</label>
																<Select
																	isDisabled={this.state.socialPlatforms.length === 0}

																	value={this.state.socialPlatforms.filter(selectedLink => this.state.newPackage.socialPlatforms === selectedLink.value)}
																	onChange={(e) =>
																		// {
																		// this.setState({
																		// 	newPackage: { ...this.state.newPackage, socialPlatforms: e.value }
																		// })
																		this.updatePriceWithSelects(e.price, 'socialPlatformsPrice', 'socialPlatforms', e.value)

																		// }
																	}
																	placeholder={window.i18n('cr_sp_lm')}
																	classNamePrefix="react-select"
																	isSearchable={false}
																	options={this.state.socialPlatforms}
																	isOptionDisabled={(option) => option.isdisabled}
																	components={{
																		IndicatorSeparator: () => null
																	}}
																/>
																{/* <input
																className="form-control"
																type="number"
																id="socialPlatforms"
																name="socialPlatforms"
																value={newPackage.socialPlatforms}
																placeholder={window.i18n('cr_sp_lm')}
																disabled={disabledToggles.socialPlatforms}
																min="0"
																onKeyDown={(e) => this.handleKeyDown(e)}
																onChange={this.onChange}
															/> */}
															</div>
															{infoNote.socialPlatforms && <small><strong className="color-theme">{window.i18n('cr_nt') + ": "}</strong> {infoNote.socialPlatforms}</small>}
														</div>
													}
												</div>
											</div>
											<hr />
										</div>}


										{/* data analytics */}
										<div className="package-detail-row pt-2 pb-2">
											<h5 className="text-uppercase font-weight-bold">{window.i18n('sol_dataAnaly')}</h5>
											<div className="row">
												{/* link history */}
												<div className="col-md-3">
													<div className="form-group">
														<label htmlFor="linkHistory" className="require">{window.i18n('price_F5D1')} (In Days)</label>
														<Select
															isDisabled={this.state.linkHistory.length === 0}

															value={this.state.linkHistory.filter(selectedLink => this.state.newPackage.linkHistory === selectedLink.value)}
															onChange={(e) =>
																// {
																// this.setState({
																// 	newPackage: { ...this.state.newPackage, linkHistory: e.value }
																// })
																this.updatePriceWithSelects(e.price, 'linkHistoryPrice', 'linkHistory', e.value)

																// }
															}
															placeholder="Upto 0 days"
															classNamePrefix="react-select"
															isSearchable={false}
															options={this.state.linkHistory}
															isOptionDisabled={(option) => option.isdisabled}
															components={{
																IndicatorSeparator: () => null
															}}
														/>
														{/* <input
															className="form-control inDays"
															type="number"
															id="linkHistory"
															name="linkHistory"
															value={newPackage.linkHistory}
															placeholder="Upto 0 days"
															disabled={disabledToggles.linkHistory}
															min="0"
															onKeyDown={(e) => this.handleKeyDown(e)}
															onChange={this.onChange}
														/> */}
													</div>
													{infoNote.linkHistory && <small><strong className="color-theme">{window.i18n('cr_nt') + ": "}</strong> {infoNote.linkHistory}</small>}
												</div>
												{/* dashboard view */}
												{!disabledToggles.dashboardView && <div className="col-md-3">
													<div className="form-group">
														<span className="d-block">{window.i18n('price_F5D2')}</span>
														<label className="custom-rounded-toggle">
															<input
																type="checkbox"
																id="dashboardView"
																name="dashboardView"
																value={newPackage.dashboardView}
																disabled={disabledToggles.dashboardView}
																onChange={(e) => this.onToggle('dashboardView', 'dashboardViewPrice')}
															/>
															<span className="slider round"></span>
														</label>
													</div>
												</div>}
												{/* analytics data export */}
												{!disabledToggles.analyticsDataExport && <div className="col-md-3">
													<div className="form-group">
														<span className="d-block">{window.i18n('cr_an_de')}</span>
														<label className="custom-rounded-toggle">
															<input
																type="checkbox"
																id="analyticsDataExport"
																name="analyticsDataExport"
																value={newPackage.analyticsDataExport}
																disabled={disabledToggles.analyticsDataExport}
																onChange={(e) => this.onToggle('analyticsDataExport', 'analyticsDataExportPrice')}
															/>
															<span className="slider round"></span>
														</label>
													</div>
												</div>}
											</div>
										</div>
										<hr />
										{/* data delivery */}
										{<div className="package-detail-row pt-2 pb-2">
											<h5 className="text-uppercase font-weight-bold">{window.i18n('cr_dd')}</h5>
											<div className="row">
												{/* api call rate */}
												<div className="col-md-3">
													<div className="form-group">
														<label className="require" htmlFor="apis">API {window.i18n('kw_limit')} ({window.i18n('kw_pm')})</label>

														<Select
															isDisabled={this.state.api.length === 0}

															value={this.state.api.filter(selectedLink => this.state.newPackage.api === selectedLink.value)}
															onChange={(e) =>

																this.updatePriceWithSelects(e.price, 'apiPrice', 'api', e.value)

															}
															placeholder={`API ${window.i18n('kw_limit')}`}
															classNamePrefix="react-select"
															isSearchable={false}
															options={this.state.api}
															isOptionDisabled={(option) => option.isdisabled}
															components={{
																IndicatorSeparator: () => null
															}}
														/>

													</div>
													{infoNote.integrations && <small><strong className="color-theme">{window.i18n('cr_nt') + ": "}</strong> {infoNote.integrations}</small>}
												</div>
												{/* integrations */}
												{

													this.state.integrations.length != 0 &&
													<div className="col-md-3">
														<div className="form-group">
															<label htmlFor="integrations">{window.i18n('price_F6D2')}</label>
															<Select
																isDisabled={this.state.integrations.length === 0}
																value={this.state.integrations.filter(selectedLink => this.state.newPackage.integrations === selectedLink.value)}
																onChange={(e) =>
																	this.updatePriceWithSelects(e.price, 'integrationsPrice', 'integrations', e.value)
																}
																placeholder={window.i18n('price_F6D2')}
																classNamePrefix="react-select"
																isSearchable={false}
																options={this.state.integrations}
																isOptionDisabled={(option) => option.isdisabled}
																components={{
																	IndicatorSeparator: () => null
																}}
															/>
														</div>
														{infoNote.integrations && <small><strong className="color-theme">{window.i18n('cr_nt') + ": "}</strong> {infoNote.integrations}</small>}
													</div>
												}
											</div>
										</div>
										}
										<hr />
										{/* QR Codes */}
										{!disabledToggles.qrCode && <div className="package-detail-row pt-2 pb-2">
											<h5 className="text-uppercase font-weight-bold">{window.i18n('price_F8H')}</h5>
											<div className="row">
												{/* generate QR Codes */}
												<div className="col-md-3">
													<div className="form-group">
														<span className="d-block">{window.i18n('cr_gqr')}</span>
														<label className="custom-rounded-toggle">
															<input
																type="checkbox"
																id="qrCodeGenerate"
																name="qrCodeGenerate"
																value={newPackage.qrCodeGenerate}
																disabled={disabledToggles.qrCode}
																onChange={(e) => this.onToggle('qrCodeGenerate', 'qrCodePrice')}
															/>
															<span className="slider round"></span>
														</label>
													</div>
												</div>
											</div>
										</div>
										}
										<div className="package-detail-row pt-2 pb-2">
											<div className="row">
												<div className="col-12">
													<div className="mb-1 form-row">
														<button
															type="button"
															disabled={this.state.smallLoader}
															onClick={this.submit}
															className="btn btn-pink mr-3 mb-3">
															{window.i18n('cont_submit')} {smallLoader && <SmallLoaderComponent size={13} />}
														</button>
														{/* <button type="button" className="btn btn-pink-outline mr-3 mb-3" onClick={this.onCancel}>{window.i18n('dellAcc_cancel')}</button> */}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
				}
			</>
		)
	}
}

const mapDispatchToProps = {
}

const mapStateToProps = state => ({
	errors: state.errors
})

export default connect(mapStateToProps, mapDispatchToProps)(CreatePackage)