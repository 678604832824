import { DOWNLOAD_INVOICE, BEFORE_DOWNLOAD_INVOICE, EMPTY_ERRORS, GET_ERRORS } from '../../../../redux/types';
import { ENV } from '../../../../config/config';

export const emptyError = () => {
    return {
        type: EMPTY_ERRORS
    }
}

export const beforeDownloadInvoice = () => {
    return {
        type: BEFORE_DOWNLOAD_INVOICE
    }
}

export const downloadInvoice = (body, lang) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'invoice/download?lang=' + lang, {

    // fetch('http://localhost:4000/invoice/download?lang=' + lang, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: DOWNLOAD_INVOICE,
                payload: data
            });
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}
