import { ADD_CONTACT, ADD_QUOTE, BEFORE_QUOTE, BEFORE_CONTACT, EMPTY_ERRORS, GET_ERRORS } from '../../../redux/types';
import { ENV } from '../../../config/config';

export const emptyError = () => {
    return {
        type: EMPTY_ERRORS
    }
}

export const beforeContact = () => {
    return {
        type: BEFORE_CONTACT
    }
}

export const beforeQuote = () => {
    return {
        type: BEFORE_QUOTE
    }
}

export const addContact = (language, body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'contact-us/create?lang=' + language,  {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: ADD_CONTACT,
                payload: data
            });
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}


export const addQuote = (language, body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'get-quote/create?lang=' + language , {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: ADD_QUOTE,
                payload: data
            });
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}
