import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
// import Swal from 'sweetalert2';
import { scrollTop } from '../../shared/common-functions';
import CircleLoader from 'react-spinners/CircleLoader';
import swal from 'sweetalert';
import { css } from '@emotion/core';
import { Link } from "react-router-dom";
import pageBannerImage from '../../images/features.png';
import MainLoader from '../../shared/loaders/MainLoader'

const override = css`
	display: block;
	position:absolute;
	left:50%;
	top:50%;
	margin:-60px 0 0 -60px;
	border-color: blue;
`;

class ResourcePage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			slug: "",
			pageData: "",
			title: "",
			mainLoader: true,
			description: "",
			language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
			logOutRedirect: false
		}
	}

	componentWillMount = () => {
		scrollTop();
		this.setState({ slug: this.props.match.params.param });
		this.getData(this.props.match.params.param);
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.param !== prevProps.match.params.param) {
			scrollTop();
			this.setState({ slug: this.props.match.params.param, mainLoader: true, title: "", description: "" }, () => {
				this.getData(this.props.match.params.param);
			});
			// call the fetch function again
		}
	}

	// componentDidCatch () {
	//     scrollTop();
	//     this.setState({ slug: this.props.match.params.param });
	//     this.getData(this.props.match.params.param);
	// }

	getData = (param) => {
		const { language } = this.state;
		fetch(window.APIURL + "cms-pages/get-by-slug?slug=" + param + '&lang=' + language + "&accessToken=" + localStorage.loginToken
			, {
				method: "get",
				headers: {
					"Content-Type": "application/json"
				}
			})
			.then(res => {
				return res.json();
			})
			.then(result => {

				if (result.sessionExpired) {
					this.setState({ logOutRedirect: true })
					return;
				}
				else if (result.status === true) {
					if (result.result.isBlocked === false) {
						this.setState({ title: result.result.title, description: result.result.description, mainLoader: false });
						this.refs.description.innerHTML = result.result.description;
					} else {
						this.setState({ mainLoader: false })
					}
				} else if (result.status === false) {
					// swal(window.i18n('alert_a'), result.message, 'error');
					this.props.history.push('/error404');
					this.setState({ mainLoader: false })
				} else {
					swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
				}
			});
	}

	render() {
		let { logOutRedirect } = this.state
		if (logOutRedirect) {
			// return <Redirect to="/logout" />;
			window.location.href = "/web/logout-and-clear-session"
		}
		return (
			<React.Fragment>
				{
					this.state.mainLoader === true ?
					<MainLoader />
						:
						this.state.title && this.state.description && this.state.mainLoader === false ?

							<div className="main-content pl-0">
								{/* <div className="page-banner">
									<div className="container h-100 d-flex justify-content-center align-items-center">
										<div className="page-title-block py-5 text-white">
											{
												this.state.title ?
													<h1>{this.state.title}</h1>
													: 'No title available'
											}
										</div>
									</div>
								</div> */}
								<div className="page-banner">
									<div className="container text-white">
										<div className="row align-items-center">
											<div className="col-md-6">
												<div className="page-banner-text">
													<h1>{this.state.title}</h1>
													<p>Neque porro quisquam esqui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.</p>
												</div>
											</div>
											<div className="col-md-6 d-none d-md-block">
												<div className="page-banner-image">
													<img className="img-fluid" src={pageBannerImage} alt="" />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="cms-content position-relative content-section">
									<div className="page-content-bg">
										<img className="img-fluid" src={require('../../images/page-content-bg.png')} alt="section bg Image" />
									</div>
									<div className="container">
										{
											this.state.description ?
												<div className="text" ref="description"></div>
												:
												'No content available'
										}
									</div>
								</div>
							</div>
							:
							<div className="coming-soon-block">
								<div className="container h-100">
									<div className="row h-100 align-items-center">
										<div className="col-md-6 col-12">
											<div className="coming-soon-image">
												<img className="img-fluid" src={require("../../images/not-found.svg")} />
											</div>
										</div>
										<div className="col-md-6 col-12">
											<div className="coming-soon-text-block px-0 px-sm-2 px-md-3">
												<h2>Coming Soon</h2>
												<p>We are working on this.</p>
												<Link className="btn btn-pink-outline" to="/">Go Back Home</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
				}
			</React.Fragment>
		);
	}
}

export default ResourcePage;