import React, { Component } from 'react';
import { Link } from "react-router-dom";

class ErrorPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <React.Fragment>
                <div id="notfound" className="user-forms-outer">
                    <div className="notfound px-3 py-4">
                        <div className="error-page-image mb-3 mb-md-5">
                            <img className="img-fluid" src={require("../../images/404-img.png")} alt="404 Image" />
                        </div>
                        <h2>404 - {window.i18n('err_t1')}</h2>
                        <p>{window.i18n('err_t2')}</p>
                        <Link to="/" className="btn btn-pink">{window.i18n('err_t3')}</Link>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ErrorPage;