import React, { Component } from "react";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class DeleteAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
      userId: "",
      selection: "",
      reason: "",
      confirmation: "",
      displayPre: true,
      redirect: false,
      logOutRedirect: false
    };
  }
  componentWillMount = () => {
    this.setState({ userId: this.props.userId });
  };
  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  cancelDeletion = () => {
    this.setState({
      displayPre: !this.state.displayPre,
      selection: "",
      reason: "",
      confirmation: "",
    });
  };
  preReasonSubmit = e => {
    e.preventDefault();
    if (this.state.selection) {
      if (this.state.selection === "other" && this.state.reason === "") {
        swal(window.i18n('alert_a'), window.i18n('alert_t'), 'error')
      } else {
        this.setState({ displayPre: !this.state.displayPre });
      }
    } else {
      swal(window.i18n('alert_a'), window.i18n('alert_t'), 'error')
    }
  };
  deleteSubmission = e => {
    e.preventDefault();
    if (this.state.confirmation.toLowerCase() === "DELETE ACCOUNT".toLowerCase()) {
      fetch(window.APIURL + "accounts/member/delete-accounts?lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          userId: this.state.userId,
          selection: this.state.selection,
          reason: this.state.reason
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 400) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {

          if (result.sessionExpired) {
            this.setState({
              logOutRedirect: true
            })
            return;
          }


          if (result.status === true) {
            this.props.logoutFun();
          } else if (result.status === false) {
            if (result.redirection === true) {
              if (result.special === true) {
                let user = JSON.parse(localStorage.getItem("LoginSession")).data._id
                swal('Info', result.message, 'info');
                this.notification(user);
              } else {
                this.setState({ redirect: true });
                swal(window.i18n('alert_a'), result.message, 'error');
              }
            } else {
              this.setState({ redirect: true });
              swal(window.i18n('alert_a'), result.message, 'error');
            }
          } else {
            swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
          }
        });
    } else {
      swal(window.i18n('alert_a'), window.i18n('alert_u'), 'error')
    }
  };


  notification = (user) => {
    fetch(window.APIURL + "accounts/member/switch-bool?userId=" + user + "&lang=" + this.state.language, {
      method: "get",
      headers: { "Content-Type": "application/json" }
    })
      .then(response => response.json())
      .then(result => {
        if (result.status === true) {
          this.setState({ logOutRedirect: true });
        }
      });
  }


  render() {
    const { redirect } = this.state;
    if (redirect) {
      window.location.href = "/web/logout-and-clear-session"
    }

    const { logOutRedirect } = this.state;
    if (logOutRedirect) {
      // return <Redirect to="/logout" />;
      window.location.href = "/web/logout-and-clear-session"
    }
    return (
      <React.Fragment>
        <div
          className="action-sheet--wrapper-MAIN data-state"
          style={{ display: "none" }}
          id="delete_user"
        >
          <div
            className="action-sheet--MAIN"
            data-selected-sheet="deactivate"
            style={{
              animation: "600ms ease 0ms 1 normal forwards running open-sheet"
            }}
          >
            <div className="action-sheet--header">
              <a>
                {" "}
                <span className="back_btn-icon" alt="back_btn-icon">
                  <FontAwesomeIcon icon="chevron-left" />
                </span>{" "}
              </a>
              <h4 className="action-sheet--header-text">{window.i18n('dellAcc_dellUser')}</h4>
              <span>
                <a>
                  <span className="close-icon" alt="close-icon" />
                </a>
              </span>
            </div>
            <div
              className={
                this.state.displayPre
                  ? "action-sheet--content-wrapper"
                  : "action-sheet--content-wrapper d-none"
              }
            >
              <form>
                <h2 className="deactivate-account--header link-no-dark td_ul">
                  {window.i18n('dellAcc_weAreSor')}
                </h2>
                <span className="action-sheet--help-text">
                  {window.i18n('dellAcc_beforeYouDel')}
                </span>
                <p className="action-sheet--text">
                  {window.i18n('dellAcc_deletingWill')}
                </p>
                <p className="action-sheet--text">
                  <strong>
                    {window.i18n('dellAcc_delYourAcc')}
                  </strong>
                </p>
                <p className="action-sheet--text">
                  {window.i18n('dellAcc_delYourAccWill')}
                </p>
                <p className="action-sheet--text">
                  {" "}
                  <strong>
                    {window.i18n('dellAcc_areYouSure')}
                  </strong>
                </p>
                <div>
                  <div className="radio-button--ALTERNATE">
                    <label className="radio-button--item false cur-poi" htmlFor="account">
                      <span className="radio-button--wrapper">
                        <input
                          type="radio"
                          className="radio-button--input"
                          name="selection"
                          id="account"
                          onChange={this.onChange}
                          value="I have another FLO.ink account"
                        />
                        <span className="radio-button--button"> </span>
                        <span className="radio-button--label">
                          {window.i18n('dellAcc_iHaveAnother')}
                        </span>
                      </span>
                    </label>
                    <label className="radio-button--item false cur-poi" htmlFor="privacy">
                      <span className="radio-button--wrapper">
                        <input
                          type="radio"
                          className="radio-button--input"
                          name="selection"
                          id="privacy"
                          onChange={this.onChange}
                          value={window.i18n('dellAcc_iHavePrivacy')}
                        />
                        <span className="radio-button--button"> </span>
                        <span className="radio-button--label">
                          {window.i18n('dellAcc_iHavePrivacy')}
                        </span>
                      </span>
                    </label>
                    <label className="radio-button--item false cur-poi" htmlFor="useful">
                      <span className="radio-button--wrapper">
                        <input
                          type="radio"
                          className="radio-button--input"
                          name="selection"
                          id="useful"
                          onChange={this.onChange}
                          value={window.i18n('dellAcc_iNoLonger')}
                        />
                        <span className="radio-button--button"> </span>
                        <span className="radio-button--label">
                          {window.i18n('dellAcc_iNoLonger')}
                        </span>
                      </span>
                    </label>
                    <label className="radio-button--item false cur-poi" htmlFor="other">
                      <span className="radio-button--wrapper">
                        <input
                          type="radio"
                          className="radio-button--input"
                          name="selection"
                          id="other"
                          onChange={this.onChange}
                          value={window.i18n('dellAcc_other')}
                        />
                        <span className="radio-button--button"> </span>
                        <span className="radio-button--label">{window.i18n('dellAcc_other')}</span>
                      </span>
                    </label>
                  </div>
                </div>
                <div className="input-field--wrapper">
                  <div className="input-field--MAIN   ">
                    <label className="input-field--label">{window.i18n('dellAcc_specifyReason')}</label>
                    <div className="input-field--input-wrapper">
                      <input
                        className="input-field--input"
                        autoComplete="false"
                        tabIndex="0"
                        onChange={this.onChange}
                        value={this.state.reason}
                        name="reason"
                      />
                    </div>
                  </div>
                </div>
                <div className="action-sheet--button-wrapper">
                  <button
                    className="button--SOLID-SECONDARY"
                    type="button"
                    tabIndex="0"
                    id="preReasonSubmit"
                    onClick={this.preReasonSubmit}
                  >
                    {window.i18n('global_Save')}
                  </button>
                </div>
              </form>
            </div>
            <div
              className={
                this.state.displayPre
                  ? "action-sheet--content-wrapper d-none"
                  : "action-sheet--content-wrapper"
              }
            >
              <form onSubmit={this.deleteSubmission}>
                <p className="action-sheet--text">{window.i18n('dellAcc_toDelYouAcc')}</p>
                <ol className="action-sheet--list">
                  <li className="action-sheet--text action-sheet--list-item">
                    {window.i18n('dellAcc_recogThere')}
                  </li>
                  <li className="action-sheet--text action-sheet--list-item">
                    {window.i18n('dellAcc_typeDelAcc')}
                  </li>
                  <li className="action-sheet--text action-sheet--list-item">
                    {window.i18n('dellAcc_clickConf')}
                  </li>
                </ol>
                <div className="input-field--wrapper">
                  <div className="input-field--MAIN   ">
                    <label className="input-field--label">{window.i18n('dellAcc_confir')}</label>
                    <div className="input-field--input-wrapper">
                      <input
                        placeholder={window.i18n('dellAcc_TypeDel')}
                        className="input-field--input"
                        autoComplete="false"
                        tabIndex="0"
                        value={this.state.confirmation}
                        id="confirmation"
                        name="confirmation"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
                <p className="action-sheet--text">
                  {window.i18n('dellAcc_remThis')}
                </p>
                <div className="action-sheet--button-wrapper">
                  <button
                    className="button--SOLID-SECONDARY"
                    type="submit"
                    tabIndex="0"
                    id="confirmationButton"
                    name="confirmationButton"
                  >
                    {window.i18n('dellAcc_confAdel')}
                  </button>
                  <button
                    className="button--SOLID-SECONDARY"
                    type="button"
                    tabIndex="0"
                    id="cancelDeletion"
                    onClick={this.cancelDeletion}
                  >
                    {window.i18n('dellAcc_cancel')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DeleteAccount;
