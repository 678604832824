require('dotenv').config();

module.exports = {
  ENV: {
    //  Local url
    // url: 'http://localhost:4000/',
    // server_ip_address: "127.0.0.1",

    // LIVE url
    // url: 'https://nedlink.arhamsoft.org/',
    // server_ip_address: "138.201.78.234",

    // Dev url
    // url: 'http://www.ned.link:8000/',
    // server_ip_address: "138.201.78.234",

    // url: 'http://34.222.59.151:8001/',
    // server_ip_address: '34.222.59.151',

    url: 'https://new-flo-ink.arhamsoft.org/',
    server_ip_address: "3.129.171.117",

    TokSecCrpt: 'cAmFiLknEopopiInKFoL',
    // stripePublishableKey: process.env.STRIPE_PUBLISHABLE_KEY
    stripePublishableKey: 'pk_test_51LpWRPGHhLsd63tRicfym3XLOqqLzLxrOr8BexFIOcGWH1BvCw1iN9nDHZtdPHaOFX2wYQpkjhP71DDtg8zRkbnR00CTKe2FMI'

  }
}