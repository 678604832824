import React, { Component } from 'react';
// import Swal from 'sweetalert2';
import { Redirect } from 'react-router-dom'
import swal from 'sweetalert';
import moment from 'moment';
import { connect } from 'react-redux';
import { downloadInvoice, beforeDownloadInvoice } from './action/invoice.action';
import { scrollTop } from '../../../shared/common-functions';
import { Table } from 'react-bootstrap';
// import { getSiteSetting, beforeSiteSettings } from '../../../shared/site-settings/site-settings.action';
import MainLoader from '../../../shared/loaders/MainLoader'
import { countries } from '../../../shared/countries';
import { Link } from 'react-router-dom'





class Invoice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
            invoiceDetail: {},
            siteDetail: {},
            userDetail: {},
            mainLoader: true,
            testing: true,
            discount: 0,
            reseller: 'N/A',
            discountAmount: 0,
            taxAmount: 0,
            dataOfInvoice: '',
            mainLoader: true,
            logOutRedirect: false
        }
    }

    componentDidMount() {
        scrollTop();

        let paymentId = this.props.match.params.paymentId;
        this.getPaymentDetails(paymentId);
        // this.props.getSiteSetting();
    }

    getPaymentDetails = (id) => {
        fetch(window.APIURL + 'invoice/' + id + "?accessToken=" + localStorage.getItem('loginToken'), {
            method: 'get',
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else if (res.status === 400) {
                return res.json();
            } else if (res.status === 422) {
                return res.json();
            } else {
                return res.json();
            }
        }).then((result) => {
            if (result.sessionExpired) {
                this.setState({
                    logOutRedirect: true
                })
                return;
            }
            if (result.status === true) {
                let data = result.invoice;
                let data1 = result.site;
                let data3 = result.user;
                let data4 = result.discount;
                let data5 = result.reseller;
                let data6 = result.taxAmount;
                let data7 = result.discountAmount;

                this.setState({ dataOfInvoice: data.createdAt, invoiceDetail: data, siteDetail: data1, userDetail: data3, discount: data4, reseller: data5, taxAmount: data6, discountAmount: data7 }, () => {
                    this.setState({ mainLoader: false })
                });
            } else if (result.status === false) {
                swal(window.i18n('alert_a'), result.message, 'error');
            } else {
                swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
            }
        });
    }

    downloadInvoice = () => {
        let { invoiceDetail, siteDetail, language, userDetail } = this.state;
        this.setState({ mainLoader: true }, () => {
            this.props.downloadInvoice({ invoiceDetail, siteDetail, userDetail }, language);
        });
    }

    componentDidUpdate() {
        if (this.props.invoice.downloadInvoiceAuth) {
            let result = this.props.invoice.downloadInvoiceRes;
            this.props.beforeDownloadInvoice();
            this.setState({ mainLoader: false }, () => {
                window.open(result.filePath, '_blank');
            })
        }
    }

    getCountryCode(country) {

        let country2 = countries.filter(eachCountry => eachCountry.substring(0, eachCountry.indexOf(" (")) === country)
        if (country2 != null && country2 != undefined && country2 !== '' && country2.length != 0)
            return country2[0].toString().substring(country2[0].indexOf("(") + 1, country2[0].indexOf(")"))
        else
            return ''
    }

    render() {
        const { invoiceDetail, siteDetail, mainLoader, dataOfInvoice, userDetail } = this.state;
        let settings;
        let typeStr;
        let str;
        let str2
        let endDate;

        if (this.state.invoiceDetail.type === "Monthly") {
            str = window.i18n('inv_mp')
            str2 = window.i18n('cr_pkg_mt')
            endDate = new Date(this.state.dataOfInvoice);
            endDate.setMonth(endDate.getMonth() + 1)
        }
        else if (this.state.invoiceDetail.type === "Annually") {
            str = window.i18n('inv_an_pc')
            str2 = window.i18n('cr_pkg_an')
            endDate = new Date(this.state.dataOfInvoice);
            endDate.setMonth(endDate.getMonth() + 12)

        }


        let priceToDeduct = !this.state.invoiceDetail.voucher
            ? 0
            : this.state.invoiceDetail.voucher.discount
        let discountedPrice2 = this.state.invoiceDetail.priceAfterDiscount - (this.state.invoiceDetail.priceAfterDiscount * (priceToDeduct / 100))
        let discountedPrice = Math.round((discountedPrice2 + Number.EPSILON) * 100) / 100

        let taxToDisplay2 = (this.state.invoiceDetail.vat / 100) * discountedPrice
        let taxToDisplay = Math.round((taxToDisplay2 + Number.EPSILON) * 100) / 100


        const { logOutRedirect } = this.state;
        if (logOutRedirect) {
            // return <Redirect to="/logout" />;
            window.location.href = "/web/logout-and-clear-session"
        }






        // if (this.props.settings.siteSettingsAuth) {
        // 	settings = this.props.settings.siteSettingsRes.site;
        // }
        // let settings = this.props.settings.siteSettingsRes ? this.props.settings.siteSettingsRes.site : {}
        return (
            <>
                {
                    mainLoader ?
                        <MainLoader /> :
                        <React.Fragment>
                            {


                                // invoice from Transfer immunity
                                <div>
                                    <meta charSet="utf-8" />
                                    <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
                                    <title>{window.i18n('inv_invoice')}</title>
                                    <div style={{ maxWidth: '1000px', margin: '0 auto', background: '#fff', color: '#000' }}>
                                        <div className="table-responsive">
                                            <div className="py-4">
                                                <Link style={{ color: "#c31977" }} to="/organization/detail"><span><i class="fa fa-arrow-left" aria-hidden="true"></i></span></Link>
                                            </div>
                                            <table style={{ width: '1000px', margin: '0 0 15px' }}>
                                                <tbody>
                                                    {
                                                        this.state.siteDetail && this.state.siteDetail.siteLogo ?
                                                            <tr style={{ verticalAlign: 'top', padding: "15px 0" }}>
                                                                <td style={{ width: '500px', textAlign: 'left', verticalAlign: 'middle' }}>
                                                                    <img src={this.state.siteDetail.siteLogo} alt="" style={{ maxWidth: "160px" }} />
                                                                </td>
                                                                <td style={{ width: '500px', textAlign: 'right', verticalAlign: 'middle', padding: "15px 0" }}>
                                                                    <img src={require('../../../images/pdf-logo2.png')} alt="" style={{ maxWidth: "180px" }} />
                                                                </td>
                                                            </tr> : ''
                                                    }

                                                    <tr>
                                                        <td colSpan="2" style={{ textAlign: "Right", paddingBottm: "15px" }}>
                                                            <span className="cur-poi text-danger" onClick={this.downloadInvoice}><i class="fa fa-download"></i> {window.i18n('inv_dnld')}</span>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ verticalAlign: 'top' }}>
                                                        <td colSpan={2} style={{ verticalAlign: 'top', height: '1000px' }}>
                                                            <table style={{ width: '100%' }}>
                                                                <tbody>
                                                                    <tr style={{ verticalAlign: 'middle' }}>
                                                                        <td style={{ width: '500px' }}>
                                                                            <table style={{ width: '500px', borderCollapse: 'collapse', fontSize: '18px', lineHeight: '18px' }}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span style={{ color: '#9f9f9f', fontSize: '14px', paddingBottom: '10px' }}>{`${this.state.siteDetail.companyName} • ${this.state.siteDetail.street} • ${this.state.siteDetail.zipCode} ${this.state.siteDetail.city}`}</span>
                                                                                            <p style={{ color: '#000', fontSize: '14px', margin: 0, paddingTop: '25px' }}>{this.state.userDetail && this.state.userDetail.firstName != null && this.state.userDetail.firstName != undefined ? this.state.userDetail.firstName : ''} {this.state.userDetail && this.state.userDetail.lastName != null && this.state.userDetail.lastName != undefined ? this.state.userDetail.lastName : ''}<br />

                                                                                                {
                                                                                                    this.state.userDetail.address ?
                                                                                                        <>
                                                                                                            {
                                                                                                                this.state.userDetail.address
                                                                                                            }
                                                                                                            <br />

                                                                                                        </> : ""
                                                                                                }
                                                                                                {this.state.userDetail.zipCode ?
                                                                                                    <>
                                                                                                        {this.state.userDetail.zipCode + " " + this.state.userDetail.city}
                                                                                                        <br />
                                                                                                    </>
                                                                                                    : ""
                                                                                                }
                                                                                                {this.state.userDetail.country ? this.state.userDetail.country : ''}
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                        <td style={{ width: '500px', paddingTop: '10px', textAlign: 'right' }}>
                                                                            <table style={{ width: '494px', borderCollapse: 'collapse', border: '1px solid #9f9f9f', fontSize: '14px', lineHeight: '18px', padding: '4px 8px' }}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{ textAlign: 'left', paddingBottom: '5px', fontSize: '16px', padding: '8px 0px 0px 10px' }}>{window.i18n('inv_invoice')}</th>
                                                                                        <th />
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style={{ padding: '8px 0 0px 10px', textAlign: 'left' }}>
                                                                                            {"NED/" + moment(this.state.invoiceDetail.createdAt).format("YYYY") + "/" + this.state.invoiceDetail.invoiceId}
                                                                                        </td>
                                                                                        <td style={{ color: '#9f9f9f', textAlign: 'right', padding: '8px 10px 0px 0px' }}>{window.i18n('inv_dt')}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ borderBottom: '1px solid #9f9f9f', padding: '0px 0 8px 10px', textAlign: 'left' }}></td>
                                                                                        <td style={{ textAlign: 'right', borderBottom: '1px solid #9f9f9f', padding: '0px 10px 8px 0px' }}>{moment(new Date()).format("DD/MM/YYYY")}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ color: '#9f9f9f', padding: '8px 0 0px 10px', textAlign: 'left' }}>{window.i18n('inv_rf')}:</td>
                                                                                        <td style={{ color: '#9f9f9f', textAlign: 'right', padding: '8px 10px 0px 0px' }}>{window.i18n('inv_sp')}:</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ borderBottom: '1px solid #9f9f9f', padding: '0px 0 8px 10px', textAlign: 'left' }}>
                                                                                            {"NED/" + moment(this.state.invoiceDetail.createdAt).format("YYYY") + "/" + this.state.invoiceDetail.invoiceId}
                                                                                        </td>
                                                                                        <td style={{ textAlign: 'right', borderBottom: '1px solid #9f9f9f', padding: '0px 10px 8px 0px' }}>{this.state.siteDetail.siteTitle}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ color: '#9f9f9f', borderBottom: '1px solid #9f9f9f', padding: '8px 0 8px 10px', textAlign: 'left' }}>{this.state.userDetail && this.state.userDetail.firstName != null && this.state.userDetail.firstName != undefined ? this.state.userDetail.firstName : ''} {this.state.userDetail && this.state.userDetail.lastName != null && this.state.userDetail.lastName != undefined ? this.state.userDetail.lastName : ''}</td>
                                                                                        <td style={{ color: '#9f9f9f', borderBottom: '1px solid #9f9f9f' }} />
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ color: '#9f9f9f', padding: '8px 0 0px 10px', textAlign: 'left' }}>{this.state.siteDetail.companyName}</td>
                                                                                        <td />
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ borderBottom: '1px solid #9f9f9f', padding: '0px 0 8px 10px', textAlign: 'left' }}>{this.state.siteDetail.vatId}</td>
                                                                                        <td style={{ borderBottom: '1px solid #9f9f9f' }} />
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ color: '#9f9f9f', padding: '8px 0 0px 10px', textAlign: 'left' }}>{window.i18n('inv_dt_fm')}</td>
                                                                                        <td style={{ color: '#9f9f9f', textAlign: 'right', padding: '8px 10px 0px 0px' }}>{window.i18n('inv_dt_t')}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ borderBottom: '1px solid #9f9f9f', padding: '0px 0 8px 10px', textAlign: 'left' }}>{moment(dataOfInvoice).format("DD/MM/YYYY")}</td>
                                                                                        <td style={{ textAlign: 'right', borderBottom: '1px solid #9f9f9f', padding: '0px 10px 8px 0px' }}> {moment(endDate).format("DD/MM/YYYY")}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ paddingTop: '100px' }}>
                                                                            <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '14px', lineHeight: '18px', marginBottom: '50px', borderTopStyle: "solid", borderTopWidth: "1px", borderTopColor: "#000", borderLeftStyle: "solid", borderLeftWidth: "1px", borderLeftColor: "#000", border: 'none' }}>
                                                                                <thead>
                                                                                    <tr style={{ padding: '12px 0' }}>
                                                                                        <th style={{ fontWeight: 'bold', textAlign: 'left', padding: '4px', borderRightStyle: "solid", borderRightWidth: "1px", borderRightColor: "#000", borderBottomStyle: "solid", borderBottomWidth: "1px", borderBottomColor: "#000", border: 'none' }}>Pos</th>
                                                                                        <th style={{ fontWeight: 'bold', textAlign: 'left', padding: '4px', borderRightStyle: "solid", borderRightWidth: "1px", borderRightColor: "#000", borderBottomStyle: "solid", borderBottomWidth: "1px", borderBottomColor: "#000", border: 'none' }}>{window.i18n('inv_pkg')}</th>
                                                                                        <th style={{ fontWeight: 'bold', textAlign: 'left', padding: '4px', borderRightStyle: "solid", borderRightWidth: "1px", borderRightColor: "#000", borderBottomStyle: "solid", borderBottomWidth: "1px", borderBottomColor: "#000", border: 'none' }}>{window.i18n('inv_dsc')}</th>
                                                                                        <th style={{ fontWeight: 'bold', textAlign: 'right', padding: '4px', borderRightStyle: "solid", borderRightWidth: "1px", borderRightColor: "#000", borderBottomStyle: "solid", borderBottomWidth: "1px", borderBottomColor: "#000", border: 'none' }}>{window.i18n('inv_tp')}</th>
                                                                                        <th style={{ fontWeight: 'bold', textAlign: 'right', padding: '4px', borderRightStyle: "solid", borderRightWidth: "1px", borderRightColor: "#000", borderBottomStyle: "solid", borderBottomWidth: "1px", borderBottomColor: "#000", border: 'none' }}>{str && str != undefined && str != null && str != '' ? str : window.i18n('inv_mp')}</th>
                                                                                        <th style={{ fontWeight: 'bold', textAlign: 'right', padding: '4px', borderRightStyle: "solid", borderRightWidth: "1px", borderRightColor: "#000", borderBottomStyle: "solid", borderBottomWidth: "1px", borderBottomColor: "#000", border: 'none' }}>{window.i18n('global_payment_status')}</th>

                                                                                        {/* <th style={{ fontWeight: 'bold', textAlign: 'right', padding: '4px', borderRightStyle: "solid", borderRightWidth: "1px", borderRightColor: "#000", borderBottomStyle: "solid", borderBottomWidth: "1px", borderBottomColor: "#000", border: 'none' }}>{window.i18n('inv_dst')}%</th> */}
                                                                                        <th style={{ fontWeight: 'bold', textAlign: 'right', padding: '4px', borderRightStyle: "solid", borderRightWidth: "1px", borderRightColor: "#000", borderBottomStyle: "solid", borderBottomWidth: "1px", borderBottomColor: "#000", border: 'none' }}>{window.i18n('inv_txs')}</th>
                                                                                        <th style={{ fontWeight: 'bold', textAlign: 'right', padding: '4px', borderRightStyle: "solid", borderRightWidth: "1px", borderRightColor: "#000", borderBottomStyle: "solid", borderBottomWidth: "1px", borderBottomColor: "#000", border: 'none' }}>{window.i18n('inv_tpc')}</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style={{ padding: "4px", textAlign: 'left', width: '80px', verticalAlign: 'top', borderRightStyle: "solid", borderRightWidth: "1px", borderRightColor: "#000", borderBottomStyle: "solid", borderBottomWidth: "1px", borderBottomColor: "#000", border: 'none' }}>1</td>
                                                                                        <td style={{ padding: "4px", textAlign: 'left', width: '110px', verticalAlign: 'top', borderRightStyle: "solid", borderRightWidth: "1px", borderRightColor: "#000", borderBottomStyle: "solid", borderBottomWidth: "1px", borderBottomColor: "#000", border: 'none' }}>
                                                                                            {this.state.invoiceDetail.planName}
                                                                                        </td>
                                                                                        <td style={{ padding: "4px", textAlign: 'left', width: '350px', verticalAlign: 'top', borderRightStyle: "solid", borderRightWidth: "1px", borderRightColor: "#000", borderBottomStyle: "solid", borderBottomWidth: "1px", borderBottomColor: "#000", border: 'none' }}>
                                                                                            {this.state.invoiceDetail.description}
                                                                                        </td>
                                                                                        <td style={{ padding: "4px", textAlign: 'right', width: '100px', verticalAlign: 'top', borderRightStyle: "solid", borderRightWidth: "1px", borderRightColor: "#000", borderBottomStyle: "solid", borderBottomWidth: "1px", borderBottomColor: "#000", border: 'none' }}>
                                                                                            {str2 && str2 != undefined && str2 != null && str2 != '' ? str2 : window.i18n('cr_pkg_mt')}

                                                                                        </td>
                                                                                        <td style={{ padding: "4px", textAlign: 'right', width: '150px', verticalAlign: 'top', borderRightStyle: "solid", borderRightWidth: "1px", borderRightColor: "#000", borderBottomStyle: "solid", borderBottomWidth: "1px", borderBottomColor: "#000", border: 'none' }}>
                                                                                            {this.state.invoiceDetail.priceAfterDiscount && this.state.invoiceDetail.priceAfterDiscount != undefined && this.state.invoiceDetail.priceAfterDiscount != null ? this.state.invoiceDetail.priceAfterDiscount : 0}
                                                                                        </td>
                                                                                        <td style={{ padding: "4px", textAlign: 'right', width: '150px', verticalAlign: 'top', borderRightStyle: "solid", borderRightWidth: "1px", borderRightColor: "#000", borderBottomStyle: "solid", borderBottomWidth: "1px", borderBottomColor: "#000", border: 'none' }}>
                                                                                            {this.state.invoiceDetail?.isFOC ? "FOC" : this.state.invoiceDetail.paymentDone ? window.i18n('global_paid') : window.i18n('global_pending')}
                                                                                        </td>
                                                                                        {/* <td style={{ padding: "4px", textAlign: 'right', width: '110px', verticalAlign: 'top', borderRightStyle: "solid", borderRightWidth: "1px", borderRightColor: "#000", borderBottomStyle: "solid", borderBottomWidth: "1px", borderBottomColor: "#000", border: 'none' }}>
                                                                                            {!this.state.invoiceDetail.voucher ? 0 : this.state.invoiceDetail.voucher.discount ? this.state.invoiceDetail.voucher.discount : 0}
                                                                                        </td> */}
                                                                                        <td style={{ padding: "4px", textAlign: 'right', width: '110px', verticalAlign: 'top', borderRightStyle: "solid", borderRightWidth: "1px", borderRightColor: "#000", borderBottomStyle: "solid", borderBottomWidth: "1px", borderBottomColor: "#000", border: 'none' }}>
                                                                                            {`${this.state.invoiceDetail.vat && this.state.invoiceDetail.vat != undefined && this.state.invoiceDetail.vat != null ? this.state.invoiceDetail.vat : 0}% ${this.getCountryCode(this.state.invoiceDetail.country) != null && this.getCountryCode(this.state.invoiceDetail.country) != undefined && this.getCountryCode(this.state.invoiceDetail.country) != '' ? this.getCountryCode(this.state.invoiceDetail.country) : 'VA'}t`}
                                                                                        </td>

                                                                                        {/* <td style={{ padding: "4px", textAlign: 'right', width: '110px', verticalAlign: 'top', borderRightStyle: "solid", borderRightWidth: "1px", borderRightColor: "#000", borderBottomStyle: "solid", borderBottomWidth: "1px", borderBottomColor: "#000", border: 'none' }}>
                                                                                            {`${this.state.invoiceDetail.vat && this.state.invoiceDetail.vat != undefined && this.state.invoiceDetail.vat != null ? this.state.invoiceDetail.vat : 0}% ${this.getCountryCode(this.state.invoiceDetail.country) != null && this.getCountryCode(this.state.invoiceDetail.country) != undefined && this.getCountryCode(this.state.invoiceDetail.country) != '' ? this.getCountryCode(this.state.invoiceDetail.country) : 'VA'}t`}
                                                                                        </td> */}
                                                                                        <td style={{ padding: "4px", textAlign: 'right', width: '110px', verticalAlign: 'top', borderRightStyle: "solid", borderRightWidth: "1px", borderRightColor: "#000", borderBottomStyle: "solid", borderBottomWidth: "1px", borderBottomColor: "#000", border: 'none' }}>
                                                                                            {!discountedPrice || discountedPrice === undefined || discountedPrice === null ? 0 : parseInt(discountedPrice)}

                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: '500px', textAlign: 'left', verticalAlign: 'middle' }}>&nbsp;</td>
                                                                        <td style={{ width: '500px', textAlign: 'right', verticalAlign: 'middle', paddingTop: '40px' }}>
                                                                            <table style={{ borderCollapse: 'collapse', width: '100%', float: 'right', fontSize: '14px', lineHeight: '18px', padding: '4px 8px' }}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style={{ padding: '10px 0 10px 0px', borderBottom: '1px solid #9f9f9f', borderTop: '1px solid #9f9f9f', textAlign: 'left' }}>
                                                                                            {window.i18n('inv_nt')}
                                                                                        </td>
                                                                                        <td style={{ color: '#000', textAlign: 'right', padding: '10px 0px 10px 0px', borderTop: '1px solid #9f9f9f', borderBottom: '1px solid #9f9f9f' }}>
                                                                                            {discountedPrice && discountedPrice != undefined && discountedPrice != null && discountedPrice != '' ? discountedPrice : 0} €</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ borderBottom: '1px solid #000', padding: '10px 0 10px 0px', textAlign: 'left' }}>{`${this.getCountryCode(this.state.invoiceDetail.country) != null && this.getCountryCode(this.state.invoiceDetail.country) != undefined && this.getCountryCode(this.state.invoiceDetail.country) != '' ? this.getCountryCode(this.state.invoiceDetail.country) : 'VA'}t ${this.state.invoiceDetail.vat && this.state.invoiceDetail.vat != undefined && this.state.invoiceDetail.vat != null && this.state.invoiceDetail.vat != '' ? this.state.invoiceDetail.vat : 0}% / ${siteDetail.defaultVat}%`}</td>
                                                                                        <td style={{ textAlign: 'right', borderBottom: '1px solid #000', padding: '10px 0px 10px 0px' }}>
                                                                                            {taxToDisplay && taxToDisplay != undefined && taxToDisplay != null && taxToDisplay != '' ? taxToDisplay : 0} €
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ padding: '10px 0 0px 0px', fontWeight: 'bold', textAlign: 'left' }}>{window.i18n('int_ttl')}</td>
                                                                                        <td style={{ textAlign: 'right', padding: '4px 0px 0px 0px', fontWeight: 'bold' }}>{`${this.state.invoiceDetail.amount} €`}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="2" style={{ paddingTop: '40px' }}>
                                                                            <table style={{ width: '100%', marginBottom: '20px' }}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td colSpan={2}>
                                                                                            <span style={{ textAlign: 'left', whiteSpace: 'nowrap', marginBottom: '10px', fontSize: '14px' }}>
                                                                                                {window.i18n('inv_cm_text')}: <span style={{ fontWeight: 'bold' }}>
                                                                                                    {"NED/" + moment(this.state.invoiceDetail.createdAt).format("YYYY") + "/" + this.state.invoiceDetail.invoiceId}</span>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ paddingTop: '04px' }}>
                                                                                            <span style={{ textAlign: 'right', whiteSpace: 'nowrap', fontSize: '14px' }}>{window.i18n('inv_pm_t')}: {str2 && str2 != undefined && str2 != null && str2 != '' ? str2 : window.i18n('cr_pkg_mt')}</span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    {this.state.invoiceDetail.voucher && this.state.invoiceDetail.voucher.reseller && <tr>
                                                                                        <td style={{ paddingTop: '04px' }}>
                                                                                            <span style={{ textAlign: 'right', whiteSpace: 'nowrap', fontSize: '14px' }}>{window.i18n('vc_g')} {this.state.invoiceDetail.voucher.reseller}</span>
                                                                                        </td>
                                                                                    </tr>}
                                                                                    {this.state.invoiceDetail.voucher && this.state.invoiceDetail.voucher.voucher &&
                                                                                        <tr>
                                                                                            <td style={{ paddingTop: '04px' }}>
                                                                                                <span style={{ textAlign: 'right', whiteSpace: 'nowrap', fontSize: '14px' }}>{window.i18n('vc_k')}: {this.state.invoiceDetail.voucher.voucher}</span>
                                                                                            </td>
                                                                                        </tr>}
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '14px', lineHeight: '18px' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td width="406px" style={{ borderBottom: '1px solid #9f9f9f', paddingBottom: '5px' }}>
                                                                            <p style={{ margin: 0, paddingBottom: '8px', color: '#9f9f9f' }}>
                                                                                {window.i18n('inv_invoice')} {"NED/" + moment(this.state.invoiceDetail.createdAt).format("YYYY") + "/" + this.state.invoiceDetail.invoiceId}
                                                                            </p>
                                                                        </td>
                                                                        <td width="333px" style={{ borderBottom: '1px solid #9f9f9f' }} />
                                                                        <td width="260px" style={{ borderBottom: '1px solid #9f9f9f', textAlign: 'right', paddingBottom: '5px' }}>
                                                                            <p style={{ margin: 0, paddingBottom: '8px', color: '#9f9f9f' }}>{window.i18n('inv_page')}: 1 / 1</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ paddingTop: '10px', verticalAlign: 'top' }}>
                                                                            <p style={{ color: '#9f9f9f' }}>
                                                                                {this.state.siteDetail.companyName}
                                                                                <br />
                                                                                {this.state.siteDetail.street}<br />
                                                                                {`${this.state.siteDetail.zipCode} ${this.state.siteDetail.city}`}<br />
                                                                                {window.i18n('inv_em')}: <span style={{ color: '#00bcd4', paddingLeft: '0px' }}>{this.state.siteDetail.email}</span><br />
                                                                                {window.i18n('inv_pt_w')}: <a style={{ color: '#00bcd4' }} href={'https://' + this.state.siteDetail.domain} target="_blank">{this.state.siteDetail.domain}</a><br />
                                                                                {window.i18n('inv_c_w')}: <a style={{ color: '#00bcd4' }} href={this.state.siteDetail.companyWebsite} target="_blank"> {this.state.siteDetail.companyWebsite} </a>
                                                                            </p>
                                                                        </td>
                                                                        <td style={{ paddingTop: '10px', verticalAlign: 'top' }}>
                                                                            <p style={{ color: '#9f9f9f' }}>
                                                                                {this.state.siteDetail.commercialRegisterAddress}<br />
                                                                                VAT-ID: {this.state.siteDetail.vatId}<br />
                                                                                TAX-ID: {this.state.siteDetail.taxId}
                                                                            </p>
                                                                        </td>
                                                                        <td style={{ paddingTop: '10px', verticalAlign: 'top' }}>
                                                                            <p style={{ color: '#9f9f9f' }}>{this.state.siteDetail.bankName}<br />
                                                                                IBAN: {this.state.siteDetail.iban}<br />
                                                                                BIC: {this.state.siteDetail.code}
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>



                                // invoice from Transfer immunity


                                //    commenting original invoice design for testing purpose


                                //                    mainLoader ?
                                //                         <div className="main-loader" >
                                //                             <div class="testing-loader-container">
                                //                                 <svg>
                                //                                     <g>
                                //                                         <path d="M 50,100 A 1,1 0 0 1 50,0" />
                                //                                     </g>
                                //                                     <g>
                                //                                         <path d="M 50,75 A 1,1 0 0 0 50,-25" />
                                //                                     </g>
                                //                                     <defs>
                                //                                         <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                //                                             <stop offset="0%" style={{ stopColor: '#ef56a0', stopOpacity: '1' }} />
                                //                                             <stop offset="100%" style={{ stopColor: '#872a5d', stopOpacit: '1' }} />
                                //                                         </linearGradient>
                                //                                     </defs>
                                //                                 </svg>
                                //                             </div>
                                //                         </div>
                                //                         :
                                //                         <div className="single-invoice-summary--layout">
                                //                             <div>
                                //                                 <div className="invoice-detail--title">
                                //                                     <div className="invoice-detail-icon--color" style={{ marginBottom: '5px' }}>

                                //                                         <div className="w-50 float-left">
                                //                                             {
                                //                                                 siteDetail ?
                                //                                                     <strong className="bitly-icon">
                                //                                                         <img style={{ width: "190px" }} className="img-fluid" src={siteDetail.siteLogo} alt="NED.link Site Logo" />
                                //                                                     </strong>
                                //                                                     : ''
                                //                                             }
                                //                                         </div>
                                //                                         <div className="w-50 float-right text-right">
                                //                                             <button type="button" className="btn btn-pink" onClick={this.downloadInvoice}><i class="fa fa-download"></i></button>
                                //                                         </div>
                                //                                     </div>
                                //                                     {/* {window.i18n('new_inv_a')} */}

                                //                                 </div>
                                //                                 {/* <div className="invoice-detail-bitly-address--section">
                                //                             <ul className="invoice-detail--list">

                                //                                  <li>&nbsp;{siteDetail.address}</li>
                                //                                  <li>139 5th Avenue, Floor 5</li>
                                //                                 <li>New York, NY 10010</li> 
                                //                             </ul>
                                //                         </div> */}



                                //                                 {/* added by rehan */}
                                //                                 <div className="invoice-detail--grid--column-large">
                                //                                     <Table bordered hover>
                                //                                         <thead>
                                //                                             <tr>
                                //                                                 <th >
                                //                                                     <div>
                                //                                                         {window.i18n('new_inv_a')}
                                //                                                     </div>
                                //                                                     <div style={{
                                //                                                         display: 'flex',
                                //                                                         justifyContent: 'space-between'
                                //                                                     }}>
                                //                                                         <strong>NED/2020/1000042</strong>
                                //                                                         <span style={{}}>Date</span>
                                //                                                     </div>
                                //                                                     <div style={{
                                //                                                         display: 'flex',
                                //                                                         justifyContent: 'space-between'
                                //                                                     }}>
                                //                                                         Please add to any letters
                                //                                                         <span style={{ marginLeft: '100px' }}>Day/Month/Year</span>
                                //                                                     </div>
                                //                                                 </th>
                                //                                             </tr>
                                //                                         </thead>
                                //                                         <tbody>
                                //                                             <tr>
                                //                                                 <td></td>
                                //                                             </tr>
                                //                                             <tr>
                                //                                                 <td></td>
                                //                                             </tr>
                                //                                             <tr>
                                //                                                 <td>
                                //                                                     <div>
                                //                                                         TIMmunity GmbH
                                //                                                     </div>
                                //                                                     <div>
                                //                                                         DE327709293
                                //                                                     </div>

                                //                                                 </td>
                                //                                             </tr>
                                //                                             <tr>
                                //                                                 <td></td>
                                //                                             </tr>
                                //                                         </tbody>
                                //                                     </Table>
                                //                                 </div>
                                //                                 {/* added by rehan */}


                                //                                 <div className="" style={{ marginTop: '12%' }}>
                                //                                     <ul className="invoice-detail--list">
                                //                                         <li>TIMmunity GmbH {siteDetail.address}</li>
                                //                                         <li><strong>Client Name</strong></li>
                                //                                         <li><strong>Street</strong></li>
                                //                                         <li><strong>City Code</strong></li>
                                //                                         <li><strong>County</strong></li>

                                //                                     </ul>
                                //                                 </div>
                                //                                 {/* <div className="invoice-detail--column-small">
                                //                                     <ul className="invoice-detail--list">
                                //                                         <li><strong>{window.i18n('new_inv_c')}:</strong>&nbsp;{moment(invoiceDetail.createdAt).format("DD/MM/YYYY")}</li>
                                //                                         <li><strong>{window.i18n('new_inv_a')} #:</strong>&nbsp;{invoiceDetail.invoiceId}</li>
                                //                                     </ul>
                                //                                 </div> */}
                                //                                 {/* <hr /> */}
                                //                                 {/* <div className="invoice-detail--column-large">
                                //                                     <ul className="invoice-detail--list invoice-detail-billing--section">
                                //                                         <li><strong>{window.i18n('new_inv_d')}:</strong></li>
                                //                                         <li><strong>{window.i18n('new_inv_e')}: </strong>{invoiceDetail.customerId ? invoiceDetail.customerId : '-'}</li>
                                //                                         <li><strong>{window.i18n('new_inv_f')}: </strong>{invoiceDetail.fullName}</li>
                                //                                     </ul>
                                //                                 </div> */}
                                //                                 {/* <div className="invoice-detail--column-small">
                                //                                     <ul className="invoice-detail--list invoice-detail-billing--section">
                                //                                         <li><strong>{window.i18n('vc_f')}</strong></li>
                                //                                         <li><strong>{window.i18n('vc_g')}</strong>&nbsp;{invoiceDetail.voucher && invoiceDetail.voucher.reseller ? invoiceDetail.voucher.reseller : '-'}</li>
                                //                                         <li><strong>{window.i18n('vc_h')}</strong>&nbsp;{invoiceDetail.voucher && invoiceDetail.voucher.voucher ? invoiceDetail.voucher.voucher : '-'}</li>
                                //                                         <li><strong>{window.i18n('vc_i')}</strong>&nbsp;{invoiceDetail.voucher && invoiceDetail.voucher.discount ? invoiceDetail.voucher.discount + '%' : '-'}</li>
                                //                                     </ul>
                                //                                 </div> */}

                                //                                 <div className="invoice-detail-charge-info--section ">
                                //                                 <Table >
                                //                                         <thead >
                                //                                             <tr>
                                //                                                 <th style={{width:'22%'}}>
                                //                                                     Pos 
                                //                                                 </th>
                                //                                                 <th style={{textAlign:'center'}}>
                                //                                                     Description
                                //                                                 </th>
                                //                                                 <th style={{textAlign:'center'}}>
                                //                                                     Quantity
                                //                                                 </th>
                                //                                                 <th style={{textAlign:'center'}}>
                                //                                                     Unit Price
                                //                                                 </th>
                                //                                                 <th style={{textAlign:'center'}}>
                                //                                                    Taxes
                                //                                                 </th>
                                //                                                 <th style={{textAlign:'center'}}    >
                                //                                                     Total Price
                                //                                                 </th>
                                //                                             </tr>
                                //                                         </thead>
                                //                                         <tbody>
                                //                                             <tr>
                                //                                                 <td>€{invoiceDetail.amount}</td>
                                //                                                 <td style={{textAlign:'center'}}>€{invoiceDetail.amount}</td>
                                //                                                 <td style={{textAlign:'center'}}>€{invoiceDetail.amount}</td>
                                //                                                 <td style={{textAlign:'center'}}>€{invoiceDetail.amount}</td>
                                //                                                 <td style={{textAlign:'center'}}>€{invoiceDetail.amount}</td>
                                //                                                 <td style={{textAlign:'center'}}>€{invoiceDetail.amount}</td>

                                //                                             </tr>
                                //                                         </tbody>
                                //                                     </Table>

                                //                                 </div>



                                //                                 {/* <div className="invoice-detail-charge-info--section ">
                                //                                     <span className="invoice-detail--column-large"><strong>Pos</strong></span>
                                //                                     <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                //                                         <span className="invoice-detail--column-small"><strong>Description</strong></span>
                                //                                         <span className="invoice-detail--column-small"><strong>Quantity</strong></span>
                                //                                         <span className="invoice-detail--column-small"><strong>Unit Price</strong></span>
                                //                                         <span className="invoice-detail--column-small"><strong>Taxes</strong></span>
                                //                                         <span className="invoice-detail--column-small"><strong>Total Price</strong></span>
                                //                                     </div>

                                //                                     <hr /><span className="invoice-detail--column-large">{invoiceDetail.description}</span>
                                //                                     <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                //                                         <span className="invoice-detail--column-small">€{invoiceDetail.amount}</span>
                                //                                         <span className="invoice-detail--column-small">€{invoiceDetail.amount}</span>
                                //                                         <span className="invoice-detail--column-small">€{invoiceDetail.amount}</span>
                                //                                         <span className="invoice-detail--column-small">€{invoiceDetail.amount}</span>
                                //                                         <span className="invoice-detail--column-small">€{invoiceDetail.amount}</span>
                                //                                     </div>
                                //                                 </div> */}

                                // {/* <div className="" style={{ width: '30%', float: 'right', marginTop: '20px' }}>

                                // <Table >
                                //                                         <thead >
                                //                                             <tr>
                                //                                                 <th >
                                //                                                     Net 
                                //                                                 </th>

                                //                                                 <th style={{textAlign:'end'}}    >
                                //                                                     18.20
                                //                                                 </th>
                                //                                             </tr>
                                //                                         </thead>
                                //                                         <tbody>
                                //                                             <tr>
                                //                                                 <td>€{invoiceDetail.amount}</td>
                                //                                                 <td style={{textAlign:'center'}}>€{invoiceDetail.amount}</td>
                                //                                                 <td style={{textAlign:'center'}}>€{invoiceDetail.amount}</td>
                                //                                                 <td style={{textAlign:'center'}}>€{invoiceDetail.amount}</td>
                                //                                                 <td style={{textAlign:'center'}}>€{invoiceDetail.amount}</td>
                                //                                                 <td style={{textAlign:'center'}}>€{invoiceDetail.amount}</td>

                                //                                             </tr>
                                //                                         </tbody>
                                //                                     </Table>
                                //                                     </div> */}

                                //                                 <div className="" style={{ width: '30%', float: 'right', marginTop: '20px' }}>
                                //                                     <hr />
                                //                                     <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                //                                         <span className="">Net</span>
                                //                                         <span className="" >18.20 €</span>
                                //                                     </div>
                                //                                     <hr />
                                //                                     <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                //                                         <span className="">USt &nbsp; 16% USt</span>
                                //                                         <span className="" >2.91 €</span>
                                //                                     </div>
                                //                                     <hr style={{ border: '1px solid' }} />
                                //                                     <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                //                                         <span className="">Total</span>
                                //                                         <span className="" >21.11 €</span>
                                //                                     </div>
                                //                                 </div>


                                //                             </div>
                                //                             <div className="" style={{ marginTop: '10%' }}>Please use the following communication for your payment : <strong>NED/2020/10000002</strong></div>

                                //                         </div>

                                //    commenting original invoice design for testing purpose

                            }
                        </React.Fragment>
                }
            </>
        );
    }
}

const mapDispatchToProps = {
    downloadInvoice, beforeDownloadInvoice
};

const mapStateToProps = state => ({
    // settings: state.settings,
    invoice: state.invoice,
    errors: state.errors
})

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);