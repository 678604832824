import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class Logout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
        }
    }
    componentWillMount() {
        localStorage.removeItem("loginToken");
        localStorage.removeItem("LoginSession");
        localStorage.removeItem("loginTime");
        this.setState({ redirect: true });
    }

    render() {
        const { redirect } = this.state;
        if (redirect) {        
            // return <Redirect to="/userLogin" />;
             window.location.href="/web/logout-and-clear-session"
        }
        return (
            <React.Fragment>
                <h1>By</h1>
            </React.Fragment>
        );
    }
}

export default Logout;