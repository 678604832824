import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { getSiteSetting, beforeSiteSettings } from '../../shared/site-settings/site-settings.action';
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { ENV } from '../../config/config';
import $ from "jquery";
const queryString = require("query-string");
var CryptoJS = require("crypto-js");


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: "",
      redirect: false,
      redirectTo: false,
      redirectToBilling: false,
      isLoader: false,
      settings: {},
      custom: false,
      subscriptionId: "",
      newPackage: "",
      userFromParam: "",
      language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
      redirect: false,
      redirectToPackageCreation: false,
      redirectToPackageCreationPage: false,
      id: '',
      redirectToPlans: false,
      navigateToPlans: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    // if (this.props.history.location.state) {
    //   if (this.props.history.location.state.redirect) {
    //     this.setState({ redirectToPackageCreation: true })
    // this.state.redirectToPackageCreationPage = true;
    // };
    // }

    var parsed = queryString.parse(this.props.location.search);
    let check = this.props.location.search;
    let params = new URLSearchParams(check);
    let subscriptionId = params.get('subscriptionId');
    let newPackage = params.get('packageId');
    let pLang = params.get('lang');
    let UserID = params.get('user');
    let isCustomize = params.get('custom');
    this.setCookie(subscriptionId, newPackage);
    if (isCustomize === 'true') {
      let lang = params.get('lang');
      this.setState(() => ({ custom: isCustomize, subscriptionId: subscriptionId, newPackage: newPackage, userFromParam: UserID, language: lang }), () => {
        localStorage.setItem('language', lang);
      })
    }

    if (parsed.token !== undefined) {
      let tokenParam = JSON.parse(parsed.token);
      if (tokenParam.status) {
        if (tokenParam.data.defaultSubscriptionPackage) {
          let encSubscriptionPlanDetails = CryptoJS.AES.encrypt(JSON.stringify(tokenParam.data.defaultSubscriptionPackage), ENV.TokSecCrpt);
          localStorage.setItem("spd", encSubscriptionPlanDetails);
        }
        localStorage.setItem("LoginSession", JSON.stringify(tokenParam));
        if (tokenParam && tokenParam.data.notify)
          $("#changePlanBannerMainDiv").show();
        localStorage.setItem("loginToken", tokenParam.data.loginToken);
        localStorage.setItem("loginTime", new Date().getTime());
        this.setState({ redirect: true });
      } else {
        this.setState({ redirect: false });
        swal(window.i18n('alert_a'), tokenParam.message, 'error');
      }
    }
    else if (localStorage.getItem("loginToken")) {
      var loginTime = parseInt(localStorage.getItem("loginTime")) / 1000;
      var timeNow = new Date().getTime() / 1000;
      var timeDifference = timeNow - loginTime;

      var timeDifferenceInMinutes = timeDifference / 60;
      if (timeDifferenceInMinutes < window.loginExpiresAfter) {
        if (isCustomize === 'true') {
          let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
          if (UserID === id) {
            this.setState({ redirectTo: true }, () => {
            });
          } else {
            this.props.history.push('/dashboard');
          }
        } else {
          this.setState({ redirect: true });
        }
      } else {
        this.setState({ redirect: false });
      }
    } else {
      this.setState({ redirect: false });
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: ''
    });
  };

  componentDidMount = () => {
    this.props.getSiteSetting(this.state.language);
    if (this.props.history.location.state) {
      if (this.props.history.location.state.redirectedFrom && this.props.history.location.state.redirectedFrom === 'plans') {
        this.setState({ redirectToPlans: true })
        return;
      }
      if (this.props.history.location.state.redirect)
        this.setState(() => ({ redirectToPackageCreation: true }))
    }

  };

  handleSubmit = e => {
    const { email, password, userFromParam } = this.state;
    e.preventDefault();
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === "") {
      this.setState({ errors: window.i18n('alert_m') });
      return;
    } else if (!email.match(regexp)) {
      this.setState({ errors: window.i18n('alert_n') });
      return;
    } else if (password === "") {
      this.setState({ errors: window.i18n('alert_ao') });
      return;
    } else {
      this.setState({ errors: "" });
      fetch(window.APIURL + "accounts/member/login?lang=" + this.state.language, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          password: password
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 400) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {
          if (result.status === true) {
            if (!result.data.isVerified) {
              swal({
                title: window.i18n('swal_acc_ver'),
                text: window.i18n('swal_acc_ver_txt'),
                icon: "warning",
                // buttons: true,
                buttons: [window.i18n('dellAcc_cancel'), window.i18n('swal_resend')],
                dangerMode: true,
              })
                .then((resend) => {
                  if (resend) {
                    fetch(window.APIURL + "accounts/member/verification-email?lang=" + this.state.language, {
                      method: "post",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({
                        email: email,
                      })
                    })
                      .then(res => {
                        if (res.status === 200) {
                          return res.json();
                        } else if (res.status === 400) {
                          return res.json();
                        } else if (res.status === 403) {
                          return res.json();
                        } else if (res.status === 422) {
                          return res.json();
                        } else if (res.status === 404) {
                          return res.json();
                        } else {
                          return res.json();
                        }
                      })
                      .then(result => {
                        if (result.status === true)
                          swal(window.i18n('ver_em_txt'), {
                            icon: "success",
                          });
                      })

                      .catch(err => swal(window.i18n('alert_ai'), {
                        icon: "error",
                      }))

                  }
                });
              return;
            }
            this.setState({
              id: result.data._id
            })
            if (result.data.isTwoStepVerification === true) {
              localStorage.setItem("_id", result.data._id);
              this.props.history.push("/verifyCode");
            } else {

              let subscriptionPlanDetails = result.subscriptionPlanDetails;
              delete result.subscriptionPlanDetails;
              let encSubscriptionPlanDetails = CryptoJS.AES.encrypt(JSON.stringify(subscriptionPlanDetails), ENV.TokSecCrpt);
              localStorage.setItem("spd", encSubscriptionPlanDetails);
              localStorage.setItem("LoginSession", JSON.stringify(result));
              if (result && result.data.notify)
                $("#changePlanBannerMainDiv").show();
              localStorage.setItem("loginToken", result.data.loginToken);
              localStorage.setItem("loginTime", new Date().getTime());
              if (this.state.redirectToPlans) this.setState(() => ({ navigateToPlans: true }))

              if (this.state.redirectToPackageCreation)
                this.setState(() => ({ redirectToPackageCreationPage: true }))

              if (this.state.custom) {
                if (result.data._id === userFromParam) {
                  this.setState({
                    redirectTo: true
                  })
                } else {
                  // if (this.state.redirectToPackageCreationPage) {
                  // this.props.history.push('/package/create');

                  // }
                  // else {
                  this.props.history.push('/dashboard');
                  // }
                }
              } else {
                this.setState({ redirect: true });
              }
            }
          } else if (result.status === false) {
            swal(window.i18n('alert_a'), result.message, 'error');;
          } else {
            swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');;
          }
        });
    }
  };

  setCookie = (subscriptionId, newPackage) => {
    if (subscriptionId !== null && newPackage !== null) {
      localStorage.setItem("subscriptionId", subscriptionId)
      localStorage.setItem("newPackage", newPackage)
    }
  }

  render() {
    const { id, redirect, redirectTo, subscriptionId, newPackage, language, redirectToPackageCreationPage, redirectToPlans, navigateToPlans } = this.state;

    if (navigateToPlans) {
      let path = `/organization/subscriptions/${id}`;
      return <Redirect to={path}
      />;
    }

    if (redirectToPackageCreationPage) {
      return <Redirect to={{
        pathname: "/package/create",
        state: { redirect: true, id: id }
      }} />;

    }

    else if (redirect && redirectToPackageCreationPage === false) {
      return <Redirect to="/dashboard" />;
    }


    if (redirectTo) {
      return <Redirect to={`/organization/subscription?subscriptionId=${subscriptionId}&packageId=${newPackage}&custom=true&lang=${language}`} />;
    }

    let settings;

    if (this.props.settings.siteSettingsAuth) {
      settings = this.props.settings.siteSettingsRes.site;
    }

    return (
      <div className="user-forms-outer" style={{ height: '100vh', display: 'flex', padding: '25px 0', overflowY: 'auto' }}>
        <div className="container-box" style={{ margin: 'auto' }}>
          <h1 className="header-logo">
            <Link to="/">
              {
                settings ?
                  // <img src={settings.siteLogo} alt="Site Logo" /> 
                  <img src={require('../../images/logo-floink-white.svg')} alt={window.i18n('mainFooter_altLogo')} />
                  : 
                  ''
              }
            </Link>
          </h1>
          <form id="sign-in" onSubmit={this.handleSubmit}>
            <div className="header">
              <h3 className="tagline">{window.i18n('login_mainTitle')}</h3>
            </div>

            <div className="susi-fields-wrapper">
              <fieldset>
                <label htmlFor="email">
                  <input
                    className="text"
                    type="text"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder={window.i18n('global_email')}
                  />
                  <span className="error-message" />
                </label>
                <label htmlFor="password" className="password-block">
                  <input
                    className="text"
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    placeholder={window.i18n('global_pswd')}
                  />
                  <Link
                    className="forgot mt-1"
                    title="Password Reset"
                    to="/forgotPassword"
                    tabIndex="6"
                  >
                    {window.i18n('login_forgotPswd')}
                  </Link>
                  <span className="error-message" />
                </label>

                {Object.keys(this.state.errors).length > 0 &&
                  this.state.errors ? (
                  <div className="alert alert-danger">{this.state.errors}</div>
                ) : (
                  ""
                )}
                <button className="btn btn-pink">{window.i18n('global_SignIn')}</button>
              </fieldset>
            </div>
          </form>
          {
            settings && !settings.enableGMSignup && !settings.enableFBSignup && !settings.enableTWSignup ?
              ""
              :
              <React.Fragment>
                <p className="separator t-center">
                  <span>{window.i18n('global_or')}</span>
                </p>

                <div className="social-sign-in">
                  <span className="susi-with">{window.i18n('login_signinWith')}</span>
                  <div className="text-center">
                    {
                      settings && settings.enableGMSignup ?
                        <a rel="nofollow" href={window.APIURL + "auth/google"} className="susi-btn google-susi-btn button d-inline-block align-top" data-network="google">
                          <img src={require("../../images/google.svg")} alt="Google" width="20" />
                        </a>
                        :
                        ""
                    }
                    {
                      settings && settings.enableFBSignup ?
                        <a rel="nofollow" href={window.APIURL + "auth/facebook"} className="susi-btn facebook-susi-btn button d-inline-block align-top" data-network="facebook">
                          <img src={require("../../images/facebook.svg")} alt="Facebook" width="20" />
                        </a>
                        :
                        ""
                    }
                    {
                      settings && settings.enableTWSignup ?
                        <a rel="nofollow" href={window.APIURL + "auth/twitter"} className="susi-btn twitter-susi-btn button d-inline-block align-top" data-network="twitter">
                          <img src={require("../../images/twitter.svg")} alt="Twitter" width="22" />
                        </a>
                        :
                        ""
                    }
                  </div>
                </div>
              </React.Fragment>
          }
          <div className="text-center">
            <div className="switch to-sign-up">
              <span className="gray-link">{window.i18n('login_noAccount')}</span>{" "}
              <Link to="/userRegister">{window.i18n('login_signup')}</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getSiteSetting, beforeSiteSettings
};

const mapStateToProps = state => ({
  settings: state.settings,
  header: state.header,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Login));