import React, { Component } from "react";
import NavbarDropdown from "./dashboard-navbar/NavbarDropdown";
import NavbarSearch from "./dashboard-navbar/NavbarSearch";
import NavbarCreateBitlink from "./dashboard-navbar/NavbarCreateBitlink";
import GenericAccessToken from "../navbar-components/generic-access-token/genericAccessToken"; //By UZair Hassan
import SelectGroup from "../navbar-components/generic-access-token/selectGroup"; //By UZair Hassan
import Notification from "../navbar-components/notifications/notification"; //By UZair Hassan
import Personal from "../navbar-components/notifications/personal"; //By UZair Hassan
import GetRegistrationCode from "../navbar-components/registered-oauth-applications/getRegistrationCode"; //By UZair Hassan
import RegisterOauthApp from "../navbar-components/registered-oauth-applications/registerOauthApp"; //By UZair Hassan
import RegisteredOauthApps from "../navbar-components/registered-oauth-applications/registeredOuthApps"; //By UZair Hassan
import RegisteredOauthAppSecond from "../navbar-components/registered-oauth-applications/registeredOauthAppSecond"; //By UZair Hassan
import RegisterOauthAppEdit from "../navbar-components/registered-oauth-applications/registerOauthAppEdit"; //By UZair Hassan
import SetDefaultApi from "../navbar-components/default-api-group/setDefaultApi"; //By UZair Hassan
import AddCustomDomain from "../navbar-components/custom-domain/addCustomDomain"; //By UZair Hassan
import Settings from "../navbar-components/settings/Settings"; //By Shoaib
import SocialNetworks from "../navbar-components/settings/social-networks/SocialNetworks"; //By Shoaib
import TwoWayAuth from "../navbar-components/security-two-way-auth/TwoWayAuth"; //By Shoaib
import LoginHistory from "../navbar-components/login-history/loginHistory"; //By Shoaib
import DeleteAccount from "../navbar-components/delete-account/deleteAccount";
import CreateBitlink from "../navbar-components/create-bitlink/CreateBitlink";
import BulkTagLink from "../navbar-components/bulk-tags/BulkTags";
import EditLink from "../navbar-components/edit-link/EditLink";
import CreateCampaign from "../navbar-components/create-campaign/CreateCampaign";
import EditCampaign from "../navbar-components/edit-campaign/EditCampaign";
import CreateFloInkCampaign from "../navbar-components/create-floink-campaign/CreateFloinkCampaign";
import ManageChannels from "../navbar-components/manage-channels/ManageChannels";
import ManageApps from "../navbar-components/manage-apps/ManageApps";
import ManagaeBitlinkChannel from "../navbar-components/manage-bitlink-channel/ManagaeBitlinkChannel";
import ManageBitlinkCampaign from "../navbar-components/manage-bitlink-campaign/ManageBitlinkCampaign";
import ShareLink from "../share-link/ShareLink";
import ResourcesComponent from "../navbar-components/resources/Resources"
import { Redirect } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { getSiteSetting, beforeSiteSettings } from '../../shared/site-settings/site-settings.action';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import { ENV } from '../../config/config';
import countriesCities from "countries-cities";
import Select from 'react-select';
import { countries_simple } from '../../shared/countries-simple.js';
import { color } from "highcharts";


// require('bootstrap-select');

class DashboardNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
      data: [],
      channelsData: [],
      selectedCampaign: "",
      selectedChannel: "",
      linkChannelIdToCampaignValue: "",
      redirect: false,
      username: "",
      accountType: "",
      fullName: "",
      userId: "",
      email: "",
      socialSigninPasswordCheck: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      isVerified: "",
      otherEmails: "",
      secondEmailToShow: "",
      phoneNumber: "",
      enabled2fa: false,
      numberCheckCall: false,
      secondEmailToShowStatus: true,
      codeNumber: "",
      verifiedEmails: [],
      ////register Oauth statessss
      oauthAppList: [],
      oauthRegistrationCode: "",
      appDetails: {},
      createdQlinkId: "",
      hideSearch: "",
      settings: {},
      csvfile: '',
      csvLog: [],
      csvReport: false,
      uploading: false,
      qrUrl: '',
      // added by rehan
      countries: [],
      country: '',
      citiesList: [{ value: '', label: '' }],
      city: 'City',
      zipCode: '',
      address: '',
      logOutRedirect: false
      // added by rehan

    };
    this.manageChildSearchField = React.createRef(); //by Uzair
    this.manageResourcesChild = React.createRef();
    this.manageShareChild = React.createRef();
    this.manageChannelsChild = React.createRef();
    this.manageAppsChild = React.createRef(); //by Uzair
    this.manageBitlinkChannelsChild = React.createRef();
    this.manageBitlinkCampaignsChild = React.createRef();
    this.manageCampaignChild = React.createRef();
    this.createfloinkCampaign = React.createRef();
    this.editCampaignChild = React.createRef();
    this.loginHistoryChild = React.createRef();
    this.editLinkChild = React.createRef();
    this.addBulkTagChild = React.createRef();
    this.manageChildInGeneric = React.createRef();
    this.deleteEmail = this.deleteEmail.bind(this);
    this.makePrimaryEmail = this.makePrimaryEmail.bind(this);
    this.secondaryEmailHandler = this.secondaryEmailHandler.bind(this);
  }

  componentWillMount = () => {
    if (localStorage.getItem("loginToken")) {
      var loginTime = parseInt(localStorage.getItem("loginTime")) / 1000;
      var timeNow = new Date().getTime() / 1000;
      var timeDifference = timeNow - loginTime;

      var timeDifferenceInMinutes = timeDifference / 60;
      if (timeDifferenceInMinutes < window.loginExpiresAfter) {
        this.setState({
          username: JSON.parse(localStorage.getItem("LoginSession")).data.username,
          userId: JSON.parse(localStorage.getItem("LoginSession")).data._id,
          redirect: false
        });
      } else {
        localStorage.removeItem("loginToken");
        localStorage.removeItem("LoginSession");
        localStorage.removeItem("loginTime");
        this.setState({ redirect: true });
      }
    } else {
      localStorage.removeItem("loginToken");
      localStorage.removeItem("LoginSession");
      localStorage.removeItem("loginTime");
      this.setState({ redirect: true });
    }
  };

  clearStates = () => { // for clear states in forms
    this.manageCampaignChild.current.clearStates();
  }

  removeSearch = () => {
    this.setState({
      hideSearch: 'off'
    })
  }

  clearInputField = () => {
    this.manageChildSearchField.current.clearChildInputField();
  }

  newLinkIdSetFunction = passedId => {
    this.setState({ createdQlinkId: passedId });
    this.editLinkChild.current.getLinkDataHash(passedId);

    //open bar when clicked from dashboard
    $("#nav").addClass("show");
    $(".menu").addClass("clicked");
    $("#account_info").hide();
    $("#edit_bitlink").show();
  };

  addBulkTagsFunction = selectedIds => {
    this.setState({ selectedIdsForBulk: selectedIds });
    this.addBulkTagChild.current.getCurrentTags(selectedIds);
    // //open bar when clicked from dashboard
    $("#nav").addClass("show");
    $(".menu").addClass("clicked");
    $(".data-state").hide();
    $("#bulk_tags_links").show();
  };

  updateDashBoard = (value, updateData = undefined) => {
    if (value && value !== undefined)
      this.props.updateDashboard(value, updateData);
    else
      this.props.updateDashboard(false, updateData);
  };

  componentDidMount = () => {
    let Country = countriesCities.getCountries();
    this.setState({
      countries: Country
    })
    this.getSiteSetting();
    this.jqueryCode();
    this.getUser();
    this.getRegisteredOauthApps();
    this.getCampaignsForSelect();
  };


  handleChangee = selectedOption => {
    let { country } = this.state;

    country = selectedOption.value;
    let cities = countriesCities.getCities(country);

    this.setState({ selectedOption, country });

    this.setState({ country: selectedOption, city: '' });

    let temp = [{ value: '', label: '' }]
    cities.forEach(element => {
      temp.push({
        value: element,
        label: element
      })
    });
    this.setState({
      citiesList: temp
    })
  };

  setCitiesList = () => {
    let { country } = this.state;
    if (country != "") {
      let cities = countriesCities.getCities(country);
      let temp = [{ value: '', label: '' }]
      cities.forEach(element => {
        temp.push({
          value: element,
          label: element
        })
      });
      this.setState({
        citiesList: temp
      })
    }
  }


  handleCityChangee = selectedCity => {
    let temp = selectedCity.value
    this.setState({
      city: temp
    })
  };

  getCampaignsForSelect = () => {
    fetch(
      window.APIURL + "campaign/select/camapaigns?userId=" + this.state.userId + "&lang=" + this.state.language + "&accessToken=" + localStorage.loginToken,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(res => {
        return res.json();
      })
      .then(result => {

        if (result.sessionExpired) {
          this.setState({ logOutRedirect: true })
          return;
        }
        else if (result.status === true) {
          let data = result.campaignsData;
          this.setState({ data: result.campaignsData });
        } else if (result.status === false) {
          swal(window.i18n('alert_a'), result.message, 'error');
        } else {
          swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
        }
      });
  }

  getRegisteredOauthApps = () => {
    if (localStorage.getItem("LoginSession") != null) {
      let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
      fetch(window.APIURL + "register-oauth-app/user-apps?userId=" + id + "&lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
        method: "get",
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {
          if (result.sessionExpired) {
            this.setState({
              logOutRedirect: true
            })
            return;
          }

          if (result.status === true) {
            let data = result.oAuthApps;
            this.setState({
              oauthAppList: data
            });
          } else if (result.status === false) {
            swal(window.i18n('alert_a'), result.message, 'error');
          } else {
            swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
          }
        });
    }
  };

  getSiteSetting = () => {
    fetch(`${ENV.url}site-settings/get-site-settings?lang=` + this.state.language, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': ENV.Authorization,
        'x-auth-token': ENV.x_auth_token
      }
    })
      .then(res => { return res.json() }).then(data => {
        if (data.status) {
          this.setState({
            settings: data.site
          });
        } else { }
      }).catch(errors => {
      })
  }

  delNumber(value) {
    this.setState({ phoneNumber: '', enabled2fa: false })
  }

  qrUrl(value) {
    this.setState({ qrUrl: value })
  }

  makePrimaryEmail = email => {
    Swal.fire({
      title: window.i18n('alert_az'),
      text: window.i18n('alert_ba'),
      showCancelButton: true,
      confirmButtonColor: '#0a6eb4',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes  '
    }).then((result) => {
      if (result.value) {
        fetch(window.APIURL + "accounts/member/make-email-primary?lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
          method: "post",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email: email,
            _id: this.state.userId
          })
        })
          .then(res => {
            if (res.status === 200) {
              return res.json();
            } else if (res.status === 403) {
              return res.json();
            } else if (res.status === 422) {
              return res.json();
            } else if (res.status === 404) {
              return res.json();
            } else {
              return res.json();
            }
          })
          .then(result => {

            if (result.sessionExpired) {
              this.setState({
                logOutRedirect: true
              })
              return;
            }


            if (result.status === true) {
              this.setState({ email: result.data.newPrimaryEmail });
              let localData = JSON.parse(localStorage.getItem("LoginSession"));
              localData.data.primaryEmail = result.data.newPrimaryEmail;
              localStorage.removeItem("LoginSession");

              localStorage.setItem("LoginSession", JSON.stringify(localData));
              this.setState({ secondEmailToShow: result.data.oldPrimaryEmail });
              this.otherEmailGetter();
              swal(window.i18n('alert_ay'), result.message, 'success')
            } else if (result.status === false) {
              swal(window.i18n('alert_a'), result.message, 'error');
            } else {
              swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
            }
          });
      }
    });
  };

  gotoContactUs = () => {
    this.props.history.push('/contact-us')
  }

  logOut = () => {
    localStorage.removeItem("loginToken");
    localStorage.removeItem("LoginSession");
    localStorage.removeItem("loginTime");
    localStorage.removeItem('newUser')

    let id = this.state.userId;
    fetch(window.APIURL + "accounts/logout?userId=" + id + "&lang=" + this.state.language, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        $("#changePlanBannerMainDiv").hide();
        this.setState({ redirect: true });
      });

  };

  getUser = () => {
    let id = this.state.userId;
    fetch(window.APIURL + "accounts/member/profile/" + id + "?lang=" + this.state.language, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) {
          this.setState({
            fullName: result.member.firstName + " " + result.member.lastName,
            username: result.member.username,
            accountType: result.member.accountType,
            subscriptionDetails: result.member.subscriptionDetails ? result.member.subscriptionDetails : {},
          });
          if (result.member.city) this.setState({ city: result.member.city })
          if (result.member.country) this.setState({ country: result.member.country })
          if (result.member.zipCode) this.setState({ zipCode: result.member.zipCode })
          if (result.member.address) this.setState({ address: result.member.address })
          this.setCitiesList();

          this.setState({ email: result.member.primaryEmail });
          this.setState({
            socialSigninPasswordCheck: result.member.isSetSocialSigninPassword
          });
          this.setState({ isVerified: result.member.isVerified });
          this.setState({ phoneNumber: result.member.phoneNumber });
          this.setState({ enabled2fa: result.member.isTwoStepVerification ? true : false });

          this.otherEmailGetter();
        } else if (result.status === false) {
          swal(window.i18n('alert_a'), result.message, 'error');
        } else {
          swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
        }
      });
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    if (e.target.name === 'selectedCampaign' && e.target.value !== "") {
      this.showChannel(e.target.value);
    }
    else if (e.target.name === 'selectedCampaign' && e.target.value === "") {
      this.setState({
        channelsData: [],
        selectedChannel: ""
      })
    }
  };

  showChannel = (id) => {
    this.state.data.map((data, index) => {
      if (data._id === id) {
        this.setState({
          channelsData: data.channel
        }, () => {
          setTimeout(() => {
            $('.my-select').selectpicker();
          });
        })
      }
    })

  }

  handleFullnameSubmit = e => {
    e.preventDefault();
    let { fullName } = this.state;

    if (fullName === "") {
      swal(window.i18n('alert_a'), window.i18n('alert_g'), 'error')
      return;
    } else {
      let firstN,
        lastN = "";
      let nameStrings = fullName.split(/ (.+)/);
      if (nameStrings.length > 1) {
        firstN = nameStrings[0];
        lastN = nameStrings[1];
      } else {
        firstN = nameStrings[0];
        lastN = "";
      }
      this.setState({ errors: "" });
      fetch(window.APIURL + "accounts/member/update?lang=" + this.state.language + "&accessToken=" + localStorage.loginToken, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          firstName: firstN,
          lastName: lastN,
          _id: this.state.userId
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else if (res.status === 401) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {

          if (result.sessionExpired) {
            this.setState({ logOutRedirect: true })
            return;
          }
          else if (result.status === true) {
            swal(window.i18n('alert_ay'), result.message, 'success')
          } else if (result.status === false) {
            swal(window.i18n('alert_a'), result.message, 'error');
          } else {
            swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
          }
        });
    }
  };

  codeVerificationForm = e => {
    e.preventDefault();
    let { codeNumber } = this.state;

    if (codeNumber === "") {
      swal(window.i18n('alert_a'), window.i18n('alert_h'), 'error')
      return;
    } else {
      fetch(window.APIURL + "accounts/member/verify-qr-code?lang=" + this.state.language, {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          codeNumber: codeNumber,
          _id: this.state.userId
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {
          if (result.status === true) {
            this.setState({ enabled2fa: true })
            $("#security").show();
            $("#security_phone").hide();
            swal(window.i18n('alert_ay'), result.message, 'success')
          } else if (result.status === false) {
            swal(window.i18n('alert_a'), result.message, 'error');
          } else {
            swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
          }
        });
    }
  };

  passwordHandle = e => {
    e.preventDefault();
    let {
      currentPassword,
      newPassword,
      confirmPassword,
      socialSigninPasswordCheck,
      userId
    } = this.state;

    if (socialSigninPasswordCheck != true && currentPassword === "") {
      swal(window.i18n('alert_a'), window.i18n('alert_i'), 'error')
      return;
    } else if (newPassword === "") {
      swal(window.i18n('alert_a'), window.i18n('alert_j'), 'error')
      return;
    } else if (confirmPassword === "") {
      swal(window.i18n('alert_a'), window.i18n('alert_k'), 'error')
      return;
    } else if (confirmPassword !== newPassword) {
      swal(window.i18n('alert_a'), window.i18n('alert_l'), 'error')
      return;
    } else {
      fetch(window.APIURL + "accounts/member/update?lang=" + this.state.language + "&accessToken=" + localStorage.loginToken, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: newPassword,
          currentPassword: currentPassword,
          userPasswordChange: true,
          socialSigninPasswordCheck: socialSigninPasswordCheck,
          _id: userId
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else if (res.status === 401) {
            return res.json();
          }
          else {
            return res.json();
          }
        })
        .then(result => {
          if (result.sessionExpired) {
            this.setState({ logOutRedirect: true })
            return;
          }
          else if (result.status === true) {
            this.setState({
              socialSigninPasswordCheck: result.member.socialSigninPasswordCheck,
              currentPassword: "",
              newPassword: "",
              confirmPassword: ""
            })
            swal(window.i18n('alert_ay'), result.message, 'success')
          } else if (result.status === false) {
            swal(window.i18n('alert_a'), result.message, 'error');
          } else {
            swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
          }
        });
    }
  };

  deleteEmail = email => {
    let otherEmailsBackup = this.state.otherEmails;
    Swal.fire({
      title: window.i18n('alert_az'),
      text: window.i18n('alert_bb'),
      showCancelButton: true,
      confirmButtonColor: '#0a6eb4',
      cancelButtonColor: '#d33',
      confirmButtonText: window.i18n('global_yesDel')
    }).then((result) => {
      if (result.value) {
        fetch(window.APIURL + "accounts/member/other-email-delete?lang=" + this.state.language + "&accessToken=" + localStorage.loginToken
          , {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: email,
              _id: this.state.userId
            })
          })
          .then(res => {
            if (res.status === 200) {
              return res.json();
            } else if (res.status === 403) {
              return res.json();
            } else if (res.status === 422) {
              return res.json();
            } else if (res.status === 404) {
              return res.json();
            } else {
              return res.json();
            }
          })
          .then(result => {

            if (result.sessionExpired) {
              this.setState({ logOutRedirect: true })
              return;
            }
            else if (result.status === true) {
              const index = otherEmailsBackup.findIndex(
                item => item.email === email
              );
              otherEmailsBackup.splice(index, 1);
              this.setState({
                otherEmails: otherEmailsBackup
              });
              $("#email_status_secondary").hide();
              $("#edit_email").show();

              swal(window.i18n('alert_ay'), result.message, 'success')
            } else if (result.status === false) {
              swal(window.i18n('alert_a'), result.message, 'error');
            } else {
              swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
            }
          });
      }
    })
  };

  verificationForm = email => {
    fetch(window.APIURL + "accounts/member/verification-email?lang=" + this.state.language, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) {
          swal(window.i18n('alert_ay'), result.message, 'success')
        } else if (result.status === false) {
          swal(window.i18n('alert_a'), result.message, 'error');
        } else {
          swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
        }
      });
  };

  otherEmailFormHandler = e => {
    e.preventDefault();
    let otherEmailsBackup = this.state.otherEmails;
    let { otherEmail } = this.state;
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (otherEmail === "") {
      swal(window.i18n('alert_a'), window.i18n('alert_m'), 'error')
      return;
    } else if (!otherEmail.match(regexp)) {
      swal(window.i18n('alert_a'), window.i18n('alert_n'), 'error')
      return;
    }
    fetch(window.APIURL + "accounts/member/other-email?lang=" + this.state.language, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        otherEmail: otherEmail,
        _id: JSON.parse(localStorage.getItem("LoginSession")).data._id
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) {
          otherEmailsBackup.push(result.data);
          this.setState({ otherEmails: otherEmailsBackup, otherEmail: "" });
          swal(window.i18n('alert_ay'), result.message, 'success')
        } else if (result.status === false) {
          swal(window.i18n('alert_a'), result.message, 'error');
        } else {
          swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
        }
      });
  };


  addressHandle = e => {
    e.preventDefault();
    if (this.state.address === "") {
      swal(window.i18n('alert_a'), window.i18n('add_req'), 'error')
      return;
    } else if (this.state.zipCode === "") {
      swal(window.i18n('alert_a'), window.i18n('zip_req'), 'error')
      return;
    }
    else if (this.state.country === "") {
      swal(window.i18n('alert_a'), window.i18n('alert_ag'), 'error')
      return;
    }
    else if (this.state.city === "") {
      swal(window.i18n('alert_a'), window.i18n('city_req'), 'error')
      return;
    }
    fetch(window.APIURL + "accounts/member/update?lang=" + this.state.language + "&accessToken=" + localStorage.loginToken, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        address: this.state.address,
        zipCode: this.state.zipCode,
        city: this.state.city,
        country: this.state.country.value,
        _id: JSON.parse(localStorage.getItem("LoginSession")).data._id
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else if (res.status === 401) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.sessionExpired) {
          this.setState({ logOutRedirect: true })
          return;
        }
        else if (result.status === true) {
          localStorage.setItem("country", result.member.country)
          // this.setState({ city: result.member.city, country: result.member.country, address: result.member.address, zipCode: result.zipCode });
          swal(window.i18n('alert_ay'), result.message, 'success')
        } else if (result.status === false) {
          swal(window.i18n('alert_a'), result.message, 'error');
        } else {
          swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
        }
      });
  }




  otherEmailGetter = () => {
    if (JSON.parse(localStorage.getItem("LoginSession")))
      fetch(window.APIURL + "accounts/member/get-other-email?lang=" + this.state.language, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          _id: JSON.parse(localStorage.getItem("LoginSession")).data._id
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 400) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {
          if (result.status === true) {
            let tempVerifiedEmails = [];
            let data = result.data;
            data.map((data, index) => {
              if (data.isActivated === true) {
                let temp = {
                  email: data.email,
                  isNotification: data.isNotification,
                  type: "other"
                };
                tempVerifiedEmails.push(temp);
              }
            });
            let index = tempVerifiedEmails.findIndex(
              x => x.isNotification === true
            );
            if (index === -1) {
              tempVerifiedEmails.push({
                email: this.state.email,
                isNotification: true,
                type: "primary"
              });
            } else {
              tempVerifiedEmails.push({
                email: this.state.email,
                isNotification: false,
                type: "primary"
              });
            }
            this.setState({ verifiedEmails: tempVerifiedEmails });
            this.setState({
              otherEmails: result.data
            });
          } else if (result.status === false) {
            swal(window.i18n('alert_a'), result.message, 'error');
          } else {
            swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
          }
        });
  };

  jqueryCode = () => {
    let location = window.location;
    if (location.hash !== "" && location.hash.split("&").length > 0) {
      var locationHash = location.hash.split("&");
      locationHash = locationHash[locationHash.length - 1].split("=")[1];
      if (locationHash !== undefined && locationHash !== "") {
        $(".menu, .overlay, #group_info, #crt_bit,#clossse,#campaign").click();
        display_menu(locationHash, true);
      }
    }
    $(".menu, .overlay, #group_info, #crt_bit,#clossse,#campaign").click(
      function () {
        if (!$(".menu").hasClass("clicked")) {
          display_menu("account_info", false);
        } else {
          $(".menu").removeClass("clicked");
          $("#nav").removeClass("show");
          $("#account_info").hide();
          $(".action-sheet--wrapper-MAIN").hide();
          location.hash = "";
        }
      }
    );

    $("body").on("click", ".checklist-menu-item", function () {
      display_menu($(this).attr("data-id"), false);
    });

    $(".back_btn-icon").click(function () {
      var hashLocation = location.hash.replace("#", "").split("&");
      var open_div = hashLocation[hashLocation.length - 2];
      var replace = hashLocation[hashLocation.length - 1];
      hashLocation = hashLocation.join("&").replace("&" + replace, "");
      location.hash = hashLocation;
      if (open_div !== undefined && open_div !== "") {
        open_div = open_div.split("=");
        if (open_div[1] !== undefined && open_div[1] !== "") {
          open_div = open_div[1];
          display_menu(open_div, true);
        } else {
          $(".menu").removeClass("clicked");
          $("#nav").removeClass("show");
          $("#account_info").hide();
          $(".action-sheet--wrapper-MAIN").hide();

          location.hash = "";
        }
      } else {
        $(".menu").removeClass("clicked");
        $("#nav").removeClass("show");
        $("#account_info").hide();
        $(".action-sheet--wrapper-MAIN").hide();
        location.hash = "";
      }
    });

    function display_menu(data_url_id = "", is_back = false) {
      $(".menu").addClass("clicked");
      $("#nav").addClass("show");
      $(".data-state").hide();
      $("#" + data_url_id).show();
      if (!is_back) {
        if (location.hash === "") {
          location.hash = "?action=" + data_url_id;
        } else {
          let lsplit = location.hash.split('&action=');
          if (lsplit[lsplit.length - 1] != data_url_id) {
            location.hash = location.hash + "&action=" + data_url_id;
          }

        }
      }
    }
  };

  secondaryEmailHandler = (email, status) => {
    $("#edit_email").hide();
    this.setState({
      secondEmailToShow: email,
      secondEmailToShowStatus: status
    });
    $("#email_status_secondary").show();
  };

  updateData(value) {
    this.getUser();
  }

  getDetailApp() {
    let rCode = this.state.oauthRegistrationCode;
    fetch(window.APIURL + "register-oauth-app/get?registerationCode=" + rCode + "&lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.sessionExpired) {
          this.setState({
            logOutRedirect: true
          })
          return;
        }



        if (result.status === true) {
          let data = result.oAuthApp;
          this.setState({
            appDetails: data
          });
          $("#app_details").show();
          $("#app_list").hide();
        } else if (result.status === false) {
          swal(window.i18n('alert_a'), result.message, 'error');
        } else {
          swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
        }
      });
  }

  childToParent(from, data) {
    if (from === "appList") {
      this.setState(
        {
          oauthRegistrationCode: data
        },
        () => {
          this.getDetailApp();
        }
      );
    } else if (from === "editOauth") {
      this.setState(
        {
          appDetails: data
        },
        () => {
          $("#app_details").show();
          $("#edit_app").hide();
        }
      );
    } else if (from === "editSecret") {
      this.setState({
        appDetails: data
      });
    }
  }

  getSearchQuery(searchValue) {
    if (searchValue !== undefined) {
      this.props.fromParent(searchValue); //passing value to dashboard Layout
    }
  }

  getChannelsParentCall = () => {
    this.manageCampaignChild.current.getChannelsForCampaign();
  };

  manageChannelsFun = () => {
    this.manageChannelsChild.current.getChannelsFunction();
  };

  manageAppsFunc = () => {
    this.manageAppsChild.current.getAppsFunction();
    this.manageAppsChild.current.activeAppsFunc();
  };

  callChildHistoryFunction = () => {
    this.loginHistoryChild.current.getLoginHistory(this.state.email);
  };

  manageBitlinkChannelsChildFun = () => {
    let hash = window.location.hash;
    if (hash !== "" && hash.split("&").length > 0) {
      let newHash = hash + "&action=manage_bitlink_channels";
      window.location.hash = newHash;
      $("#edit_bitlink").hide();
      $("#manage_bitlink_channels").show();
    }

    this.manageBitlinkChannelsChild.current.getChannelsFunction();
  };

  linkChannelIdToCampaign = id => {
    this.setState({ linkChannelIdToCampaignValue: id });
    this.manageBitlinkCampaignsChild.current.getCampaignsFunction(id);
  };

  shareLink = (hash, dom) => {
    let urlHash = window.location.hash;
    var splittedHash = urlHash.split("=");

    if (splittedHash.length > 1) {
      urlHash = urlHash + "&action=share_link"
    } else {
      urlHash = urlHash + "?action=share_link"
    }
    window.location.hash = urlHash;
    this.manageShareChild.current.shareLinkFunc(hash, dom, this.state.userId);
  }

  updateChannelsFromChild = (created) => {
    this.manageCampaignChild.current.getChannelsForCampaign(created);
    this.manageBitlinkChannelsChild.current.getChannelsFunction();
  }

  updateAppsFromChild = (created) => {
    this.manageChildInGeneric.current.getAppsForGenericToken(created);
  }

  editCampaignFunction = (param) => {
    this.editCampaignChild.current.channelData(param);
    window.location.hash = "?action=edit_campaign"
    //open bar when clicked from dashboard
    $("#nav").addClass("show");
    $(".menu").addClass("clicked");
    $("#account_info").hide();
    $("#edit_campaign").show();
  }

  reloadDashboard = (value, updateData = undefined) => {
    if (value && value !== undefined) {
      this.props.reloadDashboard(value, updateData);
    } else {
      this.props.reloadDashboard(false, updateData);
    }
  }

  newFloinkCamapignFunction = (param) => {
    this.createfloinkCampaign.current.campaignChannelData(param);
    window.location.hash = "#?action=create_floink_campaign"
    //open bar when clicked from dashboard
    $("#nav").addClass("show");
    $(".menu").addClass("clicked");
    $("#account_info").hide();
    $("#create_floink_campaign").show();
  }

  getResourcesData = () => {
    this.manageResourcesChild.current.getData();
  }

  createDashboardLink = () => {
    window.location.hash = "#?action=account_info&action=create_bitlink"
    $("#nav").addClass("show");
    $(".menu").addClass("clicked");
    $("#account_info").hide();
    $("#create_bitlink").show();
  }

  createDashboardCampaign = () => {
    window.location.hash = "#?action=create_campaign"
    $("#nav").addClass("show");
    $(".menu").addClass("clicked");
    $("#account_info").hide();
    $("#create_campaign").show();
    this.manageCampaignChild.current.getChannelsForCampaign();
  }

  showProfile = () => {
    // this.ShowProfile()
    window.location.hash = "#?action=account_info&action=edit_profile"
    $("#nav").addClass("show");
    $(".menu").addClass("clicked");
    $("#account_info").hide();
    $(".data-state").hide();
    $("#edit_profile").show();
  }

  createSidebarLink = () => {
    window.location.hash = "#?action=create_bitlink"
    $("#nav").addClass("show");
    $(".menu").addClass("clicked");
    $("#create_campaign").hide();
    $("#create_bitlink").show();
    this.manageCampaignChild.current.getChannelsForCampaign();
  }

  goToBilling = () => {
    if (this.props.history) {
      this.props.history.push("/organization/detail");
    }
    else {
      window.location.href = "/web/organization/detail";
    }
  }

  onChangeFile = (e) => {
    let file = e.target.files[0];
    if (file)
      if (file.type.includes('csv') || file.type.includes('application/vnd.ms-excel'))
        this.setState({ csvfile: file })
  }

  onCancel = () => {
    this.setState({ csvfile: '' })
    this.csvupload.value = ''
  }

  onUplaod = () => {
    if (this.state.csvfile === '') {
      swal(window.i18n('alert_a'), window.i18n('alert_o'), 'error')
      return;
    } else if (this.state.selectedCampaign !== "" && this.state.selectedChannel === "") {
      swal(window.i18n('alert_a'), window.i18n('alert_p'), 'error')
      return;
    } else {
      var formData = new FormData();
      let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
      formData.append("csvfile", this.state.csvfile);
      formData.append("userID", id)
      if (this.state.selectedCampaign !== "")
        formData.append("campaignId", this.state.selectedCampaign)
      if (this.state.selectedChannel !== "")
        formData.append("channelId", this.state.selectedChannel)
      this.setState({
        uploading: true
      }, () => {
        fetch(window.APIURL + "q-link/csvimport?lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
          method: "post",
          headers: {},
          body: formData
        })
          .then(res => {
            if (res.status === 200) {
              return res.json();
            } else if (res.status === 403) {
              return res.json();
            } else if (res.status === 422) {
              return res.json();
            } else if (res.status === 404) {
              return res.json();
            } else {
              return res.json();
            }
          })
          .then(result => {
            if (result.sessionExpired) {
              this.setState({
                logOutRedirect: true
              })
              return;
            }

            if (result.status === true) {
              let data = result.data;
              this.updateDashBoard(true);
              this.setState({ csvfile: '', selectedCampaign: "", selectedChannel: "", csvLog: data.length > 0 ? data : "", csvReport: data.length > 0 ? true : false, uploading: false });
              this.csvupload.value = '';
              swal(window.i18n('alert_ay'), result.message, 'success')
            } else if (result.status === false) {
              this.setState({ csvfile: '', selectedCampaign: "", selectedChannel: "", uploading: false });
              this.csvupload.value = '';
              swal(window.i18n('alert_a'), result.message, 'error');
            } else {
              this.setState({ csvfile: '', selectedCampaign: "", selectedChannel: "", uploading: false });
              this.csvupload.value = '';
              swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
            }
          })
      });
    }

  }




  render() {

    const { redirect, email, userId, country, city, logOutRedirect } = this.state;
    let { fromAccountDetails } = this.props;
    if (redirect) {
      window.location.href = "/web/logout-and-clear-session" //<Redirect to="/logout-and-clear-session" />;
    }

    if (logOutRedirect) {
      window.location.href = "/web/logout-and-clear-session" //<Redirect to="/logout-and-clear-session" />;
    }

    return (
      <React.Fragment>
        <header className="user-admin">
          <div className="main-header">
            <div className="container-fluid">
              <div className="menu-outsite">
                <NavbarDropdown
                  userID={this.state.userId}
                  siteSettings={this.state.settings}
                  subscriptionDetails={this.state.subscriptionDetails ? this.state.subscriptionDetails : {}}
                />

                <span className="group" onClick={this.showProfile}>
                  <span
                    className="headerUserName"
                  >
                    {this.state.username != "" ?
                      this.state.username.toUpperCase() :
                      ""
                    }
                  </span>
                </span>

                {/* TODO: add create bitlinks component */}
                {fromAccountDetails === "yes" ? (
                  ""
                ) : (
                  <NavbarCreateBitlink
                    createDashboardCampaign={this.createDashboardCampaign}
                    subscriptionDetails={this.state.subscriptionDetails ? this.state.subscriptionDetails : {}}
                  />
                )}
                {(fromAccountDetails === "yes" || this.state.hideSearch === "off") ? (
                  ""
                ) : (
                  this.state.subscriptionDetails && this.state.subscriptionDetails.linkFiltering ?
                    <NavbarSearch
                      ref={this.manageChildSearchField}
                      searchFunc={this.getSearchQuery.bind(this)} //passing function to search child to perform his action
                      subscriptionDetails={this.state.subscriptionDetails ? this.state.subscriptionDetails : {}}
                      changeLoaderStatus={this.props.changeLoaderStatus}
                    /> : ''
                )}

                <div className="menu" onClick={this.clearStates}>
                  {" "}
                  <span />{" "}
                </div>
              </div>
            </div>
          </div>
        </header>
        <div id="nav">
          <div
            className="main action-sheet--wrapper-MAIN data-state"
            style={{}}
            id="account_info"
          >
            <div
              className="action-sheet--ALTERNATE"
              data-selected-sheet="accountMain"
              style={{
                animation: "600ms ease 0ms 1 normal forwards running open-sheet"
              }}
            >
              <div className="action-sheet--header">
                <h4 className="action-sheet--header-text">{window.i18n('dashNav_yourAcc')}</h4>
                <Link
                  className="action-sheet--upsell"
                  to={"/organization/subscriptions/" + userId}
                >
                  <span className="unlock-icon" alt="unlock-icon">
                    <FontAwesomeIcon icon="unlock" />
                  </span>
                  {window.i18n('accDet_w')}
                </Link>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <div
                  className="user-account--ALTERNATE checklist-menu-item"
                  data-id="edit_profile"
                >
                  <img
                    className="user-account--image"
                    src={require("../../images/sidebar-logo-icon.svg")}
                  />
                  <div className="user-account--user-info">
                    <div
                      title={this.state.fullName}
                      className="user-account--user-name text-truncate"
                    >
                      {this.state.fullName}
                    </div>
                    <div
                      title={this.state.email}
                      className="user-account--email text-truncate"
                    >
                      {this.state.email}
                    </div>
                  </div>
                  <div className="user-account--icon">
                    <span className="selector-icon" alt="selector-icon">
                      <FontAwesomeIcon icon="chevron-right" />
                    </span>
                  </div>
                </div>
                <div className="hide-desktop links">
                  <Link to={'/dashboard/stats'}>
                    <div className="select-item--ALTERNATE">
                      <p className="select-item--label">{window.i18n('dashNav_dash')}</p>
                      <span className="clicks-icon" alt="clicks-icon" />
                    </div>
                  </Link>
                  <Link to={'/dashboard'}>
                    <div className="select-item--ALTERNATE">
                      <p className="select-item--label">{window.i18n('dashNav_nedMange')}</p>
                      <span
                        className="no_bitlinks-icon"
                        alt="no_bitlinks-icon"
                      />
                    </div>
                  </Link>
                  <Link to={'/dashboard/campaigns'}>
                    <div className="select-item--ALTERNATE">
                      <p className="select-item--label">{window.i18n('global_campaigns')}</p>
                      <span className="binoculars-icon" alt="binoculars-icon" />
                    </div>
                  </Link>
                  <Link to="/organization/domain">
                    <div className="select-item--ALTERNATE">
                      <p className="select-item--label">{window.i18n('global_customDom')}</p>
                      <span className="binoculars-icon" alt="binoculars-icon" />
                    </div>
                  </Link>
                </div>
                <div>
                  <div className="checklist--wrapper ">
                    <div className="checklist--items">
                      <div className="checklist-row--MAIN neutral checklist-menu-item" data-id="settings">
                        <div className="checklist-row--text"> {window.i18n('dashNav_settings')}</div>
                        <span className="checklist-row--icon">
                          <span className="selector-icon" alt="selector-icon">
                            <FontAwesomeIcon icon="chevron-right" />
                          </span>
                        </span>
                      </div>

                      {/* why FLO.ink */}
                      {/* <div className="signout p-0 m-0" onClick={() => { this.props.history.push('/why-nedlink') }} >
                        <div className="select-item--ALTERNATE">
                          <p className="select-item--label p-0">{window.i18n('mainNavbar_whyNed')}</p>
                        </div>
                      </div> */}

                      {/* solutions */}
                      {/* <div className="signout p-0 m-0" onClick={() => { this.props.history.push('/solutions') }} >
                        <div className="select-item--ALTERNATE">
                          <p className="select-item--label p-0">{window.i18n('global_solutions')}</p>
                        </div>
                      </div> */}

                      {/* features */}
                      {/* <div className="signout p-0 m-0" onClick={() => { this.props.history.push('/features') }} >
                        <div className="select-item--ALTERNATE">
                          <p className="select-item--label p-0">{window.i18n('global_features')}</p>
                        </div>
                      </div> */}

                      {/* pricing */}
                      {/* <div className="signout p-0 m-0" onClick={() => { this.props.history.push(`/organization/subscriptions/${userId}`) }} >
                        <div className="select-item--ALTERNATE">
                          <p className="select-item--label p-0">{window.i18n('global_pricing')}</p>
                        </div>
                      </div> */}

                      <div className="checklist-row--MAIN neutral checklist-menu-item" data-id="resources" onClick={this.getResourcesData}>
                        <div className="checklist-row--text"> {window.i18n('global_resourses')}</div>
                        <span className="checklist-row--icon">
                          <span className="selector-icon" alt="selector-icon">
                            <FontAwesomeIcon icon="chevron-right" />
                          </span>
                        </span>
                      </div>

                      <div className="checklist-row--MAIN neutral checklist-menu-item" data-id="resources" onClick={this.logOut}>
                        <div className="checklist-row--text">{window.i18n('dashNav_signOut')}</div>
                        <span className="checklist-row--icon">
                          <span className="selector-icon" alt="selector-icon">
                            <FontAwesomeIcon icon="sign-out-alt" />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <button
                  className="signout p-0 m-0"
                  onClick={this.gotoContactUs}
                >
                  <div className="select-item--ALTERNATE">
                    <p className="select-item--label p-0">{window.i18n('global_contactUS')}</p>
                    <span className="select-item--secondary-label" />
                    <span className="select-item--third-label " />
                    <span className="select-item--fourth-label" />
                    <span className="none" alt="none" />
                  </div>
                </button> */}
                {/* <button
                  className="signout p-0"
                  onClick={this.logOut}
                >
                  <div className="select-item--ALTERNATE">
                    <p className="select-item--label">{window.i18n('dashNav_signOut')}</p>
                    <span className="select-item--secondary-label" />
                    <span className="select-item--third-label " />
                    <span className="select-item--fourth-label" />
                    <span className="none" alt="none" />
                  </div>
                </button> */}
                <div className="marketing-module--GHOST d-none">
                  <p className="marketing-module--text">
                    {window.i18n('dashNav_proTip')}{" "}
                    <a target="_blank" href="#">
                      {window.i18n('dashNav_learnHow')}
                      <span className="arrow-icon" alt="arrow-icon">
                        <FontAwesomeIcon icon="arrow-right" />
                      </span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="0"
            style={{ display: "none" }}
            id="edit_profile"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="profile"
              style={{
                animation: "600ms ease 0ms 1 normal forwards running open-sheet"
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">{window.i18n('dashNav_editProf')}</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <form onSubmit={this.handleFullnameSubmit}>
                  <div className="input-field--wrapper">
                    <div className="input-field--MAIN   ">
                      <label className="input-field--label">{window.i18n('dashNav_fullName')}</label>
                      <div className="input-field--input-wrapper">
                        <input
                          type="text"
                          className="input-field--input"
                          autoComplete="false"
                          maxLength="100"
                          placeholder="Enter Name"
                          name="fullName"
                          onChange={this.onChange}
                          value={this.state.fullName}
                        />
                      </div>
                    </div>
                  </div>

                  {/* added by rehan */}
                  <div
                    className="checklist-row--MAIN neutral checklist-menu-item"
                    data-id="edit_address"
                  >
                    <div className="checklist-row--text"> {window.i18n('ds_ad_s')}</div>
                    <span className="checklist-row--icon">
                      <span className="selector-icon" alt="selector-icon">
                        <FontAwesomeIcon icon="chevron-right" />
                      </span>
                    </span>
                  </div>
                  {/* added by rehan */}
                  <div
                    className="checklist-row--MAIN neutral checklist-menu-item"
                    data-id="edit_email"
                  >
                    <div className="checklist-row--text"> {window.i18n('global_email')}</div>
                    <span className="checklist-row--icon">
                      <span className="selector-icon" alt="selector-icon">
                        <FontAwesomeIcon icon="chevron-right" />
                      </span>
                    </span>
                  </div>
                  <div
                    className="checklist-row--MAIN neutral checklist-menu-item"
                    data-id="change_password"
                  >
                    <div className="checklist-row--text"> {window.i18n('dashNav_changePass')}</div>
                    <span className="checklist-row--icon">
                      <span className="selector-icon" alt="selector-icon">
                        <FontAwesomeIcon icon="chevron-right" />
                      </span>
                    </span>
                  </div>
                  <div
                    className="checklist-row--MAIN neutral checklist-menu-item"
                    data-id="security"
                    onClick={this.callChildHistoryFunction}
                  >
                    <div className="checklist-row--text"> {window.i18n('dashNav_Secu')}</div>
                    <span className="checklist-row--icon">
                      <span className="selector-icon" alt="selector-icon">
                        <FontAwesomeIcon icon="chevron-right" />
                      </span>
                    </span>
                  </div>
                  {
                    this.state.subscriptionDetails && this.state.subscriptionDetails.integrations ?
                      <div className="checklist-row--MAIN neutral checklist-menu-item" data-id="token">
                        <div className="checklist-row--text">
                          {window.i18n('global_genAccessTok')}
                        </div>
                        <span className="checklist-row--icon">
                          <span className="selector-icon" alt="selector-icon">
                            <FontAwesomeIcon icon="chevron-right" />
                          </span>
                        </span>
                      </div> : ''
                  }
                  <div
                    className="checklist-row--MAIN neutral checklist-menu-item"
                    data-id="notification"
                  >
                    <div className="checklist-row--text"> {window.i18n('dashNav_notif')}</div>
                    <span className="checklist-row--icon">
                      <span className="selector-icon" alt="selector-icon">
                        <FontAwesomeIcon icon="chevron-right" />
                      </span>
                    </span>
                  </div>
                  <div
                    className="checklist-row--MAIN neutral"
                    data-id="go_to_billing"
                    onClick={() => this.goToBilling()}
                  >
                    <div className="checklist-row--text"> {window.i18n('dashNav_billing')}</div>
                    <span className="checklist-row--icon">
                      <span className="selector-icon" alt="selector-icon">
                        <FontAwesomeIcon icon="chevron-right" />
                      </span>
                    </span>
                  </div>
                  <span className="deactivate-account--link d-none">
                    <span>{window.i18n('dashNav_reqGdpr')}</span>
                    <div className="tooltip--ALTERNATE">
                      <div className="tooltip--content tooltip--content-narrow">
                        {window.i18n('dashNav_clickRep')}
                      </div>
                    </div>
                  </span>
                  <div className="text-center">
                    <span className="deactivate-account--link checklist-menu-item mt-4" data-id="delete_user">{window.i18n('dashNav_delAcc')}</span>
                  </div>
                  <div className="action-sheet--button-wrapper">
                    <button
                      className="button--SOLID-SECONDARY"
                      type="submit"
                      tabIndex="0"
                      id=""
                    >
                      {window.i18n('dashNav_save')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* added by rehan */}
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="1"
            style={{ display: "none" }}
            id="edit_address"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="emailList"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">{window.i18n('ds_ad_c')}</h4>

              </div>
            </div>



            <div className="action-sheet--content-wrapper">
              <form onSubmit={this.addressHandle}>
                <div>
                  <div className="input-field--wrapper">
                    <div className="input-field--MAIN   ">
                      <label className="input-field--label" >{window.i18n('ds_st')}</label>
                      <div className="input-field--input-wrapper">
                        <input
                          type="text"
                          name="address"
                          className="input-field--input"
                          autoComplete="false"
                          value={this.state.address}
                          onChange={this.onChange}
                        />
                      </div>

                      <label className="input-field--label" style={{ marginTop: '8px' }}>{window.i18n('ds_zc')}</label>
                      <div className="input-field--input-wrapper">
                        <input
                          type="text"
                          name="zipCode"
                          className="input-field--input"
                          autoComplete="false"
                          value={this.state.zipCode}
                          onChange={this.onChange}
                        />
                      </div>

                      <label className="input-field--label" style={{ marginTop: '8px' }}>{window.i18n('ds_cr')}</label>

                      <div className="input-field--input-wrapper">
                        <Select
                          styles={{
                            option: () => {
                              return {
                                color: 'black'
                              };
                            }
                          }}
                          value={this.state.selectedOption}
                          onChange={this.handleChangee}
                          placeholder={country === '' ? "Country" : country}
                          classNamePrefix="react-select"
                          isSearchable={true}
                          options={countries_simple}
                          isOptionDisabled={(option) => option.isdisabled}
                          components={{
                            IndicatorSeparator: () => null
                          }}
                        />
                      </div>

                      <label className="input-field--label" style={{ marginTop: '08px' }}>{window.i18n('ds_ct')}</label>

                      <Select
                        styles={{
                          option: () => {
                            return {
                              color: 'black'
                            };
                          }
                        }}
                        isDisabled={this.state.country === ""}
                        value={this.state.citiesList.filter(cityV => this.state.city === cityV.value)}
                        onChange={this.handleCityChangee}
                        placeholder={city === '' ? "City" : city}
                        classNamePrefix="react-select"
                        isSearchable={true}
                        options={this.state.citiesList}
                        // isOptionDisabled={(option) => option.isdisabled}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="action-sheet--button-wrapper">
                  <button className="button--SOLID-SECONDARY" tabIndex="0">
                    {window.i18n('dashNav_save')}
                  </button>
                </div>
              </form>
            </div>


          </div>






          {/* added by rehan */}
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="1"
            style={{ display: "none" }}
            id="edit_email"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="emailList"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">{window.i18n('global_email')}</h4>
                <span className="close-iconnn">
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <div className="action-sheet--text mb-3">
                  {window.i18n('dashNav_onlyYour')} <b>{window.i18n('dashNav_primEmaiAdd')}</b> {window.i18n('dashNav_willBeUsed')}
                </div>
                <div className="email-info--wrapper checklist-menu-item" data-id="email_status_primary">
                  <div>
                    <div
                      className="checklist-row--MAIN neutral">
                      <div className="checklist-row--text primaryEmailId mb-2">
                        {this.state.email}
                      </div>
                      <span className="checklist-row--icon">
                        <span className="selector-icon" alt="selector-icon">
                          <FontAwesomeIcon icon="chevron-right" />
                        </span>
                      </span>
                    </div>
                    <div className="email-info--primary">
                      {window.i18n('dashNav_primEUsedF')}
                    </div>
                    <div className="email-status">
                      {window.i18n('global_yourEmailAddIs')}
                      <span className="email-verified">
                        {" "}
                        {this.state.isVerified ? (
                          <span className="email-verified">{window.i18n('global_verified')}</span>
                        ) : (
                          <span className="email-unverified">{window.i18n('global_notVerify')}</span>
                        )}{" "}
                      </span>
                      .
                    </div>
                  </div>
                </div>
                <div className="moreAddedEmailIds">
                  {this.state.otherEmails &&
                    this.state.otherEmails.length > 0 &&
                    this.state.otherEmails.map((data, index) => (
                      <div key={index}>
                        <div
                          className="email-info--wrapper"
                          onClick={() =>
                            this.secondaryEmailHandler(
                              data.email,
                              data.isActivated
                            )
                          }
                        >
                          <div>
                            <div
                              className="checklist-row--MAIN neutral checklist-menu-item"
                              data-id={"email_status_secondary"}
                            >
                              <div className="checklist-row--text">
                                {data.email}
                              </div>
                              <span className="checklist-row--icon">
                                <span
                                  className="selector-icon"
                                  alt="selector-icon"
                                />
                              </span>
                            </div>
                            <div className="email-status">
                              {window.i18n('global_yourEmailAddIs')}{" "}
                              {data.isActivated ? (
                                <span className="email-verified">{window.i18n('global_verified')}</span>
                              ) : (
                                <span className="email-unverified">
                                  {window.i18n('global_notVerify')}
                                </span>

                              )}
                            </div>
                            <span className="checklist-row--icon">
                              <span className="selector-icon" alt="selector-icon">
                                <FontAwesomeIcon icon="chevron-right" />
                              </span>
                            </span>

                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div
                  className="checklist-row--MAIN neutral checklist-menu-item"
                  data-id="add_email"
                >
                  <div className="checklist-row--text">{window.i18n('dashNav_addNewE')} </div>
                  <span className="checklist-row--icon">
                    <span className="selector-icon" alt="selector-icon">
                      <FontAwesomeIcon icon="chevron-right" />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="2"
            style={{ display: "none " }}
            id="email_status_primary"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="editEmail"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">{window.i18n('global_editEmail')}</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <div className="action-sheet--text">{this.state.email}</div>
                <div className="email-status">
                  {window.i18n('global_yourEmailAddIs')}{" "}
                  <span className="email-verified">
                    {this.state.isVerified ? (
                      <span className="email-verified">{window.i18n('global_verified')}</span>
                    ) : (
                      <span className="email-unverified">{window.i18n('global_notVerify')}</span>
                    )}
                  </span>
                  .
                </div>
                {this.state.isVerified ? (
                  ""
                ) : (
                  <div className="action-sheet--button-wrapper">
                    <button
                      className="email-verify-linkout"
                      onClick={() => this.verificationForm(this.state.email)}
                    >
                      {window.i18n('global_verifyYEmail')}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="2"
            style={{ display: "none " }}
            id="email_status_secondary"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="editEmail"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>
                </a>
                <h4 className="action-sheet--header-text">{window.i18n('global_editEmail')}</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <div className="action-sheet--text">
                  {this.state.secondEmailToShow}
                </div>
                <div className="email-status">
                  {window.i18n('global_yourEmailAddIs')}{" "}
                  {this.state.secondEmailToShowStatus
                    ?
                    <span className="email-verified">{window.i18n('global_verified')}</span>
                    :
                    <span className="email-unverified">{window.i18n('global_notVerify')}</span>
                  }
                </div>
                {this.state.secondEmailToShowStatus ? (
                  ""
                ) : (
                  <div className="action-sheet--button-wrapper">
                    <button
                      className="email-verify-linkout"
                      onClick={() =>
                        this.verificationForm(this.state.secondEmailToShow)
                      }
                    >
                      {window.i18n('global_verifyYEmail')}
                    </button>
                  </div>
                )}
                <br />
                <div className="action-sheet--button-wrapper">
                  <button
                    className="email-verify-linkout"
                    onClick={() => this.deleteEmail(this.state.secondEmailToShow)}
                  >
                    {window.i18n('dashNav_delThisEma')}
                  </button>
                </div>
                <br />
                {this.state.secondEmailToShowStatus ? (
                  <div className="action-sheet--button-wrapper">
                    <button
                      className="email-verify-linkout"
                      onClick={() =>
                        this.makePrimaryEmail(this.state.secondEmailToShow)
                      }
                    >
                      {window.i18n('dashNav_makeThisPrim')}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="2"
            style={{ display: "none " }}
            id="add_email"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="addEmail"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">{window.i18n('dashNav_addNewE')}</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <form onSubmit={this.otherEmailFormHandler}>
                  <div>
                    <div className="input-field--wrapper">
                      <div className="input-field--MAIN   ">
                        <label className="input-field--label">{window.i18n('global_email')}</label>
                        <div className="input-field--input-wrapper">
                          <input
                            type="email"
                            name="otherEmail"
                            className="input-field--input"
                            autoComplete="false"
                            value={this.state.otherEmail}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </div>
                    <p className="action-sheet--text">
                      <Link to="/resources/privacy-policy" >
                        {window.i18n('mainFooter_privacy')}
                      </Link>
                    </p>
                  </div>
                  <div className="action-sheet--button-wrapper">
                    <button className="button--SOLID-SECONDARY" tabIndex="0">
                      {window.i18n('dashNav_save')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="3"
            style={{ display: "none " }}
            id="change_password"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="password"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">{window.i18n('dashNav_changePass')}</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper button-visible">
                <form onSubmit={this.passwordHandle}>
                  {this.state.socialSigninPasswordCheck === true ?
                    <div style={{ "marginBottom": "1em" }}>
                      <span>{window.i18n('dashNav_youHavNotCre')}</span><br />
                      <span>{window.i18n('dashNav_plsCreNew')}</span><br />
                      <span>{window.i18n('dashNav_leavCurrPass')}</span><br />
                    </div>
                    :
                    <div></div>
                  }
                  <div className="input-field--wrapper">
                    <div className="input-field--MAIN   ">
                      <label className="input-field--label">
                        {window.i18n('dashNav_currentPass')}
                      </label>
                      <div className="input-field--input-wrapper">
                        <input
                          placeholder={window.i18n('dashNav_currentPass')}
                          name="currentPassword"
                          type="password"
                          className="input-field--input"
                          autoComplete="false"
                          onChange={this.onChange}
                          value={this.state.currentPassword}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-field--wrapper">
                    <div className="input-field--MAIN   ">
                      <label className="input-field--label">{window.i18n('dashNav_newPass')}</label>
                      <div className="input-field--input-wrapper">
                        <input
                          placeholder={window.i18n('dashNav_newPass')}
                          name="newPassword"
                          type="password"
                          className="input-field--input"
                          autoComplete="false"
                          onChange={this.onChange}
                          value={this.state.newPassword}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-field--wrapper">
                    <div className="input-field--MAIN   ">
                      <label className="input-field--label">
                        {window.i18n('global_confermPass')}
                      </label>
                      <div className="input-field--input-wrapper">
                        <input
                          placeholder={window.i18n('global_confermPass')}
                          type="password"
                          name="confirmPassword"
                          className="input-field--input"
                          autoComplete="false"
                          onChange={this.onChange}
                          value={this.state.confirmPassword}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="action-sheet--button-wrapper"
                    style={{
                      animation:
                        "700ms ease 0ms 1 normal forwards running show-apply-button "
                    }}
                  >
                    <button
                      className="button--SOLID-SECONDARY"
                      type="submit"
                      tabIndex="0"
                      id=""
                    >
                      {window.i18n('dashNav_save')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="4"
            style={{ display: "none " }}
            id="security"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="security"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">{window.i18n('dashNav_Secu')}</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <TwoWayAuth
                  delNumber={this.delNumber.bind(this)}
                  qrUrl={this.qrUrl.bind(this)}
                  enabled2fa={this.state.enabled2fa}
                  phoneNumber={this.state.phoneNumber}
                  userId={this.state.userId}
                  settings={this.state.settings}
                />
                {/* TODO: Add connected apps component here */}
                <LoginHistory
                  ref={this.loginHistoryChild}
                  primaryEmail={email}
                />
              </div>
            </div>
          </div>

          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="4"
            style={{ display: "none " }}
            id="security_phone"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="security"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">{window.i18n('dashNav_Secu')}</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <form onSubmit={this.codeVerificationForm}>
                  <div>
                    <div>
                      <h5 className="action-sheet--content-header">{window.i18n('dashNav_setUp')}</h5>
                      <div className="action-sheet--help-text">
                        <p>{window.i18n('twoWayOauth_j')}</p>
                      </div>
                    </div>
                    {this.state.qrUrl !== "" ?
                      <div className="py-3 text-center">
                        <img src={this.state.qrUrl} alt="Logo" />
                      </div>
                      :
                      ""
                    }
                    <div className="input-field--wrapper">
                      <div className="input-field--MAIN   ">
                        <label className="input-field--label">
                          <p>{window.i18n('twoWayOauth_k')}</p>
                        </label>
                        <div className="input-field--input-wrapper">
                          <input
                            type="text"
                            className="input-field--input"
                            name="codeNumber"
                            value={this.state.codeNumber}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="action-sheet--ghost-button-wrapper">
                    <button
                      className="button--GHOST-SECONDARY"
                      type="submit"
                      tabIndex="0"
                      id=""
                    >
                      {window.i18n('dashNav_enable')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="5"
            style={{ display: "none " }}
            id="token"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="accessToken"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              {/* .......................//Generic Access Token */}
              <GenericAccessToken
                userName={this.state.username}
                manageAppsFun={this.manageAppsFunc}
                ref={this.manageChildInGeneric}
              />
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="6"
            style={{ display: "none " }}
            id="User_group"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="brandList"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <h4 className="action-sheet--header-text">{window.i18n('dashNav_selAGrop')}</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              {/* .......................//Select Grop Generic Access Token */}
              <SelectGroup
                name={this.state.username}
                type={this.state.accountType}
              />
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="7"
            style={{ display: "none " }}
            id="registration_code"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="getRegistrationCodeSheet"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">
                  {window.i18n('dashNav_getRegCode')}
                </h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              {/* .....................................Authhh Get Registration Code........... */}
              <GetRegistrationCode
                verified={this.state.verifiedEmails}
                email={this.state.email}
              />
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state=""
            style={{ display: "none " }}
            id="add_app"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="createOrEditOauthApp"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">
                  {window.i18n('dashNav_regOauthApp')}
                </h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              {/* ............................/ Register Oauth App Addddddddd.................. */}
              <RegisterOauthApp
                theOauthFunction={this.childToParent.bind(this)}
              />
            </div>
          </div>

          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state=""
            style={{ display: "none " }}
            id="app_list"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="registeredOauthApps"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">
                  {window.i18n('dashNav_regOauthApps')}
                </h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              {/* ............................................../RegisterdOauth  Firsttttttttt.... */}
              <RegisteredOauthApps
                theOauthFunction={this.childToParent.bind(this)}
                registeredApps={this.state.oauthAppList}
              />
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state=""
            style={{ display: "none " }}
            id="app_details"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="registeredOauthApp"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">
                  {window.i18n('dashNav_regOauthApp')}
                </h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              {/* ............................................/RegisterdOauth Second........... */}
              <RegisteredOauthAppSecond
                theOauthFunction={this.childToParent.bind(this)}
                appDetail={this.state.appDetails}
              />
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state=""
            style={{ display: "none " }}
            id="edit_app"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="createOrEditOauthApp"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">
                  {window.i18n('dashNav_regOauthApp')}
                </h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              {/* ...................../Register OauthApp Edittt.................. */}
              <RegisterOauthAppEdit
                theOauthFunction={this.childToParent.bind(this)}
                appDetail={this.state.appDetails}
              />
            </div>
          </div>

          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="8"
            style={{ display: "none " }}
            id="api_group"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="brandDefaultApi"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">
                  {window.i18n('dashNav_setDefApiG')}
                </h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              {/* ............................../ Default Api Grop............... */}
              <SetDefaultApi
                userName={this.state.username}
                accountType={this.state.accountType.charAt(0).toUpperCase() + this.state.accountType.slice(1)}
              />
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="9"
            style={{ display: "none" }}
            id="notification"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="notifications"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">{window.i18n('dashNav_notif')}</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              {/* ............................//Notification.................. */}
              <Notification verified={this.state.verifiedEmails} />
            </div>
          </div>

          {/* ............................//Personal Notification.. */}
          <Personal
            dataBind={this.updateData.bind(this)}
            verified={this.state.verifiedEmails}
          />
          <Settings
            userID={this.state.userId}
            subscriptionDetails={this.state.subscriptionDetails ? this.state.subscriptionDetails : {}}
          />
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="12"
            style={{ display: "none" }}
            id="social_connect"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="sharingAccounts"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              {/* TODO: social settings components to be added here */}
              <SocialNetworks
                userID={this.state.userId}
                subscriptionDetails={this.state.subscriptionDetails ? this.state.subscriptionDetails : {}}
              />
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="13"
            style={{ display: "none" }}
            id="domains"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="setDefaultDomain"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">{window.i18n('dashNav_DomDeafault')}</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <p className="action-sheet--text">
                  {window.i18n('Your')} <strong>{window.i18n('dashNav_defaultDom')}</strong> {window.i18n('dashNav_willBeUsedFor')}
                </p>
                <div className="checklist--wrapper">
                  <div className="checklist--wrapper ">
                    <div className="checklist--items">
                      <div className="checklist-row--MAIN neutral back_btn-icon">
                        <div className="checklist-row--text"> {window.i18n('global_nedLink')}</div>
                        <span className="checklist-row--icon">
                          <div className="radio-check--LARGE" id="bit.ly">
                            <input
                              id="bit.ly"
                              type="radio"
                              name="bit.ly"
                              className="checkbox--input"
                              defaultValue="bit.ly"
                              defaultChecked=""
                            />
                            <label htmlFor="bit.ly" className="checkmark-icon">
                              <FontAwesomeIcon icon="check" />
                            </label>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="14"
            style={{ display: "none" }}
            id="advanced"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="advancedSettings"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">{window.i18n('dashNav_adanced')}</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <div className="advanced-settings--domain-settings">
                  <div
                    className="checklist-row--MAIN neutral checklist-menu-item "
                    data-id="api_support"
                  >
                    <div className="checklist-row--text">{window.i18n('global_apiSupport')}</div>
                    <span className="checklist-row--icon">
                      <span className="selector-icon" alt="selector-icon">
                        <FontAwesomeIcon icon="chevron-right" />
                      </span>
                    </span>
                  </div>
                </div>
                <div className="action-sheet--content-header no-b">
                  {window.i18n('dashNav_forDev')}
                </div>
                <div className="action-sheet--text">
                  {window.i18n('dashNav_aKeyAreB')}{" "}
                  <a href="#">{window.i18n('dashNav_oauth')}</a>.
                </div>
              </div>
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="15"
            style={{ display: "none" }}
            id="api_support"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="apiSupport"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">{window.i18n('global_apiSupport')}</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <div className="input-field--wrapper">
                  <div className="input-field--MAIN   ">
                    <label className="input-field--label">{window.i18n('dashNav_login')}</label>
                    <div className="input-field--input-wrapper">
                      <input
                        type="text"
                        className="input-field--input"
                        autoComplete="false"
                        defaultValue="o_6a1v8urbrl"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="input-field--wrapper">
                  <div className="input-field--MAIN   ">
                    <label className="input-field--label">{window.i18n('dashNav_apiKey')}</label>
                    <div className="input-field--input-wrapper">
                      <input
                        type="text"
                        className="input-field--input"
                        autoComplete="false"
                        defaultValue="R_184bcb4de5fc49a6b44b83325761461b"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <p className="action-sheet--text">
                  <a>{window.i18n('dashNav_resetApiKey')}</a>
                </p>
                <p className="action-sheet--text">
                  {window.i18n('dashNav_apiKeyAuth')}{" "}
                  <a href="#" target="_blank">
                    {window.i18n('dashNav_apiDoc')}
                  </a>{" "}
                  {window.i18n('dashNav_orManage')}{" "}
                  <a href="#" target="_blank">
                    {window.i18n('dashNav_genAccTok')}
                  </a>
                  .
                </p>
                <p className="action-sheet--text">
                  {window.i18n('dashNav_creatWithThis')}
                </p>
              </div>
            </div>
          </div>

          {/* TODO: Resources component    */}
          <ResourcesComponent
            ref={this.manageResourcesChild}
          />


          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="17"
            style={{ display: "none " }}
            id="edit_group"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="brandSwitcher"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <h4 className="action-sheet--header-text">{window.i18n('dashNav_selAGrop')}</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <div className="action-sheet--organization">
                  <div className="action-sheet--content-header cust">
                    <span className="personal-icon" alt="personal-icon">
                      <FontAwesomeIcon icon="user" />
                    </span>
                    <span className="action-sheet--org-name">{window.i18n('dashNav_personal')}</span>
                    <span className="action-sheet--text">
                      <Link to="/organization/detail">{window.i18n('dashNav_manage')}</Link>
                    </span>
                  </div>
                  <div className="checklist--wrapper">
                    <div className="checklist--wrapper ">
                      <div className="checklist--items">
                        <div
                          className="checklist-row--MAIN neutral"
                          id="clossse"
                        >
                          <div className="checklist-row--text">
                            {" "}
                            <span className='account-type-name text-capital'>{this.state.accountType} {window.i18n('dashNav_acc')}</span>
                            <span className="checklist-row--secondary-label account-type-value">
                              {this.state.username}
                            </span>
                          </div>
                          <span className="checklist-row--icon">
                            <div
                              className="radio-check--LARGE"
                              id="Pro Account"
                            >
                              <input
                                id="Pro Account"
                                type="radio"
                                name="Bj17dCscESZ"
                                className="checkbox--input"
                                defaultChecked=""
                              />
                              <label
                                htmlFor="Pro Account"
                                className="checkmark-icon"
                              >
                                <FontAwesomeIcon icon="check" />
                              </label>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            <BulkTagLink
              ref={this.addBulkTagChild}
              updateDashBoard={this.updateDashBoard} />
          }
          {/* TODO: add create bitlink component here */}
          <CreateBitlink
            updateDashBoard={this.updateDashBoard}
            call={this.callUpdate}
            newLinkIdSet={this.newLinkIdSetFunction} />

          {/* TODO: add edit bitlink component here */}
          <EditLink
            shareLink={this.shareLink}
            updateDashBoard={this.updateDashBoard}
            newLinkIdSet={this.newLinkIdSetFunction}
            ref={this.editLinkChild}
            editQlinkId={this.state.createdQlinkId}
            manageBitlinkChannelsChildFun={this.manageBitlinkChannelsChildFun}
            subscriptionDetails={this.state.subscriptionDetails ? this.state.subscriptionDetails : {}}
          />

          {/* TODO: add create campaign componenet here */}
          <CreateCampaign
            history={this.props.history}
            createLink={this.createSidebarLink}
            reloadDashboard={this.reloadDashboard}
            ref={this.manageCampaignChild}
            manageChannelsFun={this.manageChannelsFun}
          />

          <CreateFloInkCampaign
            reloadDashboard={this.reloadDashboard}
            ref={this.createfloinkCampaign}
            newLinkIdSet={this.newLinkIdSetFunction}
          />

          <EditCampaign
            reloadDashboard={this.reloadDashboard}
            ref={this.editCampaignChild}
          />

          {/* TODO: add manage channels componenet here */}
          <ManageChannels
            changeLoaderStatus={this.props.changeLoaderStatus}
            ref={this.manageChannelsChild}
            updateChannelsFromChild={this.updateChannelsFromChild
            }
          />

          {/* TODO: add manage Apps componenet here */}
          <ManageApps
            changeLoaderStatus={this.props.changeLoaderStatus}
            ref={this.manageAppsChild}
            updateAppsFromChild={this.updateAppsFromChild}
            subscriptionDetails={this.state.subscriptionDetails ? this.state.subscriptionDetails : {}}
          />

          {/* TODO: add manage bitlink channels componenet here */}
          <ManagaeBitlinkChannel
            manageChannelsFun={this.manageChannelsFun}
            ref={this.manageBitlinkChannelsChild}
            linkChannelIdToCampaign={this.linkChannelIdToCampaign}
          />

          <ManageBitlinkCampaign
            updateDashBoard={this.updateDashBoard}
            newLinkIdSet={this.newLinkIdSetFunction}
            ref={this.manageBitlinkCampaignsChild}
            linkChannelIdToCampaignValue={
              this.state.manageBitlinkChannelsChildFun
            }
          />

          {/* TODO: add delete account componenet here */}
          <DeleteAccount userId={this.state.userId} logoutFun={this.logOut} />

          {/* TODO: add custom domain component */}
          <AddCustomDomain />

          {/* TODO: add share component component */}
          <ShareLink
            ref={this.manageShareChild}
            subscriptionDetails={this.state.subscriptionDetails ? this.state.subscriptionDetails : {}}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default DashboardNavbar;