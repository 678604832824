import React, { Component } from "react";
import App from '../../App';
import { ENV } from '../../config/config';
// import ChangePlanBanner from '../../components/notification/changePlanBanner';
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('LoginSession')),
            mainLoader: true,
            language: localStorage.getItem('language'),
        }
    }

    componentDidMount = () => {
        const { language, user } = this.state;

        this.setState({ mainLoader: !language ? true : false }, () => {
            if (!language)
                this.loadUserNativeLang();
        });
    };

    loadUserNativeLang = () => {
        const that = this;
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                let result = JSON.parse(this.responseText);
                localStorage.setItem('language', result.userLang);
                localStorage.setItem('country', result.userCountry);
                window.location.reload();
            }
        };
        let url = `${ENV.url}language/get-user-lang`;

        xhttp.open("GET", url, true);
        xhttp.send();
    }

    render() {
        const { mainLoader } = this.state;

        return (
            mainLoader ?
                <div className="main-loader">
                    <div className="testing-loader-container">
                        <svg>
                            <g>
                                <path d="M 50,100 A 1,1 0 0 1 50,0" />
                            </g>
                            <g>
                                <path d="M 50,75 A 1,1 0 0 0 50,-25" />
                            </g>
                            <defs>
                                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                    <stop offset="0%" style={{ stopColor: '#ef56a0', stopOpacity: '1' }} />
                                    <stop offset="100%" style={{ stopColor: '#872a5d', stopOpacity: '1' }} />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
                :
                <>
                    <App />
                </>
        );
    }
}

export default Index;
