import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
// import Swal from 'sweetalert2';
import QRCode from 'qrcode.react';
import swal from 'sweetalert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import $ from "jquery";
var Twit = require('twit');
var OAuth = require('oauth');

class ShareComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
            hash: "",
            userId: "",
            domain: "",
            url: "",
            twitterAccounts: [],
            logOutRedirect: false
        }
    }

    shareTwitter = (id) => {
        fetch(window.APIURL + "accounts/member/share-on-twitter?lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                _id: id,
                hash: this.state.hash,
                domain: this.state.domain
            })
        }).then(res => {
            return res.json();
        }).then(result => {


            if (result.sessionExpired) {
                this.setState({
                    logOutRedirect: true
                })
                return;
            }


            if (result.status === true) {
                swal(window.i18n('alert_ay'), result.message, "success")
            } else {
                swal(window.i18n('alert_a'), result.message, 'error');
            }
        });
    }

    shareMediaTwitter = (id) => {
        const canvas = document.getElementById("qrId");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        fetch(window.APIURL + "accounts/member/share-media-on-twitter" + "?accessToken=" + localStorage.getItem('loginToken'), {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                _id: id,
                hash: this.state.hash,
                url: this.state.url,
                img: pngUrl
            })
        }).then(res => {
            return res.json();
        }).then(result => {

            if (result.sessionExpired) {
                this.setState({
                    logOutRedirect: true
                })
                return;
            }

            if (result.status === true) {
                swal(window.i18n('alert_ay'), result.message, "success")
            } else {
                swal(window.i18n('alert_a'), result.message, 'error')
            }
        });
    }

    shareLinkFunc = (hash, domain, userId) => {
        this.setState({ hash, userId })
        this.setState({ domain, hash, userId, url: `https://${domain}/${hash}` })
        fetch(window.APIURL + "accounts/member/get-twitter-accounts?lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                _id: userId
            })
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 400) {
                    return res.json();
                } else if (res.status === 403) {
                    return res.json();
                } else if (res.status === 422) {
                    return res.json();
                } else if (res.status === 404) {
                    return res.json();
                } else {
                    return res.json();
                }
            })
            .then(result => {

                if (result.sessionExpired) {
                    this.setState({
                        logOutRedirect: true
                    })
                    return;
                }

                if (result.status === true) {
                    this.setState({
                        twitterAccounts: result.data
                    });

                    $("#nav").addClass("show");
                    $(".menu").addClass("clicked");
                    $("#account_info").hide();
                    $("#share_link").show();

                } else if (result.status === false) {
                    swal(window.i18n('alert_a'), result.message, 'error');
                } else {
                    swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
                }
            });
    }

    render() {
        const { subscriptionDetails } = this.props;
        const { logOutRedirect } = this.state;
        if (logOutRedirect) {
            // return <Redirect to="/logout" />;
             window.location.href="/web/logout-and-clear-session"
        }
        return (
            <React.Fragment>
                <div
                    className="action-sheet--wrapper-MAIN data-state"
                    data-state="25"
                    style={{ display: "none " }}
                    id="share_link"
                >
                    <div className="action-sheet--MAIN" data-selected-sheet="share_link"
                        style={{
                            animation: "600ms ease 0ms 1 normal forwards running open-sheet "
                        }} >

                    </div>
                    <div className="action-sheet--header">
                        <a>
                            {" "}
                            <span className="back_btn-icon" alt="back_btn-icon">
                                <FontAwesomeIcon icon="chevron-left" />
                            </span>{" "}
                        </a>
                        <h4 className="action-sheet--header-text">{window.i18n('socialNet_connAcc')}</h4>
                        <span>
                            <a>
                                <span className="close-icon" alt="close-icon" />
                            </a>
                        </span>
                    </div>
                    <div className="action-sheet--content-wrapper">
                        <div className="connected-account--sub-header">
                            {window.i18n('shareLink_shareOn')}
                        </div>
                        <div className="social-account-items">
                            {this.state.twitterAccounts.length > 0
                                ? this.state.twitterAccounts.map((data, index) => (
                                    <React.Fragment>
                                        <div className="social-account-item">
                                            <span className="d-block font-weight-bold mb-2">Share Link</span>
                                            <div style={{ cursor: "pointer" }} key={index} onClick={() => this.shareTwitter(data._id)} className="connected-account--MAIN">
                                                <span className="social-icon"> <img className="user-account--image" width="25" src={require("../../images/twitter.svg")} /></span>
                                                <span className="share-icon"><FontAwesomeIcon icon="share-alt" /></span>
                                                <div className="connected-account--account-name">
                                                    {data.displayName}
                                                </div>
                                                <div className="connected-account--date">
                                                    {window.i18n('socialNet_connOn')} {Date(data.createdAt).slice(4, 15)}
                                                </div>
                                            </div>
                                            <span className="d-block font-weight-bold mb-2">Share QR Code</span>
                                            <div style={{ cursor: "pointer" }} key={index} onClick={() => this.shareMediaTwitter(data._id)} className="connected-account--MAIN">
                                                <span className="social-icon"> <img className="user-account--image" width="25" src={require("../../images/twitter.svg")} /></span>
                                                <span className="share-icon"><FontAwesomeIcon icon="share-alt" /></span>
                                                <div className="connected-account--account-name">
                                                    {data.displayName}
                                                </div>
                                                <div className="connected-account--date">
                                                    {window.i18n('socialNet_connOn')} {Date(data.createdAt).slice(4, 15)}
                                                </div>
                                            </div>
                                            <div className="text-center py-3">
                                                <QRCode id="qrId" value={this.state.url} renderAs="canvas" size={100} level="H" includeMargin={true} />
                                            </div>
                                            <hr style={{ borderColor: "#fff" }} />
                                        </div>
                                    </React.Fragment>
                                ))
                                :
                                <span>
                                    {window.i18n('shareLink_noSocial')} <br />
                                    {window.i18n('shareLink_pleaseAdd')}
                                </span>
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ShareComponent;