import React, { Component } from "react";
import Select from 'react-select';
import { countryCodes } from './data';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
// import QRCode from 'qrcode.react';
import $ from "jquery";

class TwoWayAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
      userCountry: "",
      phoneNumber: "",
      enabled2fa: false,
      phoneNumberInput: "",
      userId: "",
      url: "",
      isChecked: false,
      selectedOption: null
    };
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption, userCountry: selectedOption.value });
  };

  componentWillReceiveProps = () => {
    this.setState({ userId: this.props.userId, phoneNumber: this.props.phoneNumber, enabled2fa: this.props.enabled2fa });
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }

  reciveQrImage = e => {
    e.preventDefault();
    if (!this.state.isChecked) {
      swal(window.i18n('alert_a'), window.i18n('new_alert_b'), 'error');
      return;
    }
    fetch(window.APIURL + "accounts/member/send-qr-code?lang=" + this.state.language, {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        // phoneNumber: "+" + userCountry + this.state.phoneNumberInput,
        _id: this.state.userId
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        let temp = this;
        if (result.status === true) {
          if (result.data !== "") {
            this.props.qrUrl(result.data)
            temp.setState({ url: result.data })
          }
          $("#security").hide();
          $("#security_phone").show();
        } else if (result.status === false) {
          swal(window.i18n('alert_a'), result.message, 'error');
        } else {
          swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
        }
      });
  }

  phoneNumberForm = e => {
    e.preventDefault();

    let { phoneNumberInput, userCountry } = this.state;
    var reg = new RegExp("^[0-9]+$");

    if (phoneNumberInput === "") {
      swal(window.i18n('alert_a'), window.i18n('alert_ae'), 'error');
      return;
    } else if (!phoneNumberInput.match(reg)) {
      swal(window.i18n('alert_a'), window.i18n('alert_af'), 'error');
    } else if (userCountry === "") {
      swal(window.i18n('alert_a'), window.i18n('alert_ag'), 'error');
      return;
    } else {
      fetch(window.APIURL + "accounts/member/send-mobile-token?lang=" + this.state.language, {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          phoneNumber: "+" + userCountry + this.state.phoneNumberInput,
          _id: this.state.userId
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {
          let temp = this;
          if (result.status === true) {
            $("#security").hide();
            $("#security_phone").show();
          } else if (result.status === false) {
            swal(window.i18n('alert_a'), result.message, 'error');
          } else {
            swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
          }
        });
    }
  };

  // showQrImage(url) {
  //   var image = new Image();

  //   image.onload = function () {
  //     var canvas = document.createElement('canvas');
  //     canvas.width = this.naturalWidth; // or 'width' if you want a special/scaled size
  //     canvas.height = this.naturalHeight; // or 'height' if you want a special/scaled size

  //     canvas.getContext('2d').drawImage(this, 0, 0);

  //     // Get raw image data
  //     callback(canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, ''));

  //     // ... or get as Data URI
  //     callback(canvas.toDataURL('image/png'));
  //   };

  //   image.src = url;

  // }

  deleteNumber = () => {
    Swal.fire({
      title: window.i18n('alert_az'),
      text: window.i18n('new_alert_c'),
      showCancelButton: true,
      // customClass: "conformations",
      confirmButtonColor: '#0a6eb4',
      cancelButtonColor: '#d33',
      confirmButtonText: window.i18n('new_alert_d')
    }).then((result) => {
      if (result.value) {
        fetch(window.APIURL + "accounts/member/disable-2fa?lang=" + this.state.language, {
          method: "post",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            _id: this.state.userId
          })
        })
          .then(res => {
            if (res.status === 200) {
              return res.json();
            } else if (res.status === 403) {
              return res.json();
            } else if (res.status === 422) {
              return res.json();
            } else if (res.status === 404) {
              return res.json();
            } else {
              return res.json();
            }
          })
          .then(result => {
            if (result.status === true) {
              if (this.props.delNumber) {
                this.props.delNumber("true");
              }
              this.setState({
                phoneNumber: "",
                phoneNumberInput: "",
                codeNumber: ""
              });
              swal(window.i18n('alert_ay'), result.message, 'success')
            } else if (result.status === false) {
              swal(window.i18n('alert_a'), result.message, 'error');
            } else {
              swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
            }
          });
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        {
          this.props.settings && this.props.settings.enable2FA ?
            <form onSubmit={this.reciveQrImage}>
              <div>
                <div>
                  <h5 className="action-sheet--content-header">
                    {window.i18n('twoWayOauth_twoStepVer')}
                  </h5>
                  <div className="action-sheet--help-text">
                    <p><a className="sidebar-textlink" href="https://en.wikipedia.org/wiki/Multi-factor_authentication" target="_blank">{window.i18n('twoWayOauth_a')}</a> {window.i18n('twoWayOauth_b')}</p>
                    <p>{window.i18n('twoWayOauth_c')} <a className="sidebar-textlink" href="https://en.wikipedia.org/wiki/Time-based_One-time_Password_algorithm" target="_blank">{window.i18n('twoWayOauth_d')}</a></p>
                    <p>{window.i18n('twoWayOauth_e')}</p>
                    <p>{window.i18n('twoWayOauth_f')}</p>
                    <p>{window.i18n('twoWayOauth_g')}—<a className="sidebar-textlink" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en" target="_blank">{window.i18n('twoWayOauth_h')}</a></p>
                    {/* {window.i18n('twoWayOauth_weWillSend')} */}
                  </div>
                </div>
                {this.props.enabled2fa === false ? (
                  <div className="checkbox--ALTERNATE" id="legal">
                    {/* <input id="legal" type="checkbox" className={this.state.isChecked ? "checkbox--input activeCheckBox" : "link-checkBox"} checked={this.state.isChecked} onChange={this.toggleChange} />
              <label tabIndex="7" htmlFor="legal" className="checkmark-icon checkbox-icon"></label> */}

                    <label className="custom-checkbox-label">{window.i18n('twoWayOauth_i')}
                      <input type="checkbox" className={this.state.isChecked ? "checkbox--input activeCheckBox" : "link-checkBox"} checked={this.state.isChecked} onChange={this.toggleChange} />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                ) : (
                  <div>
                    <div className="action-sheet--text">{window.i18n('twoWayOauth_statEnab')}</div>
                    {/* <div className="action-sheet--text">
                    {window.i18n('twoWayOauth_mobilee')}Mobile: {this.props.phoneNumber}
                  </div> */}
                    <button
                      type="button"
                      onClick={this.deleteNumber}
                      className="button--GHOST-SECONDARY btn-deleteNumber"
                    >
                      {window.i18n('twoWayOauth_disable2Fa')}
                    </button>
                  </div>
                )}


                {/* {this.props.phoneNumber === "" ? (
              <div>
                <div className="select-item--MAIN">
                  <Select
                    placeholder={window.i18n('twoWayOauth_selCountC')}
                    className="channelSelectDropdown cur-poi"
                    value={this.state.selectedOption}
                    onChange={this.handleChange}
                    options={countryCodes}
                    isOptionDisabled={(option) => option.isdisabled}
                    components={{
                      IndicatorSeparator: () => null
                    }}
                    styles={{
                      menu: (provided, state) => ({
                        ...provided,
                        color: state.isDisabled ? 'grey' : 'hsl(0,0%,20%)',
                        cursor: state.isDisabled ? 'not-allowed' : 'pointer'
                      })
                    }}
                  />
                  <span className="select-item--secondary-label" />
                  <span className="select-item--third-label " />
                  <span className="select-item--fourth-label" />
                  <span className="selector-icon" alt="selector-icon" />
                </div>
                <div className="input-field--wrapper">
                  <div className="input-field--MAIN   ">
                    <label className="input-field--label">{window.i18n('twoWayOauth_mobNumber')}</label>
                    <div className="input-field--input-wrapper">
                      <input
                        type="text"
                        className="input-field--input"
                        name="phoneNumberInput"
                        value={this.state.phoneNumberInput}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
                <div>
                  <div className="action-sheet--text">{window.i18n('twoWayOauth_statEnab')}</div>
                  <div className="action-sheet--text">
                    {window.i18n('twoWayOauth_mobilee')}Mobile: {this.props.phoneNumber}
                  </div>
                  <button
                    type="button"
                    onClick={this.deleteNumber}
                    className="button--GHOST-SECONDARY btn-deleteNumber"
                  >
                    {window.i18n('twoWayOauth_delMobNum')}
                  </button>
                </div>
              )}*/}
              </div>

              {this.props.enabled2fa === false ? (
                <div className="action-sheet--ghost-button-wrapper">
                  <button
                    className="button--GHOST-SECONDARY"
                    type="submit"
                    tabIndex="0"
                    id=""
                  >
                    {window.i18n('global_enable')}
                  </button>
                </div>
              ) : (
                ""
              )}
            </form>
            :
            ""
        }

      </React.Fragment>
    );
  }
}

export default TwoWayAuth;
