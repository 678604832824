import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import moment from "moment";
import { createBrowserHistory } from "history";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { ENV } from '../../config/config';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ValidateVoucherCode from '../../components/voucher-code/ValidateVoucherCode'
import DashBoardLinksListing from "../dashboard-components/dashboard-listing/DashBoardLinksListing";
import DashboardChart from "../dashboard-components/dashboard-charts/DashboardCharts";
var CryptoJS = require("crypto-js");

const queryString = require("query-string");

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
      startDate: moment(),
      endDate: moment(),
      more: false,
      redirect: false,
      search: false,
      isChecked: false,
      isBlocked: false,
      subscriptionDetails: {},
      query: "",
      userID: '',
      floInks: [],
      floInks1: [],
      linkstats: {},
      activeUrlLink: "",
      start: true,
      clipCheck: false,
      customLinkCheck: false,
      total: 0,
      page: 1,
      offset: 0,
      limit: 0,
      pages: 0,   // infinit scroll,
      domainName: '',
      showPaddingWhileLoader: false,
      language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
      socialAcc: [],
      integrations: [],
      customDom: [],
      userAssetsModal: false,
      payload: {},
      notification: false,
      customDomains: '',
      integApps: '',
      socialPlatforms: '',
      logOutRedirect: false
    };
    this.linksAndStats = React.createRef();
    this.chartsChild = React.createRef();
    this.close = this.close.bind(this);
  }

  componentWillMount() {
    let redirectURl = localStorage.getItem("redirectionURL");
    let subscriptionPlanDetails = localStorage.getItem("spd");
    if (!subscriptionPlanDetails) {
      localStorage.removeItem("loginToken");
      localStorage.removeItem("LoginSession");
      localStorage.removeItem("loginTime");
      window.location.href = "/web/userLogin";
      return;
    }
    let decryptedSubscriptionPlanDetails = CryptoJS.AES.decrypt(subscriptionPlanDetails.toString(), ENV.TokSecCrpt);
    decryptedSubscriptionPlanDetails = JSON.parse(decryptedSubscriptionPlanDetails.toString(CryptoJS.enc.Utf8));

    if (decryptedSubscriptionPlanDetails)
      this.setState({
        linkFiltering: decryptedSubscriptionPlanDetails.linkFiltering,
        linkHistory: parseInt(decryptedSubscriptionPlanDetails.linkHistory) - 1,
        customLinkHistory: parseInt(decryptedSubscriptionPlanDetails.customLinkHistory) - 1,
        socialPlatforms: decryptedSubscriptionPlanDetails.socialPlatforms !== "" ? decryptedSubscriptionPlanDetails.socialPlatforms : '0',
        integApps: decryptedSubscriptionPlanDetails.integrations !== "" ? decryptedSubscriptionPlanDetails.integrations : '0',
        cusDomains: decryptedSubscriptionPlanDetails.customDomains !== "" ? decryptedSubscriptionPlanDetails.customDomains : '0',
      }, () => {
        let splittedDomain = window.location.search.split("?dd=");
        let { linkHistory, customLinkHistory } = this.state;
        // set start date & end date according to history limits
        let days = splittedDomain[1] ? customLinkHistory : linkHistory;
        let startDate = moment().subtract(days, 'days');
        let endDate = moment();

        this.setState({ startDate, endDate });
      });
    if (redirectURl && redirectURl !== null) {
      let temp = redirectURl;
      localStorage.removeItem("redirectionURL");
      this.props.history.push(temp);
    } else if (localStorage.getItem("loginToken")) {
      this.setState({ redirect: false });
      let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
      this.setState(
        {
          userID: id,
          redirect: false,

        },
        () => {
          this.checkUserSession(id)
          this.getAllLinks();
          this.fetchAllAssets(id);
        }
      );
    } else {
      this.setState({ redirect: true });
    }
  }

  checkUserSession = (id) => {
    fetch(window.APIURL + "accounts/member/user-session-check?id=" + id + "&lang=" + this.state.language, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) {
          let sub = !localStorage.getItem("subscriptionId") || localStorage.getItem("subscriptionId") === 'null' ? null : localStorage.getItem("subscriptionId");
          let newP = !localStorage.getItem("newPackage") || localStorage.getItem("newPackage") === 'null' ? null : localStorage.getItem("newPackage");
          let lan = localStorage.getItem("language");
          if (sub !== null && newP !== null && lan !== null) {
            // localStorage.setItem("subscriptionId", null)
            // localStorage.setItem("newPackage", null)
            localStorage.removeItem("subscriptionId")
            localStorage.removeItem("newPackage")

            this.props.history.push(`/organization/subscription?subscriptionId=${sub}&packageId=${newP}&custom=true&lang=${lan}`)
          }
          this.setState({
            notification: result.result.notify
          })
        } else if (result.status === false) {
          if (result.special === true) {

            let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
            swal('Info', result.message, 'info');
            this.notification(userId)
            localStorage.removeItem("loginToken");
            localStorage.removeItem("LoginSession");
            localStorage.removeItem("loginTime");
          } else {
            localStorage.removeItem("loginToken");
            localStorage.removeItem("LoginSession");
            localStorage.removeItem("loginTime");
            this.setState({ redirect: true });
          }
        } else {
          localStorage.removeItem("loginToken");
          localStorage.removeItem("LoginSession");
          localStorage.removeItem("loginTime");
          this.setState({ redirect: true });
        }
      });
  }

  fetchAllAssets = (id) => {
    fetch(window.APIURL + "accounts/member/all-assets?Id=" + id + "&lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {


        if (result.sessionExpired) {
          this.setState({
            logOutRedirect: true
          })
          return;
        }

        if (result.status === true) {
          let data = {}
          data.apps = result.apps;
          data.socialAcc = result.twitterAcc;
          data.customDom = result.cusDomains;
          this.setState({
            integrations: result.apps,
            socialAcc: result.twitterAcc,
            customDom: result.cusDomains,
            payload: data
          })
          setTimeout(() => {
            this.setState({
              userAssetsModal: true
            })
          }, 500);
        } else if (result.status === false) {
          // swal(window.i18n('alert_a'), result.message, 'error');
        } else {
          // swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
        }
      });
  }

  oncheckBox(key, name) {
    let { payload } = this.state;
    let data = payload;
    if (name === 'customDom') {
      if (data.customDom[key].check) {
        data.customDom[key].check = false;
      } else {
        data.customDom[key].check = true;
      }
      this.setState({
        payload: data
      })
    }
    if (name === 'socialAcc') {
      if (data.socialAcc[key].check) {
        data.socialAcc[key].check = false;
      } else {
        data.socialAcc[key].check = true;
      }
      this.setState({
        payload: data
      })
    }
    if (name === 'apps') {
      if (data.apps[key].check) {
        data.apps[key].check = false;
      } else {
        data.apps[key].check = true;
      }
      this.setState({
        payload: data
      })
    }
  }

  close() {
    this.setState({
      notification: false
    })
  }

  sendPayload(check = false) {

    let { apps, socialAcc, customDom } = this.state.payload;
    let { integApps, socialPlatforms, cusDomains } = this.state;
    let appCount = 0, socialCount = 0, domainCount = 0;
    if (!check) {
      for (let i = 0; i < socialAcc.length; i++) {
        if (socialAcc[i].check === true) {
          socialCount++;
        }
      }
      for (let j = 0; j < apps.length; j++) {
        if (apps[j].check === true) {
          appCount++;
        }
      }
      for (let k = 0; k < customDom.length; k++) {
        if (customDom[k].check === true) {
          domainCount++;
        }
      }
      if (socialCount < 1 && appCount < 1 && domainCount < 1) {
        swal(window.i18n('alert_a'), window.i18n('ack_err_1'), 'error');
        return;
      }
      if (socialCount > parseInt(socialPlatforms)) {
        swal(window.i18n('alert_a'), window.i18n('ack_err_2'), 'error');
        return;
      }
      if (appCount > parseInt(integApps)) {
        swal(window.i18n('alert_a'), window.i18n('ack_err_3'), 'error');
        return;
      }
      if (domainCount > parseInt(cusDomains)) {
        swal(window.i18n('alert_a'), window.i18n('ack_err_4'), 'error');
        return;
      }
    }
    fetch(window.APIURL + "accounts/member/assests-paylod?lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        _id: this.state.userID,
        check: check ? true : false,
        payload: this.state.payload
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {


        if (result.sessionExpired) {
          this.setState({
            logOutRedirect: true
          })
          return;
        }


        if (result.status === true) {
          this.checkUserSession(this.state.userID)
        } else if (result.status === false) {
          // swal(window.i18n('alert_a'), result.message, 'error');
        } else {
          // swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
        }
      });
  }

  sendSearchQueryToFloink = searchValue => {
    if (searchValue !== "") {
      this.setState(
        {
          query: searchValue,
          search: true
        },
        () => {
          this.getAllLinks(true);
        }
      );
    } else {
      this.setState(
        {
          search: false
        },
        () => {
          this.clearSearch();
        }
      );
    }
  }

  getSearchProps = searchValue => {
    if (searchValue !== "") {
      this.setState(
        {
          query: searchValue,
          search: true
        },
        () => {
          this.getAllLinks();
        }
      );
    } else {
      this.setState(
        {
          search: false
        },
        () => {
          this.clearSearch();
        }
      );
    }
  };

  handleEvent = (e, picker) => {
    this.props.changeLoaderStatus(true);
    setTimeout(() => {
      this.setState(
        {
          dateFlag: true,
          startDate: picker.startDate,
          endDate: picker.endDate,
          search: true
        },
        () => {
          let totalDays = this.state.linkHistory && this.state.linkHistory !== undefined ? this.state.linkHistory : 60;
          let totalCLDays = this.state.customLinkHistory && this.state.customLinkHistory !== undefined ? this.state.customLinkHistory : 60;
          let startdate = moment().subtract(this.state.customLinkCheck ? totalCLDays : totalDays, "days").format("YYYY/MM/DD");
          let endDate = moment().format("YYYY/MM/DD");
          let date = moment(picker.startDate).format("YYYY/MM/DD");
          let range = moment(date).isBetween(startdate, endDate, undefined, '[]');

          if (range) {
            this.getAllLinks(true);
          } else {
            this.setState({
              dateFlag: false,
              startDate: moment()
                .subtract(1, "months")
                .add(1, "days"),
              endDate: moment(),
              search: false,
            })

            swal(window.i18n('alert_a'), window.i18n('alert_b'), 'error')
          }
        }
      );
    }, 1000);
  };

  callChildFunction = (value) => {
    this.linksAndStats.current.setAllStates(
      this.state.floInks,
      this.state.linkstats,
      this.state.activeUrlLink,
      this.state.more,    // infinit scroll
      (value && value !== undefined) ? true : false
    );
    this.chartsChild.current.setAllStates(
      this.state.floInks,
      this.state.linkstats,
      this.state.more,    // infinit scroll
      (value && value !== undefined) ? true : false
    );
  };

  getAllLinks = (value, updateData = undefined, fromInfinite = 0) => {
    if (fromInfinite) {
      this.setState({ showPaddingWhileLoader: true });
    }
    if (updateData) {
      let floInksCurrent = this.state.floInks;
      let updateIndex = floInksCurrent.findIndex((obj => obj._id.toString() === updateData._id.toString()));
      if (updateIndex > -1) {
        floInksCurrent[updateIndex].title = updateData.title;
        floInksCurrent[updateIndex].customizeBackHalf = updateData.customizeBackHalf;
        floInksCurrent[updateIndex].tags = updateData.tags;
        floInksCurrent[updateIndex].domain = updateData.domain;
        this.setState({ floInks: floInksCurrent });
      }
      return;
    }
    if (this.state.start || (value && value !== undefined)) {
      this.props.changeLoaderStatus(true);
    }
    let history = createBrowserHistory();
    let splittedDomain = window.location.search.split("?dd=");
    let domainName = "";
    if (splittedDomain[1]) {
      domainName = atob(splittedDomain[1]);
      this.setState({ domainName });
    }
    let totalLinksDays = this.state.linkHistory;
    let totalCLinksDays = this.state.customLinkHistory;

    this.setState({
      customLinkCheck: splittedDomain[1] ? true : false,
      page: this.state.start ? this.state.page : (value && value !== undefined) ? 1 : this.state.page + 1,
      start: (value && value !== undefined) ? true : false,
      clipCheck: true,
      more: true
    }, () => {
      fetch(window.APIURL + "q-link/user-links?lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: this.state.userID,
          query: this.state.query === "" ? undefined : this.state.query,
          startDate: (!this.state.dateFlag && this.state.customLinkCheck === false) ? moment().subtract(totalLinksDays && totalLinksDays !== undefined ? totalLinksDays : 10, "days").format("YYYY/MM/DD") : (!this.state.dateFlag && this.state.customLinkCheck) ? moment().subtract(totalCLinksDays, "days").format("YYYY/MM/DD") : moment(this.state.startDate).format("YYYY/MM/DD"),
          endDate: !this.state.dateFlag ? moment().format("YYYY/MM/DD") : moment(this.state.endDate).format("YYYY/MM/DD"),
          hiddenLink: this.state.isChecked,
          isBlocked: this.state.isBlocked,
          // size: this.state.limit,  // infinit scroll,
          pageNo: this.state.page,
          // offset: this.state.offset,
          domainName: this.state.domainName
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 400) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {
          if (result.sessionExpired) {
            this.setState({
              logOutRedirect: true
            })
            return;
          }


          this.setState({ showPaddingWhileLoader: false })
          if (result.status === true) {
            let data = result.qLinks;
            let data1 = result.result;
            let urlLink = "";
            if (data.length < 10) {
              this.setState({
                more: false
              })
            }   // infinit scroll
            // return;
            if (data.length > 0) {
              if (this.state.page === 1) {
                var parsed = queryString.parse(window.location.search);
                if (parsed.link !== undefined && parsed.link !== "undefined") {

                  let validationIndex = data.findIndex((obj => obj.customizeBackHalf === parsed.link))
                  if (validationIndex >= 0) {
                    urlLink = parsed.link;
                  } else {
                    urlLink = data[0].customizeBackHalf;
                  }
                } else {
                  urlLink = data[0].customizeBackHalf;
                }
                this.linksAndStats.current.setChart(urlLink);
                if (this.state.query === "") {
                  let prevHash = window.location.hash;
                  history.push({
                    pathname: "/web/dashboard",
                    search: "?link=" + urlLink
                  });
                  window.location.hash = prevHash;
                } else {
                  let prevHash = window.location.hash;
                  history.push({
                    pathname: "/web/dashboard",
                    search:
                      "?link=" + urlLink + "?query=" + this.state.query + "&filterActive=true"
                  });
                  window.location.hash = prevHash;
                }

                this.setState({
                  activeUrlLink: urlLink, linkstats: data1
                })
              }
            }
            let allLinksArray = this.state.floInks;
            let final = allLinksArray.concat(data);
            this.setState({
              // page: this.state.page + 1,
              // activeUrlLink: urlLink, 
              floInks: this.state.start ? data : final,
              // linkstats: data1,
              start: false, clipCheck: false,
              // search: false,
            }, () => {
              this.callChildFunction(value);
            });
            this.props.changeLoaderStatus(false);

          } else if (result.status === false) {
            // if (result.redirection === true) {
            //   if (result.special === true) {
            //     swal('Info', result.message, 'info');
            //     this.notification()
            //     // this.setState({ redirect: true });
            //   } else {
            //     this.setState({ redirect: true });
            //     swal(window.i18n('alert_a'), result.message, 'error');
            //   }
            // } else {
            this.setState({ redirect: true });
            swal(window.i18n('alert_a'), result.message, 'error');
            // }
            // this.setState({ redirect: true });
            // swal(window.i18n('alert_a'), result.message, 'error');
          } else {
            this.setState({ redirect: true });
            swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
          }
        });
    })  // infinit scroll
  };

  refresh = () => {
    this.setState({
      start: true,
      page: 1,
      offset: 0,
      more: false,
      floInks: [],
      linkstats: {}
    }, () => {
      this.getAllLinks()
    })
  }

  editClick = hash => {
    this.props.editClick(hash);
  };

  addBulkTags = selectedIds => {
    this.props.addBulkTags(selectedIds);
  }

  notification = (user) => {
    fetch(window.APIURL + "accounts/member/switch-bool?userId=" + user + "&lang=" + this.state.language, {
      method: "get",
      headers: { "Content-Type": "application/json" }
    })
      .then(response => response.json())
      .then(result => {
        if (result.status === true) {
          this.setState({ redirect: true });
        }
      });
  }

  toggleChange = (key) => {
    this.props.changeLoaderStatus(true)
    setTimeout(() => {
      this.setState(
        {
          [key]: !this.state[key]
        },
        () => {
          this.setState({ search: this.state.isChecked || this.state.isBlocked ? true : false })
          this.getAllLinks(true);
        }
      );
    }, 1000);
  }

  clearSearch = () => {
    this.props.changeLoaderStatus(true);
    setTimeout(() => {
      this.props.clearSearchInput();
      let { linkHistory, customLinkHistory } = this.state;
      // set start date & end date according to history limits
      let days = this.state.domainName && this.state.domainName !== '' ? customLinkHistory : linkHistory;
      let startDate = moment().subtract(days, 'days');
      let endDate = moment();

      this.setState(
        {
          search: false,
          isChecked: false,
          isBlocked: false,
          query: "",
          dateFlag: false,
          startDate: startDate,
          endDate: endDate
        },
        () => {
          this.getAllLinks(true);
        }
      );
    }, 1000);
  };

  shareHandler = (hash, dom) => {
    this.props.shareLink(hash, dom)
  }

  showfilter = () => {
    this.setState({ search: !this.state.search });
  }

  createLink = () => {
    this.props.createLink();
  }

  changeLoaderStatus = (state) => {
    this.props.changeLoaderStatus(state);
  }


  render() {
    const activeSocialAccounts = this.state.socialAcc.filter(elem => { return elem.enabled })
    const activeApps = this.state.integrations.filter(elem => { return elem.enabled })
    const activeCustomDomains = this.state.customDom.filter(elem => { return elem.isActive })

    const allowedSocialAccounts = this.state.socialPlatforms ? parseInt(this.state.socialPlatforms) : 0
    const allowedApps = this.state.integApps ? parseInt(this.state.integApps) : 0
    const allowedCustomDomains = this.state.cusDomains ? parseInt(this.state.cusDomains) : 0

    let totalDays = this.state.linkHistory && this.state.linkHistory !== undefined ? this.state.linkHistory : 60;
    let totalCLDays = this.state.customLinkHistory && this.state.customLinkHistory !== undefined ? this.state.customLinkHistory : 60;
    // let minDate = moment().subtract(1, "days");
    let minDate = moment().subtract(this.state.customLinkCheck ? totalCLDays : totalDays, "days");
    let maxDate = moment();

    let dateRange = {
      'Today': [moment(), moment()]
    };

    if (moment(minDate).isBefore(moment().subtract(1, 'days'), 'day'))
      dateRange['Yesterday'] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];

    if (moment(minDate).isBefore(moment().subtract(6, 'days'), 'day'))
      dateRange['Last 7 Days'] = [moment().subtract(6, 'days'), moment()];

    if (moment(minDate).isBefore(moment().subtract(29, 'days'), 'day'))
      dateRange['Last 30 Days'] = [moment().subtract(29, 'days'), moment()];

    if (moment(minDate).isBefore(moment().startOf('month'), 'month'))
      dateRange['This Month'] = [moment().startOf('month'), maxDate];

    if (moment(minDate).isBefore(moment().subtract(1, 'month'), 'month'))
      dateRange['Last Month'] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

    const loader = <div className="loader">Loading ...</div>;
    const { redirect } = this.state;
    if (redirect) {
      // return <Redirect to="/userLogin" />;
      window.location.href = "/web/logout-and-clear-session"
    }

    const { logOutRedirect } = this.state;
    if (logOutRedirect) {
      // return <Redirect to="/logout" />;
      window.location.href = "/web/logout-and-clear-session"
    }
    return (
      <React.Fragment>

        <div className="main-content">
          {
            this.state.linkFiltering ?
              <React.Fragment>
                <div className="filters-bar">
                  <div className="filter-list--FILTER">
                    <div className="filter-tobbar-holder">
                      <span className="filter-list--label">{window.i18n('report_l')}</span>
                      <div className="float-left">
                        {<DateRangePicker
                          id="selectDate"
                          autoApply={false}
                          locale={{ format: 'MMMM D, YYYY' }}
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          onApply={this.handleEvent}
                          minDate={minDate}
                          maxDate={maxDate}
                          style={{ float: "left" }}
                          ranges={dateRange}
                        >
                          <input
                            type="button"
                            name="daterange"
                            className="form-control date-input"
                            value={
                              this.state.startDate.format("MMMM D, YYYY") +
                              " - " +
                              this.state.endDate.format("MMMM D, YYYY")
                            }
                          />
                        </DateRangePicker>}
                      </div>
                    </div>
                    <div className="filter-tobbar-holder">
                      <div className="filter-list--BLUE">
                        <span className="hidden-links">
                          <input
                            id="legal"
                            type="checkbox"
                            name="hidden-bitlinks"
                            className={this.state.isChecked ? "link-checkBox activeCheckBox" : "link-checkBox"}
                            checked={this.state.isChecked}
                            onChange={() => this.toggleChange('isChecked')}
                          />{" "}
                          {window.i18n('global_showHiddenLinks')}
                        </span>

                        <span className="hidden-links">
                          <input
                            id="legal"
                            type="checkbox"
                            name="hidden-bitlinks"
                            className={this.state.isBlocked ? "link-checkBox activeCheckBox" : "link-checkBox"}
                            checked={this.state.isBlocked}
                            onChange={() => this.toggleChange('isBlocked')}
                          />{" "}
                          {window.i18n('global_showBlockedLinks')}
                        </span>
                      </div>
                      {
                        this.state.query != "" ?
                          <div className="filter-pill--ALTERNATE">
                            <div className="filter-pill--label">{this.state.query}</div>
                            <a
                              onClick={() => this.clearSearch()}
                              className="close-icon"
                              alt="close-icon"
                            >
                              <i className="fa fa-times" />
                            </a>
                          </div>

                          :
                          ""
                      }
                      {
                        this.state.search ?
                          <span className="cur-poi filter-list--clear" onClick={this.clearSearch}>
                            {window.i18n('global_clearAll')}
                          </span>
                          :
                          ""
                      }
                    </div>
                  </div>
                </div>
              </React.Fragment>
              :
              ""
          }


          <section
            className={
              this.state.search === false ? "links-list" : "links-list full"
            }
          >
            <DashBoardLinksListing
              isHiddenChecked={this.state.isChecked}
              clipLoader={this.state.clipCheck}
              updateDashboard={this.getAllLinks}
              changeLoaderStatus={this.changeLoaderStatus}
              createLink={this.createLink}
              showfilter={this.clearSearch}
              shareHandler={this.shareHandler}
              editClick={this.editClick}
              addBulkTags={this.addBulkTags}
              refresh={this.refresh}
              ref={this.linksAndStats}
              showPaddingWhileLoader={this.state.showPaddingWhileLoader}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
          </section>
          <section className="top-chart">
            <DashboardChart ref={this.chartsChild} />
          </section>
        </div>

        <ValidateVoucherCode />

        <Modal show={this.state.notification} className="soc-int-cd-modal">
          <Modal.Header>
            <div className="modal-title">{window.i18n('ack')}</div>
          </Modal.Header>
          <Modal.Body>
            {
              (this.state.socialAcc && this.state.socialAcc.length > 0 && parseInt(this.state.socialPlatforms) > 0 && allowedSocialAccounts >= activeSocialAccounts) || (this.state.integrations && this.state.integrations.length > 0 && parseInt(this.state.integApps) > 0 && allowedApps >= activeApps) || (this.state.customDom && this.state.customDom.length > 0 && parseInt(this.state.cusDomains) > 0 && allowedCustomDomains >= activeCustomDomains)
                ?
                <>
                  <div className="text-center">
                    <h3>{window.i18n('res_R7H1')}</h3>
                    <p>{window.i18n('ack_n2')}</p>
                    <h3>{window.i18n('ack_n3')}</h3>
                    <p>
                      <small>
                        <ul className="limits-info-list list-unstyled">
                          <li>{window.i18n('social_accounts')} {this.state.socialPlatforms}</li>
                          <li>{window.i18n('apps')} {this.state.integApps}</li>
                          <li>{window.i18n('doms')} {this.state.cusDomains}</li>
                        </ul>
                      </small>
                    </p>
                  </div>
                  <p>{window.i18n('pop_valid_5')}</p>
                </>
                :
                <div className="text-center">
                  <h3>{window.i18n('res_R7H1')}</h3>
                  <p>{window.i18n('ack_n1')}</p>
                </div>
            }
            <div className="account-limits-block">
              {this.state.socialAcc && this.state.socialAcc.length > 0 && parseInt(this.state.socialPlatforms) > 0
                &&
                allowedSocialAccounts >= activeSocialAccounts
                ?
                <div className="modal-text account_details">
                  <>
                    <h5 className="text-left mb-3 fw-b">{window.i18n('social_accounts')}</h5>
                    {
                      this.state.socialAcc.map((data, index) => (
                        <div key={index} className="connected-account--MAIN" style={{ display: "flex", justifyContent: "space-between" }}>
                          <span className="social-icon" style={{ wiidth: "43px", height: "43px" }}>
                            <img className="user-account--image" width="25" src={require("../../images/twitter.svg")} />
                          </span>
                          <div className="accounts-names-holder text-left flex-fill">
                            <div className="connected-account--account-name">
                              {data.displayName}
                            </div>
                            <div className="connected-account--date">
                              {window.i18n('socialNet_connOn')} {Date(data.createdAt).slice(4, 15)}
                            </div>
                          </div>
                          <div className="action-icons-holder">
                            <label className="onlyCheckBox">&nbsp;
                              <input type="checkbox" onChange={() => this.oncheckBox(index, 'socialAcc')} />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      ))
                    }
                    <hr />
                  </>
                </div>
                :
                ""
              }
              {this.state.integrations && this.state.integrations.length > 0 && parseInt(this.state.integApps) > 0
                && allowedApps >= activeApps
                ?
                <div className="modal-text account_details">
                  <>
                    <h5 className="text-left mb-3 fw-b">{window.i18n('apps')}</h5>
                    {
                      this.state.integrations.map((el, index) => (
                        <div
                          key={index}
                          className="dynamic-checklist--item "
                        >
                          <div className="input-field--wrapper manageAppWrapper">
                            <div className="input-field--ALTERNATE   ">
                              <label className="input-field--label" />
                              <div className="input-field--input-wrapper manageAppHolder d-flex justify-content-between">
                                <input
                                  type="text"
                                  className="input-field--input flex-fill"
                                  maxLength="100"
                                  value={el.appName}
                                  disabled
                                />
                                <div className="enable-disable-icon-holder text-center">
                                  <label className="onlyCheckBox">&nbsp;
                                    <input type="checkbox" onChange={() => this.oncheckBox(index, 'apps')} />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                    <hr />
                  </>
                </div>
                :
                ""
              }
              {this.state.customDom && this.state.customDom.length > 0 && parseInt(this.state.cusDomains) > 0
                && allowedCustomDomains >= activeCustomDomains
                ?
                <div className="modal-text account_details">
                  <>
                    <h5 className="text-left mb-3 fw-b">{window.i18n('doms')}</h5>
                    {
                      this.state.customDom.map((domain, key) => (
                        <div key={key} className="avlbl-domain-row d-flex justify-content-between">
                          <span className="flex-fill text-left">
                            {domain.name}
                            {
                              !domain.isVerified ?
                                <small style={{ fontSize: '14px' }}> <span className="text-danger">({window.i18n('global_notVerify')})</span> </small> : <small style={{ fontSize: '14px' }}> <span className="text-success">({window.i18n('global_verified')})</span> </small>
                            }
                          </span>
                          <span>
                            <label className="onlyCheckBox">&nbsp;
                              <input type="checkbox" onChange={() => this.oncheckBox(key, 'customDom')} />
                              <span className="checkmark"></span>
                            </label>
                          </span>
                        </div>
                      ))
                    }
                    <hr />
                  </>
                </div>
                :
                ""
              }
            </div>
            <div className="text-center">
              {
                (this.state.socialAcc && this.state.socialAcc.length > 0 && parseInt(this.state.socialPlatforms) > 0 && allowedSocialAccounts >= activeSocialAccounts) || (this.state.integrations && this.state.integrations.length > 0 && parseInt(this.state.integApps) > 0 && allowedApps >= activeApps) || (this.state.customDom && this.state.customDom.length > 0 && parseInt(this.state.cusDomains) > 0 && allowedCustomDomains >= activeCustomDomains)
                  ?
                  <button className="btn btn-pink-outline d-inline-block align-top ml-2 mb-2" onClick={() => this.sendPayload(false)}>{window.i18n('cont_submit')}</button>
                  :
                  <button className="btn btn-pink-outline d-inline-block align-top ml-2 mb-2" onClick={() => this.sendPayload(true)}>{window.i18n('ok')}</button>
              }
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Dashboard;
