import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import Select from 'react-select';
import { countries_simple } from '../../shared/countries-simple.js';
import { connect } from 'react-redux';
import { getSiteSetting, beforeSiteSettings } from '../../shared/site-settings/site-settings.action';
import countriesCities from "countries-cities";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { ENV } from '../../config/config';
import $ from 'jquery'
import { json } from "body-parser";
var CryptoJS = require("crypto-js");

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      rePassword: "",
      errors: "",
      language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
      countries: [],
      country: '',
      citiesList: [{ value: '', label: '' }],
      city: '',
      zipCode: '',
      address: '',
      redirectToBilling: false,
      redirectToBillingPage: false,
      id: '',
      type: '',
      userId: '',

    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    var token = localStorage.getItem("loginToken");

    if (token) {
      this.setState({ redirect: true });
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmit(e) {
    const {
      email,
      username,
      firstName,
      lastName,
      password,
      country,
      rePassword,
      city,
      zipCode,
      address,
      redirectToBilling,
      redirectToBillingPage,
      subscriptionId,
      type } = this.state;
    e.preventDefault();

    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const lenthReg = /^.{8,50}$/;
    const lowerReg = /^(?=.*[a-z])/;
    const upperReg = /^(?=.*[A-Z])/;
    const digitReg = /^(?=.*\d)/;
    const specialReg = /^(?=.*[!@.#$%^_&*])/;

    if (username === "") {
      this.setState({ errors: window.i18n('alert_ax') });
      return;
    }
    if (firstName === "") {
      this.setState({ errors: window.i18n('alert_aj') });
      return;
    }
    if (lastName === "") {
      this.setState({ errors: window.i18n('alert_ak') });
      return;
    }
    // if (address === "") {
    //   this.setState({ errors: 'Address is required' });
    //   return;
    // }
    // if (zipCode === "") {
    //   this.setState({ errors: 'Zip code is required' });
    //   return;
    // }

    if (country === "") {
      this.setState({ errors: window.i18n('alert_ag') });
      return;
    }
    // if (city === "" || city === "City") {
    //   this.setState({ errors: 'City is required' });
    //   return;
    // }
    if (email === "") {
      this.setState({ errors: window.i18n('alert_m') });
      return;
    } else if (!email.match(regexp)) {
      this.setState({ errors: window.i18n('alert_n') });
      return;
    } else if (password === "" || rePassword === "") {
      this.setState({ errors: window.i18n('alert_aw') });
      return;
    } else if (!password.match(lenthReg)) {
      this.setState({ errors: window.i18n('alert_av') });
      return;
    }
    else if (!password.match(lowerReg)) {
      this.setState({ errors: window.i18n('alert_au') });
      return;
    }
    else if (!password.match(upperReg)) {
      this.setState({ errors: window.i18n('alert_at') });
      return;
    }
    else if (!password.match(digitReg)) {
      this.setState({ errors: window.i18n('alert_as') });
      return;
    }
    else if (!password.match(specialReg)) {
      this.setState({ errors: window.i18n('alert_ar') });
      return;
    }
    else if (password !== rePassword) {
      this.setState({ errors: window.i18n('alert_aq') });
      return;
    }
    else if (username === password) {
      this.setState({ errors: window.i18n('alert_ap') });
      return;
    } else {
      this.setState({ errors: "" });
      fetch(window.APIURL + "accounts/member/register?lang=" + this.state.language, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          primaryEmail: email,
          username: username,
          firstName: firstName,
          lastName: lastName,
          country: country.value,
          password: password,
          city: city,
          zipCode: zipCode,
          address: address,

        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {
          if (result.status === true) {
            let subscriptionPlanDetails = result.defaultSubscriptionPackage;
            delete result.defaultSubscriptionPackage;
            swal(window.i18n('ver_em_txt'), {
              icon: "success",
            });

            // let encSubscriptionPlanDetails = CryptoJS.AES.encrypt(JSON.stringify(subscriptionPlanDetails), ENV.TokSecCrpt);
            // localStorage.setItem("spd", encSubscriptionPlanDetails);
            // localStorage.setItem("LoginSession", JSON.stringify(result));
            // localStorage.setItem("loginToken", result.data.loginToken);
            // localStorage.setItem("loginTime", new Date().getTime());
            // localStorage.setItem('country',result.data.country )
            if (redirectToBilling === true) {
              this.setState({ redirectToBillingPage: true, userId: result.data._id })
            }
            this.setState({ redirect: true });
          } else if (result.status === false) {
            swal(window.i18n('alert_a'), result.message, 'error');
          } else {
            swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
          }
        });
    }
  }

  componentDidMount = () => {
    let Country = countriesCities.getCountries();
    this.setState({
      countries: Country
    })



    if (this.props.history.location) {
      if (this.props.history.location.state) {
        this.setState({
          redirectToBilling: this.props.history.location.state.redirectToBilling,
          id: this.props.history.location.state.id,
          type: this.props.history.location.state.type
        })

      }
    }
    this.props.getSiteSetting(this.state.language);
  }

  handleChangee = selectedOption => {
    let { country } = this.state;

    country = selectedOption.value;
    let cities = countriesCities.getCities(country);

    this.setState({ selectedOption, country });

    this.setState({ country: selectedOption });

    let temp = [{ value: '', label: '' }]
    cities.forEach(element => {
      temp.push({
        value: element,
        label: element
      })
    });
    this.setState({
      citiesList: temp
    })
  };

  handleCityChangee = selectedCity => {
    let temp = selectedCity.value
    this.setState({
      city: temp
    })
  };

  render() {
    const { redirect, redirectToBillingPage, userId } = this.state;

    if (redirectToBillingPage === true) {
      const str = `/organization/subscription?subscriptionId=${this.state.id}&type=${this.state.type}`;
      return <Redirect to={'/organization/subscription?subscriptionId=' + this.state.id + '&type=' + this.state.type} />;
    }

    else if (redirect) {
      // localStorage.setItem('newUser', true)
      // return <Redirect to="/dashboard" />;

      // return <Redirect to="/userLogin" />;
      window.location.href = "/web/logout-and-clear-session"

    }

    let settings;

    if (this.props.settings.siteSettingsAuth) {
      settings = this.props.settings.siteSettingsRes.site;
    }

    return (
      <React.Fragment>
        <div className="user-forms-outer" style={{ height: '100vh', display: 'flex', padding: '25px 0', overflowY: 'auto' }}>
          <div className="container-box" style={{ margin: 'auto' }}>
            <h1 className="header-logo">
              <Link to="/" rel="nofollow">
                {
                  settings ?
                    // <img src={settings.siteLogo} alt="Site Logo" /> 
                    <img src={require('../../images/logo-floink-white.svg')} alt={window.i18n('mainFooter_altLogo')} />
                    : 
                    ''
                }
              </Link>
            </h1>
            <form id="sign-up" onSubmit={this.handleSubmit}>
              <div className="header">
                <h3 className="tagline">{window.i18n('signup_mainTitle')}</h3>
              </div>
              <div className="susi-fields-wrapper">
                <fieldset>
                  <label for="username">
                    <input
                      className="text"
                      type="text"
                      name="username"
                      value={this.state.username}
                      onChange={this.handleChange}
                      placeholder={window.i18n('signup_username')}
                    />
                    <span className="error-message" />
                  </label>
                  <label for="firstName">
                    <input
                      className="text"
                      type="text"
                      name="firstName"
                      value={this.state.firstName}
                      onChange={this.handleChange}
                      placeholder={window.i18n('global_firstN')}
                    />
                    <span className="error-message" />
                  </label>
                  <label for="lastName">
                    <input
                      className="text"
                      type="text"
                      name="lastName"
                      value={this.state.lastName}
                      onChange={this.handleChange}
                      placeholder={window.i18n('global_lastN')}
                    />
                    <span className="error-message" />
                  </label>


                  {/* <label for="address">
                    <input
                      className="text"
                      type="text"
                      name="address"
                      value={this.state.address}
                      onChange={this.handleChange}
                      placeholder={window.i18n('ds_ad_s')}
                    />
                    <span className="error-message" />
                  </label>

                  <label for="zipCode">
                    <input
                      className="text"
                      type="text"
                      name="zipCode"
                      value={this.state.zipCode}
                      onChange={this.handleChange}
                      placeholder={window.i18n('ds_zc')}
                    />
                    <span className="error-message" />
                  </label> */}


                  <label className="input-field--label d-block">
                    <Select
                      value={this.state.selectedOption}
                      onChange={this.handleChangee}
                      placeholder={window.i18n('ds_cr')}
                      classNamePrefix="react-select"
                      isSearchable={true}
                      options={countries_simple}
                      isOptionDisabled={(option) => option.isdisabled}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                    />

                    {/* <label className="input-field--label d-block">
                      <Select
                        isDisabled={this.state.citiesList.length === 1}
                        value={this.state.citiesList.filter(cityV => this.state.city === cityV.value)}
                        onChange={this.handleCityChangee}
                        placeholder={window.i18n('ds_ct')}
                        classNamePrefix="react-select"
                        isSearchable={true}
                        options={this.state.citiesList}
                        // isOptionDisabled={(option) => option.isdisabled}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      />
                    </label> */}
                    <span className="error-message" />
                  </label>
                  <label for="email">
                    <input
                      className="text"
                      type="text"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      placeholder={window.i18n('signup_emailAdd')}
                    />
                    <span className="error-message" />
                  </label>
                  <label for="password" className="password-block">
                    <input
                      className="text"
                      type="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      placeholder={window.i18n('global_pswd')}
                    />
                    <span className="error-message" />
                  </label>
                  <label for="rePassword">
                    <input
                      className="text"
                      type="password"
                      name="rePassword"
                      value={this.state.rePassword}
                      onChange={this.handleChange}
                      placeholder={window.i18n('signup_confirmPass')}
                    />
                    <span className="error-message" />
                  </label>
                  <div className="switch to-sign-in password-tip">
                    <span className="gray-link">
                      <ul className="list-unstyled">
                        <li>{window.i18n('signup_decription')}</li>
                      </ul>
                    </span>
                  </div>
                  {Object.keys(this.state.errors).length > 0 &&
                    this.state.errors ? (
                    <div className="alert alert-danger">{this.state.errors}</div>
                  ) : (
                    ""
                  )}
                  <button className="btn btn-pink">{window.i18n('signup_create')}</button>
                </fieldset>
              </div>
            </form>
            {
              settings && !settings.enableGMSignup && !settings.enableFBSignup && !settings.enableTWSignup ?
                ""
                :
                <React.Fragment>
                  <p className="separator t-center">
                    <span>{window.i18n('global_or')}</span>
                  </p>
                  <div className="social-sign-in">
                    <span className="susi-with">{window.i18n('signup_signInWith')}</span>
                    <div className="text-center">
                      {
                        settings && settings.enableGMSignup ?
                          <a rel="nofollow" href={window.APIURL + "auth/google"} className="susi-btn google-susi-btn button d-inline-block align-top" data-network="google">
                            <img src={require("../../images/google.svg")} alt="Google" width="20" />
                          </a>
                          :
                          ""
                      }
                      {
                        settings && settings.enableFBSignup ?
                          <a rel="nofollow" href={window.APIURL + "auth/facebook"} className="susi-btn facebook-susi-btn button d-inline-block align-top" data-network="facebook">
                            <img src={require("../../images/facebook.svg")} alt="Facebook" width="20" />
                          </a>
                          :
                          ""
                      }
                      {
                        settings && settings.enableTWSignup ?
                          <a rel="nofollow" href={window.APIURL + "auth/twitter"} className="susi-btn twitter-susi-btn button d-inline-block align-top" data-network="twitter">
                            <img src={require("../../images/twitter.svg")} alt="Twitter" width="22" />
                          </a>
                          :
                          ""
                      }
                    </div>
                  </div>
                </React.Fragment>
            }
            <div className="text-center">
              <div className="switch to-sign-in terms-and-conditions">
                <span className="gray-link">
                  {window.i18n('signup_byCreating')} <br />{window.i18n('signup_nedlink')} <Link to="/resources/terms-of-services">{window.i18n('signup_termofService')}</Link> {window.i18n('signup_and')}{" "}
                  <Link to="/resources/privacy-policy">{window.i18n('signup_privacyPolicy')}</Link>
                </span>
              </div>
              <div className="switch to-sign-in">
                <span className="gray-link">{window.i18n('signup_already')}</span>{" "}
                <Link to="userLogin">{window.i18n('global_SignIn')}</Link>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  getSiteSetting, beforeSiteSettings
};

const mapStateToProps = state => ({
  settings: state.settings,
  header: state.header,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Register));