import React, { Component } from 'react'
class MainLoaderComponent extends Component {
    render() {
        return (
            // <div className="main-loader" >
            //     <div className="testing-loader-container">
            //         <svg>
            //             <g>
            //                 <path d="M 50,100 A 1,1 0 0 1 50,0" />
            //             </g>
            //             <g>
            //                 <path d="M 50,75 A 1,1 0 0 0 50,-25" />
            //             </g>
            //             <defs>
            //                 <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            //                     <stop offset="0%" style={{ stopColor: '#ef56a0', stopOpacity: '1' }} />
            //                     <stop offset="100%" style={{ stopColor: '#872a5d', stopOpacit: '1' }} />
            //                 </linearGradient>
            //             </defs>
            //         </svg>
            //     </div>
            // </div>
            <div className="main-loader-component">
                <span class="main-loader"></span>
            </div>
        )
    }
}
export default MainLoaderComponent