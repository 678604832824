import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addCustomDomain, beforeCustomDomain } from './action/custom-domain.action';
import { scrollTop } from '../../../shared/common-functions';
import swal from 'sweetalert';
import { ENV } from '../../../config/config';
import { Redirect } from 'react-router-dom';

class AddCustomDomain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      errors: '',

      // payload
      name: ''
    };
  }

  componentDidMount() {
    scrollTop();
  }

  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.errors).length > 0) {
      this.setState({ errors: nextProps.errors.message }, () => {
        if (this.state.errors)
          swal(window.i18n('alert_a'), this.state.errors, 'error');
      })
    }

    if (nextProps.customDomain.addCustomDomainAuth) {
      this.reset();

      let result = nextProps.customDomain.addCustomDomainRes;

      // COMMENTED TEMP.
      // swal(window.i18n('alert_ay'), result.message, 'success');

      window.location.href = '/web/organization/domain?created=true';
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  reset() {
    this.setState({
      name: ''
    });
  }

  submit = (e) => {
    e.preventDefault();

    let { name } = this.state;

    const regexp = /(?:[a-z0-9](?:[a-z0-9-]{0,29}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,29}[a-z0-9]/;

    if (name === "") {
      swal(window.i18n('alert_a'), window.i18n('alert_r'), 'error');
      return;
    }
    else if (!name.match(regexp)) {
      swal(window.i18n('alert_a'), window.i18n('alert_s'), 'error');
      return;
    }
    else {
      this.setState({ errors: '' }, () => {
        let body = {
          name,
          userId: JSON.parse(localStorage.getItem("LoginSession")).data._id
        };

        this.props.addCustomDomain(body);
      });
    }
  }

  render() {
    // if (Object.keys(this.props.errors).length > 0) {
    //   this.setState({ errors: this.props.errors.message }, () => {
    //     if (this.state.errors)
    //       swal(window.i18n('alert_a'), this.state.errors, 'error');
    //   })
    // }

    // if (this.props.customDomain.addCustomDomainAuth) {
    //   this.reset();

    //   let result = this.props.customDomain.addCustomDomainRes;

    //   swal(window.i18n('alert_ay'), result.message, 'success');
    // }

    return (
      <React.Fragment>
        <div
          className="action-sheet--wrapper-MAIN data-state"
          style={{ display: "none" }}
          id="add_domain"
        >
          <div
            className="action-sheet--MAIN"
            data-selected-sheet="orgDomainAdd"
            style={{
              animation: "600ms ease 0ms 1 normal forwards running open-sheet"
            }}
          >
            <div className="action-sheet--header">
              <a>
                <span className="back_btn-icon" alt="back_btn-icon" />
              </a>
              <h4 className="action-sheet--header-text">{window.i18n('addCstmDom_addDom')}</h4>
              <span>
                <a>
                  <span className="close-icon" alt="close-icon" />
                </a>
              </span>
            </div>
            <div className="action-sheet--content-wrapper">
              {/* <div className="action-sheet--content-header">
                1. Purchase A Domain Name
              </div>
              <p className="action-sheet--help-text">
                Purchase a Custom Domain to use in place of{" "}
                <strong>FLO.ink</strong> for your Nedlinks.
              </p>
              <p className="action-sheet--help-text">
                Your Custom Domain must be less than 15 characters, including
                the dot.
              </p> */}
              <div className="action-sheet--content-header">
                {window.i18n('addCstmDom_1setUp')}
              </div>
              <p className="action-sheet--help-text">
                {window.i18n('addCstmDom_setYourDnsA')} {ENV.server_ip_address}
              </p>
              <p className="action-sheet--help-text">
                {window.i18n('addCstmDom_ifYouAre')}
              </p>
              <p className="action-sheet--text">
                {window.i18n('addCstmDom_learnMoreA')}
                {" "}
                <a className="link-no-dark td_ul" target="_blank" href="https://help.one.com/hc/en-us/articles/360000799298-How-do-I-create-an-A-record">
                  {window.i18n('addCstmDom_dnsRecordSet')}
                </a>
              </p>
              <div className="action-sheet--form">
                <div className="checklist-row--no-bottom-border">
                  <div className="action-sheet--content-header">
                    {window.i18n('addCstmDom_2entrYourC')}
                  </div>
                  <div className="text-field--wrapper">
                    <div className="text-field--MAIN">
                      <label className="text-field--label">{window.i18n('addCstmDom_customDom')} <br /><small>{window.i18n('addCstmDom_domNameMust')}</small></label>
                      <div className="text-field--textarea-wrapper">
                        <textarea
                          type="text"
                          rows="1"
                          className="text-field--textarea"
                          maxLength="29"
                          autoComplete="false"
                          name="name"
                          onChange={(e) => this.onChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="action-sheet--button-wrapper">
                  <button
                    className="button--SOLID-SECONDARY"
                    type="button"
                    tabIndex="0"
                    id=""
                    onClick={this.submit}
                  >
                    {window.i18n('addCstmDom_addDom')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  addCustomDomain, beforeCustomDomain
};

const mapStateToProps = state => ({
  customDomain: state.customDomain,
  errors: state.errors
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomDomain);