import React, { Component } from "react";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import InfiniteScroll from 'react-infinite-scroll-component';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';
import moment from 'moment/min/moment-with-locales';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: rgba(255,255,255);
`;     // infinit scroll

class LoginHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
      email: "",
      actionHistory: [],
      start: 0,
      total: 20,
      dataLength: 0,
      hasMoreRecords: true,
      actionHistoryAll: []
    };
  }

  componentDidMount = () => {
    moment.locale(localStorage.getItem('language') ? localStorage.getItem('language') : 'en');
  }

  componentWillMount = () => {

    let email = JSON.parse(localStorage.getItem("LoginSession")).data.primaryEmail;

    let hash = window.location.hash;
    if (hash !== "" && hash.split("&").length > 0) {
      let splited = hash.split("&");
      let last = splited[splited.length - 1].split("=")[1];
      if (last === "security") {
        this.getLoginHistory(email);
      }
    }
  }

  getLoginHistory = (emaill) => {
    this.setState({ start: 0, total: 20 });
    if (!emaill)
      emaill = JSON.parse(localStorage.getItem("LoginSession")).data.primaryEmail;
    fetch(window.APIURL + "accounts/member/user-actions-history?lang=" + this.state.language, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: emaill,
        // start: this.state.start,
        // total: this.state.total
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) {
          let actionHistory = this.state.actionHistory;
          let actionHistoryAll = result.history.reverse();
          let nextRecords = actionHistoryAll.splice(this.state.start, this.state.start + this.state.total);
          // actionHistory =  actionHistory.concat(nextRecords);
          actionHistory = nextRecords;
          this.setState({ actionHistory: actionHistory, start: this.state.start + 10, actionHistoryAll: actionHistoryAll, dataLength: actionHistoryAll.length }, function () {
            if (this.state.start >= result.totalRecords) {
              this.setState({ hasMoreRecords: false });
            }
          });
        } else if (result.status === false) {
          swal(window.i18n('alert_a'), result.message, 'error');
        } else {
          swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
        }
      });
  };

  getMoreLoginHistory = () => {
    let actionHistory = this.state.actionHistory;
    let actionHistoryAll = this.state.actionHistoryAll;
    let nextRecords = actionHistoryAll.splice(this.state.start, this.state.start + this.state.total);
    actionHistory = actionHistory.concat(nextRecords);
    this.setState({ actionHistory: actionHistory, start: this.state.start + 10, dataLength: actionHistoryAll.length }, function () {
      if (this.state.start >= actionHistoryAll.length) {
        this.setState({ hasMoreRecords: false });
      }
    });
  };

  render() {

    return (
      <React.Fragment>
        <h5 className="action-sheet--content-header">{window.i18n('loginHis_accessHistory')}</h5>
        <div id="loginHstoryScrollParent" className={`${this.state.hasMoreRecords ? '' : 'allDataLoaded'}`}>
          {/* <div className="action-sheet--ghost-button-wrapper">
            <a className="button--GHOST-SECONDARY">Sign out of all sessions</a>
          </div> */}
          <InfiniteScroll
            dataLength={this.state.actionHistory.length}
            next={this.getMoreLoginHistory}
            hasMore={this.state.hasMoreRecords}
            scrollableTarget="loginHstoryScrollParent"
            loader={
              <ClipLoader
                css={override}
                sizeUnit={"px"}
                size={50}
                color={'#123abc'}
                loading={true}
              />
            }
          >
            {
              this.state.actionHistory.length > 0 ? (
                this.state.actionHistory.map((row, index) => (
                  <div key={index} className="action-sheet--item">
                    <div className="session-log--MAIN">
                      <p className="session-log--action">
                        {JSON.parse(row).message}</p>
                      <p>{window.i18n('static_CLhSpm')} (
                      {JSON.parse(row).country === null ? "" : JSON.parse(row).country}
                        {JSON.parse(row).country === null ? " " : JSON.parse(row).country === null ? " " : " - "}
                        {JSON.parse(row).city === null ? "" : JSON.parse(row).city}
                      )</p>
                      <p className="session-log--date" title="time ago">
                        {" "}
                        {
                          moment.duration(Date.now() - JSON.parse(row).datetime).humanize() + ` ${window.i18n('static_IHjeHj')}`
                        }
                      </p>
                    </div>
                  </div>
                ))
              )
                :
                ""
            }
          </InfiniteScroll>
        </div>
      </React.Fragment>
    );
  }
}

export default LoginHistory;
