import React, { Component } from "react";
import { Link } from "react-router-dom";

class AccountsNavbar extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <React.Fragment>
        <ul className="layout--navigation">
          <li className="layout--navigation-item selected">
            <Link className="clickable-item" to="/organization/detail">
              Account Details{" "}
            </Link>
          </li>
          <li className="layout--navigation-item ">
            <Link className="clickable-item" to="/organization/domain">
              Custom Domains{" "}
            </Link>
          </li>
          {/* <li className="layout--navigation-item ">
            <a
              className="clickable-item"
              data-toggle="modal"
              data-target="#add_team"
            >
              Users{" "}
              <span className="unlock-icon" alt="unlock-icon">
                <i className="fas fa-unlock-alt" />
              </span>
            </a>
          </li>
          <li className="layout--navigation-item ">
            <a className="clickable-item">
              Groups{" "}
              <span className="unlock-icon" alt="unlock-icon">
                <i className="fas fa-unlock-alt" />
              </span>
            </a>
          </li>
          <li className="layout--navigation-item ">
            <a className="clickable-item">
              Mobile Apps{" "}
              <span className="unlock-icon" alt="unlock-icon">
                <i className="fas fa-unlock-alt" />
              </span>
            </a>
          </li> */}
        </ul>
      </React.Fragment>
    );
  }
}

export default AccountsNavbar;
