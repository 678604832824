import React, { Component } from 'react'
import { css } from '@emotion/core'
import MoonLoader from "react-spinners/MoonLoader"
class SmallLoaderComponent extends Component {
    render() {
        const override = css`
        display: block;
        margin: 0 auto;
        display: inline-block;
        `
        return (
            <span className="sweet-loading">
                <MoonLoader
                    css={override}
                    size={this.props.size}
                    // color={this.props.color ? this.props.color : 'white'}
                    loading={true}
                />
            </span>
        )
    }
}
export default SmallLoaderComponent