import React, { Component, useEffect } from "react";
import { getSiteSetting, beforeSiteSettings } from '../../shared/site-settings/site-settings.action';

const queryString = require("query-string");
var CryptoJS = require("crypto-js");


function MobileLogout(props) {
  useEffect(() => {
    localStorage.clear()
    props.history.push("/userLogin") 
  })

  return <h1></h1>;
}
export default MobileLogout;