import React, { Component } from 'react';
import { Link } from "react-router-dom";

class BlockedPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <React.Fragment>
                <div id="notfound" className="user-forms-outer">
                    <div className="notfound px-3 py-4">
                        <div className="error-page-image mb-3 mb-md-5">
                            <img className="img-fluid" src={require("../../images/blocked-page.svg")} alt="Blocked Image" />
                        </div>
                        <h2>{window.i18n('pg_nt_av')}</h2>
                        <p>{window.i18n('pg_tmp_bl')}</p>
                        <Link to="/" className="btn btn-pink">{window.i18n('err_t3')}</Link>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default BlockedPage;