import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { ENV } from '../../../config/config';
import swal from 'sweetalert';
var CryptoJS = require("crypto-js");

class PaymentSuccess extends Component {
    constructor(props) {
        super(props)
        this.state = {
            session: false,
            mainLoader: false,
            liteAccountText: false
        }
    }

    isJson = (str) => {
        if (str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    componentDidMount() {
        var url = this.props.location.search;
        if (localStorage.getItem("loginToken")) {
            this.setState({ session: true })
        }
        // if (!url.includes('mollie')) {
        //     const Data = this.props.match.params.secret;
        //     var reb64 = CryptoJS.enc.Hex.parse(Data);
        //     var bytes = reb64.toString(CryptoJS.enc.Base64);
        //     var decrypt = CryptoJS.AES.decrypt(bytes, "NowThis");
        //     var decryptedpaymentDetails = JSON.parse(decrypt.toString(CryptoJS.enc.Utf8));

        //     var array = url.split("?");
        //     var payerIdcollect = array[1].split('token=');
        //     var paymentIdCollect = payerIdcollect[1].split("&", 1);
        //     var payerIdCollect = url.split("&PayerID=");
        //     const token = paymentIdCollect[0];
        //     const payerId = payerIdCollect[1];

        //     if (token != '' && payerId != '') {
        //         let userId;
        //         setTimeout(() => {
        //             userId = localStorage.getItem("LoginSession") ? JSON.parse(localStorage.getItem("LoginSession")).data._id : '';
        //             // let url;
        //             // if (decryptedpaymentDetails.subscriptionPayment === true) {
        //             let url = window.APIURL + 'payment/mollie/auto-subscription';
        //             // }
        //             // else if (decryptedpaymentDetails.subscriptionPayment === false) {
        //             // url = window.APIURL + 'custom-domain/paypal/domain-payment';
        //             // }
        //             fetch(url, {
        //                 method: 'post',
        //                 headers: { 'Content-Type': 'application/json' },
        //                 body: JSON.stringify({
        //                     "token": token,
        //                     "payerId": payerId,
        //                     "userId": userId,
        //                     "data": Data,
        //                     // "firstName":"",
        //                     // "countryCode":"",
        //                     // "phoneNumber":"",
        //                     // "lastName":"",
        //                     // "email":"",
        //                     // "city":"",
        //                     // "zipCode":"",
        //                     // "paymentIdUser": decryptedpaymentDetails.paymentId,
        //                     // "packageId": decryptedpaymentDetails.packageId,
        //                     // "isCustomize": decryptedpaymentDetails.isCustomize,
        //                     // "description": decryptedpaymentDetails.description,
        //                     // "finalPrice": decryptedpaymentDetails.finalPrice,
        //                     // "billingMethod": decryptedpaymentDetails.billingMethod,
        //                     // "custom": decryptedpaymentDetails.custom,
        //                     // "packageType": decryptedpaymentDetails.packageType
        //                 })
        //             }).then((res) => {
        //                 if (res.status === 200) {
        //                     return res.json();
        //                 } else if (res.status === 400) {
        //                     return res.json();
        //                 } else if (res.status === 422) {
        //                     return res.json();
        //                 } else {
        //                     return res.json();
        //                 }
        //             }).then((result) => {

        //                 if (result.status === false) {
        //                     swal(window.i18n('alert_a'), window.i18n('alert_ai'), 'error')
        //                 }
        //                 if (result.status === true) {
        //                     let subscriptionPlanDetails = result.updatedPackage;
        //                     let encSubscriptionPlanDetails = CryptoJS.AES.encrypt(JSON.stringify(subscriptionPlanDetails), ENV.TokSecCrpt);
        //                     localStorage.setItem("spd", encSubscriptionPlanDetails);
        //                     // swal(window.i18n('alert_ay'), result.message, 'success')
        //                 }
        //                 // if (result.status === "error") {
        //                 //     // this.setState({ logout: true });
        //                 // }
        //             })
        //         }, 1000)
        //     }
        // } else if (url.includes('mollie')) {
        //     let userId;
        //     setTimeout(
        //         () => {

        //             userId = localStorage.getItem("LoginSession") ? JSON.parse(localStorage.getItem("LoginSession")).data._id : '';

        //             const Data = this.props.match.params.secret;
        //             var reb64 = CryptoJS.enc.Hex.parse(Data);
        //             var bytes = reb64.toString(CryptoJS.enc.Base64);
        //             var decrypt = CryptoJS.AES.decrypt(bytes, "NowThis");
        //             var decryptedpaymentDetails = JSON.parse(decrypt.toString(CryptoJS.enc.Utf8));

        //             if (decryptedpaymentDetails && decryptedpaymentDetails.userForOtherPlatforms.isUser)
        //                 this.setState({ liteAccountText: true })


        //             const language = localStorage.getItem('language') ? localStorage.getItem('language') : 'en'
        //             fetch(window.APIURL + 'payment/mollie/webhook?data=' + Data + '&lang=' + language, {
        //                 method: 'post',
        //                 headers: { 'Content-Type': 'application/json' },
        //                 body: JSON.stringify({
        //                     "userId": userId,
        //                     // "data": Data,
        //                 })
        //             }).then((res) => {
        //                 if (res.status === 200) {
        //                     return res.json();
        //                 } else if (res.status === 400) {
        //                     return res.json();
        //                 } else if (res.status === 422) {
        //                     return res.json();
        //                 } else {
        //                     return res.json();
        //                 }
        //             }).then((result) => {

        //                 if (result.status === false) {
        //                     if (result.redirect === true) {
        //                         this.props.history.push("/organization/detail");
        //                     } else {
        //                         this.setState({ mainLoader: false })
        //                         swal(window.i18n('alert_a'), window.i18n('payment_cancel'), 'error')
        //                     }
        //                 }
        //                 if (result.status === true) {
        //                     this.setState({ mainLoader: false })
        //                     let subscriptionPlanDetails = result.updatedPackage;
        //                     let encSubscriptionPlanDetails = CryptoJS.AES.encrypt(JSON.stringify(subscriptionPlanDetails), ENV.TokSecCrpt);
        //                     localStorage.setItem("spd", encSubscriptionPlanDetails);
        //                     // swal(window.i18n('alert_ay'), result.message, 'success')

        //                     const sub = !localStorage.getItem("subscriptionId") || localStorage.getItem("subscriptionId") === 'null' ? null : localStorage.getItem("subscriptionId");
        //                     const newP = !localStorage.getItem("newPackage") || localStorage.getItem("newPackage") === 'null' ? null : localStorage.getItem("newPackage");

        //                     if (sub && newP) {
        //                         localStorage.removeItem("subscriptionId");
        //                         localStorage.removeItem("newPackage");
        //                     }
        //                 }
        //                 // if (result.status === "error") {
        //                 //     // this.setState({ logout: true });
        //                 // }
        //             })
        //         }, 1000);
        // }
    }

    render() {
        const { liteAccountText } = this.state

        return (
            <div className="d-flex text-center paymentMessageParent success" > {
                this.state.mainLoader === true ?
                    <div className="main-loader" >
                        <div class="testing-loader-container" >
                            <svg >
                                < g >
                                    < path d="M 50,100 A 1,1 0 0 1 50,0" />
                                </g> <g>
                                    <path d="M 50,75 A 1,1 0 0 0 50,-25" />
                                </g> <defs>
                                    <linearGradient id="gradient"
                                        x1="0%"
                                        y1="0%"
                                        x2="0%"
                                        y2="100%">
                                        <stop offset="0%"
                                            style={
                                                { stopColor: '#ef56a0', stopOpacity: '1' }}
                                        />
                                        <stop offset="100%"
                                            style={
                                                { stopColor: '#872a5d', stopOpacit: '1' }}
                                        />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                    </div> :
                    <div className="messageBlock">
                        <h2> {window.i18n('alert_ay')} </h2>
                        <div className="icon-holder" >
                            <i className="fas fa-smile" > </i>
                        </div>
                        <div className="text-holder" >
                            <p> {window.i18n('paymentSuccess_p')} {liteAccountText && ` ${window.i18n('vc_d')}`} </p>
                            <div className="text-center" >
                                <Link className="btn-paymentMessage  d-inline-block align-top"
                                    to={this.state.session === true ? "/dashboard" : "/userLogin"} >
                                    <span className="float-left" > {window.i18n('paymentSuccess_cont')} </span>
                                    <span className="float-right" > < i class="fas fa-arrow-right" > </i></span >
                                </Link>
                            </div>
                        </div>
                    </div>
            }
            </div>
        )
    }
}

export default PaymentSuccess