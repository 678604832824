import { BEFORE_VOUCHER_CODE, UPSERT_VOUCHER_CODE, GET_UNUSED_VOUCHER_CODE } from '../../redux/types'

const initialState = {
    voucherCodeRes: {},
    voucherCodeAuth: false,
    getVoucherCodeRes: {},
    getVoucherCodeAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case UPSERT_VOUCHER_CODE:
            return {
                ...state,
                voucherCodeRes: action.payload,
                voucherCodeAuth: true
            }
        case BEFORE_VOUCHER_CODE:
            return {
                ...state,
                voucherCodeAuth: false,
                getVoucherCodeAuth: false
            }
        case GET_UNUSED_VOUCHER_CODE:
            return {
                ...state,
                getVoucherCodeRes: action.payload,
                getVoucherCodeAuth: true
            }
        default:
            return {
                ...state
            }
    }
}
