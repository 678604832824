import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import React, { Component } from "react";
import DashboardNavbar from "../components/layout-files/DashboardNavbar";
import LoadingOverlay from 'react-loading-overlay';
import "../App.css";
import MainLoader from '../shared/loaders/MainLoader'

library.add(far, fas);

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      updateDash: false,
      showLoader: true
    };
    this.manageChildForEdit = React.createRef();
    this.manageChildDashboard = React.createRef();
  }

  editClick = param => {
    this.manageChildForEdit.current.newLinkIdSetFunction(param);
  };
  addBulkTags = param => {
    this.manageChildForEdit.current.addBulkTagsFunction(param);
  };

  reloadDashboard = (value, updateData) => {
    if (typeof this.manageChildDashboard.current.getData === 'function') {
      if (value && value !== undefined) {
        this.manageChildDashboard.current.getData(value, updateData);
      }
      else {
        this.manageChildDashboard.current.getData(false, updateData);
      }
    }
  }

  editCampaign = (param) => {
    this.manageChildForEdit.current.editCampaignFunction(param);
  }

  createFloinkCampaign = (param) => {
    this.manageChildForEdit.current.newFloinkCamapignFunction(param);
  };

  hideSearch = param => {
    this.manageChildForEdit.current.removeSearch();
  }

  shareLink = (hash, dom) => {
    this.manageChildForEdit.current.shareLink(hash, dom);
  }

  createLink = () => {
    this.manageChildForEdit.current.createDashboardLink();
  }

  createCampaign = () => {
    this.manageChildForEdit.current.createDashboardCampaign();
  }

  clearSearchInput = () => {
    if (typeof this.manageChildForEdit.current.clearInputField === 'function') {
      this.manageChildForEdit.current.clearInputField();
    }
  }

  changeLoaderStatus = (status) => {
    this.setState({
      showLoader: status
    })
  }

  getValueFromChildren(searchValue) {
    if (searchValue !== undefined) {
      if (typeof this.manageChildDashboard.current.sendSearchQueryToFloink === 'function') {
        this.manageChildDashboard.current.sendSearchQueryToFloink(searchValue);
      } else if (typeof this.manageChildDashboard.current.sendSearchQueryToCampaign === 'function') {
        this.manageChildDashboard.current.sendSearchQueryToCampaign(searchValue);
      }
    }
  }

  updateDashboard = (value, updateData) => {
    if (typeof this.manageChildDashboard.current.getAllLinks === 'function') {
      if (value && value !== undefined)
        this.manageChildDashboard.current.getAllLinks(value, updateData);
      else
        this.manageChildDashboard.current.getAllLinks(false, updateData);
    }
  };

  render() {
    return (
      <React.Fragment>
        {
          this.state.showLoader && <MainLoader />
        }
        <DashboardNavbar
            history={this.props.history}
            changeLoaderStatus={this.changeLoaderStatus}
            reloadDashboard={this.reloadDashboard}
            updateDashboard={this.updateDashboard}
            ref={this.manageChildForEdit}
            fromParent={this.getValueFromChildren.bind(this)}
          />
          {React.cloneElement(this.props.children, {
            createFloinkCampaign: this.createFloinkCampaign,
            shareLink: this.shareLink,
            editClick: this.editClick,
            addBulkTags: this.addBulkTags,
            changeLoaderStatus: this.changeLoaderStatus,
            hideSearch: this.hideSearch,
            ref: this.manageChildDashboard,
            editCampaign: this.editCampaign,
            createLink: this.createLink,
            createCampaign: this.createCampaign,
            clearSearchInput: this.clearSearchInput // by uzair
          })}
          
        {/* <LoadingOverlay
          active={}
          spinner
        >
        </LoadingOverlay> */}
      </React.Fragment>
    );
  }
}

export default Dashboard;
