import React, { Component } from 'react'
import { connect } from 'react-redux'
import { validateVoucherCode, beforeVoucherCode, getUnusedVoucherCode } from '../../shared/voucher-code/voucher-code.action'
import { Modal } from 'react-bootstrap'
import SmallLoaderComponent from '../../shared/loaders/SmallLoader'
class ValidateVoucherCode extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userId: '',
            voucherId: '',
            errors: '',
            voucher: '',
            language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
            loader: false,
            show: false,
            isUsed: null,
            discount: null,
            getVoucherLoader: false
        }
    }

    componentDidMount() {
        let userId = '', show = false, loader = false, getVoucherLoader = false

        const user = localStorage.getItem('LoginSession') ? JSON.parse(localStorage.getItem('LoginSession')) : {}
        if (user.data)
            userId = user.data._id

        if (user.data.firstLoginPending && !localStorage.getItem('FTL')) {
            localStorage.setItem('newUser', true)
            localStorage.setItem('FTL', true)
        }

        // from signup
        if (localStorage.getItem('newUser') || this.props.show)
            show = true

        if (this.props.show)
            getVoucherLoader = true

        this.setState({ userId, show, loader, getVoucherLoader }, () => {
            // from change plan screen
            if (this.props.show && userId)
                this.props.getUnusedVoucherCode(userId)
        })
    }

    componentDidUpdate() {
        if (this.props.voucherCode.voucherCodeAuth) {
            this.props.beforeVoucherCode()

            const data = this.props.voucherCode.voucherCodeRes

            if (!data.status)
                return this.setState({ errors: data.message, loader: false })

            localStorage.removeItem('newUser')
            this.setState({ loader: false, show: false }, () => {
                // from change plan screen
                if (this.props.show) {
                    // pass data to change plan screen
                    const voucherDetails = {
                        voucherId: data._id,
                        voucher: data.code ? data.code : '',
                        isUsed: data.isUsed !== null ? data.isUsed : null,
                        discount: data.discount !== null ? data.discount : null,
                        message: data.message ? data.message : '',
                        reseller: data.reseller ? data.reseller : '',
                        secondaryProjects: data.secondaryProjects
                    }
                    this.props.getVoucher(voucherDetails)
                }
            })
        }
        if (this.props.voucherCode.getVoucherCodeAuth) {
            this.setState({
                getVoucherLoader: false
            }, () => {
                const data = this.props.voucherCode.getVoucherCodeRes
                if (data) {
                    this.setState({
                        voucherId: data._id,
                        voucher: data.code ? data.code : '',
                        isUsed: data.isUsed !== null ? data.isUsed : null,
                        discount: data.discount !== null ? data.discount : null
                    })
                }
            })
            this.props.beforeVoucherCode()
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value, errors: '' })
    }

    submit = () => {
        const { voucher } = this.state
        if (!voucher) {
            this.setState({ errors: window.i18n('voucher_req') })
            return
        }
        else {
            this.setState({ errors: '', loader: true }, () => {
                const { userId, voucherId } = this.state
                const body = { voucher, userId, voucherId }
                this.props.validateVoucherCode(body)
            })
        }
    }

    handleClose = () => {
        this.setState({ show: false }, () => {
            if (this.props.show) {
                const { errors, isUsed, voucher } = this.state
                if (errors || (!isUsed && voucher) || (!voucher))
                    this.props.toggleChange('applyVoucher')
            } else
                localStorage.removeItem('newUser')
        })
    }

    render() {
        const { voucher, errors, show, loader, getVoucherLoader } = this.state

        return (
            <Modal show={show} className="soc-int-cd-modal" id="validate-voucher" onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <div className="modal-title">{window.i18n('vc_a')}</div>
                </Modal.Header>
                <Modal.Body>
                    <p>{window.i18n('vc_b')}</p>
                    <input onChange={(e) => this.onChange(e)} type="text" value={voucher} className="form-control" name="voucher" data-form-field="Voucher Code" placeholder={window.i18n('enter_voucher')} required id="voucher" autoComplete="off" />

                    {
                        errors && <div className="alert alert-danger mt-4">{errors}</div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className="m-auto">
                        <button className="btn btn-pink-outline d-flex justify-content-center apply-voucer-btn" disabled={loader || getVoucherLoader} onClick={() => this.submit()}>{getVoucherLoader ? window.i18n('vc_e') : window.i18n('cont_submit')}
                            {(loader || getVoucherLoader) && <SmallLoaderComponent size={13} />}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapDispatchToProps = {
    validateVoucherCode, beforeVoucherCode, getUnusedVoucherCode
}

const mapStateToProps = state => ({
    voucherCode: state.voucherCode,
    errors: state.errors
})

export default connect(mapStateToProps, mapDispatchToProps)(ValidateVoucherCode)