import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Link } from "react-router-dom";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import moment from "moment";
import $ from "jquery";
import { ENV } from '../../config/config';
import ReactTooltip from 'react-tooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { faBullhorn } from '@fortawesome/free-solid-svg-icons'
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { faWifi } from '@fortawesome/free-solid-svg-icons';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import { faTabletAlt } from '@fortawesome/free-solid-svg-icons';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import Notifications, { notify } from 'react-notify-toast';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PieChart from "highcharts-react-official";
var CryptoJS = require("crypto-js");

class StatsDashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			logOutRedirect: false,

			language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
			redirect: false,
			userId: JSON.parse(localStorage.getItem('LoginSession')).data._id,
			startDate: moment(),
			endDate: moment(),
			statsLoader: true,
			enterprise: false,
			dashboardStats: {},
			userCustomBackHalf: {},
			brandedLinks: {},
			apps: {},
			socialPlatforms: {},
			customDomains: {},
			topPerformers: [],
			topDevices: [],
			locationOptions: {
				chart: {
					type: 'column'
				},
				title: {
					text: window.i18n('global_topLoca') //Top Locations'
				},
				accessibility: {
					announceNewData: {
						enabled: true
					}
				},
				xAxis: {
					type: 'category'
				},
				yAxis: {
					title: {
						text: window.i18n('static_ptJyid') //'No. of clicks'
					},
					min: 0,
					tickInterval: 2
				},
				legend: {
					enabled: false
				},
				plotOptions: {
					series: {
						borderWidth: 0,
						cursor: 'pointer',
						dataLabels: {
							enabled: true,
							format: '{point.y}'
						}
					}
				},

				tooltip: {
					headerFormat: '<span style="font-size:14px">{series.name}</span><br>',
					pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> ${window.i18n('static_s3zkfh')}<br/>`
				},

				series: [
					{
						name: window.i18n('quote_I3'), //"Location",
						cursor: 'pointer',
						colorByPoint: true,
						data: []
					}
				]
			},
			showLocations: false, // show top locations chart
			channelsOptions: {
				chart: {
					type: "pie",
					size: 300
				},
				title: {
					text: window.i18n('global_topPerfCamp') //'Top Performing Campaigns & Channels'
				},
				tooltip: {
					pointFormat: '{series.name}: <b>{point.y}</b>'
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: 'pointer',
						dataLabels: {
							enabled: true,
							format: '<b>{point.name}</b>: {point.y}'
						}
					}
				},
				series: [
					{
						name: window.i18n('static_ptJyid'), //'No. of clicks',
						cursor: 'pointer',
						colorByPoint: true,
						data: []
					}
				]
			},
			showChannels: false, // show top campaigns & channels chart
			topSocialPlatforms: []
		}
	}

	componentWillMount() {
		var selectDropDownHeight = $('.select-drop-down').innerHeight();
		// let user = JSON.parse(localStorage.getItem('LoginSession'));
		// let userId = user.data._id;
		let subscriptionPlanDetails = localStorage.getItem("spd");
		let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
		if (!subscriptionPlanDetails) {
			localStorage.removeItem("loginToken");
			localStorage.removeItem("LoginSession");
			localStorage.removeItem("loginTime");
			window.location.href = "/web/userLogin";
			return;
		}
		let decryptedSubscriptionPlanDetails = CryptoJS.AES.decrypt(subscriptionPlanDetails.toString(), ENV.TokSecCrpt);
		decryptedSubscriptionPlanDetails = JSON.parse(decryptedSubscriptionPlanDetails.toString(CryptoJS.enc.Utf8));
		if (!decryptedSubscriptionPlanDetails.dashboardView) {
			this.props.history.push("/organization/detail");
			return;
		}
		if (decryptedSubscriptionPlanDetails)
			this.setState({
				linkFiltering: decryptedSubscriptionPlanDetails.linkFiltering,
				linkHistory: parseInt(decryptedSubscriptionPlanDetails.linkHistory) - 1,
			}, () => {
				// set start date & end date according to history limits
				let { linkHistory } = this.state;
				let startDate = moment().subtract(linkHistory, 'days');
				let endDate = moment();

				this.setState({ startDate, endDate }, () => {
					this.getDashboardStats();
				});
			});
		this.setState({
			analyticsDataExport: decryptedSubscriptionPlanDetails.analyticsDataExport,
			socialPosting: decryptedSubscriptionPlanDetails.socialPosting,
			qrCodeGenerate: decryptedSubscriptionPlanDetails.qrCodeGenerate,
		});
		// this.getDashboardStats();
		this.getEnterpriseCheck();
		this.checkUserSession(id);
		setTimeout(() => {
			this.hideSearch();
		}, 200)
	}

	checkUserSession = (id) => {
		fetch(window.APIURL + "accounts/member/user-session-check?id=" + id + "&lang=" + this.state.language, {
			method: "get",
			headers: {
				"Content-Type": "application/json"
			}
		})
			.then(res => {
				if (res.status === 200) {
					return res.json();
				} else if (res.status === 403) {
					return res.json();
				} else if (res.status === 422) {
					return res.json();
				} else if (res.status === 404) {
					return res.json();
				} else {
					return res.json();
				}
			})
			.then(result => {
				if (result.status) {
				} else if (result.status === false) {
					if (result.special == true) {
						swal('Info', result.message, 'info');
						this.notification(id);
						localStorage.removeItem("loginToken");
						localStorage.removeItem("LoginSession");
						localStorage.removeItem("loginTime");
						// this.setState({ redirect: true });
					} else {
						localStorage.removeItem("loginToken");
						localStorage.removeItem("LoginSession");
						localStorage.removeItem("loginTime");
						this.setState({ redirect: true });
					}
					// swal(window.i18n('alert_a'), result.message, 'error');
				} else {
					localStorage.removeItem("loginToken");
					localStorage.removeItem("LoginSession");
					localStorage.removeItem("loginTime");
					this.setState({ redirect: true });
					// swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
				}
			});
	}

	notification = (user) => {
		fetch(window.APIURL + "accounts/member/switch-bool?userId=" + user + "&lang=" + this.state.language, {
			method: "get",
			headers: { "Content-Type": "application/json" }
		})
			.then(response => response.json())
			.then(result => {
				//console.log('.....', result)
				if (result.status == true) {
					this.setState({ redirect: true });
				}
			});
	}

	hideSearch = () => {
		this.props.hideSearch("off");
	}

	getEnterpriseCheck = () => {
		fetch(window.APIURL + 'subscription-package/check-enterprise?lang=' + this.state.language, {
			method: 'get',
			headers: {
				'Content-Type': 'application/json'
			}
		}).then((res) => {
			if (res.status === 200) {
				return res.json();
			} else {
				return res.json();
			}
		}).then((result) => {
			if (result.status === true) {
				this.setState({
					enterprise: result.result.isEnterprise
				});
			} else if (result.status === false) {
				swal(window.i18n('alert_a'), result.message, 'error');
			} else {
				swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
			}
		})
	}

	getDashboardStats() {
		this.props.changeLoaderStatus(true);

		let { userId, startDate, endDate } = this.state;

		fetch(window.APIURL + 'dashboard/data?lang=' + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
			method: 'post',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				userId,
				startDate: moment(startDate).format('YYYY/MM/DD'),
				endDate: moment(endDate).format('YYYY/MM/DD')
			})
		}).then((res) => {
			if (res.status === 200) {
				return res.json();
			} else {
				return res.json();
				// this.setState({ errors: 'Incorrect user Id' });
				// return 'InvalidCredentialsProvided';
			}
		}).then((result) => {
			if (result.sessionExpired) {
				this.setState({
					logOutRedirect: true
				})
				return;
			}

			var locationOptions = { ...this.state.locationOptions };
			locationOptions.series[0].data = result.topLocations;

			var channelsOptions = { ...this.state.channelsOptions };
			channelsOptions.series[0].data = result.topChannels;

			if (result.status) {
				this.setState({
					userCustomBackHalf: result.userCustomBackHalf,
					brandedLinks: result.brandedLinks,
					apps: result.apps,
					socialPlatforms: result.socialPlatforms,
					customDomains: result.customDomains,
					dashboardStats: result.dashboardStats,
					statsLoader: false,
					topPerformers: result.qLinks,
					topDevices: result.topDevices,
					locationOptions, channelsOptions,
					showLocations: result.topLocations && result.topLocations.length ? true : false,
					showChannels: result.topChannels && result.topChannels.length ? true : false,
					topSocialPlatforms: result.topSocialPlatforms
				});
				this.props.changeLoaderStatus(false);

			} else if (result.status === false) {
				swal(window.i18n('alert_a'), result.message, 'error');
			} else {
				swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
			}
		})
	}

	handleEvent = (e, picker) => {
		this.setState(
			{
				dateFlag: true,
				startDate: picker.startDate,
				endDate: picker.endDate
			},
			() => {
				this.getDashboardStats();
			}
		);
	};

	// handleChange(e) {
	//     this.setState({
	//         [e.target.name]: e.target.value
	//     });
	// }

	exportStats = () => {
		let { userId } = this.state;
		window.location.href = window.APIURL + `csv/FLO.ink_dashboard_data_${userId}.csv`;
	}

	editClick = (hash) => {
		this.props.editClick(hash)
	}

	shareHandler = (hash) => {
		this.props.shareLink(hash)
	}

	generateQR = (shortUrl) => {
		window.open(`/web/qr-code?shortUrl=${shortUrl}`, '_blank');
	}

	copyLink = (key, link) => {
		var fullLink = document.createElement("input");
		document.body.appendChild(fullLink);
		fullLink.value = `https://${link}/`;
		fullLink.select();
		document.execCommand("copy");
		let myColor = { background: '#0a6eb4', text: "#fff" };
		notify.show(window.i18n('landing_copyTo'), "custom", 3000, myColor);
		// $(".clipboard-" + key).show();
		// setTimeout(() => {
		//     $(".clipboard-" + key).hide();
		// }, 1800
		// )
		fullLink.remove();
	};

	upgradeToEnterprise = () => {
		this.props.history.push('/contact-us')
	}

	clearSearch() {
		let linkHistory = this.state.linkHistory;
		let startDate = moment().subtract(linkHistory, 'days');
		let endDate = moment();
		this.setState({ startDate, endDate, dateFlag: false }, () => {
			this.getDashboardStats();
		});
	}

	render() {
		const { dashboardStats, topDevices, topSocialPlatforms, startDate, endDate, userId, redirect, locationOptions, channelsOptions, showLocations, showChannels,
			userCustomBackHalf,
			brandedLinks,
			apps,
			socialPlatforms,
			customDomains
		} = this.state;
		const { logOutRedirect } = this.state;
		if (logOutRedirect) {
			return <Redirect to="/logout" />;
		}

		if (redirect) {
			return <Redirect to="/userLogin" />;
		}

		let totalDays = this.state.linkHistory ? this.state.linkHistory : 60;
		let minDate = moment().subtract(totalDays, "days");
		let maxDate = moment();

		let dateRange = {
			'Today': [moment(), moment()]
		};

		if (moment(minDate).isBefore(moment().subtract(1, 'days'), 'day'))
			dateRange['Yesterday'] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];

		if (moment(minDate).isBefore(moment().subtract(6, 'days'), 'day'))
			dateRange['Last 7 Days'] = [moment().subtract(6, 'days'), moment()];

		if (moment(minDate).isBefore(moment().subtract(29, 'days'), 'day'))
			dateRange['Last 30 Days'] = [moment().subtract(29, 'days'), moment()];

		if (moment(minDate).isBefore(moment().startOf('month'), 'month'))
			dateRange['This Month'] = [moment().startOf('month'), maxDate];

		if (moment(minDate).isBefore(moment().subtract(1, 'month'), 'month'))
			dateRange['Last Month'] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

		return (
			<React.Fragment>
				<Notifications />
				<div className="page-stats">
					<div className="flo-filters-bar p-3">
						<div className="top-info">
							<div className="filter-list--DARK">
								{this.state.analyticsDataExport ?
									<button className="btn-filter-export float-right" tabIndex="0" onClick={this.exportStats}>{window.i18n('global_export')}</button>
									: ''
								}
								<div className="float-right">
									<a style={{ display: 'block' }}>
										<DateRangePicker
											id="selectDate"
											autoApply={false}
											locale={{ format: 'MMMM D, YYYY' }}
											startDate={this.state.startDate}
											endDate={this.state.endDate}
											onApply={this.handleEvent}
											minDate={minDate}
											maxDate={maxDate}
											style={{ display: 'block' }}
											ranges={dateRange}
											opens={"left"}
										>
											<input type="button" name="daterange" className="form-control date-input" value={this.state.startDate.format("MMMM D, YYYY") + " - " + this.state.endDate.format("MMMM D, YYYY")} style={{ display: 'block', width: '100%' }} />
										</DateRangePicker>
									</a>
								</div>
								{this.state.dateFlag ?
									<span className="cur-poi filter-list--clear mr-1 mb-1 float-right" onClick={() => this.clearSearch()}>
										{window.i18n('global_clearAll')}
									</span>
									: ""
								}
							</div>
						</div>
					</div>
					<div className="flo-stats-holder">
						<div className="container-fluid">
							<div className="row">
								<div className="col-xl-4 col-lg-6 col-12 mb-3">
									<div className="stat-holder d-flex flex-column blue-box text-white">
										<strong className="stat-heading text-uppercase">{window.i18n('static_s3zkfh')}</strong>
										<div className="d-flex align-items-center">
											<div className="stat-icon-holder d-flex justify-content-center align-items-center">
												<FontAwesomeIcon icon={faLink} />
											</div>
											<div className="stat-detail-holder flex-fill">
												<div className="stat-detail-row d-flex">
													<strong className="stats-counter mr-1">{dashboardStats.totalClicks}</strong>
													<span className="stats-name">{this.state.statsLoader ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : window.i18n('StatsDash_h')}</span>
												</div>
												<div className="stat-detail-row d-flex flex-column flex-sm-row">
													<div className="w-50">
														<strong className="stats-counter mr-1">{dashboardStats.totalQLinks}</strong>
														<span className="stats-name">{this.state.statsLoader ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : window.i18n('editLink_creat')}</span>
													</div>
													<div className="w-50">
														<strong className="stats-counter mr-1">{dashboardStats.hiddenLinks}</strong>
														<span className="stats-name">{this.state.statsLoader ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : window.i18n('new_inv_i')}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-4 col-lg-6 col-12 mb-3">
									<div className="stat-holder d-flex flex-column orange-box text-white">
										<strong className="stat-heading">{window.i18n('static_9os1vU')}</strong>
										<div className="d-flex align-items-center">
											<div className="stat-icon-holder d-flex justify-content-center align-items-center">
												<FontAwesomeIcon icon={faBullhorn} />
											</div>
											<div className="stat-detail-holder flex-fill">
												<div className="stat-detail-row d-flex">
													<strong className="stats-counter mr-1">{dashboardStats.totalCampaigns}</strong>
													<span className="stats-name">{this.state.statsLoader ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : window.i18n('StatsDash_f')}</span>
												</div>
												<div className="stat-detail-row d-flex">
													<strong className="stats-counter mr-1">{dashboardStats.totalChannels}</strong>
													<span className="stats-name">{this.state.statsLoader ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : window.i18n('StatsDash_e')}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-4 col-lg-6 col-12 mb-3">
									<div className="stat-holder d-flex flex-column purple-box text-white">
										<strong className="stat-heading">{window.i18n('static_J3gCVc')}</strong>
										<div className="d-flex align-items-center">
											<div className="stat-icon-holder d-flex justify-content-center align-items-center">
												<FontAwesomeIcon icon={faWindowRestore} />
											</div>
											<div className="stat-detail-holder flex-fill">
												<div className="stat-detail-row d-flex">
													<strong className="stats-counter mr-1">{apps.allowed}</strong>
													<span className="stats-name">{this.state.statsLoader ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : window.i18n('static_hPPnpI')}</span>
												</div>
												<div className="stat-detail-row d-flex">
													<strong className="stats-counter mr-1">{apps.connected}</strong>
													<span className="stats-name">{this.state.statsLoader ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : window.i18n('static_434yZC')}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-4 col-lg-6 col-12 mb-3">
									<div className="stat-holder d-flex flex-column green-blue text-white">
										<strong className="stat-heading">{window.i18n('global_customDom')}</strong>
										<div className="d-flex align-items-center">
											<div className="stat-icon-holder d-flex justify-content-center align-items-center">
												<FontAwesomeIcon icon={faGlobe} />
											</div>
											<div className="stat-detail-holder flex-fill">
												<div className="stat-detail-row d-flex">
													<strong className="stats-counter mr-1">{customDomains.allowed}</strong>
													<span className="stats-name">{this.state.statsLoader ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : window.i18n('static_hPPnpI')}</span>
												</div>
												<div className="stat-detail-row d-flex flex-column flex-sm-row">
													<div className="w-50">
														<strong className="stats-counter mr-1">{customDomains.total}</strong>
														<span className="stats-name">{this.state.statsLoader ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : window.i18n('editLink_creat')}</span>
													</div>
													<div className="w-50">
														<strong className="stats-counter mr-1">{customDomains.verified}</strong>
														<span className="stats-name">{this.state.statsLoader ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : window.i18n('global_verified')}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-4 col-lg-6 col-12 mb-3">
									<div className="stat-holder d-flex flex-column dark-blue text-white">
										<strong className="stat-heading">{window.i18n('static_x3B1VL')}</strong>
										<div className="d-flex align-items-center">
											<div className="stat-icon-holder d-flex justify-content-center align-items-center">
												<FontAwesomeIcon icon={faWrench} />
											</div>
											<div className="stat-detail-holder flex-fill">
												<div className="stat-detail-row d-flex">
													<strong className="stats-counter mr-1">{userCustomBackHalf.allowed}</strong>
													<span className="stats-name">{this.state.statsLoader ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : window.i18n('static_hPPnpI')}</span>
												</div>
												<div className="stat-detail-row d-flex">
													<strong className="stats-counter mr-1">{userCustomBackHalf.total}</strong>
													<span className="stats-name">{this.state.statsLoader ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : window.i18n('editLink_creat')}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-4 col-lg-6 col-12 mb-3">
									<div className="stat-holder d-flex flex-column pumpkin-blue text-white">
										<strong className="stat-heading">{window.i18n('global_brandLinks')}</strong>
										<div className="d-flex align-items-center">
											<div className="stat-icon-holder d-flex justify-content-center align-items-center">
												<FontAwesomeIcon icon={faLink} />
											</div>
											<div className="stat-detail-holder flex-fill">
												<div className="stat-detail-row d-flex">
													<strong className="stats-counter mr-1">{brandedLinks.allowed}</strong>
													<span className="stats-name">{this.state.statsLoader ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : window.i18n('static_hPPnpI')}</span>
												</div>
												<div className="stat-detail-row d-flex">
													<strong className="stats-counter mr-1">{brandedLinks.total}</strong>
													<span className="stats-name">{this.state.statsLoader ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : window.i18n('editLink_creat')}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-4 col-lg-6 col-12 mb-3">
									<div className="stat-holder d-flex flex-column liberty-blue text-white">
										<strong className="stat-heading">{window.i18n('static_BeC4Bg')}</strong>
										<div className="d-flex align-items-center">
											<div className="stat-icon-holder d-flex justify-content-center align-items-center">
												<FontAwesomeIcon icon={faShareAlt} />
											</div>
											<div className="stat-detail-holder flex-fill">
												<div className="stat-detail-row d-flex">
													<strong className="stats-counter mr-1">{socialPlatforms.allowed}</strong>
													<span className="stats-name">{this.state.statsLoader ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : window.i18n('static_hPPnpI')}</span>
												</div>
												<div className="stat-detail-row d-flex">
													<strong className="stats-counter mr-1">{socialPlatforms.connected}</strong>
													<span className="stats-name">{this.state.statsLoader ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : window.i18n('static_434yZC')}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-4 col-lg-6 col-12 mb-3">
									<div className="stat-holder d-flex flex-column palm-red text-white">
										<strong className="stat-heading">{window.i18n('StatsDash_d')}</strong>
										<div className="d-flex align-items-center">
											<div className="stat-icon-holder d-flex justify-content-center align-items-center">
												<FontAwesomeIcon icon={faWifi} />
											</div>
											<div className="stat-detail-holder flex-fill">
												<div className="stat-detail-row d-flex">
													<span className="stats-name">{window.i18n('StatsDash_c')}</span>
												</div>
												{this.state.enterprise ?
													<div className="stat-detail-row d-flex">
														<button className="performance-summary--upsell-link mr-2" onClick={this.upgradeToEnterprise} >{window.i18n('StatsDash_b')}</button>
													</div>
													:
													""
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* divider */}
					<div className="col-12">
						<hr />
					</div>
					<div className="top-floinks">
						<div className="container-fluid">
							<div className="row">
								<div className="col-12">
									<div className="d-flex mb-3">
										<h4 className="info-wrapper--header flex-fill">{window.i18n('global_topPerfNed')}</h4>
										<Link to="/dashboard" className="float-right"><span className="info-wrapper--link">{window.i18n('StatsDash_a')}</span></Link>
									</div>
								</div>
								{
									this.state.topPerformers && this.state.topPerformers.length > 0 ?
										this.state.topPerformers.map((val, key) => {
											return (
												<React.Fragment key={key}>
													<div className="col-xl-4 col-lg-6 col-12 mb-3">
														<div className="top-floink pt-2 pb-4 mb-3">
															<h2 className="text-truncate">{val.title ? val.title : <span className="text-muted">{window.i18n('global_NA')}</span>}</h2>
															<div className="original-link mb-3 text-truncate">{val.link}</div>
															<div className="floink-footer d-flex px-2 flex-column flex-sm-row align-items-start align-items-sm-center">
																<a href={'https://' + val.domain + "/" + val.customizeBackHalf} className="short-link" target="_blank">
																	<span className="bitlink--MAIN" tabIndex="-1">
																		{val.domain + "/" + val.customizeBackHalf}
																		<br className="showOnMobile" />
																		<span className={"clipboard clipboard-" + key}>{window.i18n('global_copiedTo')}</span>
																	</span>
																</a>

																<ReactTooltip />

																<div className="btns-holder flex-fill">
																	<button className="btn-blue-outline" onClick={() => this.copyLink(key, val.domain + "/" + val.customizeBackHalf)} type="button" tabIndex="0" data-clipboard-text={val.domain + "/" + val.customizeBackHalf} id="" data-effect="float" data-tip={window.i18n('global_copyS')}>{window.i18n('global_copyS')}</button>
																	{
																		this.state.socialPosting &&
																		<button onClick={() => this.shareHandler(val.customizeBackHalf)} className="btn-blue-outline" type="button" tabIndex="0" id="" data-effect="float" data-tip={window.i18n('StatsDash_j')}>{window.i18n('StatsDash_j')}</button>
																	}
																	<button onClick={() => this.editClick(val.customizeBackHalf)} className="btn-blue-outline" type="button" tabIndex="0" id="" data-effect="float" data-tip={window.i18n('global_edit')}>{window.i18n('global_edit')}</button>
																	{
																		this.state.qrCodeGenerate &&
																		<button onClick={() => this.generateQR(`${val.domain}/${val.customizeBackHalf}`)} className="btn-blue-outline" type="button" tabIndex="0" id="" data-effect="float" data-tip={window.i18n('StatsDash_i')}>{window.i18n('StatsDash_i')}</button>
																	}
																</div>
															</div>
														</div>
														<span className="creation-date">{window.i18n('dashLinkList_creatAt')} {moment(val.createdAt).format("MMM Do YYYY hh:mm a")}</span>
													</div>
												</React.Fragment>
											)
										})
										:
										<div className="col-12">
											<div className="empty-state--DARK noStatsRecordFound">
												<div className="empty-state--gradient-wrapper" >
													<span>{window.i18n('global_noRecordF')}</span>
												</div>
											</div>
										</div>
								}
							</div>
						</div>
					</div>
					{/* divider */}
					<div className="col-12">
						<hr />
					</div>
					<div className="locations-campaign">
						<div className="container-fluid">
							<div className="row">
								<div className="col-lg-6 col-12">
									<div className="top-locations">
										<div className="topThreeFloinkHolder mb-3">
											<div className="info-wrapper--heading-holder">
												<h4 className="info-wrapper--header">{window.i18n('global_topLoca')}</h4>
											</div>
											{showLocations ?
												<HighchartsReact highcharts={Highcharts} options={locationOptions} />
												:
												<div className="noDataHolder text-center">
													<div className="noDataInner">
														<span className="d-inline-block my-3">{window.i18n('global_noLocF')}</span>
													</div>
												</div>
											}
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-12">
									<div className="topThreeFloinkHolder h-100">
										<div className="info-wrapper--heading-holder">
											<h4 className="info-wrapper--header">{window.i18n('global_topPerfCamp')}</h4>
										</div>
										{showChannels ?
											<PieChart highcharts={Highcharts} options={channelsOptions} />
											:
											<div className="noDataHolder text-center">
												<div className="noDataInner">
													<span className="d-inline-block my-3">{window.i18n('global_noDataF')}</span>
												</div>
											</div>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* divider */}
					<div className="col-12">
						<hr />
					</div>
					<div className="devices-soc-platforms">
						<div className="container-fluid">
							<div className="row">
								<div className="col-lg-6 col-12">
									<div className="top-devices">
										<h4 className="info-wrapper--header mb-3">{window.i18n('global_topDev')}</h4>
										<div className="row">
											{
												topDevices && topDevices.length ?
													topDevices.map((device, key) => {
														let deviceColor = device._id === 'desktop' ? 'orange-box' : device._id === 'mobile' || device._id === 'phone' ? 'purple-box' : device._id === 'tablet' ? 'green-box' : 'orange-box';

														let deviceIcon = device._id === 'desktop' ? faDesktop : device._id === 'mobile' || device._id === 'phone' ? faMobileAlt : device._id === 'tablet' ? faTabletAlt : faDesktop;

														let deviceName = device._id === 'desktop' ? window.i18n('dashLinkList_comp') : device._id === 'mobile' || device._id === 'phone' ? window.i18n('dashLinkList_smartP') : device._id === 'tablet' ? window.i18n('dashLinkList_tablet') : window.i18n('dashLinkList_comp');

														return (
															<div key={key} className="col-lg-4 col-md-6 col-12">
																<div className={`top-device text-white text-center ${deviceColor}`}>
																	<strong className="device-name mb-3 d-block">{deviceName}</strong>
																	<div className="device-icon-holder mb-3 d-inline-block align-top">
																		<span className="device-icon d-flex justify-content-center align-items-center">
																			<FontAwesomeIcon icon={deviceIcon} />
																		</span>
																	</div>
																	<strong className="clcik-count d-block mb-2">{device.clicks}</strong>
																	<span className="click-text">{window.i18n('static_ptJyid')}</span>
																</div>
															</div>
														)
													})
													:
													<div className="col-12">
														<div className="noDataHolder text-center">
															<div className="noDataInner">
																<span className="d-inline-block my-3">{window.i18n('global_noDevF')}</span>
															</div>
														</div>
													</div>
											}
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-12">
									<div className="top-soc-platforms">
										<h4 className="info-wrapper--header mb-3">{window.i18n('global_topPerfS')}</h4>
										<div className="row">
											{
												topSocialPlatforms && topSocialPlatforms.length ?
													topSocialPlatforms.map((platform, key) => {
														let platformColor = platform._id === 'Facebook' ? 'facebook' : platform._id === 'Twitter' ? 'twitter' : '';

														let platformIcon = platform._id === 'Facebook' ? faFacebookF : platform._id === 'Twitter' ? faTwitter : '';

														let platformName = platform._id === 'Facebook' ? window.i18n('dashLinkList_fbook') : platform._id === 'Twitter' ? window.i18n('dashLinkList_tw') : '';

														return (
															<div key={key} className='col-md-6 col-12'>
																<div className={`top-device text-white text-center theme-${platformColor}`}>
																	<strong className="device-name mb-3 d-block">{platformName}</strong>
																	<div className="device-icon-holder mb-3 d-inline-block align-top">
																		<span className="device-icon d-flex justify-content-center align-items-center">
																			<FontAwesomeIcon icon={platformIcon} />
																		</span>
																	</div>
																	<strong className="clcik-count d-block mb-2">{platform.clicks}</strong>
																	<span className="click-text">{window.i18n('static_ptJyid')}</span>
																</div>
															</div>
														)
													})
													:
													<div className="col-12">
														<div className="noDataHolder text-center">
															<div className="noDataInner">
																<span className="d-inline-block my-3">{window.i18n('global_noSplat')}</span>
															</div>
														</div>
													</div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment >
		);
	}
}

export default StatsDashboard;
