import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addQuote, beforeQuote } from './action/contact-page.action';
import countriesCities from "countries-cities";
import { scrollTop } from '../../shared/common-functions';
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import $ from 'jquery'

// require('bootstrap-select');

class GetQuote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: '',
            countries: [],
            // payload
            name: "",
            organization: "",
            location: "",
            website: "",
            strength: "",
            department: "",
            designation: "",
            email: "",
            message: "",
            language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en'
        }
    }

    componentDidMount() {
        scrollTop();
        let Contry = countriesCities.getCountries();
        this.setState({
            countries: Contry,
        },
            (() => {
                setTimeout(() => {
                    $('.my-select').selectpicker();
                }, 1000);
            })
        )
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.contactUs.getQuoteAuth) {
            this.reset();
            let result = nextProps.contactUs.getQuoteRes;
            swal(window.i18n('alert_ay'), result.message, 'success');
            this.props.beforeQuote();
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value, errors: "" });
    }

    reset = () => {
        this.setState({
            name: "",
            organization: "",
            location: "",
            website: "",
            strength: "",
            department: "",
            designation: "",
            email: "",
            message: "",
        }, () => {
            $('#quote-location').siblings('button').children().find('div.filter-option-inner-inner').html('Select Country');
        });
    }

    submit = (e) => {
        e.preventDefault();

        let { name, organization, location, strength, website, department, designation, email, message } = this.state;
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (name === "") {
            this.setState({ errors: window.i18n('alert_bt') });
            return
        }
        else if (organization === "") {
            this.setState({ errors: window.i18n('alert_bu') });
            return;
        } else if (location === "") {
            this.setState({ errors: window.i18n('alert_bv') });
            return;
        }
        else if (strength === "") {
            this.setState({ errors: window.i18n('alert_bw') });
            return;
        }
        else if (department === "") {
            this.setState({ errors: window.i18n('alert_bx') });
            return;
        }
        // else if (designation === "") {
        //     this.setState({ errors: "Designation is Required" });
        //     return;
        // }
        else if (email === "") {
            this.setState({ errors: window.i18n('alert_m') });
            return;
        }
        else if (!email.match(regexp)) {
            this.setState({ errors: window.i18n('alert_n') });
            return;
        }
        // else if (message === "") {
        //     this.setState({ errors: "Message is Required" });
        //     return;
        // }
        else {
            this.setState({ errors: '' }, () => {
                let body = { name, organization, location, strength, department, website, designation, email, message };

                this.props.addQuote(this.state.language, body);
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="main-content">
                    <div className="page-banner">
                        <div className="container h-100 d-flex justify-content-center align-items-center">
                            <div className="page-title-block py-5 text-white">
                                <h1>{window.i18n('global_getAQuote')}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="cms-content content-section">
                        <div className="contact-quotation-holder">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="media-container-column col-12" data-form-type="formoid">
                                        <form className="mbr-form" autoComplete="off">
                                            <input type="hidden" data-form-email="true" value="zdGVBcNRThcdWUUfI+3PsLnnLtHc8JIdEhub3sFJZu3mJPkE6oxv5FmU79KVvaLjKSmEfsOBbKYj1Cps8hm2o1hmKtkhiOdHe4oDrPZiHdR0Yvomv7W9ZOtj6J4piMeE" />
                                            <div className="row row-sm-offset">
                                                <div className="col-lg-4 col-md-6 multi-horizontal" data-for="name">
                                                    <div className="form-group">
                                                        <label className="form-control-label mbr-fonts-style display-7 require" for="name-form1-2w">{window.i18n('quote_I1')}</label>
                                                        <input onChange={(e) => this.onChange(e)} type="text" value={this.state.name} className="form-control" name="name" data-form-field="Name" placeholder={window.i18n('quote_I1PlacH')} required id="name-form1-2w" autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 multi-horizontal" data-for="organization">
                                                    <div className="form-group">
                                                        <label className="form-control-label mbr-fonts-style display-7 require" for="organization-form1-2w">{window.i18n('quote_I2')}</label>
                                                        <input onChange={(e) => this.onChange(e)} type="text" value={this.state.organization} className="form-control" name="organization" data-form-field="Organization" placeholder={window.i18n('quote_I2')} required id="organization-form1-2w" autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 multi-horizontal mb-0" data-for="location">
                                                    <div className="input-field--MAIN px-0 py-0 mb-0">
                                                        <div className="form-group position-relative">
                                                            <label className="form-control-label mbr-fonts-style display-7 require" for="location-form1-2w">{window.i18n('quote_I3')} </label>
                                                            {/* <label className="input-field--label d-block">Country</label> */}
                                                            <select id="quote-location" className="my-select" style={{ width: "100%" }} name="location" value={this.state.location} onChange={(e) => this.onChange(e)}>
                                                                <option value="">{window.i18n('quote_I3PlacH')}</option>
                                                                {
                                                                    this.state.countries.length > 0 ?
                                                                        this.state.countries.map((val, key) => {
                                                                            return (
                                                                                <option key={key} value={val}>{val}</option>
                                                                            )
                                                                        }) : <option value="">{window.i18n('price_noRecordF')}</option>
                                                                }

                                                            </select>
                                                            {/* <input onChange={(e) => this.onChange(e)} type="text" value={this.state.location} className="form-control" name="location" data-form-field="Location" placeholder="e.g. John" required id="location-form1-2w" autoComplete="off" /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 multi-horizontal" data-for="website">
                                                    <div className="form-group">
                                                        <label className="form-control-label mbr-fonts-style display-7" for="website-form1-2w">{window.i18n('quote_I4')}</label>
                                                        <input onChange={(e) => this.onChange(e)} type="text" value={this.state.website} className="form-control" name="website" data-form-field="Website" placeholder={window.i18n('quote_I4PlacH')} required id="website-form1-2w" autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 multi-horizontal" data-for="strength">
                                                    <div className="form-group">
                                                        <label className="form-control-label mbr-fonts-style display-7 require" for="name-form1-2w">{window.i18n('quote_I5')}</label>
                                                        <input onChange={(e) => this.onChange(e)} type="number" value={this.state.strength} className="form-control" name="strength" data-form-field="Strength" placeholder={window.i18n('quote_I5PlacH')} required id="strength-form1-2w" autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 multi-horizontal" data-for="department">
                                                    <div className="form-group">
                                                        <label className="form-control-label mbr-fonts-style display-7 require" for="department-form1-2w">{window.i18n('quote_I6')}</label>
                                                        <input onChange={(e) => this.onChange(e)} type="text" value={this.state.department} className="form-control" name="department" data-form-field="Department" placeholder={window.i18n('quote_I6PlacH')} required id="department-form1-2w" autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 multi-horizontal" data-for="designation">
                                                    <div className="form-group">
                                                        <label className="form-control-label mbr-fonts-style display-7" for="designation-form1-2w">{window.i18n('quote_I7')}</label>
                                                        <input onChange={(e) => this.onChange(e)} type="text" value={this.state.designation} className="form-control" name="designation" data-form-field="Designation" placeholder={window.i18n('quote_I7PlacH')} required id="designation-form1-2w" autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 multi-horizontal" data-for="email">
                                                    <div className="form-group">
                                                        <label className="form-control-label mbr-fonts-style display-7 require" for="email-form1-2w">{window.i18n('global_email')}</label>
                                                        <input onChange={(e) => this.onChange(e)} type="email" value={this.state.email} className="form-control" name="email" data-form-field="Email" placeholder={window.i18n('quote_I8PlacH')} required id="email-form1-2w" autoComplete="off" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group" data-for="message">
                                                <label className="form-control-label mbr-fonts-style display-7" for="message-form1-2w">{window.i18n('cont_I6')}</label>
                                                <textarea onChange={(e) => this.onChange(e)} type="text" value={this.state.message} className="form-control" name="message" rows="7" placeholder={window.i18n('quote_I9PlacH')} data-form-field="Message" id="message-form1-2w" autoComplete="off"></textarea>
                                            </div>

                                            {
                                                this.state.errors ?
                                                    <div className="alert alert-danger">{this.state.errors}</div>
                                                    : ''
                                            }
                                            <span className="input-group-btn float-right">
                                                <button type="button" className="btn btn-pink btn-form" onClick={this.submit}>{window.i18n('cont_submit')}</button>
                                            </span>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    addQuote, beforeQuote
};

const mapStateToProps = state => ({
    contactUs: state.contactUs,
    errors: state.errors
})

export default connect(mapStateToProps, mapDispatchToProps)(GetQuote);