import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';

class AccountActivation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: "",
      redirect: false,
      redirectToVerify: false,
      redirectVerified: false,
      activationToken: "",
      userId: "",
      language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',

    };
  }
  componentWillMount = () => {
    // if (localStorage.getItem("loginToken")) {
    if (this.props.match.params.activationToken) {
      let id = this.props.match.params.userId;
      this.activationTokenVerification(
        this.props.match.params.activationToken, id
      );
    }

    // } else {
    //   localStorage.setItem("redirectionURL", this.props.location.pathname);
    //   this.setState({ redirect: true });
    // }
  };

  activationTokenVerification = (token, userId) => {
    fetch(window.APIURL + "accounts/member/verify-email/" + token+"?lang="+this.state.language, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        _id: userId
      }),
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) {
          // swal(window.i18n('alert_ay'), result.message, 'success')
          this.setState({ redirectToVerify: true });
        } else if (result.status === false) {
          // swal(window.i18n('alert_a'), result.message, 'error');
          this.setState({ redirectVerified: true });
        } else {
          this.setState({ redirect: true });
          swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
        }
      });
  };

  render() {
    const { redirect, redirectToVerify, redirectVerified } = this.state;
    if (redirect) {
      // return <Redirect to="/userLogin" />;
       window.location.href="/web/logout-and-clear-session"
    }
    if (redirectToVerify) {
      return <Redirect to="/email/verified" />;
    }
    if (redirectVerified) {
      return <Redirect to="/email/isVerified" />;
    }
    return <React.Fragment />;
  }
}

export default AccountActivation;
