import React, { Component } from "react";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import $ from "jquery";
import { createBrowserHistory } from "history";
import { Redirect } from "react-router-dom";

class BulkTagLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
      qLinkTags: [],
      removeTagsList: [],
      addTagsList: [],
      createNewTag: "",
      manageTaglLoader: false,
    };
    this.saveBulkTags = this.saveBulkTags.bind(this);
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  componentWillMount = () => {
    // this.getCurrentTags();
  };

  getCurrentTags = selectedIds => {
    // this.setState({ createNewTag: "" })
    let qLinkIds = selectedIds;
    if (!selectedIds) {
      window.location.hash = '';
      $("#nav").removeClass("show");
      $(".menu").removeClass("clicked");
      $(".data-state").hide();
      return
    }
    this.setState({ selectedIds: selectedIds });
    fetch(window.APIURL + "q-link/get-tags?lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        qLinkIds: qLinkIds
      })
    }).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 400) {
        return res.json();
      } else if (res.status === 403) {
        return res.json();
      } else if (res.status === 422) {
        return res.json();
      } else if (res.status === 404) {
        return res.json();
      } else {
        return res.json();
      }
    }).then(result => {
      if (result.sessionExpired) {
        this.setState({
          logOutRedirect: true
        })
        return;
      }


      if (result.status === true) {
        if (result.qlinkTagsData.length) {
          this.setState({ qLinkTags: result.qlinkTagsData[0].tags });
        }
        else {
          this.setState({ qLinkTags: [] });
        }

      } else if (result.status === false) {
        swal(window.i18n('alert_a'), result.message, 'error');;
      } else {
        swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');;
      }
    })
  };

  saveBulkTags = e => {
    e.preventDefault();

    var createdTags = this.state.addTagsList;
    var removedTags = this.state.removeTagsList;

    if (createdTags.length || removedTags.length) {
      let history = createBrowserHistory();
      this.setState({
        manageTaglLoader: true
      }, () => {
        fetch(window.APIURL + "q-link/save-bulk-tags?lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
          method: "post",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            removeTagsList: this.state.removeTagsList,
            addTagsList: this.state.addTagsList,
            selectedIds: this.state.selectedIds,
            userId: JSON.parse(localStorage.getItem("LoginSession")).data._id
          })
        })
          .then(res => {
            if (res.status === 200) {
              return res.json();
            } else if (res.status === 400) {
              return res.json();
            } else if (res.status === 403) {
              return res.json();
            } else if (res.status === 422) {
              return res.json();
            } else if (res.status === 404) {
              return res.json();
            } else {
              return res.json();
            }
          })
          .then(result => {

            if (result.sessionExpired) {
              this.setState({
                logOutRedirect: true
              })
              return;
            }


            if (result.status === true) {
              window.location.hash = '';
              $("#nav").removeClass("show");
              $(".menu").removeClass("clicked");
              $(".data-state").hide();
              swal(window.i18n('alert_ay'), result.message, 'success');
              this.props.updateDashBoard(true);
              this.setState({ createNewTag: "", removeTagsList: [], addTagsList: [], manageTaglLoader: false });
            } else if (result.status === false) {
              if (result.redirection === true) {
                this.setState({ logOutRedirect: true });
                swal(window.i18n('alert_a'), result.message, 'error');
              }
              swal(window.i18n('alert_a'), result.message, 'error');;
            } else {
              swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');;
            }
          });
      })
    } else {
      swal(window.i18n('alert_a'), window.i18n('alert_cf'), 'error');
    }
  };

  removeTags(tag) {
    let qLinkTags = this.state.qLinkTags;
    const index = qLinkTags.indexOf(tag);
    if (index > -1) {
      qLinkTags.splice(index, 1);
    }
    this.setState({ qLinkTags: qLinkTags });

    let removeTagsList = this.state.removeTagsList;
    removeTagsList.push(tag);
    this.setState({ removeTagsList: removeTagsList });
  }

  addTags = e => {
    let tag = this.state.createNewTag;
    $('[name=createNewTag]').val('');

    this.setState({ createNewTag: '' });
    let qLinkTags = this.state.qLinkTags;
    const index = qLinkTags.indexOf(tag);
    if (index < 0) {
      qLinkTags.push(tag);
    }
    this.setState({ qLinkTags: qLinkTags });

    let addTagsList = this.state.addTagsList;
    addTagsList.push(tag);
    this.setState({ addTagsList: addTagsList });
  }

  render() {
    const { logOutRedirect } = this.state;
    if (logOutRedirect) {
      // return <Redirect to="/logout" />;
      window.location.href = "/web/logout-and-clear-session"
    }
    return (
      <React.Fragment>
        <div
          className="action-sheet--wrapper-MAIN data-state"
          data-state="18"
          id="bulk_tags_links"
        >
          <div
            className="action-sheet--MAIN"
            data-selected-sheet="create"
            style={{
              animation: "600ms ease 0ms 1 normal forwards running open-sheet "
            }}
          >
            <div className="action-sheet--header">
              <h4 className="action-sheet--header-text">{window.i18n('bulkTag_creTags')}</h4>
              <span>
                <a>
                  <span className="close-icon" alt="close-icon" />
                </a>
              </span>
            </div>
            <div className="action-sheet--content-wrapper">
              <div>
                <input className="createTagsInput" type="text" name="createNewTag" value={this.state.createNewTag} placeholder={window.i18n('bulkTag_creTags')} onChange={this.onChange} />
                {
                  this.state.createNewTag.length ?
                    <span className="createTagButton" onClick={this.addTags}>{window.i18n('bulkTag_creTag')}</span>
                    : ''
                }
              </div>
              <div className="tagsHolder">
                {
                  this.state.qLinkTags.map((tag, index) => {
                    return <React.Fragment key={index}>
                      <div className="addedTag">
                        <span>{tag}</span>
                        <span className="cross-icon" onClick={() => this.removeTags(tag)}><i className="fa fa-times"></i></span>
                      </div>
                    </React.Fragment>
                  })
                }
              </div>
              {
                this.state.manageTaglLoader === true ?
                  <div className="text-center">
                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                  </div>
                  :
                  ""
              }
              {/* <div>
                <button onClick={this.saveBulkTags}>Save</button>
              </div> */}
              <div className="action-sheet--button-wrapper">
                <button className="button--SOLID-SECONDARY" disabled={this.state.manageTaglLoader === true ? true : false} type="button" onClick={this.saveBulkTags} tabIndex="0" id="">{window.i18n('global_Save')}</button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BulkTagLink;
