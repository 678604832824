import React, { Component } from 'react';
import $ from 'jquery';
import { ENV } from '../../config/config';
import swal from 'sweetalert';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import ClipLoader from 'react-spinners/ClipLoader';
import { Redirect } from 'react-router-dom';
import { css } from '@emotion/core';
import LoadingOverlay from 'react-loading-overlay';
import moment from "moment";
import { countries } from '../../shared/countries';
import Notifications, { notify } from 'react-notify-toast';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import ReactTooltip from 'react-tooltip'

const CryptoJS = require("crypto-js");

const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
`;

class LinkReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logOutRedirect: false,
            language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
            linkHistory: '',
            startDate: moment(),
            endDate: moment(),
            isDesktop: false,
            isMobile: false,
            isTablet: false,
            isExport: false,
            search: '',
            filter: '',
            location: '',
            qlink: {},
            qlinkId: '',
            campaignId: '',
            channelId: '',
            userId: '',
            mainLoader: true,
            reports: [],
            total: 0,
            page: 1,
            limit: 20,
            pages: 0,
            // hasMore: true,
            redirect: false,
        };
    }

    componentDidMount() {
        let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        if (userId) {
            this.setState({
                userId
            }, () => {
                this.checkUserSession(userId);
            });
        }

        let subscriptionPlanDetails = localStorage.getItem("spd");
        let decryptedSubscriptionPlanDetails = CryptoJS.AES.decrypt(subscriptionPlanDetails.toString(), ENV.TokSecCrpt);
        decryptedSubscriptionPlanDetails = JSON.parse(decryptedSubscriptionPlanDetails.toString(CryptoJS.enc.Utf8));

        if (decryptedSubscriptionPlanDetails) {
            let linkHistory = parseInt(decryptedSubscriptionPlanDetails.linkHistory) - 1;

            let startDate = moment().subtract(linkHistory, 'days');
            let endDate = moment();

            this.setState({
                linkHistory,
                startDate, endDate
            });
        }

        if (this.props.location.search) {
            let qlinkId, campaignId, channelId;
            var searchParams = new URLSearchParams(window.location.href);

            // display the key/value pairs
            for (var pair of searchParams.entries()) {
                if (pair[0].includes('link'))
                    qlinkId = pair[1];
                else if (pair[0].includes('campaign'))
                    campaignId = pair[1];
                else if (pair[0].includes('channel'))
                    channelId = pair[1];
            }

            if (qlinkId) {
                this.setState({ qlinkId, campaignId, channelId }, () => {
                    this.getReport();
                });
            }
        }
    }

    componentDidUpdate() {
        ReactTooltip.rebuild()
    }

    checkUserSession = (id) => {
        fetch(window.APIURL + "accounts/member/user-session-check?id=" + id + "&lang=" + this.state.language, {
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => { return res.json(); })
            .then(result => {
                if (result.status === true) { }
                else if (result.status === false) {
                    if (result.special === true) {
                        swal('Info', result.message, 'info');
                        this.notification(id);
                        localStorage.removeItem("loginToken");
                        localStorage.removeItem("LoginSession");
                        localStorage.removeItem("loginTime");
                        // this.setState({ redirect: true });
                    } else {
                        localStorage.removeItem("loginToken");
                        localStorage.removeItem("LoginSession");
                        localStorage.removeItem("loginTime");
                        this.setState({ redirect: true });
                    }
                } else {
                    localStorage.removeItem("loginToken");
                    localStorage.removeItem("LoginSession");
                    localStorage.removeItem("loginTime");
                    this.setState({ redirect: true });
                }
            });
    }

    notification = (user) => {
        fetch(window.APIURL + "accounts/member/switch-bool?userId=" + user + "&lang=" + this.state.language, {
            method: "get",
            headers: { "Content-Type": "application/json" }
        })
            .then(response => response.json())
            .then(result => {
                if (result.status === true) {
                    this.setState({ redirect: true });
                }
            });
    }

    getReport = (page = 1) => {
        let { qlinkId, campaignId, channelId, startDate, endDate, filter, location, search, isDesktop, isMobile, isTablet, isExport } = this.state;

        this.setState({
            // limit: this.state.limit + 10,
            mainLoader: true
        }, () => {
            let url = `${ENV.url}q-link/report?qlinkId=${qlinkId}` + "&accessToken=" + localStorage.getItem('loginToken');

            if (campaignId)
                url += `&campaignId=${campaignId}`;

            if (channelId)
                url += `&channelId=${channelId}`;

            if (startDate)
                url += `&startDate=${startDate}`;

            if (endDate)
                url += `&endDate=${endDate}`;

            if (filter) {
                if (filter === 'isUnique')
                    url += '&isUnique=true';
                else if (filter === 'isRepetitive')
                    url += '&isRepetitive=true';
            }

            if (location) {
                location = location.split(' (')[1].slice(0, -1);
                url += `&location=${location}`;
            }

            if (search)
                url += `&search=${search}`;

            if (isDesktop)
                url += '&isDesktop=true';

            if (isMobile)
                url += '&isMobile=true';

            if (isTablet)
                url += '&isTablet=true';

            if (isExport)
                url += '&isExport=true';
            else
                url += `&page=${page}&limit=${this.state.limit}`;

            fetch(url += '&lang=' + this.state.language, {
                method: "get",
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(res => {
                    return res.json();
                })
                .then(result => {
                    if (result.sessionExpired) {
                        this.setState({
                            logOutRedirect: true
                        })
                        return;
                    }


                    this.setState({ mainLoader: false });
                    if (result.status) {
                        if (isExport) {
                            this.setState({ isExport: false });
                            window.open(result.filePath, '_blank');
                        }
                        else {
                            this.setState({
                                qlink: result.qlink,
                                reports: result.reports,
                                // hasMore: result.reports.length === result.data.total ? false : true,
                                page: result.data.page,
                                pages: result.data.pages,
                                limit: result.data.limit,
                                total: result.data.total,
                            });
                        }
                    } else
                        swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');;
                });
        });
    }

    onPageChange = (page) => {
        this.getReport(page);
    }

    toggleChange = (key, value) => {
        this.setState({
            [key]: !value
        });
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    onExport = () => {
        this.setState({
            isExport: true,
            mainLoader: true
        }, () => {
            this.getReport();
        });
    }

    reset = () => {
        let { linkHistory } = this.state;

        this.setState({
            search: '',
            filter: '',
            location: '',
            isDesktop: false,
            isMobile: false,
            isTablet: false,
            startDate: moment().subtract(linkHistory, 'days'),
            endDate: moment(),
            mainLoader: true
        }, () => {
            $('#visits-select').siblings('button').children().find('div.filter-option-inner-inner').html('All visits');
            $('#countries-select').siblings('button').children().find('div.filter-option-inner-inner').html('All countries');
            this.getReport();
        });
    }

    handleEvent = (e, picker) => {
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate
        });
    };

    copyLink = (link, linkType = 1) => {
        var fullLink = document.createElement("input");
        document.body.appendChild(fullLink);
        fullLink.value = linkType === 1 ? `https://${link}/` : link;
        fullLink.select();
        document.execCommand("copy");
        let myColor = { background: '#872a5d', text: "#fff" };
        notify.show(window.i18n('landing_copyTo'), "custom", 3000, myColor);
        // $(".clipboard-" + index).show();
        // setTimeout(() => {
        //   $(".clipboard-" + index).hide();
        // }, 1800
        // )
        fullLink.remove();
    };

    render() {
        let { linkHistory, redirect, mainLoader, total, hasMore, reports, qlink, filter, location, search, isDesktop, isMobile, isTablet } = this.state;
        if (redirect) {
            // return <Redirect to="/userLogin" />;
            window.location.href = "/web/logout-and-clear-session"
        }

        let totalDays = linkHistory ? linkHistory : 60;
        let minDate = moment().subtract(totalDays, "days");
        let maxDate = moment();

        let dateRange = {
            'Today': [moment(), moment()]
        };

        if (moment(minDate).isBefore(moment().subtract(1, 'days'), 'day'))
            dateRange['Yesterday'] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];

        if (moment(minDate).isBefore(moment().subtract(6, 'days'), 'day'))
            dateRange['Last 7 Days'] = [moment().subtract(6, 'days'), moment()];

        if (moment(minDate).isBefore(moment().subtract(29, 'days'), 'day'))
            dateRange['Last 30 Days'] = [moment().subtract(29, 'days'), moment()];

        if (moment(minDate).isBefore(moment().startOf('month'), 'month'))
            dateRange['This Month'] = [moment().startOf('month'), maxDate];

        if (moment(minDate).isBefore(moment().subtract(1, 'month'), 'month'))
            dateRange['Last Month'] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

        const { logOutRedirect } = this.state;
        if (logOutRedirect) {
            window.location.href = "/web/logout-and-clear-session"
        }

        return (
            <React.Fragment>
                <Notifications />
                <LoadingOverlay
                    active={mainLoader}
                    spinner
                >
                    <div className="admin-section--MAIN reportPageParent">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="admin-section--top">
                                    <div className="mb-2">
                                        <h2 className="admin-section--header d-block">{window.i18n('report_m')}</h2>
                                        <div>
                                            <time className="item-detail--created-date" dateTime="2019-07-30">
                                                <span>{window.i18n('dashLinkList_creatAt')} </span>
                                                {
                                                    moment(qlink.createdAt)
                                                        .format("MMM DD hh:mm A")
                                                        .toUpperCase()
                                                }
                                            </time>

                                            <ReactTooltip />

                                            <div>
                                                <div className="item-detail--title ml-0 long-link-shortner">
                                                    <span data-effect="float" data-tip={qlink.title === '' || qlink.title === undefined ? qlink.link : qlink.title}>
                                                        {qlink.title === '' || qlink.title === undefined ? qlink.link : qlink.title}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <a className="item-detail--url ml-0 long-link-shortner" href={qlink.link} target="_blank" data-effect="float" data-tip={qlink.link}>{qlink.link}</a>
                                                &nbsp;&nbsp;
                                                <span className="copy-icon cur-poi" onClick={() =>
                                                    this.copyLink(
                                                        qlink.link, 2
                                                    )
                                                }>
                                                    <FontAwesomeIcon icon={faCopy} />
                                                </span>
                                            </div>
                                            <ReactTooltip />
                                            <div className="item-detail--title ml-0">
                                                <div className="d-block">
                                                    <a href={'https://' + qlink.domain + "/" + qlink.customizeBackHalf} className="bitlink--copyable-text" target="_blank">
                                                        <span className="bitlink--MAIN" tabIndex="-1">
                                                            {qlink.domain + "/"}
                                                            <span className="bitlink--hash">
                                                                {qlink.customizeBackHalf}
                                                            </span>
                                                            <span className="clipboard">{window.i18n('global_copiedTo')}</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="report-details">
                                            <p>
                                                <span className="d-inline-block align-top mr-1 detail-heading">{window.i18n('campList_e')}</span>
                                                <span className="d-inline-block align-top detail-value">{qlink.totalClicks}</span>
                                            </p>
                                            <>{qlink.channel && <p>
                                                <span className="d-inline-block align-top mr-1 detail-heading">{window.i18n('global_asocChann')}&nbsp;</span>
                                                <span className="d-inline-block align-top detail-value">{qlink.channel}</span>
                                            </p>}</>
                                            <>{qlink.campaign && <p>
                                                <span className="d-inline-block align-top detail-heading">{window.i18n('global_asocCamp')}&nbsp;</span>
                                                <span className="d-inline-block align-top detail-value">{qlink.campaign}</span>
                                            </p>}</>
                                        </div>
                                    </div>
                                </div>
                                {
                                    qlink.tags && qlink.tags.length ?
                                        <div className="addedTagsHoler">
                                            <h2 className="admin-section--header d-block">{window.i18n('price_F1D2')}</h2>
                                            {
                                                qlink.tags.map((tag, ind) => {
                                                    return <span key={ind}>{tag}</span>
                                                })
                                            }
                                        </div>
                                        : ''
                                }
                            </div>
                            <div className="col-md-4">
                                <div className="btnHolder text-right">
                                    {
                                        reports && reports.length ?
                                            <button className="btn exportBtn d-inline-block align-top cur-poi" onClick={this.onExport}><i class="fa fa-download"></i> {window.i18n('inv_dnld')}</button>
                                            :
                                            <button className="btn btn-light exportBtn d-inline-block align-top" disabled={true}><i class="fa fa-download"></i> {window.i18n('inv_dnld')}</button>
                                    }
                                </div>
                                <h2 className="admin-section--header d-block">{window.i18n('report_l')}</h2>
                                <div className="select-item--MAIN report-page-filters">
                                    <div className="mb-2">
                                        <a style={{ display: 'block' }}>
                                            <DateRangePicker
                                                id="selectDate"
                                                autoApply={false}
                                                locale={{ format: 'MMMM D, YYYY' }}
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                onApply={this.handleEvent}
                                                minDate={minDate}
                                                maxDate={maxDate}
                                                style={{ display: 'block' }}
                                                ranges={dateRange}
                                                opens={"left"}
                                            >
                                                <input
                                                    type="button"
                                                    name="daterange"
                                                    className="form-control date-input"
                                                    value={
                                                        this.state.startDate.format("MMMM D, YYYY") +
                                                        " - " +
                                                        this.state.endDate.format("MMMM D, YYYY")
                                                    }
                                                    style={{ display: 'block', width: '100%' }}
                                                />
                                            </DateRangePicker>
                                        </a>
                                    </div>

                                    <div className="mb-2">
                                        <select id="visits-select" className="my-select" value={filter} name="filter" onChange={this.onChange}>
                                            <option value="">{window.i18n('report_k')}</option>
                                            <option value="isUnique">{window.i18n('report_j')}</option>
                                            <option value="isRepetitive">{window.i18n('report_i')}</option>
                                        </select>
                                    </div>
                                    <>
                                        {
                                            countries.length &&
                                            <div className="mb-2">
                                                <select id="countries-select" className="my-select" value={location} name="location" onChange={this.onChange}>
                                                    <option value="">{window.i18n('report_h')}</option>
                                                    {
                                                        countries.map((country, key) => {
                                                            return (
                                                                <option key={key} value={country}>
                                                                    {country}
                                                                </option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        }
                                    </>
                                    <div className="mb-2">
                                        <label className="d-block">{window.i18n('report_e')}</label>
                                        <input className="form-control" value={search} name="search" onChange={this.onChange} />
                                    </div>
                                    <div className="mb-2">
                                        <label className="d-block">{window.i18n('report_c')}</label>
                                        <div className="checkboxesHolder">
                                            <label class="customCheckBoxLabel">{window.i18n('report_g')}
                                                <input type="checkbox" name="isDesktop" checked={isDesktop} onChange={(e) => this.toggleChange('isDesktop', isDesktop)} />
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="customCheckBoxLabel">{window.i18n('global_phone')}
                                                <input type="checkbox" name="isMobile" checked={isMobile} onChange={(e) => this.toggleChange('isMobile', isMobile)} />
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="customCheckBoxLabel">{window.i18n('report_f')}
                                                <input type="checkbox" name="isTablet" checked={isTablet} onChange={(e) => this.toggleChange('isTablet', isTablet)} />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    {
                                        // (filter || search || isDesktop || isMobile || isTablet) &&
                                        <div className="bottomBtnsHolder mb-2 pt-2">
                                            <button className="btn btn-search" type="button" onClick={this.getReport}>{window.i18n('navSearch_search')}</button>
                                            <button className="btn btn-reset" type="button" onClick={this.reset}>{window.i18n('forgot_reset')}</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="customDomainLoaderHolder" style={{ position: 'relative' }}>
                            {/* <InfiniteScroll
                                dataLength={total}
                                next={() => this.getReport()}
                                hasMore={hasMore}
                                loader={
                                    <ClipLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={50}
                                        color={'#123abc'}
                                        loading={hasMore}
                                    />
                                }
                            > */}
                            <div className="empty-state--BITLINKS">
                                <div className="table-responsive">
                                    <table class="table table-borderless reportTable">
                                        <thead>
                                            <tr>
                                                <th>{window.i18n('report_e')}</th>
                                                <th>{window.i18n('report_d')}</th>
                                                <th>{window.i18n('report_c')}</th>
                                                <th>{window.i18n('report_b')}</th>
                                                <th>{window.i18n('report_a')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                reports && reports.length ?
                                                    reports.map((report, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td style={{ fontWeight: 'bold' }}>{report.ip ? report.ip.toUpperCase() : 'N/A'}</td>
                                                                <td>{report.clicks ? report.clicks : 0}</td>
                                                                <td className="text-capitalize">{report.device ? report.device : 'N/A'}</td>
                                                                <td className="text-capitalize">{report.location ? report.location : 'N/A'}</td>
                                                                <td className="text-capitalize">{report.browser ? report.browser : 'N/A'}</td>
                                                            </tr>
                                                        )
                                                    }) :
                                                    <tr>
                                                        <td colSpan="5" className="text-center">{window.i18n('global_noRecordF')}</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* </InfiniteScroll> */}
                            {
                                reports && reports.length ?
                                    <div className="d-flex justify-content-center">
                                        <Pagination
                                            defaultCurrent={1}
                                            pageSize // items per page
                                            current={this.state.page} // current active page
                                            total={this.state.pages} // total pages
                                            onChange={this.onPageChange}
                                            showTitle={false}
                                        />
                                    </div> : ''
                            }
                        </div>
                    </div>
                </LoadingOverlay>
            </React.Fragment >
        )
    }
}

export default LinkReports;