import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addContact, beforeContact } from './action/contact-page.action';
import { scrollTop } from '../../shared/common-functions';
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import $ from 'jquery'
import ReCAPTCHA from "react-google-recaptcha"

// require('bootstrap-select');

class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.recaptchaRef = React.createRef()
        this.state = {
            isCaptcha: false,
            errors: '',

            // payload
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: '',
            category: '',
            language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en'
        }
    }

    componentDidMount() {
        scrollTop();
        $('.my-select').selectpicker();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.contactUs.contactUsAuth) {
            this.reset();
            let result = nextProps.contactUs.contactUsRes;
            swal(window.i18n('alert_ay'), result.message, 'success');
            this.props.beforeContact();
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value, errors: "" });
    }

    reset = () => {
        this.setState({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: '',
            category: '',
            isCaptcha: false
        }, () => {
            // reset captcha as well
            const reCaptchaResult = this.recaptchaRef?.current.reset();
          
            $('#contact-category').siblings('button').children().find('div.filter-option-inner-inner').html('Select Category');
        });
    }

    acceptGoogleCaptcha = (value) => {
        if (value)
            this.setState({ isCaptcha: true })
    }

    submit = (e) => {
        e.preventDefault();

        let { firstName, category, lastName, email, phone, message, isCaptcha } = this.state;
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (category === "") {
            this.setState({ errors: window.i18n('alert_bs') });
            return
        }
        else if (firstName === "") {
            this.setState({ errors: window.i18n('alert_aj') });
            return;
        } else if (lastName === "") {
            this.setState({ errors: window.i18n('alert_ak') });
            return;
        }
        else if (email === "") {
            this.setState({ errors: window.i18n('alert_m') });
            return;
        } else if (!email.match(regexp)) {
            this.setState({ errors: window.i18n('alert_am') });
            return;
        }
        // else if (phone === "") {
        //     this.setState({ errors: "Phone is Required" });
        //     return;
        // }
        else if (message === "") {
            this.setState({ errors: window.i18n('alert_an') });
            return;
        }
        else if (!isCaptcha) {
            this.setState({ errors: window.i18n('captcha_err') });
            return;
        }
        else {
            this.setState({ errors: '' }, () => {
                let body = { category, firstName, lastName, email, phone, message };

                this.props.addContact(this.state.language, body);
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="main-content pl-0">
                    <div className="page-banner">
                        <div className="container h-100 d-flex justify-content-center align-items-center">
                            <div className="page-title-block py-5 text-white">
                                <h1>{window.i18n('global_contactUS')}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="cms-content content-section">
                        <div className="contact-quotation-holder">
                            <div className="container">
                                <div className="row justify-content-center contact-quotation-row">
                                    <div className="col-lg-6">
                                        <div className="contact-map-holder h-100 pb-sm-4 pb-md-5 pb-lg-0">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2442.4277044023374!2d10.466925315797518!3d52.25377787976468!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47aff633f1712015%3A0xf0272e235929f1af!2sErftstra%C3%9Fe%2015%2C%2038120%20Braunschweig!5e0!3m2!1sen!2sde!4v1603353933229!5m2!1sen!2sde" style={{ 'width': '100%', 'height': '100%', 'border': 'none' }} allowfullscreen="" ></iframe>
                                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3403.2564770379877!2d74.27327261450283!3d31.462130181388947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919017cdc86aba3%3A0x1ab82d275b76913!2sArhamSoft%20(Pvt.)%20Ltd.!5e0!3m2!1sen!2s!4v1568875375516!5m2!1sen!2s" style={{ 'width': '100%', 'height': '100%', 'border': 'none' }} allowFullScreen=""></iframe> */}

                                        </div>
                                    </div>
                                    <div className="media-container-column col-lg-6" data-form-type="formoid">
                                        <form className="mbr-form contactUsform" autoComplete="off">
                                            <input type="hidden" data-form-email="true" value="zdGVBcNRThcdWUUfI+3PsLnnLtHc8JIdEhub3sFJZu3mJPkE6oxv5FmU79KVvaLjKSmEfsOBbKYj1Cps8hm2o1hmKtkhiOdHe4oDrPZiHdR0Yvomv7W9ZOtj6J4piMeE" />
                                            <div className="row row-sm-offset">
                                                <div className="col-md-6">
                                                    <div className="input-field--wrapper">
                                                        <div className="input-field--MAIN false undefined py-0">
                                                            <label className="input-field--label d-block require">{window.i18n('cont_I1')}</label>
                                                            <div className="input-field--input-wrapper">
                                                                <div className="StripeElement StripeElement--empty">
                                                                    <div className="__PrivateStripeElement"
                                                                        style={{
                                                                            margin: "0px !important", padding: "0px !important", border: "none !important",
                                                                            display: "block !important", background: "transparent !important", position: "relative !important",
                                                                            opacity: "1 !important"
                                                                        }}>
                                                                        <select id="contact-category" className="my-select" name="category" value={this.state.category} onChange={(e) => this.onChange(e)}>
                                                                            <option value="">{window.i18n('cont_I1Opt1')}</option>
                                                                            <option value="help">{window.i18n('cont_I1Opt2')}</option>
                                                                            <option value="complaint">{window.i18n('cont_I1Opt3')}</option>
                                                                            <option value="feedback">{window.i18n('cont_I1Opt4')}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 multi-horizontal" data-for="firstname">
                                                    <div className="form-group">
                                                        <label className="form-control-label mbr-fonts-style display-7 require" htmlFor="name-form1-2w">{window.i18n('global_firstN')}</label>
                                                        <input onChange={(e) => this.onChange(e)} type="text" value={this.state.firstName} className="form-control" name="firstName" data-form-field="Name" placeholder={window.i18n('cont_I2PlacH')} required id="name-form1-2w" autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 multi-horizontal" data-for="lastname">
                                                    <div className="form-group">
                                                        <label className="form-control-label mbr-fonts-style display-7 require" htmlFor="name-form1-2w">{window.i18n('global_lastN')} </label>
                                                        <input onChange={(e) => this.onChange(e)} type="text" value={this.state.lastName} className="form-control" name="lastName" data-form-field="Name" placeholder={window.i18n('cont_I3PlacH')} required id="name-form1-2w" autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 multi-horizontal" data-for="email">
                                                    <div className="form-group">
                                                        <label className="form-control-label mbr-fonts-style display-7 require" htmlFor="email-form1-2w">{window.i18n('global_email')}</label>
                                                        <input onChange={(e) => this.onChange(e)} type="email" value={this.state.email} className="form-control" name="email" data-form-field="Email" placeholder={window.i18n('cont_I4PlacH')} required id="email-form1-2w" autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 multi-horizontal" data-for="phone">
                                                    <div className="form-group">
                                                        <label className="form-control-label mbr-fonts-style display-7" htmlFor="phone-form1-2w">{window.i18n('cont_I5')}</label>
                                                        <input onChange={(e) => this.onChange(e)} type="number" value={this.state.phone} className="form-control" name="phone" data-form-field="Phone" placeholder={window.i18n('cont_I5PlacH')} id="phone-form1-2w" autoComplete="off" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group" data-for="message">
                                                <label className="form-control-label mbr-fonts-style display-7 require" htmlFor="message-form1-2w">{window.i18n('cont_I6')}</label>
                                                <textarea onChange={(e) => this.onChange(e)} type="text" value={this.state.message} className="form-control" name="message" rows="7" placeholder={window.i18n('cont_I6PlacH')} data-form-field="Message" id="message-form1-2w" autoComplete="off"></textarea>
                                            </div>

                                            <div className="mb-3">
                                                <ReCAPTCHA
                                                    ref={this.recaptchaRef}
                                                    sitekey="6LcnZAoeAAAAAFiiWaYCaPX1k_RlTGRXMRghYwAb"
                                                    onChange={this.acceptGoogleCaptcha}
                                                    className="recaptha-section"
                                                />
                                            </div>

                                            {
                                                this.state.errors ?
                                                    <div className="alert alert-danger">{this.state.errors}</div>
                                                    : ''
                                            }

                                            <span className="input-group-btn float-right">
                                                <button type="button" className="btn btn-pink btn-form" onClick={this.submit}>{window.i18n('cont_submit')}</button>
                                            </span>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    addContact, beforeContact
};

const mapStateToProps = state => ({
    contactUs: state.contactUs,
    errors: state.errors
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);