import React, { Component } from "react";
import {Redirect} from 'react-router-dom'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import swal from "sweetalert";

var _ = require("lodash");
var fp = require("lodash/fp");

class DashboardCharts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logOutRedirect: false,
      floInks: [],
      linkstats: {},
      // To avoid unnecessary update keep all options in the state.
      chartOptions: {
        chart: {
          zoomType: 'x'
        },
        title: {
          text: window.i18n('last_1M')
        },
        subtitle: {
          text: document.ontouchstart === undefined ?
            window.i18n('static_yPwSwv') : window.i18n('static_kdgpiL')
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: window.i18n('cre_Lin_Cou')
          },
          min: 0,
          tickInterval: 2
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          area: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [0, '#7921cb'],
                [1, '#3d00d9']
              ]
            },
            marker: {
              radius: 2
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1
              }
            },
            threshold: null
          }
        },

        series: [{
          cursor: 'pointer',
          type: 'area',
          name: window.i18n('no_of_li'),
          data: [],
          color: '#0a6eb4'
        }],
      },
      hoverData: null,
      isLoader: true
    };
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("LoginSession"));
    if (user)
      this.getChartData(user.data._id); // get past 30 days stats
  }

  setHoverData = e => {
    this.setState({ hoverData: e.target.category });
  };

  setAllStates(links, stats) {
    // Not needed this func. anymore
    // this.setChart(links);
    this.setState({
      floInks: links,
      linkstats: stats
    });
  }

  setChart = links => {
    var dataToDisplay = fp.map(fp.pick(["createdAt"]), links);
    _.forEach(dataToDisplay, function (obj) {
      _.set(obj,
        "createdAt",
        moment(obj.createdAt).format("MMMM DD YYYY"));
      _.set(obj, "date", moment(obj.createdAt).date());
    });

    var resultByDate = _.groupBy(dataToDisplay, function (data) {
      return data.createdAt;
    });

    let chartDays = 29;
    let datesForChart = [];
    for (var i = chartDays; i >= 0; i--) {
      datesForChart.push(
        moment()
          .subtract(i, "days")
          .format("MMMM DD YYYY")
      );
    }

    let countsForChar = [];
    datesForChart.forEach(element => {
      if (resultByDate[element] !== undefined) {
        countsForChar.push(resultByDate[element].length);
      } else {
        countsForChar.push(0);
      }
    });

    var chartOptions = { ...this.state.chartOptions };
    chartOptions.xAxis.categories = datesForChart;
    chartOptions.series[0].data = countsForChar;
    this.setState({ chartOptions });
  };

  getChartData(userId) {
    fetch(`${window.APIURL}q-link/month-stats?userId=${userId}` + "&accessToken=" + localStorage.getItem('loginToken'), {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        return res.json();
      })
      .then(result => {
        if (result.sessionExpired) {
          this.setState({
            logOutRedirect: true
          })
          return;
        }

        if (result.status) {
          var chartOptions = { ...this.state.chartOptions };
          chartOptions.xAxis.categories = result.monthLinks ? result.monthLinks.datesForChart : [];
          chartOptions.series[0].data = result.monthLinks ? result.monthLinks.dataForChart : [];

          this.setState({
            isLoader: false,
            chartOptions
          });
        } else
          swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
      });
  }

  render() {
    const { logOutRedirect } = this.state;
    if (logOutRedirect) {
      // return <Redirect to="/logout" />;
       window.location.href="/web/logout-and-clear-session"
    }
    const { chartOptions, hoverData } = this.state;
    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    );
  }
}

export default DashboardCharts;
