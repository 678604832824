import { ADD_CUSTOM_DOMAIN, BEFORE_CUSTOM_DOMAIN, GET_CUSTOM_DOMAINS } from '../../../../redux/types';

const initialState = {
    addCustomDomainRes: {},
    addCustomDomainAuth: false,

    getCustomDomainsRes: {},
    getCustomDomainsAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_CUSTOM_DOMAIN:
            return {
                ...state,
                addCustomDomainRes: action.payload,
                addCustomDomainAuth: true
            }
        case GET_CUSTOM_DOMAINS:
            return {
                ...state,
                getCustomDomainsRes: action.payload,
                getCustomDomainsAuth: true
            }
        case BEFORE_CUSTOM_DOMAIN:
            return {
                ...state,
                addCustomDomainAuth: false,
                getCustomDomainsAuth: false
            }
        default:
            return {
                ...state
            }
    }
}
