import React, { Component } from "react";
import {Redirect} from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
// import Swal from 'sweetalert2';
import swal from "sweetalert";

class Personal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
      userName: "",
      logOutRedirect: false

    };
    this.setPrimary = this.setPrimary.bind(this);
  }

  componentDidMount() { }

  setPrimary = (email, type) => {
    fetch(window.APIURL + "accounts/member/notifications?lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userId: JSON.parse(localStorage.getItem("LoginSession")).data._id,
        email: email,
        type: type
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {

        if (result.sessionExpired) {
          this.setState({
            logOutRedirect: true
          })
          return;
        }


        if (result.status === true) {
          swal(window.i18n('alert_ay'), result.message, 'success')
          // let temp = { email: email };
          this.props.dataBind(email);
        } else if (result.status === false) {
          swal(window.i18n('alert_a'), result.message, 'error');
        } else {
          swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
        }
      });
    // $("#notification").show();
    // $("#personal").hide();
  };

  render() {
    let { userName } = this.state;

    const { logOutRedirect } = this.state;
    if (logOutRedirect) {
      // return <Redirect to="/logout" />;
       window.location.href="/web/logout-and-clear-session"
    }

    return (
      <React.Fragment>
        <div
          className="action-sheet--wrapper-MAIN data-state"
          data-state="10"
          style={{ display: "none" }}
          id="personal"
        >
          <div
            className="action-sheet--MAIN"
            data-selected-sheet="addPersonalEmail"
            style={{
              animation: "600ms ease 0ms 1 normal forwards running open-sheet "
            }}
          >
            <div className="action-sheet--header">
              <a>
                {" "}
                <span className="back_btn-icon" alt="back_btn-icon">
                  <FontAwesomeIcon icon="chevron-left" />
                </span>{" "}
              </a>
              <h4 className="action-sheet--header-text">{window.i18n('notif_personal')}</h4>
              <span>
                <a>
                  <span className="close-icon" alt="close-icon" />
                </a>
              </span>
            </div>
            <div className="action-sheet--content-wrapper">
              {/* <form> */}
              <div className="checklist--wrapper ">
                {this.props.verified &&
                  this.props.verified.length > 0 &&
                  this.props.verified.map((data, index) => (
                    <div
                      className="checklist--items"
                      key={index + 1}
                      onClick={() => this.setPrimary(data.email, data.type)}
                    >
                      <div
                        className="checklist-row--MAIN neutral"
                      // data-id="notification"
                      >
                        <div className="checklist-row--text">
                          {" "}
                          {/* sdsf@gmail.com */}
                          {data.email}
                        </div>
                        <span className="checklist-row--icon">
                          <div className="radio-check--LARGE">
                            {data.isNotification === true ? (
                              <label
                                // htmlFor={data.email}
                                className="checkmark-icon"
                              >
                                <FontAwesomeIcon icon="check" />
                              </label>
                            ) : (
                                ""
                              )}
                          </div>
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Personal;
