import React, { Component } from "react";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { Redirect } from "react-router-dom";

import ReactTags from "react-tag-autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";

class CreateCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
      campaignTitle: "",
      description: "",
      channels: [],
      suggestions: [],
      tags: [],
      completeChannelData: "",
      channelCount: 0,
      sameScreen: true,
      viewAllChannels: false,
      errors: '',
      errors1: '',
      logOutRedirect: false,
      numOfChannel: 0,
      logOutRedirect: false

    };
  }

  getChannelsForCampaign = () => {
    let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
    fetch(window.APIURL + "channel/user-channels?userId=" + userId + "&lang=" + this.state.language + "&accessToken=" + localStorage.loginToken
    )
      .then(response => response.json())
      .then(result => {
        if (result.sessionExpired) {
          this.setState({ logOutRedirect: true })
          return;
        }
        let channelNames = [];
        result.channels.forEach(element => {
          channelNames.push({ name: element.title });
        });
        this.setState({
          suggestions: channelNames,
          completeChannelData: result.channels
        });
      });
  };

  handleDelete(i) {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
    this.setState({ channelCount: tags.length });
  }

  handleAddition(tag) {
    let objIndex = this.state.tags.findIndex((obj => obj.name === tag.name));
    if (objIndex === -1) {
      const tags = [].concat(this.state.tags, tag);
      this.setState({ tags });
      this.setState({ channelCount: tags.length });
    }
  }

  handleClickAddition = (tag) => {
    let temp = { name: tag };
    let objIndex = this.state.tags.findIndex((obj => obj.name === temp.name));
    if (objIndex === -1) {
      const tags = [].concat(this.state.tags, temp);
      this.setState({ tags });
      this.setState({ channelCount: tags.length });
    }
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  componentWillMount = () => {
    let hash = window.location.hash;
    if (hash != "" && hash.split("&").length > 0) {
      let splited = hash.split("&");
      let last = splited[splited.length - 1].split("=")[1];
      if (last === "create_campaign") {
        this.getChannelsForCampaign();
      }
    }
  };

  updateChannels = (created) => {
    // this.setState({
    //   // channels: [...this.state.channels, created],
    // });
  }

  createTemplate = (e) => {
    if (this.state.numOfChannel < 1) {
      this.setState({
        errors1: "Must be greater then zero"
      })
      return;
    } else {
      let locationHerf = window.location.href;
      let parts = locationHerf.split('?');
      let firstPart = parts[0];
      let value = firstPart.includes('dashboard/campaigns');
      fetch(window.APIURL + "campaign/add/template?lang=" + this.state.language + "&accessToken=" + localStorage.loginToken, {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          numOfChannel: this.state.numOfChannel,
          userId: JSON.parse(localStorage.getItem("LoginSession")).data._id
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 400) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {
          if (result.sessionExpired) {
            this.setState({ logOutRedirect: true })
            return;
          }
          else if (result.status === true) {
            this.setState({
              numOfChannel: 0
            });

            if (value) {
              this.props.reloadDashboard(true);
            }
            else {
              this.props.history.push('/dashboard/campaigns')
            }
            swal(window.i18n('alert_ay'), result.message, 'success')
          } else if (result.status === false) {
            if (result.redirection === true) {
              if (result.special === true) {
                let user = JSON.parse(localStorage.getItem("LoginSession")).data._id
                swal('Info', result.message, 'info');
                this.notification(user);
              } else {
                this.setState({ logOutRedirect: true });
                swal(window.i18n('alert_a'), result.message, 'error');
              }
              // this.setState({ logOutRedirect: true });
              // swal(window.i18n('alert_a'), result.message, 'error');
            } else {
              this.setState({ logOutRedirect: true });
              swal(window.i18n('alert_a'), result.message, 'error');
            }
          } else {
            swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
          }
        });
    }
  }

  notification = (user) => {
    fetch(window.APIURL + "accounts/member/switch-bool?userId=" + user + "&lang=" + this.state.language, {
      method: "get",
      headers: { "Content-Type": "application/json" }
    })
      .then(response => response.json())
      .then(result => {
        if (result.status === true) {
          this.setState({ logOutRedirect: true });
        }
      });
  }

  campaignForm = (e) => {
    if (this.state.campaignTitle === "") {
      this.setState({
        errors: window.i18n('alert_ch')
      })
      // Swal.fire({ title: 'Error', text: "Campaign title is required", customClass: "dangered" })
      return;
    }
    else if (this.state.tags.length === 0) {
      this.setState({
        errors: window.i18n('alert_ci')
      })
      // Swal.fire({ title: 'Error', text: "Atleast one channel is required", customClass: "dangered" })
      return;
    }
    else {
      e.preventDefault();
      let idsChannel = [];
      this.state.tags.forEach(element => {
        let obj = this.state.completeChannelData.find(
          o => o.title === element.name
        );
        idsChannel.push(obj._id);
      });

      let locationHerf = window.location.href;
      let parts = locationHerf.split('?');
      let firstPart = parts[0];
      let value = firstPart.includes('dashboard/campaigns');
      // if (value) {
      //   this.setState({
      //     sameScreen: false
      //   })
      // }

      fetch(window.APIURL + "campaign/create?lang=" + this.state.language + "&accessToken=" + localStorage.loginToken, {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          title: this.state.campaignTitle,
          description: this.state.description,
          channels: idsChannel,
          userId: JSON.parse(localStorage.getItem("LoginSession")).data._id
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 400) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {

          if (result.sessionExpired) {
            this.setState({ logOutRedirect: true })
            return;
          }
          else if (result.status === true) {
            this.setState({
              campaignTitle: "",
              description: "",
              tags: [],
              channelCount: 0
            });
            if (value) {
              this.props.reloadDashboard(true);
            }
            else {
              this.props.history.push('/dashboard/campaigns')
            }
            // $(".back_btn-icon").trigger("click");
            swal(window.i18n('alert_ay'), result.message, 'success')
          } else if (result.status === false) {

            if (result.redirection === true) {
              if (result.special === true) {
                let user = JSON.parse(localStorage.getItem("LoginSession")).data._id
                swal('Info', result.message, 'info');
                this.notification(user);
              } else {
                this.setState({ logOutRedirect: true });
                swal(window.i18n('alert_a'), result.message, 'error');
              }
            } else {
              this.setState({ logOutRedirect: true });
              swal(window.i18n('alert_a'), result.message, 'error');
            }

          } else {
            swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
          }
        });
    }
  };

  clearStates = () => {
    this.setState({
      campaignTitle: '',
      description: '',
      // channels: [],
      tags: [],
      suggestions: [],
      errors: ''
    })
  }

  // handleAdditionFromAll() {
  // };

  viewAllChannelsFun = () => {
    this.setState({ viewAllChannels: !this.state.viewAllChannels });
  };

  render() {
    const { logOutRedirect } = this.state;
    // if (value) {
    //   this.setState({
    //     sameScreen: false
    //   })
    // }
    if (logOutRedirect) {
      window.location.href = "/web/logout-and-clear-session"
    }
    return (
      <React.Fragment>
        <div
          className="action-sheet--wrapper-MAIN data-state"
          data-state="19"
          style={{ display: "none" }}
          id="create_campaign"
        >
          <div
            className="action-sheet--MAIN"
            data-selected-sheet="createCampaign"
            style={{
              animation: "600ms ease 0ms 1 normal forwards running open-sheet "
            }}
          >
            <div className="action-sheet--header">
              <h4 className="action-sheet--header-text">{window.i18n('creatCamp_creaCamp')}</h4>
              <span>
                <a onClick={this.clearStates}>
                  <span className="close-icon" alt="close-icon" />
                </a>
              </span>
            </div>
            <form
            // onSubmit={this.campaignForm}
            >
              <div className="action-sheet--content-wrapper">
                <div className="input-field--wrapper">
                  <div className="input-field--MAIN   ">
                    <label className="input-field--label">{window.i18n('global_title')}&nbsp;<small>({window.i18n('global_required')})</small></label>
                    <div className="input-field--input-wrapper">
                      <input
                        placeholder=""
                        className="input-field--input"
                        autoComplete="false"
                        name="campaignTitle"
                        value={this.state.campaignTitle}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-field--wrapper">
                  <div className="input-field--MAIN   ">
                    <label className="input-field--label">{window.i18n('creatCamp_desc')}&nbsp;<small>({window.i18n('creatCamp_optional')})</small></label>
                    <div className="input-field--input-wrapper">
                      <input
                        placeholder=""
                        className="input-field--input"
                        autoComplete="false"
                        name="description"
                        value={this.state.description}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="dynamic-search-list--ALTERNATE">
                  <h5 className="dynamic-search-list--header">
                    {window.i18n('creatCamp_selectchannels')} ( {this.state.channelCount} )
                  </h5>
                  <a
                    className="search-view-all td_ul"
                    onClick={this.viewAllChannelsFun}
                  >
                    {
                      this.state.viewAllChannels === true ? (
                        <span> {window.i18n('creatCamp_hideAll')} </span>
                      ) : (
                        <span> {window.i18n('creatCamp_showAll')} </span>
                      )
                    }

                  </a>
                  <div className="campaign-tags-selected-list">
                    {
                      this.state.tags &&
                        this.state.tags.length > 0 ?
                        this.state.tags.map((item, index) => (
                          <div className="campaign-tag-item" key={index}>
                            <span className="tag_name">
                              {item.name}
                            </span>
                            <span className="tag_close" onClick={() => this.handleDelete(index)}>
                              <FontAwesomeIcon icon="times" />
                            </span>
                          </div>
                        ))
                        :
                        ""
                    }
                  </div>
                  <div className="search--ALTERNATE">
                    <ReactTags
                      placeholder={window.i18n('editCamp_addChannel')}
                      tags={this.state.tags}
                      suggestions={this.state.suggestions}
                      handleDelete={this.handleDelete.bind(this)}
                      handleAddition={this.handleAddition.bind(this)}
                    />
                  </div>
                  <div className="dynamic-search-list--filter-pills " />
                </div>

                {this.state.viewAllChannels === true ?
                  this.state.completeChannelData.length > 0 ?
                    <div className="allAddedChannels" style={{
                      border: "1px solid",
                      padding: "5px 10px",
                      marginBottom: "5px"

                    }}>
                      {
                        <div>
                          <span style={{ fontWeight: "bold" }} > {window.i18n('creatCamp_allChannels')} </span><br />
                          {this.state.completeChannelData.map((el, index) => {
                            return (
                              <div key={index} onClick={() => this.handleClickAddition(el.title)} >

                                <span style={{ width: "100%", cursor: "pointer", display: "inline-block" }}>
                                  <FontAwesomeIcon icon="arrow-circle-right" /> {el.title}
                                </span>
                                <br />

                              </div>
                            )
                          })
                          }
                        </div>
                      }
                    </div>
                    :
                    <span> {window.i18n('creatCamp_noChannelCr')}</span>
                  :
                  ""
                }
                <div>
                  <div className="action-sheet--link-channel checklist-menu-item mb-2 td_ul" data-id="manage_channels" onClick={this.props.manageChannelsFun}>
                    {window.i18n('creatCamp_manageChann')}
                  </div>
                  {
                    Object.keys(this.state.errors).length > 0 && this.state.errors ?
                      <div className="alert alert-danger">
                        {this.state.errors}
                      </div> : ''
                  }

                  <div className="action-sheet--button-wrapper-visible">
                    <button
                      onClick={this.campaignForm}
                      className="button--SOLID-SECONDARY"
                      type="button"
                    >
                      {window.i18n('creatCamp_saveAaddNed')}
                    </button>
                  </div>

                  {/* <button className="button--SOLID-SECONDARY link checklist-menu-item" */}
                  <button className="btn btn-pink"
                    tabIndex="0"
                    // data-id="create_bitlink"
                    type="button"
                    onClick={() => this.props.createLink()}
                  >

                    {window.i18n('creatCamp_addNednew')}
                  </button>
                </div>
              </div>
            </form>

          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CreateCampaign;
