import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import $ from "jquery";
import { createBrowserHistory } from "history";
const queryString = require("query-string");

class CreateFloinkCampaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
            channels: [],
            suggestions: [],
            completeChannelData: "",
            channelCount: 0,
            viewAllChannels: false,
            selectedId: '',
            campaignID: '',
            longUrl: '',
            errors: '',
            logOutRedirect: false
        };
    }

    campaignChannelData = (param) => {
        this.setState({
            selectedId: '',
            longUrl: '',
            campaignID: param
        })
        let userid = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        fetch(
            window.APIURL + "channel/user-campaign-channels?lang=" + this.state.language + "&accessToken=" + localStorage.loginToken
            , {
                method: "post",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userId: userid,
                    campaignId: param
                })
            })
            .then(response => response.json())
            .then(result => {
                if (result.sessionExpired) {
                    this.setState({ logOutRedirect: true })
                    return;
                }
                let channelNames = [];
                let data = result.channels.channels;
                data.forEach(element => {
                    channelNames.push({ name: element.title, id: element._id });
                });
                this.setState({
                    suggestions: channelNames,
                    completeChannelData: result.channels
                });
            });
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    componentWillMount = () => {
        let hash = window.location.hash;
        if (hash != "" && hash.split("&").length > 0) {
            let splited = hash.split("&");
            let last = splited[splited.length - 1].split("=")[1];
            if (last === "create_floink_campaign") {
                var parsed = queryString.parse(window.location.search);
                if (parsed.campaign !== undefined && parsed.campaign !== "undefined") {
                    let campaign = parsed.campaign;
                    this.campaignChannelData(campaign);
                }
            }
        }
    };


    CreateFloinkCampaign = e => {
        let history = createBrowserHistory();
        const { longUrl, selectedId, campaignID } = this.state;
        if (longUrl === "") {
            this.setState({
                errors: "Long URL is required"
            })
            // Swal.fire({ title: 'Error', text: "Campaign title is required", customClass: "dangered" })
            return;
        }
        else if (selectedId === '') {
            this.setState({
                errors: "Channel is required"
            })
            // Swal.fire({ title: 'Error', text: "Atleast one channel is required", customClass: "dangered" })
            return;
        }
        else {
            let userid = JSON.parse(localStorage.getItem("LoginSession")).data._id;
            e.preventDefault();
            fetch(window.APIURL + "q-link/add?lang=" + this.state.language+ "&accessToken=" + localStorage.getItem('loginToken')  + "&url=" + longUrl , {
                method: "post",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    campaignId: campaignID,
                    link: longUrl,
                    channelId: selectedId,
                    userId: userid
                })
            })
                .then(res => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 400) {
                        return res.json();
                    } else if (res.status === 403) {
                        return res.json();
                    } else if (res.status === 422) {
                        return res.json();
                    } else if (res.status === 404) {
                        return res.json();
                    } else {
                        return res.json();
                    }
                })
                .then(result => {
                    if (result.sessionExpired) {
                        this.setState({
                            logOutRedirect: true
                        })
                        return;
                    }

                    if (result.message && result.message.includes('Malware')) {
                        swal(window.i18n('swal_mal_h'), window.i18n('swal_mal_d'), 'error')
                        return;
                    }
                    if (result.status === true) {
                        this.props.newLinkIdSet(result.qlink.customizeBackHalf);
                        let currentHash = window.location.hash.replace("#", "").split("&");

                        history.push({
                            pathname: "/web/dashboard",
                            search: "?link=" + result.qlink.customizeBackHalf
                        });
                        this.setState({
                            long_url: ""
                        });
                        currentHash[currentHash.length - 1] = "action=edit_bitlink";
                        currentHash = currentHash.join("&");
                        $("#edit_bitlink").show();
                        $("#create_floink_campaign").hide();
                        window.location.hash = currentHash;

                        swal(window.i18n('alert_ay'), result.message, 'success')
                    } else if (result.status === false) {
                        swal(window.i18n('alert_a'), result.message, 'error');
                    } else {
                        swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
                    }
                });
        }
    };

    setCheck = (id) => {
        this.setState({
            selectedId: id
        })
    }

    render() {
        const { selectedId, logOutRedirect } = this.state;

        if (logOutRedirect) {
            // return <Redirect to="/logout" />;
             window.location.href="/web/logout-and-clear-session"
        }
        return (
            <React.Fragment>
                <div
                    className="action-sheet--wrapper-MAIN data-state"
                    data-state="19"
                    style={{ display: "none" }}
                    id="create_floink_campaign"
                >
                    <div
                        className="action-sheet--MAIN"
                        data-selected-sheet="createFloinkCampaign"
                        style={{
                            animation: "600ms ease 0ms 1 normal forwards running open-sheet "
                        }}
                    >
                        <div className="action-sheet--header">
                            <h4 className="action-sheet--header-text">{window.i18n('creaFcamp_addNed')}</h4>
                            <span>
                                <a onClick={this.clearStates}>
                                    <span className="close-icon" alt="close-icon" />
                                </a>
                            </span>
                        </div>
                        <form
                        // onSubmit={this.campaignForm}
                        >
                            <div className="action-sheet--content-wrapper">
                                <div className="input-field--wrapper">
                                    <div className="input-field--MAIN mb-0">
                                        <label className="input-field--label mb-0">
                                            <h5 className="mb-0">{window.i18n('global_campaign')}: {this.state.completeChannelData.title}</h5>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-field--wrapper">
                                    <div className="input-field--MAIN   ">
                                        <label className="input-field--label">{window.i18n('global_pasteLongU')}</label>
                                        <div className="input-field--input-wrapper">
                                            <input
                                                placeholder={window.i18n('creaFcamp_url')}
                                                className="input-field--input"
                                                autoComplete="false"
                                                name="longUrl"
                                                value={this.state.longUrl}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* <form> */}
                                <div className="checklist--wrapper addFloInkAddChannelList mb-4">
                                    <label className="input-field--label">
                                        <h5 className="mb-0">{window.i18n('creaFcamp_selChannel')}</h5>
                                    </label>
                                    {this.state.suggestions &&
                                        this.state.suggestions.length > 0 &&
                                        this.state.suggestions.map((data, index) => (
                                            <div
                                                className="checklist--items cur-poi"
                                                key={index + 1}
                                                onClick={() => this.setCheck(data.id)}
                                            >
                                                {/* <div
                                                    className="checklist-row--MAIN neutral checklist-menu-item"
                                                    data-id="manage_channels"
                                                > */}
                                                <div className="checklist-row--text">
                                                    {" "}
                                                    {/* sdsf@gmail.com */}
                                                    {data.name}
                                                </div>
                                                <span className="checklist-row--icon">
                                                    <div className="radio-check--LARGE">
                                                        {selectedId === data.id ?
                                                            <label
                                                                // htmlFor={data.email}
                                                                className="checkmark-icon"
                                                            >
                                                                <FontAwesomeIcon icon="check" />
                                                            </label>
                                                            :
                                                            ""
                                                        }

                                                    </div>
                                                </span>
                                                {/* </div> */}
                                            </div>
                                        ))}
                                </div>
                                {
                                    Object.keys(this.state.errors).length > 0 && this.state.errors ?
                                        <div className="alert alert-danger">
                                            {this.state.errors}
                                        </div> : ''
                                }
                                <div className="action-sheet--button-wrapper-visible">
                                    <button
                                        // className="button--SOLID-SECONDARY checklist-menu-item"
                                        className="button--SOLID-SECONDARY"
                                        type="button"
                                        tabIndex="0"
                                        // data-id="edit_bitlink"
                                        onClick={this.CreateFloinkCampaign}
                                    >
                                        {window.i18n('creatCamp_addNednew')}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CreateFloinkCampaign;
