import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { Link, NavLink, withRouter } from "react-router-dom";
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { getSiteSetting, beforeSiteSettings } from '../../shared/site-settings/site-settings.action';

class MainFooter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoader: false,
			settings: {},
			resources: [],
			allResources: {},
			logOutRedirect: false
		}
	}

	componentDidMount() {
		this.setState({ isLoader: true });
		this.props.getSiteSetting();
		this.getResources();
	}

	getResources = () => {
		let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

		fetch(window.APIURL + "cms-pages/getAllResources?lang=" + lang + "&accessToken=" + localStorage.loginToken, {
			method: "get",
			headers: {
				"Content-Type": "application/json"
			}
		})
			.then(res => {
				return res.json();
			})
			.then(result => {
				if (result.status === true) {
					this.setState({
						resources: result.result
					})
				} else if (result.status === false) {
					swal(window.i18n('alert_a'), result.message, 'error');
				} else {
					swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
				}
			});
	}

	render() {
		let settings;
		let { logOutRedirect } = this.state
		if (this.props.settings.siteSettingsAuth) {
			settings = this.props.settings.siteSettingsRes.site;
		}
		if (logOutRedirect) {
			return <Redirect to="/logout" />;
		}
		return (
			<React.Fragment>
				<footer id="footer">
					<div className="footer-get-started">
						<div className="container">
							<div className="ft-get-started-block d-flex justify-content-between align-items-center">
								<strong>Start sharing powerful links</strong>
								<Link className="btn btn-pink" to="/userRegister">Get Started</Link>
							</div>
						</div>
					</div>
					<div className="footer-top">
						<div className="container">
							<div className="row">
								<div className="col-md-12">
									{
										settings ?
											<div className="footer-logo-holder text-center">
												<strong className="footer-logo d-inline-block align-top">
													<Link to="/" className="d-inline-block align-top">
														{/* <img src={settings.siteLogo} alt={window.i18n('mainFooter_altLogo')} /> */}
														<img src={require('../../images/logo-floink.svg')} alt={window.i18n('mainFooter_altLogo')} />
													</Link>
												</strong>
											</div>
											:
											""
									}
									<div className="social-links-holder text-center">
										<ul className="list-unstyled social-links d-flex flex-rows">
											{
												settings && settings.fbSocial ?
													<li className="facebook">
														<a className="h-100 d-flex justify-content-center align-items-center" href={settings.fbSocial} target="_blank">
															<i className="fab fa-facebook-f"></i>
														</a>
													</li>
													: ""
											}
											{
												settings && settings.twSocial ?
													<li className="twitter">
														<a className="h-100 d-flex justify-content-center align-items-center" href={settings.twSocial} target="_blank">
															<i className="fab fa-twitter"></i>
														</a>
													</li>
													:
													""
											}
											{
												settings && settings.liSocial ?
													<li className="linkedin">
														<a className="h-100 d-flex justify-content-center align-items-center" href={settings.liSocial} target="_blank">
															<i className="fab fa-linkedin-in"></i>
														</a>
													</li>
													:
													""
											}
										</ul>
									</div>
									<div className="contact-info text-center mb-3 mb-md-5">
										<li className="">
											<span className="contact-nav-icon">
												<img className="img-fluid" src={require("../../images/icon-04.svg")} alt="" />
											</span>
											<a href={(settings && settings.phoneNumber !== "") ? "tel:" + settings.phoneNumber : "N/A"}>{(settings && settings.phoneNumber !== "") ? settings.phoneNumber : "N/A"}</a>
										</li>
										<li className="">
											<span className="contact-nav-icon">
												<img className="img-fluid" src={require("../../images/icon-05.svg")} alt="" />
											</span>
											<a href={(settings && settings.email !== "") ? "mailto:" + settings.email : "N/A"}>{(settings && settings.email !== "") ? settings.email : "N/A"}</a>
										</li>
									</div>
									<ul className="footer-nav list-unstyled text-center">
										{this.state.resources.length > 0 ?
											this.state.resources.map((val, key) => {
												return (
													<li key={key} className="d-inline-block align-top">
														<Link to={`/resources/${val.slug}`}>
															{val.title}
														</Link>
													</li>
												)
											})
										:
											''
										}
									</ul>
								</div>
								{/* <div className="col-lg-4 col-md-6">
									<div className="footer-col">
										{
											settings ?
												<div className="footer-logo-holder">
													<strong className="footer-logo">
														<Link to="/">
															<img src={settings.siteLogo} alt={window.i18n('mainFooter_altLogo')} />
														</Link>
													</strong>
												</div>
												:
												""
										}
										<div className="footer-about-holder">
											<p>{window.i18n('mainFooter_about')}</p>
										</div>
										<div className="social-links-holder">
											<ul className="list-unstyled social-links d-flex flex-rows">
												{
													settings && settings.fbSocial ?
														<li className="facebook">
															<a className="h-100 d-flex justify-content-center align-items-center" href={settings.fbSocial} target="_blank">
																<i className="fab fa-facebook-f"></i>
															</a>
														</li>
														: ""
												}
												{
													settings && settings.twSocial ?
														<li className="twitter">
															<a className="h-100 d-flex justify-content-center align-items-center" href={settings.twSocial} target="_blank">
																<i className="fab fa-twitter"></i>
															</a>
														</li>
														:
														""
												}
												{
													settings && settings.liSocial ?
														<li className="linkedin">
															<a className="h-100 d-flex justify-content-center align-items-center" href={settings.liSocial} target="_blank">
																<i className="fab fa-linkedin-in"></i>
															</a>
														</li>
														:
														""
												}
											</ul>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6">
									<div className="footer-col d-flex flex-column flex-sm-row">
										<div className="footer-block w-100">
											<h3>{window.i18n('mainFooter_legal')}</h3>
											<ul className="footer-nav list-unstyled">
												{
													this.state.resources.length > 0 ?
														this.state.resources.map((val, key) => {
															return (
																<li key={key}>
																	<Link to={`/resources/${val.slug}`}>
																		{val.title}
																	</Link>
																</li>
															)
														})
														:
														''
												}
											</ul>
										</div>
										<div className="footer-block w-100">
											<h3>{window.i18n('mainFooter_WhyNed')}</h3>
											<ul className="footer-nav list-unstyled">
												<li>
													<Link to='/solutions'>{window.i18n('global_solutions')}</Link>
												</li>
												<li>
													<Link to='/features'>{window.i18n('global_features')}</Link>
												</li>
												<li>
													<Link to='/pricing'>{window.i18n('global_pricing')}</Link>
												</li>
												<li>
													<Link to="/contact-us">{window.i18n('global_contactUS')}</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="footer-col d-flex">
										<div className="footer-block w-100">
											<h3>{window.i18n('mainFooter_contact')}</h3>
											<ul className="footer-nav footer-contact-nav list-unstyled">
												<li className="d-flex flex-rows">
													<span className="contact-nav-icon">
														<img className="img-fluid" src={require("../../images/icon-04.svg")} alt="" />
													</span>
													<a href={(settings && settings.phoneNumber !== "") ? "tel:" + settings.phoneNumber : "N/A"}>{(settings && settings.phoneNumber !== "") ? settings.phoneNumber : "N/A"}</a>
												</li>
												<li className="d-flex flex-rows">
													<span className="contact-nav-icon">
														<img className="img-fluid" src={require("../../images/icon-05.svg")} alt="" />
													</span>
													<a href={(settings && settings.email !== "") ? "mailto:" + settings.email : "N/A"}>{(settings && settings.email !== "") ? settings.email : "N/A"}</a>
												</li>
												<li className="d-flex flex-rows">
													<span className="contact-nav-icon">
														<img className="img-fluid" src={require("../../images/icon-06.svg")} alt="" />
													</span>
													<span>{(settings && settings.address !== "") ? settings.address : "N/A"}</span>
												</li>
											</ul>
										</div>
									</div>
								</div> */}
							</div>
						</div>
					</div>
					<div className="footer-bottom">
						<div className="container">
							<div className="copy-text">
								<p>{window.i18n('mainFooter_copyRights')}</p>
								<p>Design &amp; Developed By <a href="https://www.arhamsoft.com" target="_blank">Arhamsoft</a> Pvt Ltd.</p>
							</div>
						</div>
					</div>
				</footer>
			</React.Fragment >
		);
	}
}

const mapDispatchToProps = {
	getSiteSetting, beforeSiteSettings
};

const mapStateToProps = state => ({
	settings: state.settings,
	header: state.header,
	errors: state.errors
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(MainFooter));