import React, { Component } from "react";
import { Redirect } from 'react-router-dom'
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";

// library.add(fab , FontAwesomeIcon);
class SocialNetworks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
      userId: "",
      twitterAccounts: "",
      activeTwitterAccounts: "",
      logOutRedirect: false
    };
    this.deleteTwitter = this.deleteTwitter.bind(this);
  }

  componentWillMount = () => {
    this.setState({
      userId: JSON.parse(localStorage.getItem("LoginSession")).data._id
    });
  };
  componentDidMount = () => {
    this.getTwitterAccounts();
    this.getActiveTwitterAcc();
  };

  enableTwitter = id => {
    let twitterAccountsBackup = this.state.twitterAccounts;

    Swal.fire({
      title: window.i18n('alert_az'),
      text: window.i18n('static_Ls2bhA'),
      showCancelButton: true,
      // customClass: "conformations",
      confirmButtonColor: '#0a6eb4',
      cancelButtonColor: '#d33',
      confirmButtonText: window.i18n('static_lYZwMQ')
    }).then((result) => {
      if (result.value) {


        fetch(window.APIURL + "accounts/member/enable-twitter-accounts?lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
          method: "post",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            _id: id
          })
        })
          .then(res => {
            if (res.status === 200) {
              return res.json();
            } else if (res.status === 400) {
              return res.json();
            } else if (res.status === 403) {
              return res.json();
            } else if (res.status === 422) {
              return res.json();
            } else if (res.status === 404) {
              return res.json();
            } else {
              return res.json();
            }
          })
          .then(result => {

            if (result.sessionExpired) {
              this.setState({
                logOutRedirect: true
              })
              return;
            }

            if (result.status === true) {
              this.getTwitterAccounts();
              this.getActiveTwitterAcc();
              swal(window.i18n('alert_ay'), result.message, 'success')
            } else if (result.status === false) {
              swal(window.i18n('alert_a'), result.message, 'error');
            } else {
              swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
            }
          });
      }
    })
  }

  disableTwitter = id => {
    let twitterAccountsBackup = this.state.twitterAccounts;

    Swal.fire({
      title: window.i18n('alert_az'),
      text: window.i18n('static_LYB4LT'),
      showCancelButton: true,
      // customClass: "conformations",
      confirmButtonColor: '#0a6eb4',
      cancelButtonColor: '#d33',
      confirmButtonText: window.i18n('new_alert_d')
    }).then((result) => {
      if (result.value) {


        fetch(window.APIURL + "accounts/member/disable-twitter-accounts?lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
          method: "post",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            _id: id
          })
        })
          .then(res => {
            if (res.status === 200) {
              return res.json();
            } else if (res.status === 400) {
              return res.json();
            } else if (res.status === 403) {
              return res.json();
            } else if (res.status === 422) {
              return res.json();
            } else if (res.status === 404) {
              return res.json();
            } else {
              return res.json();
            }
          })
          .then(result => {

            if (result.sessionExpired) {
              this.setState({
                logOutRedirect: true
              })
              return;
            }


            if (result.status === true) {
              this.getTwitterAccounts();
              this.getActiveTwitterAcc();
              // const index = twitterAccountsBackup.findIndex(
              //   item => item._id === id
              // );
              // twitterAccountsBackup.splice(index, 1);
              // this.setState({
              //   twitterAccounts: twitterAccountsBackup
              // });
              swal(window.i18n('alert_ay'), result.message, 'success')
            } else if (result.status === false) {
              swal(window.i18n('alert_a'), result.message, 'error');
            } else {
              swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
            }
          });
      }
    })
  }

  deleteTwitter = id => {
    let twitterAccountsBackup = this.state.twitterAccounts;

    Swal.fire({
      title: window.i18n('alert_az'),
      text: window.i18n('alert_bc'),
      showCancelButton: true,
      // customClass: "conformations",
      confirmButtonColor: '#0a6eb4',
      cancelButtonColor: '#d33',
      confirmButtonText: window.i18n('global_yesDel')
    }).then((result) => {
      if (result.value) {


        fetch(window.APIURL + "accounts/member/delete-twitter-accounts?lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
          method: "post",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            _id: id
          })
        })
          .then(res => {
            if (res.status === 200) {
              return res.json();
            } else if (res.status === 400) {
              return res.json();
            } else if (res.status === 403) {
              return res.json();
            } else if (res.status === 422) {
              return res.json();
            } else if (res.status === 404) {
              return res.json();
            } else {
              return res.json();
            }
          })
          .then(result => {


            if (result.sessionExpired) {
              this.setState({
                logOutRedirect: true
              })
              return;
            }



            if (result.status === true) {
              const index = twitterAccountsBackup.findIndex(
                item => item._id === id
              );
              twitterAccountsBackup.splice(index, 1);
              this.setState({
                twitterAccounts: twitterAccountsBackup
              });
              swal(window.i18n('alert_ay'), result.message, 'success')
            } else if (result.status === false) {
              swal(window.i18n('alert_a'), result.message, 'error');
            } else {
              swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
            }
          });
      }
    })
  };

  getTwitterAccounts = () => {
    fetch(window.APIURL + "accounts/member/get-twitter-accounts-setting?lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        _id: this.state.userId
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {

        if (result.sessionExpired) {
          this.setState({
            logOutRedirect: true
          })
          return;
        }


        if (result.status === true) {
          this.setState({
            twitterAccounts: result.data
          });
        } else if (result.status === false) {
          swal(window.i18n('alert_a'), result.message, 'error');
        } else {
          swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
        }
      });
  };

  getActiveTwitterAcc = () => {
    fetch(window.APIURL + "accounts/member/get-twitter-accounts?lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        _id: this.state.userId
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {

        if (result.sessionExpired) {
          this.setState({
            logOutRedirect: true
          })
          return;
        }

        if (result.status === true) {
          this.setState({
            activeTwitterAccounts: result.data
          });
        } else if (result.status === false) {
          swal(window.i18n('alert_a'), result.message, 'error');
        } else {
          swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
        }
      });
  };

  facebookDisabled = () => {
    swal(window.i18n('alert_a'), window.i18n('alert_ah'), 'error');
  }

  render() {
    const { subscriptionDetails } = this.props;
    let totalPlatforms = parseInt(subscriptionDetails.socialPlatforms);
    const { logOutRedirect } = this.state;
    if (logOutRedirect) {
      window.location.href = "/web/logout-and-clear-session"
    }

    return (
      <React.Fragment>
        <div className="action-sheet--header">
          <a>
            {" "}
            <span className="back_btn-icon" alt="back_btn-icon">
              <FontAwesomeIcon icon="chevron-left" />
            </span>{" "}
          </a>
          <h4 className="action-sheet--header-text">{window.i18n('socialNet_connectToSoc')}</h4>
          <span>
            <a>
              <span className="close-icon" alt="close-icon" />
            </a>
          </span>
        </div>
        <div className="action-sheet--content-wrapper">
          <div className="connected-account--sub-header">
            {window.i18n('socialNet_connAcc')}
          </div>
          {this.state.twitterAccounts.length > 0
            ? this.state.twitterAccounts.map((data, index) => (
              <div key={index} className="connected-account--MAIN" style={{ display: "flex" }}>
                <span className="social-icon" style={{ wiidth: "43px", height: "43px" }}>
                  <img className="user-account--image" width="25" src={require("../../../../images/twitter.svg")} />
                </span>
                <div className="accounts-names-holder" style={{ flex: "1 1 auto" }}>
                  <div className="connected-account--account-name">
                    {data.displayName}
                  </div>
                  <div className="connected-account--date">
                    {window.i18n('socialNet_connOn')} {Date(data.createdAt).slice(4, 15)}
                  </div>
                </div>
                <div className="action-icons-holder" style={{ width: "16px" }}>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => this.deleteTwitter(data._id)}
                    className="close-icon"
                    alt="close-icon"
                  >
                    <FontAwesomeIcon icon="times-circle" />
                  </a>
                  {
                    data.enabled ?
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => this.disableTwitter(data._id)}
                        className="close-icon text-success"
                        alt="close-icon"
                      >
                        <FontAwesomeIcon icon="eye" />
                      </a>
                      :
                      !data.enabled && this.state.activeTwitterAccounts.length < totalPlatforms ?
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => this.enableTwitter(data._id)}
                          className="close-icon  text-danger"
                          alt="close-icon"
                        >
                          <FontAwesomeIcon icon="eye-slash" />
                        </a>
                        :
                        ""
                  }
                </div>
              </div>
            ))
            : " "}
          {
            this.state.twitterAccounts.length < totalPlatforms ?
              <React.Fragment>
                <a
                  className="connected-account--link"
                  href={
                    window.APIURL + "add/twitter?id=" + this.state.userId
                  }
                >
                  <div className="select-item--MAIN-b b">
                    <p className="select-item--label">{window.i18n('socialNet_connToATwit')}</p>
                    <span className="select-item--secondary-label" />
                    <span className="select-item--third-label " />
                    <span className="select-item--fourth-label" />
                    <span className="checklist-row--icon">
                      <span className="selector-icon" alt="selector-icon">
                        <FontAwesomeIcon icon="chevron-right" />
                      </span>
                    </span>
                  </div>
                </a>

                {/* <a onClick={this.facebookDisabled} className="connected-account--link"> Commented by Uzair Hassan
                  <div className="select-item--MAIN-b b">
                    <p className="select-item--label">
                      Connect to a Facebook account
                </p>
                    <span className="select-item--secondary-label" />
                    <span className="select-item--third-label " />
                    <span className="select-item--fourth-label" />
                    <span className="checklist-row--icon">
                      <span className="selector-icon" alt="selector-icon">
                        <FontAwesomeIcon icon="chevron-right" />
                      </span>
                    </span>
                  </div>
                </a> */}
              </React.Fragment>
              :
              ""
          }
        </div>
      </React.Fragment>
    );
  }
}

export default SocialNetworks;
