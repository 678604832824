import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import LoadingOverlay from 'react-loading-overlay';
import React, { Component } from "react";
import DashboardNavbar from "../components/layout-files/DashboardNavbar";
import AccountsNavbar from "../components/layout-files/accounts-navbar/accountsNavbar";

import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
library.add(far, fas)
class AccountNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false
    };
  }

  changeLoaderStatus = (status) => {
    this.setState({
      showLoader: status
    })
  }

  render() {
    return (
      <React.Fragment>
        <LoadingOverlay
          active={this.state.showLoader}
          spinner
        >
          <DashboardNavbar
            history={this.props.history}
            changeLoaderStatus={this.changeLoaderStatus}
            fromAccountDetails={"yes"} />
          <div className="orggnztn-holder">
					<div className="container">
						<div className="row layout--wrapper">
							<div className="col-md-12">
								{this.props.children}
							</div>
						</div>
					</div>
				</div>
        </LoadingOverlay>
      </React.Fragment>
    )
  }
}

export default AccountNav;
