import React, { Component } from "react";
import { Tabs, Tab, Modal } from 'react-bootstrap';
import countriesCities from "countries-cities";
import { Redirect } from "react-router-dom";
import moment from 'moment';
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { Input } from 'reactstrap';
import { ENV } from './../../../config/config';
import { Link } from 'react-router-dom';
import CircleLoader from 'react-spinners/CircleLoader';
import ClipLoader from 'react-spinners/ClipLoader';
import Swal from 'sweetalert2';
import { css } from '@emotion/core';
import ReactTooltip from 'react-tooltip';
import InputMask from 'react-input-mask';
import InfiniteScroll from 'react-infinite-scroll-component';
import $ from 'jquery'
import SmallLoaderComponent from '../../../shared/loaders/SmallLoader'
import MainLoader from '../../../shared/loaders/MainLoader';

var CryptoJS = require("crypto-js");

// require('bootstrap-select');

const override = css`
display: block;
position:absolute;
left:50%;
top:50%;
margin:-60px 0 0 -60px;
border-color: #882b5e;
`;

class AccountDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            smallLoader: false,
            language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
            contactInfo: {},
            users: {},
            packageDetail: {},
            username: '',
            accountType: '',
            planType: '',
            linksPerMonth: '',
            price: 0,
            startMonth: '',
            createdAt: '',
            actualSubDate: '',
            billingMethod: '',
            //......Contact info
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            //......Billing info
            cardTypeName: '',
            cardNumberEnding: '',
            billingInformation: '',
            cardName: '',
            cardNumber: '',
            expiryMonth: '',
            expiryYear: '',
            CVV: '',
            cardType: 1,
            country: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            //..............
            years: [],
            countries: [],
            cities: [],
            allPaymentHistoryArray: [],
            userSession: {},

            // monthly usage
            monthlyUsage: {},
            linksProgressBar: '',

            contactModal: false,
            billingModal: false,
            userNameModal: false,
            // Payment Record
            payment: {},
            billingType: '',
            userID: '',
            errors1: '',
            mainLoader: true,
            packageInformation: {},
            redirect: false,
            start: true,

            hasMore: false,
            clipLoader: false,
            total: 0,
            page: 1,
            offset: 0,
            limit: 0,
            pages: 0,
            customMonths: false,
            customMonthsNumber: 0,
            subscriptionEndDate: '',
            logOutRedirect: false,
            accountSummary: '',
            customSubscriptionEndDate: '',
        }
        this.openAccountModel = this.openAccountModel.bind(this);
        this.openBillingModel = this.openBillingModel.bind(this);
        this.openUserNameModel = this.openUserNameModel.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount() {
        if (localStorage.getItem("LoginSession")) {
            let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
            let Contry = countriesCities.getCountries(); // Returns an array of country names
            var start = moment(new Date()).format("YYYY");
            let yearArray = [];
            for (var year = 1; year < 40; year++) {
                yearArray.push(start);
                start = parseInt(start) + 1;
            }
            this.setState({
                userID: id,
                countries: Contry,
                years: yearArray,
            }, () => {
                this.getAccountDetails();
                this.getPaymentHistory();
                this.checkUserSession(this.state.userID)
            })
        } else {
            this.props.history.push('/userLogin')
        }
    }

    checkUserSession = (id) => {
        fetch(window.APIURL + "accounts/member/user-session-check?id=" + id + "&lang=" + this.state.language, {
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 403) {
                    return res.json();
                } else if (res.status === 422) {
                    return res.json();
                } else if (res.status === 404) {
                    return res.json();
                } else {
                    return res.json();
                }
            })
            .then(result => {
                if (result.status === true) {
                    this.setState({ userSession: result.result })
                } else if (result.status === false) {
                    if (result.special === true) {
                        swal('Info', result.message, 'info');
                        this.notification(id)
                        localStorage.removeItem("loginToken");
                        localStorage.removeItem("LoginSession");
                        localStorage.removeItem("loginTime");
                        // this.setState({ redirect: true });
                    } else {
                        localStorage.removeItem("loginToken");
                        localStorage.removeItem("LoginSession");
                        localStorage.removeItem("loginTime");
                        this.setState({ redirect: true });
                    }
                    // swal(window.i18n('alert_a'), result.message, 'error');
                } else {
                    localStorage.removeItem("loginToken");
                    localStorage.removeItem("LoginSession");
                    localStorage.removeItem("loginTime");
                    this.setState({ redirect: true });
                    // swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
                }
            });
    }

    notification = (user) => {
        fetch(window.APIURL + "accounts/member/switch-bool?userId=" + user + "&lang=" + this.state.language, {
            method: "get",
            headers: { "Content-Type": "application/json" }
        })
            .then(response => response.json())
            .then(result => {
                if (result.status === true) {
                    this.setState({ redirect: true });
                }
            });
    }

    getAccountDetails = () => {
        let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        fetch(window.APIURL + 'accounts/summary?userId=' + id + "&lang=" + this.state.language + "&accessToken=" + localStorage.loginToken, {
            method: 'get',
            headers: { 'Content-Type': 'application/json' },
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else if (res.status === 400) {
                return res.json();
            } else if (res.status === 422) {
                return res.json();
            } else {
                return res.json();
            }
        }).then((result) => {


            if (result.sessionExpired) {
                this.setState({ logOutRedirect: true })
                return;
            }
            else if (result.status === true) {
                let customSubscriptionEndDate = result.accountSummary.user.customSubscriptionEndDate
                let accountType = result.accountSummary.user.accountType;
                let planType = result.accountSummary.user.planType;
                let planPrice = result.accountSummary.subscriptionPackage.price;
                let data, data1, data2, data3, data4, data5, data6, data7;
                if (accountType != 'free'
                    // parseInt(planPrice) !== 0
                ) {
                    data = result.accountSummary.subscriptionPackage;
                    data1 = result.accountSummary.user;
                    data2 = result.billingInformation;
                    data3 = result.contactInformation;
                    data4 = result.latestPayment;
                    // !== undefined ? result.latestPayment: undefined;
                    data5 = result.billingMethod;
                    data6 = result.packageInfo;
                    data7 = result.subscriptionPackage
                    this.setState({
                        planPrice: planPrice,
                        linksPerMonth: data.linksPerMonth, packageDetail: data,
                        packageInformation: data6,
                        users: data1, username: data1.username, accountType: data1.accountType, createdAt: data1.createdAt, startMonth: data1.subscriptionDate,
                        billingInformation: data2 !== null ? data2.cardType + "..." + data2.cardNumberEnding : '',
                        cardTypeName: data2 !== null ? data2.cardType : '',
                        cardNumberEnding: data2 !== null ? data2.cardNumberEnding : '',
                        contactInfo: data3,
                        firstName: data3 !== null ? data3.firstName : '',
                        lastName: data3 !== null ? data3.lastName : '',
                        phoneNumber: data3 !== null ? data3.phoneNumber : '',
                        email: data3 !== null ? data3.email : '',
                        payment: data4 !== undefined ? data4 : undefined,
                        billingType: data5 !== undefined ? data5 : "",
                        customMonths: data7.customMonths ? data7.customMonths : false,
                        customMonthsNumber: data7.customMonthsNumber ? data7.customMonthsNumber : 0,
                        mainLoader: false,
                        billingMethod: result.billingMethod,
                        actualSubDate: result.actualSubDate,
                        subscriptionEndDate: data1.subscriptionEndDate,
                        customType: data.customMonths === true ? data.customType : '',
                        accountSummary: data,
                        customSubscriptionEndDate: customSubscriptionEndDate
                    });

                    if (data.customMonths === false) {
                        if (data.isAnnual === true) this.setState({ billingType: 1 })
                        else if (data.isMonthly === true) this.setState({ billingType: 2 })
                    }
                    else {
                        if (data.customType === "year") this.setState({ billingType: 1 })
                        else if (data.isMonthly === "month") this.setState({ billingType: 2 })
                    }
                } else {
                    data = result.accountSummary.subscriptionPackage;
                    data1 = result.accountSummary.user;
                    data6 = result.packageInfo;
                    this.setState({
                        planPrice: planPrice,
                        packageDetail: data,
                        billingInformation: "",
                        packageInformation: data6,
                        linksPerMonth: data.linksPerMonth,
                        users: data1, username: data1.username, accountType: data1.accountType, createdAt: data1.createdAt, mainLoader: false,
                        accountSummary: data,

                    });
                }

                data = result.accountSummary.subscriptionPackage;
                let barPercent = result.monthlyUsage && result.monthlyUsage.linksPerMonth != null ? `${((result.monthlyUsage.linksPerMonth / data.linksPerMonth) * 100)}%` : '0%';
                this.setState({
                    monthlyUsage: result.monthlyUsage,
                    linksProgressBar: barPercent
                });

            } else if (result.status === false) {
                swal(window.i18n('alert_a'), result.message, 'error');;
            } else {
                swal(window.i18n('alert_a'), "Contact Admin", 'error');
            }
        });
    }

    getPaymentHistory = (value) => {
        let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        this.setState({
            page: this.state.start ? this.state.page : (value && value !== undefined) ? 1 : this.state.page + 1,
            start: (value && value !== undefined) ? true : false,
            hasMore: true,
            clipLoader: true,
        }, () => {
            fetch(window.APIURL + 'payment/user/get-history?userId=' + id + "&size=" + this.state.limit + "&pageNo=" + this.state.page + "&lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
                method: 'get',
                headers: { 'Content-Type': 'application/json' },
            }).then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 400) {
                    return res.json();
                } else if (res.status === 422) {
                    return res.json();
                } else {
                    return res.json();
                }
            }).then((result) => {
                if (result.sessionExpired) {
                    this.setState({
                        logOutRedirect: true
                    })
                    return;
                }


                if (result.status === true) {
                    if (result.paymentHistory && result.paymentHistory.length < 10) {
                        this.setState({ hasMore: false })
                    }
                    let data = result.paymentHistory;
                    let allpaymentsArray = this.state.allPaymentHistoryArray;
                    let final;
                    if (this.state.start) {
                        final = result.paymentHistory
                    }
                    else {
                        final = allpaymentsArray.concat(result.paymentHistory);
                    }
                    this.setState({
                        allPaymentHistoryArray: final,
                        total: result.total,
                        start: false,
                        clipLoader: false
                    });
                } else if (result.status === false) {
                    swal(window.i18n('alert_a'), result.message, 'error');;
                } else {
                    swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');;
                }
            });
        })

    }

    editContactInfo = (e) => {
        e.preventDefault();
        let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        this.setState({ errors1: '' });
        const { firstName, lastName, email, phoneNumber } = this.state;

        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (firstName === '') {
            this.setState({ errors1: window.i18n('alert_aj') })
            return;
        }
        else if (lastName === '') {
            this.setState({ errors1: window.i18n('alert_ak') })
            return;
        }
        else if (phoneNumber === '') {
            this.setState({ errors1: window.i18n('alert_ae') });
            return;
        }
        else if (email === '') {
            this.setState({ errors1: window.i18n('alert_m') });
            return;
        }
        else if (email && !email.match(regexp)) {
            this.setState({ errors1: window.i18n('alert_n') });
            return;
        }
        else {
            this.setState({ errors1: '', smallLoader: true })
            fetch(window.APIURL + 'contact-information/edit?lang=' + this.state.language + "&accessToken=" + localStorage.loginToken
                , {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        "userId": id,
                        "firstName": firstName,
                        "lastName": lastName,
                        "email": email,
                        "phoneNumber": phoneNumber
                    })
                }).then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 400) {
                        return res.json();
                    } else if (res.status === 422) {
                        return res.json();
                    } else {
                        return res.json();
                    }
                }).then((result) => {
                    if (result.sessionExpired) {
                        this.setState({ logOutRedirect: true })
                        return;
                    }
                    else if (result.status === true) {
                        let contactInfo = {
                            "userId": id,
                            "firstName": firstName,
                            "lastName": lastName,
                            "email": email,
                            "phoneNumber": phoneNumber
                        }
                        swal(window.i18n('alert_ay'), result.message, 'success')
                        this.setState({ contactModal: false, contactInfo, smallLoader: false });
                    } else if (result.status === false) {
                        swal(window.i18n('alert_a'), result.message, 'error');;
                    } else {
                        swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');;
                    }
                });
        }
    }

    editBillingInfo = (e) => {
        e.preventDefault();
        let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        const {
            cardName,
            cardNumber,
            expiryMonth,
            expiryYear,
            CVV,
            cardType,
            country,
            address1,
            address2,
            city,
            state,
            zipCode } = this.state;

        // const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // if (firstName === '') {
        //     this.setState({ errors1: 'First Name is required' })
        // }
        // else if (lastName === '') {
        //     this.setState({ errors1: 'Last Name is required' })
        // }
        // else if (phoneNumber === '') {
        //     this.setState({ errors: 'Phone number is Invalid' });
        //     return;
        // }
        // else if (email === '') {
        //     this.setState({ errors: 'Email is requires' });
        //     return;
        // }
        // else if (email && !email.match(regexp)) {
        //     this.setState({ errors: 'Email is Invalid' });
        //     return;
        // }
        // else {
        this.setState({ errors: '' })
        fetch(window.APIURL + 'billing-information/edit?lang=' + this.state.language + "&accessToken=" + localStorage.loginToken, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "userId": id,
                "cardName": cardName,
                "cardNumber": cardNumber,
                "expiryMonth": expiryMonth,
                "expiryYear": expiryYear,
                "CVV": CVV,
                "cardType": cardType,
                "country": country,
                "address1": address1,
                "address2": address2,
                "city": city,
                "state": state,
                "zipCode": zipCode
            })
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else if (res.status === 400) {
                return res.json();
            } else if (res.status === 422) {
                return res.json();
            } else {
                return res.json();
            }
        }).then((result) => {

            if (result.sessionExpired) {
                this.setState({ logOutRedirect: true })
                return;
            }
            else if (result.status === true) {
                swal(window.i18n('alert_ay'), result.message, 'success')
                this.setState({ billingModal: false });
            } else if (result.status === false) {
                swal(window.i18n('alert_a'), result.message, 'error');;
            } else {
                swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');;
            }
        });
    }

    editUserNameInfo = (e) => {
        e.preventDefault();
        let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        const { username } = this.state;
        this.setState({ smallLoader: true })
        fetch(window.APIURL + 'accounts/member/update?lang=' + this.state.language + "&accessToken=" + localStorage.loginToken, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "_id": id,
                "username": username
            })
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else if (res.status === 400) {
                return res.json();
            } else if (res.status === 422) {
                return res.json();
            }
            else {
                return res.json();
            }
        }).then((result) => {
            if (result.sessionExpired) {
                this.setState({ logOutRedirect: true })
                return;
            }
            else if (result.status === true) {
                let { users } = this.state
                users = { ...users, username }
                swal(window.i18n('alert_ay'), result.message, 'success')
                this.setState({ userNameModal: false, smallLoader: false, users });
            } else if (result.status === false) {
                swal(window.i18n('alert_a'), result.message, 'error');;
            } else {
                swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');;
            }
        });
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onChangeCountry = (e) => {
        var that = this;
        let checkCountry = e.target.value;
        let city;
        // let city = countriesCities.getCities(checkCountry);
        // setTimeout(() => {
        that.setState({
            country: checkCountry
        }, () => {
            city = countriesCities.getCities(checkCountry);
            that.setState({
                cities: city
            });
        });

        // }, 2000);


        // this.setState({

        // });
    }

    close() {
        this.setState({
            contactModal: false,
            billingModal: false,
            userNameModal: false,
        })
    }

    openAccountModel() {
        this.setState({
            contactModal: true
        })
    }

    openBillingModel() {
        this.setState({
            billingModal: true
        },
            (() => {
                //     setTimeout(()=>{
                $('.my-select').selectpicker();
                //    });
            })
        )
    }

    openUserNameModel() {
        this.setState({
            userNameModal: true,
        })
    }

    cancelSubscription = () => {
        let { billingInformation, billingMethod, actualSubDate } = this.state;
        let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        Swal.fire({
            title: window.i18n('alert_az'),
            text: window.i18n('alert_bi') + `${billingInformation} ` + window.i18n('alert_bj'),
            showCancelButton: true,
            // customClass: "conformations",
            // confirmButtonColor: '#0a6eb4',
            // cancelButtonColor: '#d33',
            cancelButtonText: window.i18n('static_pB2tx5'),
            confirmButtonText: window.i18n('alert_cg')
        }).then((result) => {
            if (result.value) {
                this.setState({ mainLoader: true })
                fetch(window.APIURL + 'payment/cancel?lang=' + this.state.language, {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        "userId": id,
                        "method": billingMethod,
                        "subDate": actualSubDate
                    })
                }).then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 400) {
                        return res.json();
                    } else if (res.status === 422) {
                        return res.json();
                    } else {
                        return res.json();
                    }
                }).then((result) => {
                    if (result.status === true) {
                        if (result.cron === true) {
                            swal(window.i18n('alert_ay'), result.message, 'success')



                            this.props.history.push('/dashboard')
                        } else {
                            let subscriptionPlanDetails = result.updatedPackage;
                            let encSubscriptionPlanDetails = CryptoJS.AES.encrypt(JSON.stringify(subscriptionPlanDetails), ENV.TokSecCrpt);
                            localStorage.setItem("spd", encSubscriptionPlanDetails);
                            this.getAccountDetails()
                            this.getPaymentHistory()
                            swal(window.i18n('alert_ay'), result.message, 'success')
                            this.props.history.push('/dashboard')
                        }
                        // window.location.reload();
                    } else if (result.status === false) {
                        swal(window.i18n('alert_a'), result.message, 'error');
                    } else {
                        swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
                    }
                });
            }
        });
    }

    render() {
        let { planPrice, packageDetail, subscriptionEndDate, customType, logOutRedirect,
            monthlyUsage, linksProgressBar, customMonths, customMonthsNumber, accountSummary, customSubscriptionEndDate,
            startMonth, linksPerMonth, username, createdAt, accountType, userID, firstName, lastName, phoneNumber, email, cardNumberEnding, billingInformation, cardTypeName, users, contactInfo, payment, billingType, packageInformation, redirect, userSession } = this.state;
        if (redirect) {
            window.location.href = "/web/logout-and-clear-session"
        }

        if (logOutRedirect) {
            window.location.href = "/web/logout-and-clear-session"
        }

        return (
            <React.Fragment>
                {
                    this.state.mainLoader === true ?
                    <MainLoader />
                        :
                        <div className="admin-section--MAIN">
                            <div className="payment--MAIN"></div>
                            <span className="account-detail--header">{window.i18n('accDet_a')}</span>
                            <Link to={"/organization/subscriptions/" + userID} className="admin-section--cta">
                                <button className="btn btn-pink" tabIndex="0" id="">{window.i18n('accDet_w')}</button>
                            </Link>
                            <div className="tabs-outer account">
                                <Tabs defaultActiveKey="accountsummary" id="uncontrolled-tab-example">
                                    <Tab eventKey="accountsummary" title={window.i18n('static_zpkoPF')}>
                                        {/* static_zpkoPF */}
                                        <div>
                                            <div className="account-detail--type">
                                                <div className="account-detail--name">{accountSummary.type} {window.i18n('accDet_b')}</div>
                                                <div className="account-detail--info">
                                                    <Link className="item-detail--link pointer" to={"/organization/subscriptions/" + userID}>
                                                        {window.i18n('global_manage')}
                                                    </Link>
                                                    <span className="d-inline-block align-top pl-3">{window.i18n('accDet_c')} <span className="text-capital">{accountSummary.type}</span> {window.i18n('accDet_d')}</span>
                                                    <ul className="pl-3 package-short-detail-list">
                                                        <li>
                                                            <span className="text-capital">{linksPerMonth}</span> {window.i18n('global_links')}
                                                        </li>
                                                        <li>
                                                            {
                                                                !packageDetail.customDomains ?
                                                                    ''
                                                                    :
                                                                    packageDetail.customDomains + ` ${window.i18n('addCstmDom_customDom')}`
                                                            }
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {
                                                // (billingInformation || billingType) && 
                                                // parseInt(planPrice) !== 0 ?
                                                accountType != 'free' ?

                                                    <div className="account-detail--item margin-top">{(users.hasCancelled) || (customSubscriptionEndDate && customSubscriptionEndDate != null && customSubscriptionEndDate != '' && moment(customSubscriptionEndDate).format("DD/MM/YYYY").toString() === moment(subscriptionEndDate).format("DD/MM/YYYY").toString()) ? window.i18n('sub_exp_txt') : window.i18n('accDet_e')} {moment(subscriptionEndDate).format("DD/MM/YYYY")}
                                                    </div>
                                                    : ''
                                            }
                                            <div className="account-detail--content-header">{window.i18n('accDet_f')}</div>
                                            <div className="account-detail--item">
                                                <div className="name">{users.username}</div>
                                                <span className="account-detail--member-desc">{window.i18n('accDet_g')} {moment(createdAt).format('YYYY/MM/DD')}.</span><a className="item-detail--link" id="edit_name" onClick={this.openUserNameModel}>{window.i18n('global_edit')}</a>
                                            </div>
                                            <div>
                                                <div className="account-detail--content-header">{window.i18n('accDet_h')}</div>
                                                <div className="rate-limit-progress-bar--MAIN">
                                                    <div className="rate-limit-progress-bar--header">{window.i18n('accDet_i')}</div>
                                                    <div className="rate-limit-progress-bar--stat">
                                                        <div className="rate-limit-progress-bar--total">
                                                            <div className="rate-limit-progress-bar--usage" style={{ width: linksProgressBar }}></div>
                                                        </div>
                                                        <span className="rate-limit-progress-bar--counter text-capital">{monthlyUsage != null ? monthlyUsage.linksPerMonth : 0} / {linksPerMonth != undefined && linksPerMonth != null ? linksPerMonth : 0}</span>
                                                    </div>
                                                    <span className="rate-limit-progress-bar--link">
                                                        <Link to={"/organization/subscriptions/" + userID}>{window.i18n('accDet_j')}</Link>
                                                    </span>
                                                </div>
                                                {/* TODO */}
                                                {/* <div className="rate-limit-progress-bar--MAIN">
                                            <div className="rate-limit-progress-bar--header">Branded Floinks</div>
                                            <div className="rate-limit-progress-bar--stat">
                                                <div className="rate-limit-progress-bar--total">
                                                    <div className="rate-limit-progress-bar--usage" style={{ width: "0%" }}></div>
                                                </div>
                                                <span className="rate-limit-progress-bar--counter">0 / 3,000</span>
                                            </div>
                                            <a href="#" target="_blank" className="rate-limit-progress-bar--link">Need More?</a>
                                        </div> */}
                                            </div>
                                        </div>
                                    </Tab>
                                    {/* <!-- Billing tab start--> */}
                                    {
                                        // (billingInformation || billingType) &&
                                        // parseInt(planPrice) !== 0 ?
                                        accountType != 'free' ?
                                            <Tab eventKey="billing" title={window.i18n('dashNav_billing')}>
                                                <div>
                                                    <div className="account-detail--content-header">{window.i18n('accDet_k')}</div>
                                                    <div className="account-detail--item text-capital">{customMonths === true ? accountSummary.type : accountSummary.type} {window.i18n('accDet_l')} ({
                                                        customMonths === true && customType === "month" ?
                                                            `${customMonthsNumber} Months`
                                                            : customMonths === true && customType === "year" ?
                                                                `${customMonthsNumber} Years`
                                                                : (billingType === 1 ? window.i18n('accDet_m') : window.i18n('accDet_n'))})<span className="stat">€ {accountSummary.price === 0 ? accountSummary.price : payment?.amount}
                                                            {/* {payment && payment.amount} */}
                                                        </span></div>
                                                    <div className="account-detail--content-header">{window.i18n('accDet_o')}<a className="item-detail--link" data-toggle="modal" onClick={this.openAccountModel}>{window.i18n('global_edit')}</a></div>
                                                    <div className="account-detail--item">{window.i18n('global_FullN_C')}<span className="stat">{(contactInfo && contactInfo.firstName != undefined ? contactInfo.firstName : 'N/A') + ' ' + ((contactInfo && contactInfo.lastName !== undefined && contactInfo && contactInfo.lastName !== null) ? contactInfo.lastName : '')}</span></div>
                                                    <div className="account-detail--item">{window.i18n('accDet_p')}<span className="stat">{contactInfo && contactInfo.phoneNumber !== '' ? contactInfo.phoneNumber : 'N/A'}</span></div>
                                                    <div className="account-detail--item">{window.i18n('global_email')}<span className="stat">{contactInfo && contactInfo.email !== '' ? contactInfo.email : 'N/A'} </span></div>
                                                    {/* <div className="account-detail--content-header">{window.i18n('global_SignIn')}BILLING INFORMATION<a className="item-detail--link" data-toggle="modal" onClick={this.openBillingModel}>{window.i18n('global_SignIn')}Update Card Details</a></div> */}
                                                    {/* <div className="account-detail--item">{window.i18n('global_SignIn')}Credit Card Info<span className="stat">{billingInformation ? billingInformation : 'N/A'}</span></div> */}
                                                    <div className="paymollie-note-text my-4">
                                                        <p className="mb-0"><strong className="text-uppercase text-danger">{window.i18n('res_R7H1')}&nbsp;</strong>{window.i18n('cancel_plan_n1_a')} {startMonth} {window.i18n('cancel_plan_n1_b')}</p>
                                                        <p className="mb-0">{window.i18n('cancel_plan_n1_c')}</p>
                                                    </div>
                                                    {(packageInformation && packageDetail.originalPackageId !== packageInformation._id) ?
                                                        <div className="text-right py-3 cancel-btn-holder">
                                                            <button className="btn btn-danger" disabled={userSession && userSession.hasCancelled === true ? true : false} type="button" onClick={this.cancelSubscription} tabIndex="0" id="">{window.i18n('accDet_q')}</button>
                                                        </div>
                                                        :
                                                        ''
                                                    }
                                                    <div class="table-responsive">
                                                        <div className="paymenyHistoryTable">
                                                            <div className="tableHead">
                                                                <div className="tableRow d-flex flex-rows">
                                                                    <div className="tableCol numberCol">#</div>
                                                                    <div className="tableCol historyCol">{window.i18n('accDet_r')}</div>
                                                                    <div className="tableCol w-100">{window.i18n('accDet_s')}</div>
                                                                    <div className="tableCol w-100">{window.i18n('accDet_t')}</div>
                                                                    <div className="tableCol text-uppercase w-100">{window.i18n('vc_j')}</div>
                                                                    {/* <div className="tableCol text-uppercase w-100">{window.i18n('vc_k')}</div>
                                                                    <div className="tableCol text-uppercase discountCol">{window.i18n('vc_l')}</div> */}
                                                                    <div className="tableCol text-uppercase invoiceLinkCol">{window.i18n('accDet_u')}</div>
                                                                </div>
                                                            </div>
                                                            <div className="tableBody">
                                                                <div className="customDomainLoaderHolder" style={{ position: 'relative' }}>
                                                                    <InfiniteScroll
                                                                        dataLength={this.state.allPaymentHistoryArray.length}
                                                                        next={() => this.getPaymentHistory()}
                                                                        hasMore={this.state.hasMore}
                                                                        loader={
                                                                            <ClipLoader
                                                                                css={override}
                                                                                sizeUnit={"px"}
                                                                                size={50}
                                                                                color={'#123abc'}
                                                                                loading={this.state.clipLoader}
                                                                            />
                                                                        }
                                                                    >
                                                                        {
                                                                            this.state.allPaymentHistoryArray.length > 0 ?
                                                                                this.state.allPaymentHistoryArray.map((val, key) => {
                                                                                    return (
                                                                                        <div className="tableRow d-flex flex-rows" key={key}>
                                                                                            <div className="tableCol numberCol">{key + 1}</div>
                                                                                            <div className="tableCol historyCol">{moment(val.createdAt).format('YYYY/MM/DD')}</div>
                                                                                            <div className="tableCol w-100">{val.description}</div>
                                                                                            <div className="tableCol w-100">{"€" + val?.amount + " EUR"}</div>
                                                                                            <div className="tableCol w-100">{val.voucher && val.voucher.reseller ? val.voucher.reseller : '-'}</div>
                                                                                            {/* <div className="tableCol w-100">{val.voucher && val.voucher.voucher ? val.voucher.voucher : '-'}</div>
                                                                                            <div className="tableCol discountCol">{val.voucher && val.voucher.discount ? val.voucher.discount + '%' : '-'}</div> */}
                                                                                            <div className="tableCol invoiceLinkCol text-right">
                                                                                                <a className="link-dark td_ul" href={"/web/organization/invoice/" + val._id}>{"NED/" + moment(val.createdAt).format('YYYY') + "/" + val.invoiceId}</a>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                                :
                                                                                <div className="text-center">{window.i18n('global_noRecordF')}
                                                                                </div>
                                                                        }
                                                                    </InfiniteScroll>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                            :
                                            ''
                                    }

                                    {/* <!-- Billing tab end--> */}
                                    {/* </div> */}
                                </Tabs>
                            </div>




                        </div>
                }
                {/* <!---After Signup Popup End---1111111111111111111111111111111>  */}
                {/* <!--- Edit account info on billing  start--1111111111111111111111111111> */}
                <Modal
                    show={this.state.contactModal}
                >
                    <Modal.Header>
                        <div className="modal-title">{window.i18n('accDet_v')}</div>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.close}>&times;</button>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.errors1 && <span style={{ "color": "red" }}>{window.i18n('global_fillAllRequiredFields')}</span>}
                        <div className="modal-text edit_account_info">
                            <form onSubmit={this.editContactInfo} >
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputEmail4">{window.i18n('global_firstN')} <sup className="text-danger">*</sup></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="firstname"
                                            placeholder={window.i18n('global_firstN')}
                                            name="firstName"
                                            value={this.state.firstName}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputPassword4">{window.i18n('global_lastN')} <sup className="text-danger">*</sup></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="lastname"
                                            placeholder={window.i18n('global_lastN')}
                                            name="lastName"
                                            value={this.state.lastName}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputEmail4">{window.i18n('global_email')} <sup className="text-danger">*</sup></label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            placeholder={window.i18n('global_email')}
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="phone">{window.i18n('global_phone')} <sup className="text-danger">*</sup></label>
                                        <input
                                            type="phone"
                                            className="form-control"
                                            id="phone"
                                            placeholder={window.i18n('global_phone')}
                                            name="phoneNumber"
                                            value={this.state.phoneNumber}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <button type="submit"
                                    className="btn btn-primary"
                                //  disabled={this.state.smallLoader}
                                >{window.i18n('accDet_w')}
                                    {(this.state.smallLoader) && <SmallLoaderComponent size={14} />}
                                </button>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* <!--- Edit account info on billing  end--2222222222222222222222222222> */}
                {/* <!--- edit billing info on billing start--222222222222222222222222222222222> */}
                {/* <div className="modal" id="edit_billing_info"> */}
                <Modal show={this.state.billingModal} >
                    <Modal.Header>
                        <div className="modal-title">BILLING INFO</div>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.close}>&times;</button>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="modal-text edit_billing_info">
                            <form onSubmit={this.editBillingInfo}>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="cardName">Name on Credit Card<sup className="text-danger">*</sup></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="card_name"
                                            placeholder="Card Name"
                                            name="cardName"
                                            value={this.state.cardName}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="cardNumber">Credit Card Number<sup className="text-danger">*</sup></label>
                                        <div className="StripeElement StripeElement--empty">
                                            <div className="__PrivateStripeElement" style={{ margin: "0px !important", padding: "0px !important", border: "none !important", display: "block !important", background: "transparent !important", position: "relative !important", opacity: "1 !important" }}>
                                                <InputMask
                                                    className="form-control"
                                                    mask="9999 9999 9999 9999"
                                                    maskChar={null}
                                                    alwaysShowMask={false}
                                                    name="cardNumber"
                                                    value={this.state.cardNumber}
                                                    onChange={this.onChange}
                                                    placeholder="Card Number"
                                                />
                                            </div>
                                        </div>
                                        {/* <input
                                            type="number"
                                            className="form-control"
                                            id="card_number"
                                            placeholder="Card Number"
                                            name="cardNumber"
                                            value={this.state.cardNumber}
                                            onChange={this.onChange}
                                        /> */}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label><span className="hidden-xs">Expiration<sup className="text-danger">*</sup></span> </label>
                                            <div className="form-inline">
                                                <div style={{ width: "45%" }}>
                                                    <select className="form-control my-select" id="exampleSelect" name="expiryMonth" value={this.state.expiryMonth
                                                    } onChange={this.onChange}>
                                                        <option value="">MM</option>
                                                        <option value="01">01</option>
                                                        <option value="02">02</option>
                                                        <option value="03">03</option>
                                                        <option value="04">04</option>
                                                        <option value="05">05</option>
                                                        <option value="06">06</option>
                                                        <option value="07">07</option>
                                                        <option value="08">08</option>
                                                        <option value="09">09</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                    </select>
                                                </div>
                                                <span style={{ width: "10%", textAlign: "center" }}> / </span>
                                                <div style={{ width: "45%" }}>
                                                    <select className="form-control my-select" name="expiryYear" value={this.state.expiryYear} onChange={this.onChange}>
                                                        <option value="">YY</option>
                                                        {
                                                            this.state.years.length > 0 ?
                                                                this.state.years.map((val, key) => {
                                                                    return (
                                                                        <option key={key} value={val}>{val}</option>
                                                                    )
                                                                }) : <option value="">No Year Available</option>
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>CVV <sup className="text-danger">*</sup><i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Your CVV code is 3-digit number on the back of your card."></i></label>

                                            <ReactTooltip />

                                            <input
                                                className="form-control"
                                                required=""
                                                type="text"
                                                placeholder="CVV"
                                                maxLength="3"
                                                min="0"
                                                name="CVV"
                                                value={this.state.CVV}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        {/* <!-- form-group.// --> */}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label><span className="hidden-xs">Card Type <sup className="text-danger">*</sup></span> </label>
                                            <select className="form-control my-select" style={{ width: "100%" }} name="cardType" value={this.state.cardType} onChange={this.onChange}>
                                                <option value="">Select type of card</option>
                                                <option value="1">Visa</option>
                                                <option value="2">Mastercard</option>
                                                <option value="3">American Express</option>
                                                <option value="4">Discover</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label><span className="hidden-xs">Country <sup className="text-danger">*</sup></span> </label>
                                            <select className="form-control my-select" style={{ width: "100%" }} name="country" value={this.state.country} onChange={this.onChangeCountry}>
                                                <option value="">Select Country</option>
                                                {
                                                    this.state.countries.length > 0 ?
                                                        this.state.countries.map((val, key) => {
                                                            return (
                                                                <option key={key} value={val}>{val}</option>
                                                            )
                                                        }) : <option value="">No Record Found</option>
                                                }

                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="address1">Address <sup className="text-danger">*</sup></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inputAddress"
                                            placeholder="Address 1"
                                            name="address1"
                                            value={this.state.address1}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="address2">Address 2 <sup className="text-danger">*</sup></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inputAddress2"
                                            placeholder="Address 2"
                                            name="address2"
                                            value={this.state.address2}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    {this.state.cities && this.state.cities.length > 0 ?
                                        <div className="form-group col-md-6">
                                            <label htmlFor="inputCity">City<sup className="text-danger">*</sup></label>
                                            <select className="form-control my-select" style={{ width: "100%" }} name="city" value={this.state.city} onChange={this.onChange}>
                                                {
                                                    this.state.cities && this.state.cities.length > 0 ?
                                                        this.state.cities.map((val, key) => {
                                                            return (
                                                                <option key={key} value={val}>{val}</option>
                                                            )
                                                        }) : <option value="">Select country first</option>
                                                }
                                            </select>
                                        </div>
                                        :
                                        ""
                                    }

                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputState">State<sup className="text-danger">*</sup></label>
                                        <input
                                            id="inputState"
                                            className="form-control"
                                            placeholder="Province"
                                            name="state"
                                            value={this.state.state}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputZip">Zip<sup className="text-danger">*</sup></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Zip"
                                            id="inputZip"
                                            min="0"
                                            maxLength="8"
                                            name="zipCode"
                                            value={this.state.zipCode}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary">Update</button>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* <!--- Edit User Info --3333333333333333333333333333333> */}
                {/* <!--- edit userName--33333333333333333333333333333333333333333333> */}
                <Modal
                    show={this.state.userNameModal}
                >
                    <Modal.Header>
                        <div className="modal-title">{window.i18n('accDet_f')}</div>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.close}>&times;</button>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="modal-text account_details">
                            <form onSubmit={this.editUserNameInfo} >
                                <div className="form-row justify-content-center">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputEmail4">{window.i18n('accDet_x')}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="firstname"
                                            placeholder={window.i18n('accDet_x')}
                                            name="username"
                                            value={this.state.username}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-pink" disabled={this.state.smallLoader}>{window.i18n('accDet_w')}
                                    {(this.state.smallLoader) && <SmallLoaderComponent size={14} />}
                                </button>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

export default AccountDetails;