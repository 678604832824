import React, { Component, useEffect } from "react";
import swal from 'sweetalert'
import { ENV } from '../../config/config';
import $ from "jquery";
import { Redirect } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import { getSiteSetting, beforeSiteSettings } from '../../shared/site-settings/site-settings.action';
// import Swal from 'sweetalert2';

const queryString = require("query-string");
var CryptoJS = require("crypto-js");

function MobileLoginC(props) {
  useEffect(() => {
    const userId = props?.match?.params?.id
    const mobile = props?.match?.params?.isMobile

    fetch(window.APIURL + `v1/mobile-apis/auth/direct-login?_id=${userId}&mobile=${mobile}`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        _id: userId,
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) { }
        if (result.subscriptionPlanDetails) {
          let subscriptionPlanDetails = result.subscriptionPlanDetails;
          delete result.subscriptionPlanDetails;
          let encSubscriptionPlanDetails = CryptoJS.AES.encrypt(JSON.stringify(subscriptionPlanDetails), ENV.TokSecCrpt);
          localStorage.setItem("spd", encSubscriptionPlanDetails);
        }

        // let tokenParam = result // JSON.parse(result);
        localStorage.setItem("loginToken", result.data.loginToken);
        localStorage.setItem("loginTime", new Date().getTime());
        localStorage.setItem("LoginSession", JSON.stringify(result));

        //   window.location.replace("/dashboard");
        props.history.push("/dashboard")
        //   swal(window.i18n('ver_em_txt'), {
        //     icon: "success",
        //   });
      })
      .catch(err => {
        window.location.href = "/web/logout-and-clear-session"
      })
    // .catch(err => swal(window.i18n('alert_ai'), {
    //   icon: "error",
    // }))


  })

  return <h1></h1>;
}
export default MobileLoginC;