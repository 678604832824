import React, { Component } from "react";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import {Redirect} from 'react-router-dom'
class RegisterOauthAppEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
            registrationCode: '',
            appName: '',
            appLink: '',
            redirectURIs: '',
            appDescription: '',
            _id: '',
            clientId: '',
            clientSecret: '',
            errors: '',
            logOutRedirect: false

        }
    }

    onUpdateOauth = (e) => {
        e.preventDefault();
        const { appName, appLink, redirectURIs, appDescription, registrationCode, _id } = this.state;
        if (appName === '') {
            this.setState({ errors: window.i18n('alert_ca') });
            return;
        } else if (appLink === '') {
            this.setState({ errors: window.i18n('alert_cb') });
            return;
        }
        else if (redirectURIs === '') {
            this.setState({ errors: window.i18n('alert_cc') });
            return;
        }
        else if (appDescription === '') {
            this.setState({ errors: window.i18n('alert_cd') });
            return;
        }
        else {
            fetch(window.APIURL + 'register-oauth-app/edit?lang=' + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "_id": _id,
                    "registrationCode": registrationCode,
                    "appName": appName,
                    "appLink": appLink,
                    "redirectURIs": redirectURIs,
                    "appDescription": appDescription,
                })
            }).then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 422) {
                    return res.json();
                } else if (res.status === 400) {
                    return res.json();
                } else {
                    return res.json();
                }
            }).then((result) => {
                if (result.sessionExpired) {
                    this.setState({
                        logOutRedirect: true
                    })
                    return;
                }


                if (result.status === true) {
                    swal(window.i18n('alert_ay'), result.message, 'success')
                    let data = result.result;
                    let from = 'editOauth';
                    this.props.theOauthFunction(from, data)
                } else if (result.status === false) {
                    swal(window.i18n('alert_a'), result.message, 'error');
                } else {
                    swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
                }
            });
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    componentWillReceiveProps() {
        let { appDetail } = this.props;
        if (appDetail === undefined || appDetail === 'undefined' || appDetail === '') {
            this.setState({
                appName: appDetail.appName,
                appLink: appDetail.appLink,
                redirectURIs: appDetail.redirectURIs,
                appDescription: appDetail.appDescription,
                _id: appDetail._id,
                registrationCode: appDetail.registrationCode,
            })
        }
    }

    render() {

        const { logOutRedirect } = this.state;
        if (logOutRedirect) {
            // return <Redirect to="/logout" />;
             window.location.href="/web/logout-and-clear-session"
        }





        return (
            <React.Fragment>
                <div className="action-sheet--content-wrapper">
                    <p className="action-sheet--text">
                        {window.i18n('regOauthApp_forDoc')}{" "}
                        <a target="_blank" href="#">
                            {window.i18n('global_apiDocume')}
                        </a>
                    </p>
                    <form onSubmit={this.onUpdateOauth}>
                        <div className="text-field--wrapper">
                            <div className="text-field--MAIN  ">
                                <label className="text-field--label">
                                    {window.i18n('regOauthApp_applName')}
                                </label>
                                <div className="text-field--textarea-wrapper">
                                    <textarea
                                        value={this.state.appName}
                                        onChange={this.onChange}
                                        name="appName"
                                        type="text"
                                        placeholder={window.i18n('regOauthApp_required')}
                                        rows="2"
                                        className="text-field--textarea"
                                        maxLength="500"
                                        autoComplete="false"
                                        style={{ height: "38px " }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="action-sheet--help-text">
                            {window.i18n('regOauthApp_plsProvide')}
                        </div>
                        <br />
                        <div className="text-field--wrapper">
                            <div className="text-field--MAIN  ">
                                <label className="text-field--label">
                                    {window.i18n('regOauthApp_appLink')}
                                </label>
                                <div className="text-field--textarea-wrapper">
                                    <textarea
                                        value={this.state.appLink}
                                        onChange={this.onChange}
                                        name="appLink"
                                        type="text"
                                        placeholder={window.i18n('regOauthApp_required')}
                                        rows="2"
                                        className="text-field--textarea"
                                        maxLength="500"
                                        autoComplete="false"
                                        style={{ height: "56px " }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="action-sheet--help-text">
                            {window.i18n('regOauthApp_urlOfYourApp')}
                        </div>
                        <br />
                        <div className="text-field--wrapper">
                            <div className="text-field--MAIN  ">
                                <label className="text-field--label">{window.i18n('regOauthApp_redirUris')}</label>
                                <div className="text-field--textarea-wrapper">
                                    <textarea
                                        value={this.state.redirectURIs}
                                        onChange={this.onChange}
                                        name="redirectURIs"
                                        type="text"
                                        placeholder={window.i18n('regOauthApp_required')}
                                        rows="2"
                                        className="text-field--textarea"
                                        maxLength="500"
                                        autoComplete="false"
                                        style={{ height: "56px " }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="action-sheet--help-text">
                            {window.i18n('regOauthApp_forMulEnter')}
                            <br />
                            <span>
                                {window.i18n('regOauthApp_your_application')}
                                <br />
                                {window.i18n('regOauthApp_dev_your')}
                            </span>
                        </div>
                        <br />
                        <div className="text-field--wrapper">
                            <div className="text-field--MAIN  ">
                                <label className="text-field--label">
                                    {window.i18n('regOauthApp_appDescr')}
                                </label>
                                <div className="text-field--textarea-wrapper">
                                    <textarea
                                        value={this.state.appDescription}
                                        onChange={this.onChange}
                                        name="appDescription"
                                        type="text"
                                        placeholder={window.i18n('regOauthApp_required')}
                                        rows="2"
                                        className="text-field--textarea"
                                        maxLength="500"
                                        autoComplete="false"
                                        style={{ height: "38px " }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="action-sheet--button-wrapper">
                            <button
                                className="button--SOLID-SECONDARY"
                                // data-id="app_details"
                                type="submit"
                                tabIndex="0"
                                id=""
                            >
                                {window.i18n('price_off')}
                            </button>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

export default RegisterOauthAppEdit