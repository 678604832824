import en from './../i18n/en';
// import de from './../i18n/de';
// import fr from './../i18n/fr';
// import es from './../i18n/es';
// import it from './../i18n/it';
// import ja from './../i18n/ja';
// import nl from './../i18n/nl';
// import pl from './../i18n/pl';
// import pt from './../i18n/pt';
// import ru from './../i18n/ru';
// import zh from './../i18n/zh';
// import ptbr from './../i18n/pt-br';
// import ptpt from './../i18n/pt-pt';
// import bg from './../i18n/bg';
// import cs from './../i18n/cs';
// import da from './../i18n/da';
// import et from './../i18n/et';
// import fi from './../i18n/fi';
// import el from './../i18n/el';
// import hu from './../i18n/hu';
// import lv from './../i18n/lv';
// import lt from './../i18n/lt';
// import ro from './../i18n/ro';
// import sk from './../i18n/sk';
// import sl from './../i18n/sl';
// import sv from './../i18n/sv';

// let languagesArray = ['de', 'en', 'fr', 'es', 'it', 'ja', 'nl', 'pl', 'pt', 'ru', 'zh', 'ptbr', 'ptpt', 'bg', 'cs', 'da', 'et', 'fi', 'el', 'hu', 'lv', 'lt', 'ro', 'sk', 'sl', 'sv'];
let languagesArray = ['en'];

// const languages = {
//   de, en, fr, es, it, ja, nl, pl, pt, ru, zh, ptbr, ptpt, bg, cs, da, et, fi, el, hu, lv, lt, ro, sk, sl, sv
// };
const languages = {en};

let defaultLanguage = 'en';
let currentLanguage = localStorage.getItem('language');
if (currentLanguage) {
  if (currentLanguage.includes('-')) {
    currentLanguage = currentLanguage.replace('-', '');
  }

  if (languagesArray.indexOf(currentLanguage) > -1) {
    defaultLanguage = currentLanguage;
  } else {
    localStorage.setItem('language', 'en');
  }
}
window.i18nData = languages[defaultLanguage];
window.i18n = (key) => window.i18nData[key];

window.changeLanguage = (lang) => {
  if (lang.includes('-')) {
    lang = lang.replace('-', '');
  }
  window.i18nData = languages[lang];
};