import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom"
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { getSiteSetting, beforeSiteSettings } from '../../../shared/site-settings/site-settings.action';

class NavbarSimple extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en'
        }
    }

    componentDidMount = () => {
        this.props.getSiteSetting(this.state.language);
        if (localStorage.getItem("loginToken")) {
            this.setState({
                //   showLogin: false,
                userId: JSON.parse(localStorage.getItem("LoginSession")).data._id
            }, () => {
                this.checkUserSession(this.state.userId)
            });
        }
    }

    checkUserSession = (id) => {
        fetch(window.APIURL + "accounts/member/user-session-check?id=" + id, {
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 403) {
                    return res.json();
                } else if (res.status === 422) {
                    return res.json();
                } else if (res.status === 404) {
                    return res.json();
                } else {
                    return res.json();
                }
            })
            .then(result => {
                if (result.status === true) {
                } else if (result.status === false) {
                    if (result.special === true) {
                        swal('Info', result.message, 'info');
                        this.notification(id)
                        localStorage.removeItem("loginToken");
                        localStorage.removeItem("LoginSession");
                        localStorage.removeItem("loginTime");
                        // this.setState({ redirect: true });
                    } else {
                        localStorage.removeItem("loginToken");
                        localStorage.removeItem("LoginSession");
                        localStorage.removeItem("loginTime");
                        this.setState({ redirect: true });
                    }
                } else {
                    localStorage.removeItem("loginToken");
                    localStorage.removeItem("LoginSession");
                    localStorage.removeItem("loginTime");
                    this.setState({ redirect: true });
                    // swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
                }
            });
    }

    notification = (user) => {
        fetch(window.APIURL + "accounts/member/switch-bool?userId=" + user + "&lang=" + this.state.language, {
            method: "get",
            headers: { "Content-Type": "application/json" }
        })
            .then(response => response.json())
            .then(result => {
                if (result.status === true) {
                    this.setState({ redirect: true });
                }
            });
    }

    render() {
        let settings;

        if (this.props.settings.siteSettingsAuth) {
            settings = this.props.settings.siteSettingsRes.site;
        }

        return (
            <React.Fragment>
                <header className="user-admin manage-account">
                    <div className="main-header">
                        <div className="container-fluid">
                            <div className="menu-outsite">
                                <Link className="logo" to="/dashboard">
                                    {
                                        settings ?
                                            // <img className="img-fluid" src={settings.siteLogo} alt="Site Logo" />
                                            <img src={require('../../../images/logo.png')} alt="Site Logo" />
                                            : ''
                                    }
                                </Link>
                                {/* <span style={{marginLeft : "35"}} className="navigation--header m-hide">Settings</span> */}
                                <Link to="/organization/detail"><span className="close-page"><i className="fas fa-times"></i></span></Link>
                            </div>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {
    getSiteSetting, beforeSiteSettings
};

const mapStateToProps = state => ({
    settings: state.settings,
    header: state.header,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(NavbarSimple));
