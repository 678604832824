import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { Link, NavLink } from "react-router-dom";
import CircleLoader from 'react-spinners/CircleLoader';
import { css } from '@emotion/core';
import { Redirect, withRouter } from 'react-router-dom';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip'
import { ENV } from './../../../config/config';
import { getSiteSetting, beforeSiteSettings } from '../../../shared/site-settings/site-settings.action';
import { connect } from 'react-redux';
import moment from "moment";
import MainLoader from '../../../shared/loaders/MainLoader';
var CryptoJS = require("crypto-js");

const override = css`
display: block;
position:absolute;
left:50%;
top:50%;
margin:-60px 0 0 -60px;
border-color: blue;
`;

class Plans extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
            packageType: '',
            planType: null,
            billingMethod: 1,
            allPackages: [],
            monthlyPackages: [],
            annualPackages: [],
            mainLoader: true,
            userID: '',
            annualyBilling: '1',
            monthlyBilling: '2',
            redirect: false,
            vat: '',
            packageRenewalDate: '',
            remainingDays: '',
            eventKey: 'monthlyPackages', // tab
            logOutRedirect: false
        }
    }

    componentDidMount() {
        localStorage.removeItem('voucherDetails')
        let id
        $("body").on('click', '.more-less-btn span', function () {
            $(this).parents('.tier-summary-panel--body').toggleClass('active');
        });
        if (JSON.parse(localStorage.getItem("LoginSession")))
            id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        // let selectedCountry = JSON.parse(localStorage.getItem("LoginSession")).data.country;
        let cookie = localStorage.getItem("country");
        let check = this.props.match.params.type;
        this.setState({
            userID: check
        }, () => {
            this.props.getSiteSetting();
            this.getUserProfile(check);
            this.getPlanDetails();
            this.checkUserSession(id);
            cookie ? this.getVAT(cookie) : this.getDefaultVat()
        })
    }

    getVAT(country) {
        fetch(window.APIURL + "vatController/get-vat?country=" + country, {
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 403) {
                    return res.json();
                } else if (res.status === 422) {
                    return res.json();
                } else if (res.status === 404) {
                    return res.json();
                } else {
                    return res.json();
                }
            })
            .then(result => {
                if (result.status === true) {
                    let data = result.vat;
                    if (data) {
                        this.setState({ vat: data.vat });
                    }
                } else if (result.status === false) {
                    this.getDefaultVat();
                    // swal(window.i18n('alert_a'), result.message, 'error');
                } else {
                    this.getDefaultVat();
                    // swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
                }
            });
    }

    getDefaultVat = () => {
        fetch(window.APIURL + `site-settings/get-site-settings?lang=` + this.state.language, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 403) {
                    return res.json();
                } else if (res.status === 422) {
                    return res.json();
                } else if (res.status === 404) {
                    return res.json();
                } else {
                    return res.json();
                }
            })
            .then(result => {
                if (result.status === true) {
                    let data = result.site;
                    if (data) {
                        this.setState({ vat: data.defaultVat });
                    }
                } else if (result.status === false) {

                    swal(window.i18n('alert_a'), result.message, 'error');
                } else {

                    swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
                }
            });
    }

    checkUserSession = (id) => {
        fetch(window.APIURL + "accounts/member/user-session-check?id=" + id, {
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 403) {
                    return res.json();
                } else if (res.status === 422) {
                    return res.json();
                } else if (res.status === 404) {
                    return res.json();
                } else {
                    return res.json();
                }
            })
            .then(result => {
                if (result.status === true) {
                } else if (result.status === false) {
                    if (result.special === true) {
                        swal('Info', result.message, 'info');
                        this.notification(id);
                        localStorage.removeItem("loginToken");
                        localStorage.removeItem("LoginSession");
                        localStorage.removeItem("loginTime");
                        // this.setState({ redirect: true });
                    } else {
                        localStorage.removeItem("loginToken");
                        localStorage.removeItem("LoginSession");
                        localStorage.removeItem("loginTime");
                        this.setState({ redirect: true });
                    }
                    // swal(window.i18n('alert_a'), result.message, 'error');
                } else {
                    localStorage.removeItem("loginToken");
                    localStorage.removeItem("LoginSession");
                    localStorage.removeItem("loginTime");
                    this.setState({ redirect: true });
                    // swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
                }
            });
    }

    notification = (user) => {
        fetch(window.APIURL + "accounts/member/switch-bool?userId=" + user + "&lang=" + this.state.language, {
            method: "get",
            headers: { "Content-Type": "application/json" }
        })
            .then(response => response.json())
            .then(result => {
                if (result.status === true) {
                    this.setState({ redirect: true });
                }
            });
    }

    getUserProfile = (id) => {
        fetch(window.APIURL + 'accounts/member/profile/' + id, {
            method: 'get',
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else if (res.status === 400) {
                return res.json();
            } else if (res.status === 422) {
                return res.json();
            } else {
                return res.json();
            }
        }).then((result) => {
            if (result.status === true) {
                let data = result.member;
                let date = moment();
                let dateB = moment(data.packageRenewDate);
                let calculatedDays = dateB.diff(date, 'days')
                this.setState({
                    packageType: data.accountType,
                    planType: data.planType,
                    billingMethod: data.billingInfo ? data.billingInfo.billingMethod : 1,
                    packageRenewalDate: data.packageRenewDate,
                    remainingDays: calculatedDays,
                    isUserPlanAnnual: data.subscriptionDetails.isAnnual,
                    isUserPlanMonthly: data.subscriptionDetails.isMonthly
                });
            } else if (result.status === false) {
                swal(window.i18n('alert_a'), result.message, 'error');
            } else {
                swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
            }
        });
    }

    getPlanDetails = () => {
        let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

        fetch(`${window.APIURL}subscription-package/all?lang=${lang}`, {
            method: 'get',
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else if (res.status === 400) {
                return res.json();
            } else if (res.status === 422) {
                return res.json();
            } else {
                return res.json();
            }
        }).then((result) => {
            if (result.status === true) {
                let data = result.subscriptionPackages;
                let monthlyPackages = data.filter((elem) => { return elem.isMonthly && elem.lang === lang });
                let annualPackages = data.filter((elem) => { return elem.isAnnual && elem.lang === lang });
                let allPackages = monthlyPackages; // assigning monthly packages by default

                this.setState({ allPackages, monthlyPackages, annualPackages, mainLoader: false });
            } else if (result.status === false) {
                swal(window.i18n('alert_a'), result.message, 'error');
            } else {
                swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
            }
        });
    }

    downgradeToDefault = (type, id) => {
        let { packageRenewalDate, remainingDays, packageType, eventKey } = this.state;
        Swal.fire({
            title: window.i18n('downgrade_to_default_a'),
            text: `${window.i18n('downgrade_to_default_b')} ${packageType} ${window.i18n('downgrade_to_default_c')} ${type} ${window.i18n('downgrade_to_default_d')} ${window.i18n('downgrade_to_default_e')} '${remainingDays}' ${window.i18n('downgrade_to_default_f')} ${window.i18n('downgrade_to_default_g')} ${packageRenewalDate} ${window.i18n('downgrade_to_default_h')}`,
            showCancelButton: true,
            cancelButtonText: window.i18n('static_pB2tx5'),
            confirmButtonText: window.i18n('price_btn3')
        }).then((result) => {
            if (result.value) {
                fetch(window.APIURL + 'accounts/member/update-plan?accessToken=' + localStorage.loginToken, {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        "userId": this.state.userID,
                        "accountType": type,
                        "packageId": id,
                        planType: eventKey === 'monthlyPackages' ? 'monthly' : 'annual'
                    })
                }).then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 400) {
                        return res.json();
                    } else if (res.status === 422) {
                        return res.json();
                    } else {
                        return res.json();
                    }
                }).then((result) => {
                    if (result.sessionExpired) {
                        this.setState({ logOutRedirect: true })
                        return;
                    }
                    else if (result.status === true) {
                        swal(window.i18n('alert_ay'), window.i18n('alert_bq'), "success")
                        this.getUserProfile(this.state.userID);
                        let subscriptionPlanDetails = result.updatedPackage;
                        let encSubscriptionPlanDetails = CryptoJS.AES.encrypt(JSON.stringify(subscriptionPlanDetails), ENV.TokSecCrpt);
                        localStorage.setItem("spd", encSubscriptionPlanDetails);
                        this.props.history.push("/organization/detail");
                        // this.setState({ redirect: true });
                    } else if (result.status === false) {
                        swal(window.i18n('alert_a'), result.message, 'error');
                    } else {
                        swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
                    }
                });
            }
        });
    }

    toggleMobileDescription = (e) => {
        let parentP = $(e.target).hasClass("tier-comparison-mobile--section-title") ? $(e.target) : $(e.target).parents('.tier-comparison-mobile--section-title');
        if (!parentP.next().is(":visible")) {
            $(".mobile-tier-section-url").hide();
            parentP.next().show();
            parentP.find(".selector-icon").removeClass("fa-chevron-right");
            parentP.find(".selector-icon").addClass("fa-chevron-down");
        }
        else {
            $(".mobile-tier-section-url").hide();
            $(".selector-icon").removeClass("fa-chevron-down");
            $(".selector-icon").addClass("fa-chevron-right");
        }
    }

    changeTab(e) {
        let eventKey = e.target.innerHTML;

        let { annualPackages, monthlyPackages } = this.state;

        eventKey === 'Pay Monthly' ?
            this.setState({ eventKey: 'monthlyPackages', allPackages: monthlyPackages })
            :
            this.setState({ eventKey: 'annualPackages', allPackages: annualPackages });
    }

    contactUs = () => {
        this.props.history.push('/get-quote');
    }

    calcRound = (num) => {
        var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
        return with2Decimals;
    }

    render() {
        let { redirect, vat, logOutRedirect } = this.state;
        if (redirect) {
            return <Redirect to={{
                pathname: "/userLogin",
                state: { redirectedFrom: 'plans' }
            }} />;
        }
        if (logOutRedirect) {
            // return <Redirect to="/logout" />;
            window.location.href = "/web/logout-and-clear-session"
        }
        return (
            <React.Fragment>
                <div className="main-content pl-0">
                    <div className="page-banner">
                        <div className="container h-100 d-flex justify-content-center align-items-center">
                            <div className="page-title-block py-5 text-white">
                                <h1>{window.i18n('price_pack')}</h1>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.mainLoader === true ?
                            <MainLoader />
                            :
                            <div className="cms-content content-section">
                                <div className="container">
                                    <div className="subscription-pricing--layout">
                                        <div className="subscription-pricing--tiers">
                                            <div className="section-heading-block text-center mb-0 pb-4">
                                                <h2>
                                                    <span className="d-inline-block align-top">{window.i18n('price_H1')}</span>
                                                </h2>
                                                <small className="d-block">{window.i18n('price_P1')}</small>
                                            </div>
                                            <div className="paymollie-note-text mb-4">
                                                <p><strong className="text-uppercase text-danger">{window.i18n('res_R7H1')}&nbsp;</strong>{''}</p>
                                                {/* <ul className="pl-5">
                                                    <li>{window.i18n('static_cg2Jg2')}
                                                    </li>
                                                    <li>{window.i18n('static_XIBDgj')}
                                                    </li>
                                                    <li>{window.i18n('static_IA4QhF')}
                                                    </li>
                                                </ul> */}
                                                <p className="mb-0">{window.i18n('switch_plan_n1_a')}&nbsp;<NavLink to={"/contact-us"} className="go-back link-dark td_ul p-0">
                                                    <span className="back_btn-icon" alt="back_btn-icon">
                                                    </span>{window.i18n('switch_plan_n1_b')}
                                                </NavLink></p>
                                            </div>
                                            <div className="tab-content" id="pills-tabContent">
                                                <Tabs defaultActiveKey="payMonthly" transition={false} id="uncontrolled-tab-example" onClick={(e) => this.changeTab(e)}>
                                                    <Tab eventKey="payMonthly" title={window.i18n('price_tab1')}>
                                                        {/* <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"> */}
                                                        <div className="subscription-pricing-panel--row row" style={{ 'flexWrap': 'nowrap' }}>
                                                            {
                                                                this.state.monthlyPackages.length > 0 ?
                                                                    this.state.monthlyPackages.map((data, index) => {
                                                                        data.price = data.price ? data.price : 0;
                                                                        let vatValue = (vat / 100) * data.price;
                                                                        return (
                                                                            <div className="subscription-pricing-summary--panel subscription-5-panel col-12 col-sm-7 col-md-5 col-lg-4 col-xl-3" key={index}>
                                                                                <div className="tier-summary--panel">
                                                                                    <div className="price-col-bg">
                                                                                        <svg className="img-fluid" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="490.768px" height="500px" viewBox="0 0 490.768 500" enableBackground="new 0 0 490.768 500" space="preserve">
                                                                                            <g>
                                                                                                <g id="OBJECTS">
                                                                                                    <path d="M340.104,174.298c-4.801-8.493-1.847-19.202,6.277-24.003l144.387-83.457V0H0v422.083l490.768-278.435    v-35.82l-126.661,73.117C355.612,185.746,344.903,182.792,340.104,174.298z" />
                                                                                                    <path d="M237.445,342.688c-4.801-8.492-15.51-11.447-24.003-6.646L0,459.38V500l230.798-133.309    C239.291,361.521,242.244,351.182,237.445,342.688z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </svg>
                                                                                    </div>
                                                                                    <div className="tier-summary-panel--header text-center">
                                                                                        <p className="tier-summary--title">
                                                                                            <span className="text-uppercase">{data.type}</span>
                                                                                        </p>
                                                                                        {
                                                                                            data.isEnterprise ?
                                                                                                <p className="tier-summary--price">
                                                                                                    <span className="vat-prices d-block">
                                                                                                        <p>NET: €€€</p>
                                                                                                        <p>VAT %: **</p>
                                                                                                    </span>
                                                                                                    <span className="packag-entprz">€€€</span><span className="tier-summary-month-descriptor">&nbsp;/mo&nbsp;</span>
                                                                                                </p>
                                                                                                :
                                                                                                <p className="tier-summary--price">
                                                                                                    <span className="vat-prices d-block">
                                                                                                        <p>NET: €{data.price ? data.price : 0}</p>
                                                                                                        <p>VAT %: {data.price ? vat : 0} %</p>
                                                                                                    </span>
                                                                                                    €{data.price ? this.calcRound(data.price + vatValue) : 0}<span className="tier-summary-month-descriptor">&nbsp;/mo&nbsp;</span>
                                                                                                </p>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="tier-summary-panel--body">
                                                                                        <p className="tier-summary--description">{data.description}</p>
                                                                                        <div className="short-details-holder">
                                                                                            <ul className="tier-summary-consumables--list text-center">
                                                                                                {
                                                                                                    !data.customDomains ?
                                                                                                        ''
                                                                                                        :
                                                                                                        <li>
                                                                                                            <strong className="feature-value d-block text-capitalize">{data.customDomains}</strong>
                                                                                                            <span className="feature-name d-block text-uppercase">{window.i18n('global_customDom')}</span>
                                                                                                        </li>
                                                                                                }
                                                                                                <li>
                                                                                                    <strong className="feature-value d-block text-capitalize">{data.linksPerMonth === 'unlimited' ? window.i18n('global_unlimited') : data.linksPerMonth}</strong>
                                                                                                    <span className="feature-name d-block text-uppercase">{window.i18n('price_feat2')}</span>
                                                                                                </li>
                                                                                                {
                                                                                                    !data.customBackHalfLimit ?
                                                                                                        ''
                                                                                                        :
                                                                                                        <li>
                                                                                                            <strong className="feature-value d-block text-capitalize">{data.customBackHalfLimit === 'unlimited' ? window.i18n('global_unlimited') : data.customBackHalfLimit}</strong>
                                                                                                            <span className="feature-name d-block text-uppercase">{window.i18n('price_feat4')}</span>
                                                                                                        </li>
                                                                                                }
                                                                                            </ul>
                                                                                        </div>
                                                                                        <div className="bottom-btn-holder">
                                                                                            {
                                                                                                data.isEnterprise ?
                                                                                                    <button className="btn btn-pink" tabIndex="0" onClick={() => this.contactUs()}>{window.i18n('global_getAQuote')}</button>
                                                                                                    :
                                                                                                    <span>
                                                                                                        {data._id === this.state.planType && this.state.isUserPlanMonthly ?
                                                                                                            <span className="tier-summary-currentPlan"><strong class="currentPackage">{window.i18n('price_curPlan')}</strong></span>
                                                                                                            :
                                                                                                            <span>
                                                                                                                {data.annualPrice && !data.isDefault ?
                                                                                                                    <Link className="btn btn-pink" tabIndex="0" to={"/organization/subscription?subscriptionId=" + data._id + "&type=" + this.state.monthlyBilling}>{window.i18n('price_btn3')}</Link>
                                                                                                                    :
                                                                                                                    <button className="btn btn-pink" onClick={() => this.downgradeToDefault(data.type.toLowerCase(), data._id)}>{window.i18n('price_btn3')}</button>
                                                                                                                }
                                                                                                            </span>
                                                                                                        }
                                                                                                    </span>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {data._id === this.state.planType && this.state.isUserPlanMonthly ?
                                                                                    <div className="elementor-price-table__ribbon">
                                                                                        <div className="elementor-price-table__ribbon-inner">{window.i18n('global_active')}</div>
                                                                                    </div>
                                                                                    :
                                                                                    ""
                                                                                }
                                                                                {data.isPopular === true ?
                                                                                    <div className="elementor-price-table__ribbon popular">
                                                                                        <div className="elementor-price-table__ribbon-inner">{window.i18n('price_popular')}</div>
                                                                                    </div>
                                                                                    :
                                                                                    ""

                                                                                }
                                                                            </div>
                                                                        )
                                                                    }) : <option value="">{window.i18n('global_noRecordF')}</option>
                                                            }
                                                        </div>
                                                        {/* </div> */}
                                                    </Tab>
                                                    <Tab eventKey="payAnnualy" title={window.i18n('price_tab2')}>
                                                        {/* <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab"> */}
                                                        <div className="subscription-pricing-panel--row row" style={{ 'flexWrap': 'nowrap' }}>
                                                            {
                                                                this.state.annualPackages.length > 0 ?
                                                                    this.state.annualPackages.map((data, index) => {
                                                                        data.price = data.price ? data.price : 0;
                                                                        data.savingRatio = data.savingRatio ? data.savingRatio : 0;
                                                                        let netValue = (data.price - (data.price * (data.savingRatio / 100))) * 12;
                                                                        let vatValue = (vat / 100) * netValue;
                                                                        return (
                                                                            <div className="subscription-pricing-summary--panel subscription-5-panel col-12 col-sm-7 col-md-5 col-lg-4 col-xl-3" key={index}>
                                                                                <div className="tier-summary--panel">
                                                                                    <div className="price-col-bg">
                                                                                        <svg className="img-fluid" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="490.768px" height="500px" viewBox="0 0 490.768 500" enableBackground="new 0 0 490.768 500" space="preserve">
                                                                                            <g>
                                                                                                <g id="OBJECTS">
                                                                                                    <path d="M340.104,174.298c-4.801-8.493-1.847-19.202,6.277-24.003l144.387-83.457V0H0v422.083l490.768-278.435    v-35.82l-126.661,73.117C355.612,185.746,344.903,182.792,340.104,174.298z" />
                                                                                                    <path d="M237.445,342.688c-4.801-8.492-15.51-11.447-24.003-6.646L0,459.38V500l230.798-133.309    C239.291,361.521,242.244,351.182,237.445,342.688z" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </svg>
                                                                                    </div>
                                                                                    <div className="tier-summary-panel--header text-center">
                                                                                        <p className="tier-summary--title">
                                                                                            <span className="text-uppercase">{data.type}</span>
                                                                                        </p>
                                                                                        <div className="discounted-price-holder">
                                                                                            {
                                                                                                data.savingRatio ?
                                                                                                    <span className="savedPrcntValue d-block">{window.i18n('price_off') + ' ' + data.savingRatio + " %"}</span>
                                                                                                    :
                                                                                                    <span className="savedPrcntValue d-block">{window.i18n('price_off') + ' ' + (data.isEnterprise ? '**' : 0) + ' %'}</span>
                                                                                            }
                                                                                            {
                                                                                                data.isEnterprise ?
                                                                                                    <p className="tier-summary--price">
                                                                                                        <del className="withOutDscntPrice">NET: €{data.price}</del>
                                                                                                        <span className="vat-prices d-block">
                                                                                                            <p>VAT %: **</p>
                                                                                                        </span>
                                                                                                        €€€<span className="tier-summary-month-descriptor">&nbsp;/yr&nbsp;</span>
                                                                                                    </p>
                                                                                                    :
                                                                                                    <p className="tier-summary--price">
                                                                                                        {
                                                                                                            // data.savingRatio ?
                                                                                                            <React.Fragment>
                                                                                                                <del className="withOutDscntPrice">NET: €{data.price}</del>
                                                                                                            </React.Fragment>
                                                                                                            // : '' 
                                                                                                        }
                                                                                                        {
                                                                                                            <span className="vat-prices d-block">
                                                                                                                <p>VAT %: {data.price ? vat : 0} %</p>
                                                                                                            </span>
                                                                                                        }
                                                                                                        €{data.price ? this.calcRound(netValue + vatValue) : 0}<span className="tier-summary-month-descriptor">&nbsp;/yr&nbsp;</span>
                                                                                                    </p>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="tier-summary-panel--body">
                                                                                        <p className="tier-summary--description">{data.description}</p>
                                                                                        <div className="short-details-holder">
                                                                                            <ul className="tier-summary-consumables--list text-center">
                                                                                                {
                                                                                                    !data.customDomains ?
                                                                                                        ''
                                                                                                        :
                                                                                                        <li>
                                                                                                            <strong className="feature-value d-block text-capitalize">{data.customDomains}</strong>
                                                                                                            <span className="feature-name d-block text-uppercase">{window.i18n('global_customDom')}</span>
                                                                                                        </li>
                                                                                                }
                                                                                                <li className="text-capitalize">
                                                                                                    <strong className="feature-value d-block text-capitalize">{data.linksPerMonth === 'unlimited' ? window.i18n('global_unlimited') : data.linksPerMonth}</strong>
                                                                                                    <span className="feature-name d-block text-uppercase">{window.i18n('global_links')}</span>
                                                                                                </li>
                                                                                                {
                                                                                                    !data.customBackHalfLimit ?
                                                                                                        ''
                                                                                                        :
                                                                                                        <li className="text-capitalize">
                                                                                                            <strong className="feature-value d-block text-capitalize">{data.customBackHalfLimit === 'unlimited' ? window.i18n('global_unlimited') : data.customBackHalfLimit}</strong>
                                                                                                            <span className="feature-name d-block text-uppercase">{window.i18n('price_feat4')}</span>
                                                                                                        </li>
                                                                                                }
                                                                                            </ul>
                                                                                        </div>
                                                                                        <div className="bottom-btn-holder">
                                                                                            {
                                                                                                data.isEnterprise ?
                                                                                                    <button className="btn btn-pink" tabIndex="0" onClick={() => this.contactUs()}>{window.i18n('global_getAQuote')}</button>
                                                                                                    :
                                                                                                    <span>
                                                                                                        {data._id === this.state.planType && this.state.isUserPlanAnnual ?
                                                                                                            <span className="tier-summary-currentPlan"><strong class="currentPackage">{window.i18n('price_curPlan')}</strong></span>
                                                                                                            :
                                                                                                            <span>
                                                                                                                {data.annualPrice && !data.isDefault ?
                                                                                                                    <Link className="btn btn-pink" tabIndex="0" to={"/organization/subscription?subscriptionId=" + data._id + "&type=" + this.state.annualyBilling}>{window.i18n('price_btn3')}</Link>
                                                                                                                    :
                                                                                                                    <button className="btn btn-pink" onClick={() => this.downgradeToDefault(data.type.toLowerCase(), data._id)}>{window.i18n('price_btn3')}</button>
                                                                                                                }
                                                                                                            </span>
                                                                                                        }
                                                                                                    </span>
                                                                                            }
                                                                                        </div>
                                                                                        {/* {
                                                                                            data.isEnterprise ?
                                                                                                <button className="button--SOLID-TERTIARY" tabIndex="0" onClick={() => this.contactUs()}>Contact Us</button>
                                                                                                :
                                                                                                <span>
                                                                                                    {data._id === this.state.planType && this.state.isUserPlanAnnual ?
                                                                                                        <span className="tier-summary-currentPlan"><strong>YOUR CURRENT PLAN</strong></span>
                                                                                                        :
                                                                                                        <span>
                                                                                                            {data.annualPrice && !data.isDefault ?
                                                                                                                <Link className="button--SOLID-TERTIARY" tabIndex="0" to={"/organization/subscription?subscriptionId=" + data._id + "&type=" + this.state.annualyBilling}>SUBSCRIBE TO <span className="text-capital">{data.type.toUpperCase()}</span></Link>
                                                                                                                :
                                                                                                                <button className="button--SOLID-TERTIARY" onClick={() => this.downgradeToDefault(data.type.toLowerCase(), data._id)}>SUBSCRIBE TO <span className="text-capital">{data.type.toUpperCase()}</span></button>
                                                                                                            }
                                                                                                        </span>
                                                                                                    }
                                                                                                </span>
                                                                                        } */}
                                                                                    </div>
                                                                                </div>
                                                                                {data._id === this.state.planType && this.state.isUserPlanAnnual ?
                                                                                    <div className="elementor-price-table__ribbon">
                                                                                        <div className="elementor-price-table__ribbon-inner">{window.i18n('global_active')}</div>
                                                                                    </div>
                                                                                    :
                                                                                    ''
                                                                                }
                                                                                {data.isPopular === true ?
                                                                                    <div className="elementor-price-table__ribbon popular">
                                                                                        <div className="elementor-price-table__ribbon-inner">{window.i18n('price_popular')}</div>
                                                                                    </div>
                                                                                    :
                                                                                    ""

                                                                                }
                                                                            </div>
                                                                        )
                                                                    }) : <option value="">{window.i18n('global_noRecordF')}</option>
                                                            }

                                                        </div>
                                                        {/* </div> */}
                                                    </Tab>
                                                </Tabs>
                                                <div className="section-heading-block text-center py-4">
                                                    <small className="d-block d-flex justify-content-center align-items-center flex-column flex-sm-row">
                                                        <span className="mr-2 mb-2">{window.i18n('cr_own_cst_pckg')}</span>
                                                        <Link className="btn btn-pink" to="/package/create">{window.i18n('cr_pckg_btn')}</Link>
                                                    </small>
                                                </div>
                                                <header className="subscription-title--container">
                                                    <h1 className="subscription-title--title"></h1>
                                                    <p className="subscription-title--blurb">{window.i18n('price_H2')}</p>
                                                </header>
                                                <ReactTooltip />

                                                <div className="subscription-pricing--hidden-mobile subscription-pricing-block">
                                                    <div className="subscription-pricing-comparison-matrix subscription-pricing-row">
                                                        <div className="subscription-pricing-comparison-label-column subscription-pricing-col">
                                                            <ul>
                                                                <div className="tier-comparison-label--section">
                                                                    <li className="section--title"><strong>{window.i18n('price_F1H')}</strong></li>
                                                                    <div>
                                                                        <li>
                                                                            <p>{window.i18n('price_F1D1')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F1D1T')}></i></p>
                                                                        </li>
                                                                    </div>

                                                                    <div>
                                                                        <li>
                                                                            <p>{window.i18n('price_F1D2')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F1D2T')}></i></p>
                                                                        </li>
                                                                    </div>
                                                                    <div>
                                                                        <li>
                                                                            <p>{window.i18n('price_F1D3')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F1D3T')}></i></p>
                                                                        </li>
                                                                    </div>
                                                                    <div>
                                                                        <li>
                                                                            <p>{window.i18n('price_F1D4')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F1D4T')}></i></p>
                                                                        </li>
                                                                    </div>
                                                                </div>
                                                                <div className="tier-comparison-label--section">
                                                                    <li className="section--title"><strong>{window.i18n('global_customLinks')} </strong></li>
                                                                    <div>
                                                                        <li>
                                                                            <p>{window.i18n('price_F2D1')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F2D1T')}></i></p>
                                                                        </li>
                                                                    </div>
                                                                    <div>
                                                                        <li>
                                                                            <p>{window.i18n('price_F2D2')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F2D2T')}></i></p>
                                                                        </li>
                                                                    </div>
                                                                    <div>
                                                                        <li>
                                                                            <p>{window.i18n('price_F2D3')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F2D3T')}></i></p>
                                                                        </li>
                                                                    </div>
                                                                </div>
                                                                <div className="tier-comparison-label--section">
                                                                    <li className="section--title"><strong>{window.i18n('price_F3H')}</strong></li>
                                                                    <div>
                                                                        <li>
                                                                            <p>{window.i18n('global_customDom')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F3D1T')}></i></p>
                                                                        </li>
                                                                        {/* default domains */}
                                                                        <li>
                                                                            <p>{window.i18n('global_defaultDomains')}<i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F3D1T')}></i></p>
                                                                        </li>
                                                                    </div>
                                                                    {/* <li className="section--title"><strong>{window.i18n('price_F3H')}</strong></li> */}

                                                                </div>
                                                                <div className="tier-comparison-label--section">
                                                                    <li className="section--title"><strong>{window.i18n('price_F4H')}</strong></li>
                                                                    <div>
                                                                        <li>
                                                                            <p>{window.i18n('price_F4D1')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F4D1T')}></i></p>
                                                                        </li>
                                                                    </div>
                                                                    <div>
                                                                        <li>
                                                                            <p>{window.i18n('price_F4D2')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F4D2T')}></i></p>
                                                                        </li>
                                                                    </div>
                                                                    <div>
                                                                        <li>
                                                                            <p>{window.i18n('price_F4D3')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F4D3T')}></i></p>
                                                                        </li>
                                                                    </div>
                                                                    <div>
                                                                        <li>
                                                                            <p>{window.i18n('price_F4D4')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F4D4T')}></i></p>
                                                                        </li>
                                                                    </div>
                                                                    <div>
                                                                        <li>
                                                                            <p>{window.i18n('price_F4D5')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F4D5T')}></i></p>
                                                                        </li>
                                                                    </div>
                                                                </div>
                                                                <div className="tier-comparison-label--section">
                                                                    <li className="section--title"><strong>{window.i18n('price_F5H')}</strong></li>
                                                                    <div>
                                                                        <li>
                                                                            <p>{window.i18n('price_F5D1')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F5D1T')}></i></p>
                                                                        </li>
                                                                    </div>
                                                                    <div>
                                                                        <li>
                                                                            <p>{window.i18n('price_F5D2')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F5D2T')}></i></p>
                                                                        </li>
                                                                    </div>
                                                                    <div>
                                                                        <li>
                                                                            <p>{window.i18n('price_F5D3')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F5D3T')}></i></p>
                                                                        </li>
                                                                    </div>
                                                                </div>
                                                                <div className="tier-comparison-label--section">
                                                                    <li className="section--title"><strong>{window.i18n('price_F6H')}</strong></li>
                                                                    <div>
                                                                        <li>
                                                                            <p>{window.i18n('price_F6D1')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F6D1T')}></i></p>
                                                                        </li>
                                                                    </div>
                                                                    <div>
                                                                        <li>
                                                                            <p>{window.i18n('price_F6D2')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F6D2T')}></i></p>
                                                                        </li>
                                                                    </div>
                                                                </div>
                                                                <div className="tier-comparison-label--section">
                                                                    <li className="section--title"><strong>{window.i18n('price_F7H')}</strong></li>
                                                                    <div>
                                                                        <div>
                                                                            <li>
                                                                                <p>{window.i18n('price_F7D1')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F7D1T')}></i></p>
                                                                            </li>
                                                                        </div>
                                                                        <div>
                                                                            <li>
                                                                                <p>{window.i18n('price_F7D2')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F7D2T')}></i></p>
                                                                            </li>
                                                                        </div>
                                                                        <div>
                                                                            <li>
                                                                                <p>{window.i18n('price_F7D3')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F7D3T')}></i></p>
                                                                            </li>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="tier-comparison-label--section">
                                                                    <li className="section--title"><strong>{window.i18n('price_F8H')}</strong></li>
                                                                    <div>
                                                                        <div>
                                                                            <li>
                                                                                <p>{window.i18n('price_F8H')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F8D1T')}></i></p>
                                                                            </li>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                        </div>
                                                        {
                                                            this.state.allPackages.length > 0 ?
                                                                this.state.allPackages.map((data, index) => {
                                                                    return (
                                                                        <div className="subscription-pricing-comparison-column subscription-pricing-col">
                                                                            <ul>
                                                                                <li className="tier-comparison-column--title"><strong>{data.type}</strong></li>
                                                                                <div className="tier-comparison-column--section">
                                                                                    <div>
                                                                                        <li><span className="text-capitalize"><strong>{data.linksPerMonth === 'unlimited' ? window.i18n('global_unlimited') : data.linksPerMonth}</strong>&nbsp;</span></li>
                                                                                    </div>
                                                                                    <div>
                                                                                        {
                                                                                            data.tags === true ?
                                                                                                <li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
                                                                                                :
                                                                                                <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                        }
                                                                                    </div>
                                                                                    <div>
                                                                                        {
                                                                                            data.bulkLinkTagging ?
                                                                                                <li>
                                                                                                    <span className="tier-comparison-value text-capitalize"><strong>{data.bulkLinkTagging}</strong>&nbsp;</span>
                                                                                                </li>
                                                                                                :
                                                                                                <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                        }
                                                                                    </div>
                                                                                    <div>
                                                                                        {
                                                                                            data.linkFiltering === true ?
                                                                                                <li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
                                                                                                :
                                                                                                <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tier-comparison-column--section">
                                                                                    <div>
                                                                                        {
                                                                                            data.brandedLinkLimit === "" ?
                                                                                                <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                                :
                                                                                                <li><span><strong>{data.brandedLinkLimit}</strong>&nbsp;</span></li>
                                                                                        }
                                                                                    </div>
                                                                                    <div>
                                                                                        {!data.customBackHalfLimit ?
                                                                                            <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                            :
                                                                                            <li><span className="text-capitalize"><strong>{data.customBackHalfLimit === 'unlimited' ? window.i18n('global_unlimited') : data.customBackHalfLimit}</strong>&nbsp;</span></li>
                                                                                        }
                                                                                    </div>
                                                                                    <div>
                                                                                        {!data.customLinkHistory ?
                                                                                            <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                            :
                                                                                            <li><span><strong>{data.customLinkHistory}</strong>&nbsp;</span></li>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tier-comparison-column--section">
                                                                                    <div>
                                                                                        {!data.customDomains ?
                                                                                            <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                            :
                                                                                            <li><span><strong>{data.customDomains}</strong>&nbsp;</span></li>
                                                                                        }
                                                                                    </div>
                                                                                    {/* default domains */}
                                                                                    <div>
                                                                                        {!data.defaultDomains ?
                                                                                            <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                            :
                                                                                            <li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
                                                                                        }
                                                                                    </div>
                                                                                    {/* <div>
                                                                                        {!data.customDomains ?
                                                                                            <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                            :
                                                                                            <li><span><strong>{data.customDomains}</strong>&nbsp;</span></li>
                                                                                        }
                                                                                    </div> */}
                                                                                </div>
                                                                                <div className="tier-comparison-column--section">
                                                                                    <div>
                                                                                        {data.campaigns === true ?
                                                                                            <li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
                                                                                            :
                                                                                            <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                        }
                                                                                    </div>
                                                                                    <div>
                                                                                        {data.campaignsTracking === true ?
                                                                                            <li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
                                                                                            :
                                                                                            <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                        }
                                                                                    </div>
                                                                                    <div>
                                                                                        {data.campaignDataExport === true ?
                                                                                            <li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
                                                                                            :
                                                                                            <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                        }
                                                                                    </div>
                                                                                    <div>
                                                                                        {data.socialPosting === true ?
                                                                                            <li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
                                                                                            :
                                                                                            <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                        }
                                                                                    </div>
                                                                                    <div>
                                                                                        {!data.socialPlatforms ?
                                                                                            <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                            :
                                                                                            <li><span><strong>{data.socialPlatforms}</strong>&nbsp;</span></li>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tier-comparison-column--section">
                                                                                    <div>
                                                                                        {data.linkHistory === "" ?
                                                                                            <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                            :
                                                                                            <li><span><strong>{data.linkHistory}</strong>&nbsp;</span></li>
                                                                                        }
                                                                                    </div>
                                                                                    <div>
                                                                                        {data.dashboardView === true ?
                                                                                            <li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
                                                                                            :
                                                                                            <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                        }
                                                                                    </div>
                                                                                    <div>
                                                                                        {data.analyticsDataExport === true ?
                                                                                            <li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
                                                                                            :
                                                                                            <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tier-comparison-column--section">
                                                                                    <div>
                                                                                        {data.API === "" ?
                                                                                            <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                            :
                                                                                            <li><span><strong>{data.API}</strong>&nbsp;</span></li>
                                                                                        }
                                                                                    </div>
                                                                                    <div>
                                                                                        {data.integrations === "" ?
                                                                                            <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                            :
                                                                                            <li><span><strong>{data.integrations}</strong>&nbsp;</span></li>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tier-comparison-column--section">
                                                                                    <div>
                                                                                        {data.accountManagement === true ?
                                                                                            <li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
                                                                                            :
                                                                                            <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                        }
                                                                                    </div>
                                                                                    <div>
                                                                                        {data.onboardingAndSetup === true ?
                                                                                            <li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
                                                                                            :
                                                                                            <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                        }
                                                                                    </div>
                                                                                    <div>
                                                                                        {data.emailSupport === true ?
                                                                                            <li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
                                                                                            :
                                                                                            <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tier-comparison-column--section">
                                                                                    <div>
                                                                                        {data.qrCodeGenerate === true ?
                                                                                            <li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
                                                                                            :
                                                                                            <li><span className="tier-comparison-column--emdash">—</span></li>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </ul>
                                                                            <div className="sticky-upgrade-button--column">
                                                                                {
                                                                                    data.isEnterprise ?
                                                                                        <div className="sticky-upgrade-button--column text-center">
                                                                                            <button className="btn btn-pink" onClick={() => this.contactUs()}>{window.i18n('global_getAQuote')}</button>
                                                                                        </div>
                                                                                        :
                                                                                        <span>
                                                                                            {
                                                                                                this.state.eventKey === 'monthlyPackages' ?
                                                                                                    data._id === this.state.planType && this.state.isUserPlanMonthly ?
                                                                                                        <div className="sticky-upgrade-button--column"><span className="current-plan--text"><strong class="currentPackage">{window.i18n('price_curPlan')}</strong></span></div>
                                                                                                        :
                                                                                                        <div className="sticky-upgrade-button--column text-center">
                                                                                                            {
                                                                                                                data.annualPrice && !data.isDefault ?
                                                                                                                    <Link className="btn btn-pink" to={"/organization/subscription?subscriptionId=" + data._id + "&type=" + this.state.annualyBilling}>{window.i18n('price_btn3')} </Link>
                                                                                                                    :
                                                                                                                    <button className="btn btn-pink" onClick={() => this.downgradeToDefault(data.type.toLowerCase(), data._id)}>{window.i18n('price_btn3')} </button>
                                                                                                            }
                                                                                                        </div>
                                                                                                    : ''
                                                                                            }
                                                                                            {
                                                                                                this.state.eventKey === 'annualPackages' ?
                                                                                                    data._id === this.state.planType && this.state.isUserPlanAnnual ?
                                                                                                        <div className="sticky-upgrade-button--column"><span className="current-plan--text"><strong class="currentPackage">{window.i18n('price_curPlan')}</strong></span></div>
                                                                                                        :
                                                                                                        <div className="sticky-upgrade-button--column text-center">
                                                                                                            {
                                                                                                                data.annualPrice && !data.isDefault ?
                                                                                                                    <Link className="btn btn-pink" to={"/organization/subscription?subscriptionId=" + data._id + "&type=" + this.state.annualyBilling}>{window.i18n('price_btn3')} </Link>
                                                                                                                    :
                                                                                                                    <button className="btn btn-pink" onClick={() => this.downgradeToDefault(data.type.toLowerCase(), data._id)}>{window.i18n('price_btn3')} </button>
                                                                                                            }
                                                                                                        </div>
                                                                                                    : ''
                                                                                            }
                                                                                        </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }) : <option value="">{window.i18n('global_noRecordF')}</option>
                                                        }
                                                    </div >
                                                </div >
                                                <div className="subscription-pricing--hidden-desktop">
                                                    <div className="tier-comparison-mobile">
                                                        <p className="tier-comparison-mobile--section-title" onClick={(e) => this.toggleMobileDescription(e)}><span><strong>{window.i18n('price_F1H')}&nbsp;</strong></span><span className="tier-comparison-mobile--icon"><strong><span className="selector-icon fas fa-chevron-right" alt="selector-icon"></span></strong></span></p>
                                                        <ul className="tier-comparison-mobile-hide mobile-tier-section-url">
                                                            <li className="tier-comparison-mobile--item-title"><strong>{window.i18n('price_F1D1')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F1D1T')}></i></strong></li>
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (<li className="tier-comparison-mobile--item-detail">
                                                                        <span className="tier-comparison-mobile--item-label text-capitalize">{pack.type}</span>
                                                                        <span>
                                                                            <span className="tier-comparison-value text-capitalize"><strong>{pack.linksPerMonth}</strong>&nbsp;</span>
                                                                        </span>
                                                                    </li>)
                                                                })
                                                            }
                                                            <li className="tier-comparison-mobile--item-title"><strong>{window.i18n('price_F1D2')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F1D2T')}></i></strong></li>
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (<li className="tier-comparison-mobile--item-detail">
                                                                        <span className="tier-comparison-mobile--item-label text-capitalize">
                                                                            {pack.type}
                                                                        </span>
                                                                        {
                                                                            pack.tags ?
                                                                                <span>
                                                                                    <span className="tier-comparison-value">
                                                                                        <span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
                                                                                    </span>
                                                                                </span>
                                                                                :
                                                                                <span>
                                                                                    <span className="tier-comparison-value tier-comparison-column--emdash">
                                                                                        —
                                                                                    </span>
                                                                                </span>
                                                                        }
                                                                    </li>)
                                                                })
                                                            }
                                                            <li className="tier-comparison-mobile--item-title"><strong>{window.i18n('price_F1D3')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F1D3T')}></i></strong></li>
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (<li className="tier-comparison-mobile--item-detail">
                                                                        <span className="tier-comparison-mobile--item-label text-capitalize">
                                                                            {pack.type}
                                                                        </span>
                                                                        {
                                                                            pack.bulkLinkTagging ?
                                                                                <span>
                                                                                    <span className="tier-comparison-value text-capitalize"><strong>{pack.bulkLinkTagging}</strong>&nbsp;</span>
                                                                                </span>
                                                                                :
                                                                                <span>
                                                                                    <span className="tier-comparison-value tier-comparison-column--emdash">
                                                                                        —
                                                                                    </span>
                                                                                </span>
                                                                        }
                                                                    </li>)
                                                                })
                                                            }
                                                            <li className="tier-comparison-mobile--item-title"><strong>{window.i18n('price_F1D4')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F1D4T')}></i></strong></li>
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (<li className="tier-comparison-mobile--item-detail">
                                                                        <span className="tier-comparison-mobile--item-label text-capitalize">
                                                                            {pack.type}
                                                                        </span>
                                                                        {
                                                                            pack.linkFiltering ?
                                                                                <span>
                                                                                    <span className="tier-comparison-value">
                                                                                        <span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
                                                                                    </span>
                                                                                </span>
                                                                                :
                                                                                <span>
                                                                                    <span className="tier-comparison-value tier-comparison-column--emdash">
                                                                                        —
                                                                                    </span>
                                                                                </span>
                                                                        }
                                                                    </li>)
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className="tier-comparison-mobile">
                                                        <p className="tier-comparison-mobile--section-title" onClick={(e) => this.toggleMobileDescription(e)}><span><strong>{window.i18n('price_feat3')}&nbsp;</strong></span><span className="selector-icon-expanded tier-comparison-mobile--icon"><strong><span className="selector-icon fas fa-chevron-right" alt="selector-icon"></span></strong></span></p>
                                                        <ul className="tier-comparison-mobile-hide mobile-tier-section-url">
                                                            <li className="tier-comparison-mobile--item-title"><strong>{window.i18n('price_F2D1')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F2D1T')}></i></strong></li>
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (<li className="tier-comparison-mobile--item-detail">
                                                                        <span className="tier-comparison-mobile--item-label text-capitalize">{pack.type}</span>
                                                                        <span>
                                                                            {
                                                                                parseInt(pack.brandedLinkLimit) > 0 ?
                                                                                    <span className="tier-comparison-value"><strong>{pack.brandedLinkLimit}</strong>&nbsp;</span>
                                                                                    :
                                                                                    <span><span className="tier-comparison-value tier-comparison-column--emdash">—</span></span>
                                                                            }
                                                                        </span>
                                                                    </li>)
                                                                })
                                                            }
                                                            <li className="tier-comparison-mobile--item-title"><strong>{window.i18n('price_F2D2')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F2D2T')}></i></strong></li>
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (<li className="tier-comparison-mobile--item-detail">
                                                                        <span className="tier-comparison-mobile--item-label text-capitalize">{pack.type}</span>
                                                                        <span>
                                                                            {
                                                                                parseInt(pack.customBackHalfLimit) > 0 ?
                                                                                    <span className="tier-comparison-value"><strong>{pack.customBackHalfLimit}</strong>&nbsp;</span>
                                                                                    :
                                                                                    <span><span className="tier-comparison-value tier-comparison-column--emdash">—</span></span>
                                                                            }
                                                                        </span>
                                                                    </li>)
                                                                })
                                                            }
                                                            <li className="tier-comparison-mobile--item-title"><strong>{window.i18n('price_F2D3')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F2D3T')}></i></strong></li>
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (<li className="tier-comparison-mobile--item-detail">
                                                                        <span className="tier-comparison-mobile--item-label text-capitalize">{pack.type}</span>
                                                                        <span>
                                                                            {
                                                                                parseInt(pack.customLinkHistory) > 0 ?
                                                                                    <span className="tier-comparison-value"><strong>{pack.customLinkHistory}</strong>&nbsp;</span>
                                                                                    :
                                                                                    <span><span className="tier-comparison-value tier-comparison-column--emdash">—</span></span>
                                                                            }
                                                                        </span>
                                                                    </li>)
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className="tier-comparison-mobile">
                                                        <p className="tier-comparison-mobile--section-title" onClick={(e) => this.toggleMobileDescription(e)}><span><strong>{window.i18n('price_F3H')}&nbsp;</strong></span><span className="tier-comparison-mobile--icon"><strong><span className="selector-icon fas fa-chevron-right" alt="selector-icon"></span></strong></span></p>
                                                        <ul className="tier-comparison-mobile-hide mobile-tier-section-url">
                                                            <li className="tier-comparison-mobile--item-title"><strong>{window.i18n('global_customDom')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F3D1T')}></i></strong></li>
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (<li className="tier-comparison-mobile--item-detail">
                                                                        <span className="tier-comparison-mobile--item-label text-capitalize">{pack.type}</span>
                                                                        <span>
                                                                            {
                                                                                parseInt(pack.customDomains) > 0 ?
                                                                                    <span className="tier-comparison-value"><strong>{pack.customDomains}</strong>&nbsp;</span>
                                                                                    :
                                                                                    <span><span className="tier-comparison-value tier-comparison-column--emdash">—</span></span>
                                                                            }
                                                                        </span>
                                                                    </li>)
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className="tier-comparison-mobile">
                                                        <p className="tier-comparison-mobile--section-title" onClick={(e) => this.toggleMobileDescription(e)}><span><strong>{window.i18n('price_F4H')}&nbsp;</strong></span><span className="tier-comparison-mobile--icon"><strong><span className="selector-icon fas fa-chevron-right" alt="selector-icon"></span></strong></span></p>
                                                        <ul className="tier-comparison-mobile-hide mobile-tier-section-url">
                                                            <li className="tier-comparison-mobile--item-title"><strong>{window.i18n('price_F4D1')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F4D1T')}></i></strong></li>
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (<li className="tier-comparison-mobile--item-detail">
                                                                        <span className="tier-comparison-mobile--item-label text-capitalize">
                                                                            {pack.type}
                                                                        </span>
                                                                        {
                                                                            pack.campaigns ?
                                                                                <span>
                                                                                    <span className="tier-comparison-value">
                                                                                        <span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
                                                                                    </span>
                                                                                </span>
                                                                                :
                                                                                <span>
                                                                                    <span className="tier-comparison-value tier-comparison-column--emdash">
                                                                                        —
                                                                                    </span>
                                                                                </span>
                                                                        }
                                                                    </li>)
                                                                })
                                                            }
                                                            <li className="tier-comparison-mobile--item-title"><strong>{window.i18n('price_F4D2')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F4D2T')}></i></strong></li>
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (<li className="tier-comparison-mobile--item-detail">
                                                                        <span className="tier-comparison-mobile--item-label text-capitalize">
                                                                            {pack.type}
                                                                        </span>
                                                                        {
                                                                            pack.campaignsTracking ?
                                                                                <span>
                                                                                    <span className="tier-comparison-value">
                                                                                        <span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
                                                                                    </span>
                                                                                </span>
                                                                                :
                                                                                <span>
                                                                                    <span className="tier-comparison-value tier-comparison-column--emdash">
                                                                                        —
                                                                                    </span>
                                                                                </span>
                                                                        }
                                                                    </li>)
                                                                })
                                                            }
                                                            <li className="tier-comparison-mobile--item-title"><strong>{window.i18n('price_F4D3')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F4D3T')}></i></strong></li >
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (<li className="tier-comparison-mobile--item-detail">
                                                                        <span className="tier-comparison-mobile--item-label text-capitalize">
                                                                            {pack.type}
                                                                        </span>
                                                                        {
                                                                            pack.campaignDataExport ?
                                                                                <span>
                                                                                    <span className="tier-comparison-value">
                                                                                        <span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
                                                                                    </span>
                                                                                </span>
                                                                                :
                                                                                <span>
                                                                                    <span className="tier-comparison-value tier-comparison-column--emdash">
                                                                                        —
                                                                                    </span>
                                                                                </span>
                                                                        }
                                                                    </li>)
                                                                })
                                                            }
                                                            < li className="tier-comparison-mobile--item-title" > <strong>{window.i18n('price_F4D4')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F4D4T')}></i></strong ></li >
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (<li className="tier-comparison-mobile--item-detail">
                                                                        <span className="tier-comparison-mobile--item-label text-capitalize">
                                                                            {pack.type}
                                                                        </span>
                                                                        {
                                                                            pack.socialPosting ?
                                                                                <span>
                                                                                    <span className="tier-comparison-value">
                                                                                        <span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
                                                                                    </span>
                                                                                </span>
                                                                                :
                                                                                <span>
                                                                                    <span className="tier-comparison-value tier-comparison-column--emdash">
                                                                                        —
                                                                                    </span>
                                                                                </span>
                                                                        }
                                                                    </li>)
                                                                })
                                                            }
                                                            < li className="tier-comparison-mobile--item-title" > <strong>{window.i18n('price_F4D5')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F4D5T')}></i></strong ></li >
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (<li className="tier-comparison-mobile--item-detail">
                                                                        <span className="tier-comparison-mobile--item-label text-capitalize">{pack.type}</span>
                                                                        <span>
                                                                            {
                                                                                parseInt(pack.socialPlatforms) > 0 ?
                                                                                    <span className="tier-comparison-value"><strong>{pack.socialPlatforms}</strong>&nbsp;</span>
                                                                                    :
                                                                                    <span><span className="tier-comparison-value tier-comparison-column--emdash">—</span></span>
                                                                            }
                                                                        </span>
                                                                    </li>)
                                                                })
                                                            }
                                                        </ul >
                                                    </div >
                                                    <div className="tier-comparison-mobile">
                                                        <p className="tier-comparison-mobile--section-title" onClick={(e) => this.toggleMobileDescription(e)}><span><strong>{window.i18n('price_F5H')}&nbsp;</strong></span><span className="tier-comparison-mobile--icon"><strong><span className="selector-icon fas fa-chevron-right" alt="selector-icon"></span></strong></span></p>
                                                        <ul className="tier-comparison-mobile-hide mobile-tier-section-url">
                                                            <li className="tier-comparison-mobile--item-title"><strong>{window.i18n('price_F5D1')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F5D1T')}></i></strong></li>
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (<li className="tier-comparison-mobile--item-detail">
                                                                        <span className="tier-comparison-mobile--item-label text-capitalize">{pack.type}</span>
                                                                        <span>
                                                                            {
                                                                                parseInt(pack.linkHistory) > 0 ?
                                                                                    <span className="tier-comparison-value"><strong>{pack.linkHistory}</strong>&nbsp;</span>
                                                                                    :
                                                                                    <span><span className="tier-comparison-value tier-comparison-column--emdash">—</span></span>
                                                                            }
                                                                        </span>
                                                                    </li>)
                                                                })
                                                            }
                                                            <li className="tier-comparison-mobile--item-title"><strong>{window.i18n('price_F5D2')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F5D2T')}></i></strong></li>
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (<li className="tier-comparison-mobile--item-detail">
                                                                        <span className="tier-comparison-mobile--item-label text-capitalize">
                                                                            {pack.type}
                                                                        </span>
                                                                        {
                                                                            pack.dashboardView ?
                                                                                <span>
                                                                                    <span className="tier-comparison-value">
                                                                                        <span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
                                                                                    </span>
                                                                                </span>
                                                                                :
                                                                                <span>
                                                                                    <span className="tier-comparison-value tier-comparison-column--emdash">
                                                                                        —
                                                                                    </span>
                                                                                </span>
                                                                        }
                                                                    </li>)
                                                                })
                                                            }
                                                            <li className="tier-comparison-mobile--item-title"><strong>{window.i18n('price_F5D3')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F5D3T')}></i></strong></li >
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (<li className="tier-comparison-mobile--item-detail">
                                                                        <span className="tier-comparison-mobile--item-label text-capitalize">
                                                                            {pack.type}
                                                                        </span>
                                                                        {
                                                                            pack.analyticsDataExport ?
                                                                                <span>
                                                                                    <span className="tier-comparison-value">
                                                                                        <span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
                                                                                    </span>
                                                                                </span>
                                                                                :
                                                                                <span>
                                                                                    <span className="tier-comparison-value tier-comparison-column--emdash">
                                                                                        —
                                                                                    </span>
                                                                                </span>
                                                                        }
                                                                    </li>)
                                                                })
                                                            }
                                                        </ul >
                                                    </div >
                                                    <div className="tier-comparison-mobile">
                                                        <p className="tier-comparison-mobile--section-title" onClick={(e) => this.toggleMobileDescription(e)}><span><strong>{window.i18n('price_F6H')}&nbsp;</strong></span><span className="tier-comparison-mobile--icon"><strong><span className="selector-icon fas fa-chevron-right" alt="selector-icon"></span></strong></span></p>
                                                        <ul className="tier-comparison-mobile-hide mobile-tier-section-url">
                                                            <li className="tier-comparison-mobile--item-title"><strong>{window.i18n('price_F6D1')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F6D1T')}></i></strong></li>
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (<li className="tier-comparison-mobile--item-detail">
                                                                        <span className="tier-comparison-mobile--item-label text-capitalize">{pack.type}</span>
                                                                        <span>
                                                                            {
                                                                                parseInt(pack.API) > 0 ?
                                                                                    <span className="tier-comparison-value"><strong>{pack.API}</strong>&nbsp;</span>
                                                                                    :
                                                                                    <span><span className="tier-comparison-value tier-comparison-column--emdash">—</span></span>
                                                                            }
                                                                        </span>
                                                                    </li>)
                                                                })
                                                            }
                                                            <li className="tier-comparison-mobile--item-title"><strong>{window.i18n('price_F6D2')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F6D2T')}></i></strong></li>
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (<li className="tier-comparison-mobile--item-detail">
                                                                        <span className="tier-comparison-mobile--item-label text-capitalize">{pack.type}</span>
                                                                        <span>
                                                                            {
                                                                                parseInt(pack.integrations) > 0 ?
                                                                                    <span className="tier-comparison-value"><strong>{pack.integrations}</strong>&nbsp;</span>
                                                                                    :
                                                                                    <span><span className="tier-comparison-value tier-comparison-column--emdash">—</span></span>
                                                                            }
                                                                        </span>
                                                                    </li>)
                                                                })
                                                            }
                                                        </ul >
                                                    </div >
                                                    <div className="tier-comparison-mobile">
                                                        <p className="tier-comparison-mobile--section-title" onClick={(e) => this.toggleMobileDescription(e)}><span><strong>{window.i18n('price_F7H')}&nbsp;</strong></span><span className="tier-comparison-mobile--icon"><strong><span className="selector-icon fas fa-chevron-right" alt="selector-icon"></span></strong></span></p>
                                                        <ul className="tier-comparison-mobile-hide mobile-tier-section-url">
                                                            <li className="tier-comparison-mobile--item-title"><strong>{window.i18n('price_F7D1')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F7D1T')}></i></strong></li>
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (
                                                                        <li className="tier-comparison-mobile--item-detail">
                                                                            <span className="tier-comparison-mobile--item-label text-capitalize">
                                                                                {pack.type}
                                                                            </span>
                                                                            {
                                                                                pack.accountManagement ?
                                                                                    <span>
                                                                                        <span className="tier-comparison-value">
                                                                                            <span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
                                                                                        </span>
                                                                                    </span>
                                                                                    :
                                                                                    <span>
                                                                                        <span className="tier-comparison-value tier-comparison-column--emdash">
                                                                                            —
                                                                                        </span>
                                                                                    </span>
                                                                            }
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                            <li className="tier-comparison-mobile--item-title"><strong>{window.i18n('price_F7D2')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F7D2T')}></i></strong></li>
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (
                                                                        <li className="tier-comparison-mobile--item-detail">
                                                                            <span className="tier-comparison-mobile--item-label text-capitalize">
                                                                                {pack.type}
                                                                            </span>
                                                                            {
                                                                                pack.onboardingAndSetup ?
                                                                                    <span>
                                                                                        <span className="tier-comparison-value">
                                                                                            <span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
                                                                                        </span>
                                                                                    </span>
                                                                                    :
                                                                                    <span>
                                                                                        <span className="tier-comparison-value tier-comparison-column--emdash">
                                                                                            —
                                                                                        </span>
                                                                                    </span>
                                                                            }
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                            <li className="tier-comparison-mobile--item-title"><strong>{window.i18n('price_F7D3')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F7D3T')}></i></strong></li>
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (
                                                                        <li className="tier-comparison-mobile--item-detail">
                                                                            <span className="tier-comparison-mobile--item-label text-capitalize">
                                                                                {pack.type}
                                                                            </span>
                                                                            {
                                                                                pack.emailSupport ?
                                                                                    <span>
                                                                                        <span className="tier-comparison-value">
                                                                                            <span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
                                                                                        </span>
                                                                                    </span>
                                                                                    :
                                                                                    <span>
                                                                                        <span className="tier-comparison-value tier-comparison-column--emdash">
                                                                                            —
                                                                                        </span>
                                                                                    </span>
                                                                            }
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul >
                                                    </div >
                                                    <div className="tier-comparison-mobile">
                                                        <p className="tier-comparison-mobile--section-title" onClick={(e) => this.toggleMobileDescription(e)}><span><strong>{window.i18n('price_F8H')}&nbsp;</strong></span><span className="tier-comparison-mobile--icon"><strong><span className="selector-icon fas fa-chevron-right" alt="selector-icon"></span></strong></span></p>
                                                        <ul className="tier-comparison-mobile-hide mobile-tier-section-url">
                                                            <li className="tier-comparison-mobile--item-title"><strong>{window.i18n('price_F8H')} <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip={window.i18n('price_F8D1T')}></i></strong></li>
                                                            {
                                                                this.state.allPackages.map((pack, index) => {
                                                                    return (<li className="tier-comparison-mobile--item-detail">
                                                                        <span className="tier-comparison-mobile--item-label text-capitalize">
                                                                            {pack.type}
                                                                        </span>
                                                                        {
                                                                            pack.qrCodeGenerate ?
                                                                                <span>
                                                                                    <span className="tier-comparison-value">
                                                                                        <span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
                                                                                    </span>
                                                                                </span>
                                                                                :
                                                                                <span>
                                                                                    <span className="tier-comparison-value tier-comparison-column--emdash">
                                                                                        —
                                                                                    </span>
                                                                                </span>
                                                                        }
                                                                    </li>)
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div >


                                                <div className="subscription-pricing--disclaimer-text">
                                                    <p>{window.i18n('price_P2')}</p>
                                                    <p>{window.i18n('price_P3')}</p>
                                                    {/* <p>**First custom domain registration included with subscription (up to $30)</p> */}
                                                </div>
                                            </div >

                                        </div >
                                    </div >
                                </div >
                            </div >
                    }
                </div>
            </React.Fragment >
        );
    }
}

const mapDispatchToProps = {
    getSiteSetting, beforeSiteSettings
};

const mapStateToProps = state => ({
    settings: state.settings,
    // header: state.header,
    // errors: state.errors
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Plans));

// export default Plans;