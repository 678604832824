import React from "react";
import NavbarSimple from "../components/layout-files/dashboard-navbar/NavbarSimple";
import "bootstrap/dist/css/bootstrap.min.css";

import "../App.css";

const SimpletLayout = ({ children }) => (
    <React.Fragment>
      <NavbarSimple />
      {children}
    </React.Fragment>
  );
  
  export default SimpletLayout;