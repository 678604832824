import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from "./routes/routes";
import { ENV } from '../src/config/config';
import $ from 'jquery';
// import Language from './components/language/language'
import './services/localizationService';
import 'bootstrap-select/dist/css/bootstrap-select.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import Draggable from 'react-draggable';
require('bootstrap-select');
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
      languages: [],
      settings: {},
      languageClass: '',
      mainLoader: false
    }
  }

  componentDidMount() {
    this.getSiteSetting();
  }

  getSiteSetting = () => {
    fetch(`${ENV.url}site-settings/get-site-settings`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': ENV.Authorization,
        'x-auth-token': ENV.x_auth_token
      }
    })
      .then(res => { return res.json() }).then(data => {
        if (data.status) {
          // add to google analytics script
          $('body').append(data.site.googleAnalytics);
        } else {
        }
      }).catch(errors => {
      })
  }

  getLanguages() {
    let isDefault = localStorage.getItem('language') ? true : false;
    fetch(`${ENV.url}language/list?isActive=true&isTranslated=true&all=true&isDefault=${isDefault}&sortBy=name`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    })
      .then(res => { return res.json() }).then(data => {
        const { userLang, languages, message, status } = data;
        let { language } = this.state;

        if (!isDefault) {
          language = userLang ? userLang : language ? language : 'en'
          localStorage.setItem('language', language);
          this.setState({ language });
        }
        if (status) {
          this.setState({ languages });
        } else if (!status) {
        } else {
        }
      },
        (error) => {
        });
  }

  // changeLanguage = (e) => {
  //   let language = e.target.value;
  //   window.changeLanguage(language);
  //   this.forceUpdate();
  // };

  toggleLanguage() {
    let { languageClass } = this.state;
    if (languageClass === '') {
      this.setState({ languageClass: 'active' });
    }
    else {
      this.setState({ languageClass: '' });
    }
  }

  render() {
    return (
      <Router basename={process.env.REACT_APP_BASENAME || ""}>
        {/* <Language changeLanguage={this.changeLanguage} /> */}
        <Switch>
          {/* <div> */}
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={
                  props => {
                    return (
                      <route.layout {...props}>
                        <route.component {...props} />
                      </route.layout>
                    );
                  }
                }
              />
            );
          })}
          {/* </div> */}
        </Switch>
      </Router>
    )
  }
}

export default App;
