import React, { Component } from "react";
import { Link } from "react-router-dom";
import { scrollTop } from '../../shared/common-functions';
import pageBannerImage from '../../images/features.png';

class Features extends Component {
	componentDidMount() {
		scrollTop();
	}
	render() {
		return (
			<React.Fragment>
				<div class="main-content pb-0 pl-0">
					{/* <div className="page-banner">
						<div className="container h-100 d-flex justify-content-center align-items-center">
							<div className="page-title-block py-5 text-white">
								<h1>{window.i18n('global_features')}</h1>
							</div>
						</div>
					</div> */}
					<div className="page-banner">
						<div className="container text-white">
							<div className="row align-items-center">
								<div className="col-md-6">
									<div className="page-banner-text">
										<h1>{window.i18n('global_features')}</h1>
										<p>Neque porro quisquam esqui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.</p>
									</div>
								</div>
								<div className="col-md-6 d-none d-md-block">
									<div className="page-banner-image">
										<img className="img-fluid" src={pageBannerImage} alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="cms-content">
						<div className="content-section">
							<div className="container">
								<div className="section-heading-block text-center lessBottomPadding">
									<h2 className="text-center">
										<span className="d-inline-block align-top">{window.i18n('global_linkShort')}</span>
									</h2>
									<small className="d-block">{window.i18n('feat_S1SubH')}</small>
								</div>
								<div className="section-intro-text text-center">
									<p>{window.i18n('feat_S1desc')}</p>
								</div>
								<div className="content-articles-holder">
									<article className="content-article section-bg-grey">
										<h3>{window.i18n('feat_S1Li1H')}</h3>
										<p>{window.i18n('feat_S1Li1De')}</p>
									</article>
									<article className="content-article section-bg-grey">
										<h3>{window.i18n('feat_S1Li2H')}</h3>
										<p>{window.i18n('feat_S1Li2De')}</p>
									</article>
									<article className="content-article section-bg-grey">
										<h3>{window.i18n('feat_S1Li3H')}</h3>
										<p>{window.i18n('feat_S1Li3De')}</p>
									</article>
									{/* <article className="content-article section-bg-grey">
										<h3>{window.i18n('feat_S1Li4H')}</h3>
										<p>{window.i18n('feat_S1Li4De')}</p>
									</article> */}
									<div className="centerBtnsHolder text-center">
										<Link to="/pricing">
											<button className="btn btn-pink">{window.i18n('global_getStarted')}</button>
										</Link>
										<Link to="/get-quote">
											<button className="btn btn-pink-outline">{window.i18n('global_getAQuote')}</button>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="content-section section-bg-grey">
							<div className="container">
								<div className="section-heading-block text-center lessBottomPadding">
									<h2 className="text-center">
										<span className="d-inline-block align-top">{window.i18n('global_brandLinks')}</span>
									</h2>
									<small className="d-block">{window.i18n('feat_S2SubH')}</small>
								</div>
								<div className="section-intro-text text-center">
									<p>{window.i18n('feat_S2desc')}</p>
								</div>
								<div className="content-articles-holder">
									<article className="content-article bgLightBlue">
										<h3>{window.i18n('feat_S2Li1H')}</h3>
										<p>{window.i18n('feat_S2Li1De')}</p>
									</article>
									<article className="content-article bgLightBlue">
										<h3>{window.i18n('feat_S2Li2H')}</h3>
										<p>{window.i18n('feat_S2Li2De')}</p>
									</article>
									<article className="content-article bgLightBlue">
										<h3>{window.i18n('feat_S2Li3H')}</h3>
										<p>{window.i18n('feat_S2Li3De')}</p>
									</article>
									<article className="content-article bgLightBlue">
										<h3>{window.i18n('feat_S2Li4H')}</h3>
										<p>{window.i18n('feat_S2Li4De')}</p>
									</article>
									<article className="content-article bgLightBlue">
										<h3>{window.i18n('feat_S2Li5H')}</h3>
										<p>{window.i18n('feat_S2Li5De')}</p>
									</article>
									<div className="centerBtnsHolder text-center">
										<Link to="/pricing">
											<button className="btn btn-pink">{window.i18n('global_getStarted')}</button>
										</Link>
										<Link to="/get-quote">
											<button className="btn btn-pink-outline">{window.i18n('global_getAQuote')}</button>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="content-section">
							<div className="container">
								<div className="section-heading-block text-center lessBottomPadding">
									<h2 className="text-center">
										<span className="d-inline-block align-top">{window.i18n('feat_S3Heading')}</span>
									</h2>
									<small className="d-block">{window.i18n('feat_S3SubH')}</small>
								</div>
								<div className="section-intro-text text-center">
									<p>{window.i18n('feat_S3desc')}</p>
								</div>
								<div className="content-articles-holder">
									<article className="content-article section-bg-grey">
										<h3>{window.i18n('feat_S3Li1H')}</h3>
										<p>{window.i18n('feat_S3Li1De')}</p>
									</article>
									<article className="content-article section-bg-grey">
										<h3>{window.i18n('feat_S3Li2H')}</h3>
										<p>{window.i18n('feat_S3Li2De')}</p>
									</article>
									<article className="content-article section-bg-grey">
										<h3>{window.i18n('feat_S3Li3H')}</h3>
										<p>{window.i18n('feat_S3Li3De')}</p>
									</article>
									<article className="content-article section-bg-grey">
										<h3>{window.i18n('feat_S3Li4H')}</h3>
										<p>{window.i18n('feat_S3Li4De')}</p>
									</article>
									<div className="centerBtnsHolder text-center">
										<Link to="/pricing">
											<button className="btn btn-pink">{window.i18n('global_getStarted')}</button>
										</Link>
										<Link to="/get-quote">
											<button className="btn btn-pink-outline">{window.i18n('global_getAQuote')}</button>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="content-section section-bg-blue02">
							<div className="container">
								<div className="section-heading-block text-center lessBottomPadding">
									<h2 className="text-center">
										<span className="d-inline-block align-top">{window.i18n('feat_S4Heading')}</span>
									</h2>
									<small className="d-block">{window.i18n('feat_S4SubH')}</small>
								</div>
								<div className="section-intro-text text-center">
									<p>{window.i18n('feat_S4desc')}</p>
								</div>
								<div className="content-articles-holder">
									<article className="content-article ">
										<h3>{window.i18n('feat_S4Li1H')}</h3>
										<p>{window.i18n('feat_S4Li1De')}</p>
									</article>
									<article className="content-article">
										<h3>{window.i18n('feat_S4Li2H')}</h3>
										<p>{window.i18n('feat_S4Li2De')}</p>
									</article>
									<article className="content-article">
										<h3>{window.i18n('feat_S4Li3H')}</h3>
										<p>{window.i18n('feat_S4Li3De')}</p>
									</article>
									{/* <article className="content-article">
										<h3>{window.i18n('feat_S4Li4H')}</h3>
										<p>{window.i18n('feat_S4Li4De')}</p>
									</article> */}
									<div className="centerBtnsHolder text-center">
										<Link to="/pricing">
											<button className="btn btn-pink">{window.i18n('global_getStarted')}</button>
										</Link>
										<Link to="/get-quote">
											<button className="btn btn-pink-outline">{window.i18n('global_getAQuote')}</button>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default Features;