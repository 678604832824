import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// import Swal from 'sweetalert2';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import { TweenMax } from 'gsap/all';
import swal from 'sweetalert';
import { scrollTop } from '../../shared/common-functions';
import { css } from '@emotion/core';
import { Link } from "react-router-dom";
import MainLoader from '../../shared/loaders/MainLoader'
import QRCode from 'qrcode.react';
import $ from "jquery";
import videoThumbnail from '../../images/video-thumbnail.jpg';
import playIcon from '../../images/play-icon.png';



const override = css`
    display: block;
	position:absolute;
    left:50%;
    top:50%;
    margin:-60px 0 0 -60px;
    border-color: #0a6eb4;
`;

const blueOverride = css`
  display: inline-block;
  vertical-align: top;
  margin: 0 auto;
  border-color: #0a6eb4;
`;
class LandingPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirect: false,
			urlLink: '',
			shortner: {},
			url: "",
			create: false,
			mainLoader: false,
			smallLoader: false,
		};
	}
	componentDidMount() {
		AOS.init();
		$('#example').popover();
		// $("#main-banner").mousemove(function (e) {
		// 	parallaxIt(e, ".banner-image-holder", -100);
		// });

		// function parallaxIt(e, target, movement) {
		// 	var $this = $("#main-banner");
		// 	var relX = e.pageX - $this.offset().left;
		// 	var relY = e.pageY - $this.offset().top;

		// 	TweenMax.to(target, 1, {
		// 		x: (relX - $this.width() / 2) / $this.width() * movement,
		// 		y: (relY - $this.height() / 2) / $this.height() * movement
		// 	});
		// }
	}

	componentWillMount() {
		scrollTop();
		let token = this.props.match.params.token;
		if (token !== undefined && token !== "undefined") {
			localStorage.setItem("loginToken", token);
			localStorage.setItem("loginTime", new Date().getTime());
			this.setState({ redirect: true });
		} else if (localStorage.getItem("loginToken")) {
			this.setState({ redirect: true });
		} else {
			this.setState({
				redirect: false,
				// mainLoader: true
			});
			// this.timeOut();
		}
	}

	timeOut = () => {
		setTimeout(() => {
			this.setState({
				mainLoader: false
			})
		}, 1000);
	}

	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	urlShortner = () => {
		this.setState({ smallLoader: true });
		const regexp = /(?:[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?\.)+[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9]/;
		var pattern = new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[A-Za-z\\u00a1-\\uffff0-9]+-?)*[A-Za-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[A-Za-z\\u00a1-\\uffff0-9]+-?)*[A-Za-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[A-Za-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$');
		let link = this.state.urlLink;
		if (link.includes('http')) {
			if (pattern.test(link)) {
				fetch(window.APIURL + "guest-url?url=" + this.state.urlLink, {
					method: "post",
					headers: {
						"Content-Type": "application/json"
					},
					body: JSON.stringify({
						link: this.state.urlLink
					})
				})
					.then(res => {
						if (res.status === 200) {
							return res.json();
						} else if (res.status === 400) {
							return res.json();
						} else if (res.status === 403) {
							return res.json();
						} else if (res.status === 422) {
							return res.json();
						} else if (res.status === 404) {
							return res.json();
						} else {
							return res.json();
						}
					})
					.then(result => {
						if (result.message && result.message.includes('Malware')) {
							swal(window.i18n('swal_mal_h'), window.i18n('swal_mal_d'), 'error')
							return;
						}
						else if (result.status === true) {

							console.log('result.guestUrl.subDomain', result.guestUrl.subDomain)
							// 		//console.log('result.guestUrl.backHalf',result.guestUrl.backHalf)
							this.setState({ shortner: result.guestUrl, create: true, smallLoader: false, url: `https://${result.guestUrl.subDomain}${result.guestUrl.backHalf}` });
						} else if (result.status === false) {
							this.setState({ smallLoader: false })
							swal(window.i18n('alert_a'), result.message, 'error');
						} else {
							this.setState({ smallLoader: false })
							swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
						}
					})
			} else {
				this.setState({ smallLoader: false })
				swal(window.i18n('alert_a'), window.i18n('alert_f'), 'error')
			}
		} else {
			if (link.match(regexp)) {
				let theLink = "https://" + link + "/";
				if (pattern.test(theLink)) {
					this.setState({
						urlLink: theLink
					}, () => {
						fetch(window.APIURL + "guest-url?url=" + this.state.urlLink, {
							method: "post",
							headers: {
								"Content-Type": "application/json"
							},
							body: JSON.stringify({
								link: this.state.urlLink
							})
						})
							.then(res => {
								if (res.status === 200) {
									return res.json();
								} else if (res.status === 400) {
									return res.json();
								} else if (res.status === 403) {
									return res.json();
								} else if (res.status === 422) {
									return res.json();
								} else if (res.status === 404) {
									return res.json();
								} else {
									return res.json();
								}
							})
							.then(result => {
								if (result.message && result.message.includes('Malware')) {
									swal(window.i18n('swal_mal_h'), window.i18n('swal_mal_d'), 'error')
									return;
								}
								else if (result.status === true) {
									// //console.log('result.guestUrl.subDomain',result.guestUrl.subDomain)
									// //console.log('result.guestUrl.backHalf',result.guestUrl.backHalf)
									this.setState({ shortner: result.guestUrl, create: true, smallLoader: false, url: `https://${result.guestUrl.subDomain}${result.guestUrl.backHalf}` });
								} else if (result.status === false) {
									this.setState({ smallLoader: false })
									swal(window.i18n('alert_a'), result.message, 'error');
								} else {
									this.setState({ smallLoader: false })
									swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
								}
							})
					})
				}
				else {
					this.setState({ smallLoader: false })
					swal(window.i18n('alert_a'), window.i18n('alert_f'), 'error')
				}
			} else {
				this.setState({ smallLoader: false })
				swal(window.i18n('alert_a'), window.i18n('alert_f'), 'error', {
					onOpen: function () {
					}
				})
			}
		}
		// if (pattern.test(this.state.urlLink)) {
		// } else {
		// 	swal(window.i18n('alert_a'), "Type a valid url", 'error')
		// }
		// fetch(window.APIURL + "guest-url", {
		// 	method: "post",
		// 	headers: {
		// 		"Content-Type": "application/json"
		// 	},
		// 	body: JSON.stringify({
		// 		link: this.state.urlLink
		// 	})
		// })
		// 	.then(res => {
		// 		if (res.status === 200) {
		// 			return res.json();
		// 		} else if (res.status === 400) {
		// 			return res.json();
		// 		} else if (res.status === 403) {
		// 			return res.json();
		// 		} else if (res.status === 422) {
		// 			return res.json();
		// 		} else if (res.status === 404) {
		// 			return res.json();
		// 		} else {
		// 			return res.json();
		// 		}
		// 	})
		// 	.then(result => {
		// 		if (result.status === true) {
		// 			this.setState({ shortner: result.guestUrl, create: true });
		// 		} else if (result.status === false) {
		// 			swal(window.i18n('alert_a'), result.message, 'error');
		// 		} else {
		// 			swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
		// 		}
		// 	});
	}

	copyLink = link => {
		var fullLink = document.createElement("input");
		document.body.appendChild(fullLink);
		fullLink.value = link;
		fullLink.select();
		document.execCommand("copy");
		setTimeout(() => {
			$('#example').popover('hide');
		}, 1000
		)
		fullLink.remove();
	};

	handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			this.urlShortner();
		}
	}

	goToSolutions(navigationPath) {
		this.setState({
			mainLoader: true
		},
			() => {
				$('html, body').animate({
					scrollTop: 0
				},
					() => {
						this.props.history.push(navigationPath);
					}
				);
			}
		)
	}

	render() {
		const { redirect, shortner } = this.state;
		if (redirect) {
			return <Redirect to="/dashboard" />;
		}
		return (
			<React.Fragment>
				<section id="main-banner">
					<div className="container h-100">
						<div className="d-flex flex-column justify-content-end  h-100">
							<div className=" align-items-center mb-1 mb-md-3">
								<div className="banner-text mb-3">
									<span className="border-text">Increases your brand engagement<br /> and exposure</span>
									<h1>{window.i18n('landing_fly')}<span className="d-block">{window.i18n('landing_clickWorthy')}</span></h1>
									<span className="border-text">Build and boost a whole new brand<br /> identity using powerful, recognizable,<br /> click-worthy short links.</span>
									{/* <p>{window.i18n('landing_elevateYour')}</p> */}
									{/* <div className="banner-btns-holder left-btns-holder">
										<Link to="/userRegister" className="btn btn-pink text-uppercase">{window.i18n('landing_signUp')}</Link>
										<Link to="/userLogin" className="btn btn-pink-outline text-uppercase">{window.i18n('landing_logIn')}</Link>
									</div> */}
								</div>
								<div className="mobile banner-text mb-3">
								<h1>{window.i18n('landing_fly')} {window.i18n('landing_clickWorthy')}</h1>
									<span className="border-text">Increases your brand engagement<br /> and exposure</span>
									<span className="border-text">Build and boost a whole new brand<br /> identity using powerful, recognizable,<br /> click-worthy short links.</span>
								</div>
								<div className="banner-image-holder">
									<img className="img-fluid" src={require("../../images/image-01.png")} alt="Banner Image" />
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<div className="link-shortner-block">
										<div className="link-shortner-from-holder mx-auto">
											<form className="link-shortner-form d-flex align-items-center flex-column flex-sm-row">
												<div className="flex-fill">
													<div className="form-group">
														<div className="input-holder float-md-left">
															<input type="text" name="urlLink" placeholder={window.i18n('landing_shortenYour')} onKeyPress={this.handleKeyPress} value={this.state.urlLink} onChange={this.onChange} />
														</div>
														<div className="btn-holder float-md-right">
															<button type="button" className="btn btn-white text-uppercase" onClick={this.urlShortner}>{window.i18n('landing_shorten')}</button>
														</div>
													</div>
													<span className="note-text d-block">{window.i18n('landing_byClicking')}</span>
													<div className="shortened-link-holder w-100 flex-row flex-sm-row" style={{ display: this.state.create === true ? '' : 'none' }}>
														<div className="generated-link-holder flex-fill">
															<div className="generated-link-block d-flex flex-row align-items-center">
																<div className="flex-fill">
																	<a href={"https://" + shortner.subDomain + shortner.backHalf} target="_blank" className="shotened-link">{shortner.subDomain + shortner.backHalf}</a>
																</div>
																<span className="btn-copy d-none d-sm-block cur-poi" id="example" onClick={() => this.copyLink(shortner.subDomain + shortner.backHalf)} data-container="body" data-toggle="popover" data-placement="top" data-content={window.i18n('landing_copyTo')}>{window.i18n('landing_copy')}</span>
																<span className="d-block d-sm-none cur-poi color-theme" data-toggle="popover" data-placement="top" data-content={window.i18n('landing_copyTo')}>
																	<i className="far fa-copy"></i>
																</span>
															</div>
														</div>
													</div>
												</div>
												<div className="QR-code-holder" style={{ display: this.state.create === true ? '' : 'none' }}>
													<QRCode id="qrId" value={this.state.url} renderAs="canvas" size={150} level="H" includeMargin={true} />
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="home-page-content">
					{/* <div className="page-content-bg">
						<img className="img-fluid" src={require('../../images/page-content-bg.png')} alt="section bg Image" />
					</div>
					<div className="link-shortner-block">
						<div className="container">

						</div>
					</div>
					<div className="one-step-block">
						<div className="container">
							<div className="one-step-holder text-center">
								<div className="section-heading-block">
									<h2>{window.i18n('landing_beOn')}</h2>
									<small className="d-block sub-heading">{window.i18n('landing_breakThe')}</small>
								</div>
								<p>{window.i18n('landing_createShortLinks')}</p>
							</div>
						</div>
					</div>
					<div className="get-started-block text-center">
						<div className="btn-holder d-inline-block align-top">
							<Link to="/pricing" className="btn btn-pink text-uppercase">{window.i18n('landing_getStared')}</Link>
						</div>
					</div> */}
					<div className="platform-section">
						<div className="container">
							<div className="section-heading-block text-center">
								<h2 className="pb-0 mb-0">{window.i18n('landing_highScalable')}</h2>
								{/* <small className="d-block sub-heading">{window.i18n('landing_strengthenYour')}</small> */}
							</div>
							<div className="services-holder text-center">
								<div className="row">
									<div className="col-lg-4 col-12 mb-5">
										<div className="service-holder link">
											<div className="service-icon-holder">
												<img src={require("../../images/link.svg")} alt="social media icon" />
											</div>
											<div className="service-text-holder">
												<h3>{window.i18n('landing_increasedBrand')}</h3>
												<p>{window.i18n('landing_elevateYourBusiness')}</p>
											</div>
											{/* <div className="service-btn-holder">
												<span onClick={() => this.goToSolutions('/solutions#socialMedia')} className="btn btn-pink-outline text-uppercase">{window.i18n('landing_socialMedia')}</span>
											</div> */}
										</div>
									</div>
									<div className="col-lg-4 col-12 mb-5">
										<div className="service-holder brand">
											<div className="service-icon-holder">
												<img src={require("../../images/fingerprint.svg")} alt="social media icon" />
											</div>
											<div className="service-text-holder">
												<h3>{window.i18n('landing_campOptim')}</h3>
												<p>{window.i18n('landing_integrateYour')}</p>
											</div>
											{/* <div className="service-btn-holder">
												<span onClick={() => this.goToSolutions('/solutions#digitalMarketing')} className="btn btn-pink-outline text-uppercase">{window.i18n('landing_digitalMark')}</span>
											</div> */}
										</div>
									</div>
									<div className="col-lg-4 col-12 mb-5">
										<div className="service-holder mobile">
											<div className="service-icon-holder">
												<img src={require("../../images/mobile-link.svg")} alt="social media icon" />
											</div>
											<div className="service-text-holder">
												<h3>{window.i18n('landing_consumerInter')}</h3>
												<p>{window.i18n('landing_fosterLong')}</p>
											</div>
											{/* <div className="service-btn-holder">
												<span onClick={() => this.goToSolutions('/solutions#customerServices')} className="btn btn-pink-outline text-uppercase">{window.i18n('landing_customerService')}</span>
											</div> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="video-with-text section-bg-grey">
						<div className="container">
							<div className="row">
								<div className="col-lg-6">
									<div className="section-heading-block">
										<h2 className="title pb-0 mb-0">FLO.ink </h2>
										<h3 className="d-block sub-heading">Go Beyond What’s Possible</h3>
									</div>
									<p>Create, monitor, manage secure and reliable links. Every link you create with FLO.ink, increases your brand engagement and exposure. You can share your branded links to get more clicks; making your audience more likely to engage with your brand and its content.</p>
									<p>This flexible URL shortening platform brings you value-based solutions where you can create a seamless user experience while tracking and optimizing every touchpoint.</p>
									<div className="left-btns-holder">
										<Link to="/userRegister" className="btn btn-pink">Free Sign Up</Link>
										<Link to="/pricing" className="btn btn-pink">Get Enterprise</Link>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="VideoHolder d-inline-block align-top">
										<video width="320" height="240" poster={videoThumbnail} controls>
											<source src="movie.mp4" type="video/mp4" />
											<source src="movie.ogg" type="video/ogg" />
										</video>
										<span className="play-icon cur-poi">
											<img src={playIcon} alt="" />
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="features section-bg-yellow">
						<div className="container">
							<div className="row">
								<div className="col-md-4">
									<div className="text">
										<h2 className="site-title">FLO.ink</h2>
									</div>
								</div>
								<div className="col-md-8">
									<div className="features-list">
										<div className="feature">
											<div className="image">
												<img src={require("../../images/icon-user.svg")} alt="" className="img-fluid"/>
											</div>
											<div className="text">
												<h3>Social Media</h3>
												<p>Make influential connections and win more customers. Create and share click-worthy links across every platform.</p>
											</div>
										</div>
										<div className="feature">
											<div className="image">
												<img src={require("../../images/icon-boost.svg")} alt="" className="img-fluid"/>
											</div>
											<div className="text">
												<h3>Digital Marketing</h3>
												<p>Enhance click-through rates with reliable links for increased online conversions.</p>
											</div>
										</div>
										<div className="feature">
											<div className="image">
												<img src={require("../../images/icon-headphone.svg")} alt="" className="img-fluid"/>
											</div>
											<div className="text">
												<h3>Customer Service</h3>
												<p>Equip your customer service teams with powerful business links and analytics.</p>
											</div>
										</div>
										<div className="feature">
											<div className="image">
												<img src={require("../../images/icon-api.svg")} alt="" className="img-fluid"/>
											</div>
											<div className="text">
												<h3>Reliable API &amp; Integration</h3>
												<p>Improve your customer engagement with real-time and personalized communication at scale.</p>
											</div>
										</div>
										<div className="feature">
											<div className="image">
												<img src={require("../../images/icon-user.svg")} alt="" className="img-fluid"/>
											</div>
											<div className="text">
												<h3>Social Media</h3>
												<p>Make influential connections and win more customers. Create and share click-worthy links across every platform.</p>
											</div>
										</div>
										<div className="feature">
											<div className="image">
												<img src={require("../../images/icon-boost.svg")} alt="" className="img-fluid"/>
											</div>
											<div className="text">
												<h3>Digital Marketing</h3>
												<p>Enhance click-through rates with reliable links for increased online conversions.</p>
											</div>
										</div>
										<div className="feature">
											<div className="image">
												<img src={require("../../images/icon-headphone.svg")} alt="" className="img-fluid"/>
											</div>
											<div className="text">
												<h3>Customer Service</h3>
												<p>Equip your customer service teams with powerful business links and analytics.</p>
											</div>
										</div>
										<div className="feature">
											<div className="image">
												<img src={require("../../images/icon-api.svg")} alt="" className="img-fluid"/>
											</div>
											<div className="text">
												<h3>Reliable API &amp; Integration</h3>
												<p>Improve your customer engagement with real-time and personalized communication at scale.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
				</section>
				{/* <section className="sh-inf-block bg-theme">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-12 order-2 order-lg-1">
								<div className="sh-inf-img-holder">
									<img className="img-fluid" src={require("../../images/image-02.png")} alt="Three Person Image" />
								</div>
							</div>
							<div className="col-lg-6 col-12 align-self-center sh-inf-txt-outer order-1 order-lg-2">
								<div className="sh-inf-txt-holder text-white mb-5 mb-lg-0 pt-4 pt-lg-0">
									<h2>{window.i18n('landing_notJustA')}</h2>
									<p>{window.i18n('landing_getBraNU')} &amp; {window.i18n('landing_analy')}</p>
									<Link to="/pricing" className="btn btn-pink">{window.i18n('landing_getStared')}</Link>
								</div>
							</div>
						</div>
					</div>
				</section> */}
				{
					this.state.mainLoader === true ?
						<MainLoader />
						:
						<React.Fragment></React.Fragment>
				}
			</React.Fragment>
		);
	}
}

export default LandingPage;
