import React, { Component } from "react";

class RegisteredOauthApps extends Component {
    constructor(props) {
        super(props);
        this.sendOauth = this.sendOauth.bind(this);
    }

    sendOauth = (appId) => {
        let from = 'appList'
        this.props.theOauthFunction(from, appId);
    }

    render() {
        return (
            <React.Fragment>
                <div className="action-sheet--content-wrapper button-visible">
                    <div className="action-sheet--text">
                    {window.i18n('getRegCode_curre')}{" "}
                        <a target="_blank" href="#">
                        {window.i18n('getRegCode_oauth2')}
                  </a>{" "}
                  {window.i18n('getRegCode_draftSpec')}{" "}
                        <a target="_blank" href="#">
                        {window.i18n('global_apiDocume')}
                  </a>
                        .
                </div>
                    <div className="action-sheet--header-rule">
                    {window.i18n('regOauthApps_regOauthapps')}
                </div>

                    {/* <!--- list-item start--> */}
                    {
                        this.props.registeredApps &&
                        this.props.registeredApps.length > 0 &&
                        this.props.registeredApps.map((data, index) => (
                            // <a className="checklist-menu-item" data-id="app_details">
                            <a className="checklist-menu-item" key={index + 1} onClick={() => this.sendOauth(data.registrationCode)}>
                                <div className="select-item--CUSTOM">
                                    <p className="select-item--label">{data.appName}</p>
                                    <span className="select-item--secondary-label" />
                                    <span className="select-item--third-label " />
                                    <span className="select-item--fourth-label" />
                                    <span className="selector-icon" alt="selector-icon" />
                                </div>
                            </a>
                        ))
                    }
                    {/* <!---list-item  end--> */}
                    <div
                        className="action-sheet--button-wrapper"
                        style={{
                            animation:
                                "700ms ease 0ms 1 normal forwards running show-apply-button "
                        }}
                    >
                        <button
                            className="button--SOLID-SECONDARY checklist-menu-item"
                            data-id="registration_code"
                            tabIndex="0"
                            id=""
                        >
                            {window.i18n('regOauthApps_regNewApp')}
                  </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default RegisteredOauthApps;