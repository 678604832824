import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from '@fortawesome/free-solid-svg-icons'
import $ from "jquery";

class NavbarDropdown extends Component {
	constructor(props) {
		super(props)
		this.state = {
			// item: 'FLOINKS',
			activeState: false
		}
		// this.onHandleClick = this.onHandleClick.bind(this);
	}
	componentDidMount = () => { }

	onClickItem = () => {
		window.location.href = '/web/dashboard?all=true';
	}

	setStateActive = () => {
		this.setState({ activeState: !this.state.activeState })
	}

	render() {
		const { userID, subscriptionDetails } = this.props;
		return (
			<div className={`flo-mini-sidebar ${this.state.activeState ? "active" : ""}`}>
				<span className="sidebar-toggle-icon cur-poi" onClick={() => this.setStateActive()}>
					<FontAwesomeIcon icon={faCog} />
				</span>
				{
					this.props.siteSettings &&
					<strong className="flo-logo d-block">
						<Link className="d-inline-block align-top" onClick={() => window.location.href = '/web/dashboard'}>
							{/* <img style={{ width: '140px' }} src={this.props.siteSettings.siteLogo} className="img-fluid" /> */}
							<img style={{width : '140px'}} src={require('../../../images/logo-floink-white.svg')} alt="" className="img-fluid" />
						</Link>
					</strong>
				}
				<ul className="list-unstyled links-list">
					<li className="text-center">
						<Link className="dropdown-item d-inline-block align-top" id={"DASHBOARD"} data-id="user_dashboard" to={subscriptionDetails.dashboardView ? "/dashboard/stats" : "/organization/subscriptions/" + userID}>
							<img className="mini-sidebar-icon" src={require('../../../images/icon-dashboard.png')} />
							<div className="link-text-holder text-capitalize flex-fill d-inline-block align-top">
								{window.i18n('navDrop_das')}
								{
									!subscriptionDetails.dashboardView &&
									<span className="badge badge-secondary float-right">{window.i18n('global_upgrade')}</span>
								}
							</div>
						</Link>
					</li>
					<li className="text-center">
						<Link className="dropdown-item d-inline-block align-top" id={"FLOINKS"} onClick={() => window.location.href = '/web/dashboard'}>
							<img className="mini-sidebar-icon" src={require('../../../images/icon-link.png')} />
							<div className="link-text-holder text-capitalize flex-fill d-inline-block align-top">
								{window.i18n('navDrop_nedlink')}
							</div>
						</Link>
					</li>
					<li className="text-center">
						<Link className="dropdown-item d-inline-block align-top" id={"CAMPAIGNS"} data-id="create_campaign" to={subscriptionDetails.campaigns ? "/dashboard/campaigns" : "/organization/subscriptions/" + userID}>
							<img className="mini-sidebar-icon" src={require('../../../images/icon-campaign.png')} />
							<div className="link-text-holder text-capitalize flex-fill d-inline-block align-top">
								{window.i18n('navDrop_campaigns')}
								{
									!subscriptionDetails.campaigns &&
									<span className="badge badge-secondary ml-1">{window.i18n('global_upgrade')}</span>
								}
							</div>
						</Link>
					</li>
					<li className="text-center">
						<Link className="dropdown-item d-inline-block align-top" id={"CUSTOM_DOMAINS"} data-id="custom_domains" to={subscriptionDetails.customDomains && parseInt(subscriptionDetails.customDomains) > 0 ? "/organization/domain" : "/organization/subscriptions/" + userID}>
							<img className="mini-sidebar-icon" src={require('../../../images/icon-domain.png')} />
							<div className="link-text-holder text-capitalize flex-fill d-inline-block align-top">
								{window.i18n('navDrop_customDom')}
								{
									!subscriptionDetails.customDomains && parseInt(subscriptionDetails.customDomains) > 0 &&
									<span className="badge badge-secondary ml-1">{window.i18n('global_upgrade')}</span>
								}
							</div>
						</Link>
					</li>
				</ul>

			</div>
		);
	}
}

export default NavbarDropdown;