import React, { Component } from "react";
// import Swal from 'sweetalert2'
import swal from 'sweetalert';
import {Redirect} from 'react-router-dom'
class RegisteredOauthAppSecond extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
            logOutRedirect: false
        }
        this.generateSecret = this.generateSecret.bind(this);
    }

    generateSecret = (rCode, id) => {
        fetch(window.APIURL + 'register-oauth-app/edit?lang=' + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "_id": id,
                "registrationCode": rCode,
                "generateClientSecret": true,
            })
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else if (res.status === 422) {
                return res.json();
            } else if (res.status === 400) {
                return res.json();
            } else {
                return res.json();
            }
        }).then((result) => {
            if (result.sessionExpired) {
                this.setState({
                    logOutRedirect: true
                })
                return;
            }


            if (result.status === true) {
                swal(window.i18n('alert_ay'), result.message, 'success')
                let data = result.result;
                let from = 'editSecret';
                this.props.theOauthFunction(from, data)
            } else if (result.status === false) {
                swal(window.i18n('alert_a'), result.message, 'error');
            } else {
                swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
            }
        });
    }

    render() {
        const { logOutRedirect } = this.state;
        if (logOutRedirect) {
            // return <Redirect to="/logout" />;
             window.location.href="/web/logout-and-clear-session"
        }

        let { appDetail } = this.props;
        return (
            <React.Fragment>
                <form action="#" className="button-visible">
                    <div className="action-sheet--content-wrapper">
                        <div className="action-sheet--content-header">{appDetail.appName}</div>
                        <div className="action-sheet--text">
                            <a target="_blank" href="#">
                                {appDetail.appLink}
                            </a>
                            .
                        </div>
                        <div className="action-sheet--text">{appDetail.appDescription}</div>
                        <div className="action-sheet--seperator" />
                        <div className="action-sheet--content-header">{window.i18n('regOauthSec_cliId')}</div>
                        <div className="action-sheet--text-sm">
                            {appDetail.clientId}
                        </div>
                        <div className="action-sheet--content-header">
                            {window.i18n('regOauthSec_cliSec')}
                        </div>
                        <div className="action-sheet--text-sm">
                            {appDetail.clientSecret}
                        </div>
                        <div
                            className="action-sheet--button-wrapper two-buttons"
                            style={{
                                animation:
                                    "700ms ease 0ms 1 normal forwards running show-apply-button-2 "
                            }}
                        >
                            <button
                                className="button--GHOST-SECONDARY"
                                tabIndex="0"
                                id=""
                                type="button"
                                onClick={() => this.generateSecret(appDetail.registrationCode, appDetail._id)}
                            >
                                {window.i18n('regOauthSec_regen')}
                            </button>
                            <button
                                className="button--SOLID-SECONDARY checklist-menu-item"
                                data-id="edit_app"
                                tabIndex="0"
                                type="button"
                                id=""
                            >
                                {window.i18n('regOauthSec_edit')}
                            </button>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default RegisteredOauthAppSecond;