import React, { Component } from "react";
import { Link } from "react-router-dom";
import { scrollTop } from '../../shared/common-functions';
import videoThumbnail from '../../images/video-thumbnail.jpg';
import playIcon from '../../images/play-icon.png';
import pageBannerImage from '../../images/banner-solutions.png';
import $ from 'jquery'

class Solutions extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		// scrollTop();
		//console.log(this.props);
		let hash = this.props.location.hash;
		//console.log(this.props);
		if (hash !== '') {
			$('html, body').animate({
				scrollTop: $(hash).offset().top
			});
		}
	}
	componentWillMount() {
		// scrollTop();
		$('html, body').animate({
			scrollTop: 0
		});
	}
	render() {
		return (
			<React.Fragment>
				<div className="main-content pl-0">
					<div className="page-banner">
						<div className="container text-white">
							<div className="row align-items-center">
								<div className="col-md-6">
									<div className="page-banner-text">
										<h1>{window.i18n('global_solutions')}</h1>
										<p>We deliver unparalleled quality, spread the magic of creativity to achieve excellence.</p>
									</div>
								</div>
								<div className="col-md-6 d-none d-md-block">
									<div className="page-banner-image">
										<img className="img-fluid" src={pageBannerImage} alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="cms-content">
						<section className="solutionContnetSection" id="socialMedia">
							<div className="solution-text-block">
								<div className="container">
									<div className="row">
										<div className="col-12">
											<div className="section-icon-holder bg-grey d-flex justify-content-center align-items-center">
												<img className="img-fluid" src={require("../../images/icon-user.svg")} alt="" />
											</div>
										</div>
									</div>
									<div className="row align-items-center">
										<div className="col-lg-6">
											<div className="solution-text-holder">
												<div className="heading-section-style-02">
													<h2>{window.i18n('sol_socialMed')}</h2>
													<p>{window.i18n('sol_makeYour')}</p>
												</div>
												<h3>{window.i18n('sol_bestIn')}</h3>
												<p>{window.i18n('sol_helpsIndv')}</p>
												<h3>{window.i18n('global_keyFeat')}</h3>
												<ul className="list-unstyled solution-detail-list">
													<li>
														<strong className="d-block">{window.i18n('global_brandLinks')}</strong>
														<span className="d-block">{window.i18n('sol_increaseBrand')}</span>
													</li>
													<li>
														<strong className="d-block">{window.i18n('sol_socialLink')}</strong>
														<span className="d-block">{window.i18n('sol_strengthYourdigi')}</span>
													</li>
													<li>
														<strong className="d-block">{window.i18n('sol_onlinePub')}</strong>
														<span className="d-block">{window.i18n('sol_postBrand')}</span>
													</li>
												</ul>
											</div>
											<div className="action-button centerButtonsHolder mb-5 mb-lg-0">
												<Link className="btn btn-pink d-inline-block align-top" to="/pricing">{window.i18n('global_getStarted')}</Link>
												<Link className="btn btn-pink-outline d-inline-block align-top" to="/get-quote">{window.i18n('global_getAQuote')}</Link>
											</div>
										</div>
										<div className="col-lg-6 text-center">
											{/* <div className="solutionImageHolder">
												<img src={require("../../images/social-media.jpg")} alt="" className="img-fluid" />
											</div> */}
											<div className="solutionVideoHolder d-inline-block align-top">
												<video width="320" height="240" poster={videoThumbnail} controls>
													<source src="movie.mp4" type="video/mp4" />
													<source src="movie.ogg" type="video/ogg" />
												</video>
												<span className="play-icon cur-poi">
													<img src={playIcon} alt="" />
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="solution-details-block colored-bg section-bg-grey">
								<div className="container">
									<div className="row">
										<div className="col-lg-6">
											<div className="mb-3 mb-md-5">
												<h2><span className="yellow-text ml-0">Dynamic Link</span> Management Platform</h2>
												<p>{window.i18n('sol_creOptmMang')}</p>
											</div>
											<div className="qout=btn-holder mb-5 mb-lg-0">
												<Link className="btn btn-grey" to="/get-quote">{window.i18n('global_getAQuote')}</Link>
											</div>
										</div>
										<div className="col-lg-6">
											<ul className="list-unstyled link-features-list">
												<li className="d-flex">
													<div className="feature-icon-holder mr-3 d-flex justify-cotent-center align-items-center">
														<img src={require("../../images/chart.svg")} alt="" className="img-fluid" />
													</div>
													<div className="feature-text-holder">
														<strong className="d-block">{window.i18n('sol_inksManagement')}</strong>
														<p>{window.i18n('sol_manageTrack')}</p>
													</div>
												</li>
												<li className="d-flex">
													<div className="feature-icon-holder mr-3 d-flex justify-cotent-center align-items-center">
														<img src={require("../../images/link.svg")} alt="" className="img-fluid" />
													</div>
													<div className="feature-text-holder">
														<strong className="d-block">{window.i18n('sol_customizedLink')}</strong>
														<p>{window.i18n('sol_enjoyIntellig')}</p>
													</div>
												</li>
												<li className="d-flex">
													<div className="feature-icon-holder mr-3 d-flex justify-cotent-center align-items-center">
														<img src={require("../../images/data.svg")} alt="" className="img-fluid" />
													</div>
													<div className="feature-text-holder">
														<strong className="d-block">{window.i18n('sol_dataAnaly')}</strong>
														<p>{window.i18n('sol_getInsight')}</p>
													</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
								{/*<div className="container">
									<div className="section-heading-block text-center">
										<h2>{window.i18n('sol_intuitiveLink')}</h2>
										<small className="d-block">{window.i18n('sol_creOptmMang')}</small>
									</div>
									<div className="solution-details-row">
										<div className="row">
											 <div className="col-lg-4">
												<div className="solution-detail-block text-center section-bg-blue text-white">
													<div className="iconHolder">
														<img src={require("../../images/chart.svg")} alt="" className="img-fluid" />
													</div>
													<h3>{window.i18n('sol_inksManagement')}</h3>
													<p>{window.i18n('sol_manageTrack')}</p>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="solution-detail-block text-center section-bg-blue text-white">
													<div className="iconHolder">
														<img src={require("../../images/sport-team.svg")} alt="" className="img-fluid" />
													</div>
													<h3>{window.i18n('sol_customizedLink')}</h3>
													<p>{window.i18n('sol_enjoyIntellig')}</p>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="solution-detail-block text-center section-bg-blue text-white">
													<div className="iconHolder">
														<img src={require("../../images/secure.svg")} alt="" className="img-fluid" />
													</div>
													<h3>{window.i18n('sol_dataAnaly')}</h3>
													<p>{window.i18n('sol_getInsight')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>*/}
							</div>
						</section>
						<section className="solutionContnetSection" id="digitalMarketing">
							<div className="solution-text-block">
								<div className="container">
									<div className="row">
										<div className="col-12">
											<div className="section-icon-holder bg-blue d-flex justify-content-center align-items-center">
												<img className="img-fluid" src={require("../../images/icon-boost.svg")} alt="" />
											</div>
										</div>
									</div>
									<div className="row align-items-center">
										<div className="col-lg-8">
											<div className="solution-text-holder">
												<div className="heading-section-style-02">
													<h2>{window.i18n('sol_digiMark')}</h2>
													<p>{window.i18n('sol_meaningUser')}</p>
												</div>
												<h3>{window.i18n('sol_extractLeads')}</h3>
												<p>{window.i18n('sol_incClicks')}</p>
												<h3>{window.i18n('global_keyFeat')}</h3>
												<ul className="list-unstyled solution-detail-list">
													<li>
														<strong className="d-block">{window.i18n('global_linkShort')}</strong>
														<span className="d-block">{window.i18n('sol_convYour')}</span>
													</li>
													<li>
														<strong className="d-block">{window.i18n('sol_cusLinks')}</strong>
														<span className="d-block">{window.i18n('sol_buildAPower')}</span>
													</li>
													<li>
														<strong className="d-block">{window.i18n('sol_campManage')}</strong>
														<span className="d-block">{window.i18n('sol_optmBrand')}</span>
													</li>
												</ul>
											</div>
											<div className="action-button centerButtonsHolder mb-5 mb-lg-0">
												<Link className="btn btn-pink d-inline-block align-top" to="/pricing">{window.i18n('global_getStarted')}</Link>
												<Link className="btn btn-pink-outline d-inline-block align-top" to="/get-quote">{window.i18n('global_getAQuote')}</Link>
											</div>
										</div>
										<div className="col-lg-4 text-center text-lg-right">
											<div className="solutionImageHolder">
												<img src={require("../../images/digital-marketing.png")} alt="" className="img-fluid" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="solution-details-block colored-bg section-bg-blue02">
								<div className="container">
									<div className="row">
										<div className="col-lg-6">
											<div className="mb-3 mb-md-5">
												<h2>
													Superlative
													<span className="yellow-text">Experience</span>
													<span className="blue-text">Reliability</span>
												</h2>
												<p>{window.i18n('sol_indLead')}</p>
											</div>
											<div className="qout=btn-holder mb-5 mb-lg-0">
												<Link className="btn btn-light-blue" to="/get-quote">{window.i18n('global_getAQuote')}</Link>
											</div>
										</div>
										<div className="col-lg-6">
											<ul className="list-unstyled link-features-list">
												<li className="d-flex">
													<div className="feature-icon-holder mr-3 d-flex justify-cotent-center align-items-center">
														<img src={require("../../images/chart.svg")} alt="" className="img-fluid" />
													</div>
													<div className="feature-text-holder">
														<strong className="d-block">{window.i18n('sol_flawlessInteg')}</strong>
														<p>{window.i18n('sol_integWithM')}</p>
													</div>
												</li>
												<li className="d-flex">
													<div className="feature-icon-holder mr-3 d-flex justify-cotent-center align-items-center">
														<img src={require("../../images/together.svg")} alt="" className="img-fluid" />
													</div>
													<div className="feature-text-holder">
														<strong className="d-block">{window.i18n('sol_secConnec')}</strong>
														<p>{window.i18n('sol_provImmaculate')}</p>
													</div>
												</li>
												<li className="d-flex">
													<div className="feature-icon-holder mr-3 d-flex justify-cotent-center align-items-center">
														<img src={require("../../images/data.svg")} alt="" className="img-fluid" />
													</div>
													<div className="feature-text-holder">
														<strong className="d-block">{window.i18n('sol_actionAnaly')}</strong>
														<p>{window.i18n('sol_realTime')}</p>
													</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							{/* <div className="solution-details-block">
								<div className="container">
									<div className="section-heading-block text-center">
										<h2>{window.i18n('sol_unpreReliable')}</h2>
										<small className="d-block">{window.i18n('sol_indLead')}</small>
									</div>
									<div className="solution-details-row">
										<div className="row">
											<div className="col-lg-4">
												<div className="solution-detail-block text-center section-bg-blue text-white">
													<div className="iconHolder">
														<img src={require("../../images/chart.svg")} alt="" className="img-fluid" />
													</div>
													<h3>{window.i18n('sol_flawlessInteg')}</h3>
													<p>{window.i18n('sol_integWithM')}</p>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="solution-detail-block text-center section-bg-blue text-white">
													<div className="iconHolder">
														<img src={require("../../images/together.svg")} alt="" className="img-fluid" />
													</div>
													<h3>{window.i18n('sol_secConnec')}</h3>
													<p>{window.i18n('sol_provImmaculate')}</p>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="solution-detail-block text-center section-bg-blue text-white">
													<div className="iconHolder">
														<img src={require("../../images/support.svg")} alt="" className="img-fluid" />
													</div>
													<h3>{window.i18n('sol_actionAnaly')}</h3>
													<p>{window.i18n('sol_realTime')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div> */}
						</section>
						<section className="solutionContnetSection" id="customerServices">
							<div className="solution-text-block">
								<div className="container">
									<div className="row">
										<div className="col-12">
											<div className="section-icon-holder bg-light-brown d-flex justify-content-center align-items-center">
												<img className="img-fluid" src={require("../../images/icon-headphone.svg")} alt="" />
											</div>
										</div>
									</div>
									<div className="row align-items-center">
										<div className="col-lg-6">
											<div className="solution-text-holder">
												<div className="heading-section-style-02">
													<h2>{window.i18n('sol_custoSupport')}</h2>
													<p>{window.i18n('sol_stayConnec')}</p>
												</div>
												<h3>{window.i18n('sol_uniqueQuick')}</h3>
												<p>{window.i18n('sol_empSupport')}</p>
												<h3>{window.i18n('global_keyFeat')}</h3>
												<ul className="list-unstyled solution-detail-list">
													<li>
														<strong className="d-block">{window.i18n('sol_setupOnboard')}</strong>
														<span className="d-block">{window.i18n('sol_ourExperts')}</span>
													</li>
													<li>
														<strong className="d-block">{window.i18n('sol_accManage')}</strong>
														<span className="d-block">{window.i18n('sol_ourSupport')}</span>
													</li>
													<li>
														<strong className="d-block">{window.i18n('sol_roundTheClock')}</strong>
														<span className="d-block">{window.i18n('sol_ourDedicated')}</span>
													</li>
												</ul>
											</div>
											<div className="action-button centerButtonsHolder mb-5 mb-lg-0">
												<Link className="btn btn-pink d-inline-block align-top" to="/get-quote">{window.i18n('global_getAQuote')}</Link>
												<Link className="btn btn-pink-outline d-inline-block align-top" to="/pricing">{window.i18n('global_getStarted')}</Link>
											</div>
										</div>
										<div className="col-lg-6 text-center">
											<div className="solutionImageHolder">
												<img src={require("../../images/customer-service.png")} alt="" className="img-fluid" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="solution-details-block colored-bg section-bg-light-brown">
								<div className="container">
									<div className="row">
										<div className="col-lg-6">
											<div className="mb-3 mb-md-5">
												<h2>
													Best-In-Class
													<small>Support, Scalability, Security</small>
												</h2>
												<p>{window.i18n('sol_isA_High')}</p>
											</div>
											<div className="qout=btn-holder mb-5 mb-lg-0">
												<Link className="btn btn-light-brown" to="/get-quote">{window.i18n('global_getAQuote')}</Link>
											</div>
										</div>
										<div className="col-lg-6">
											<ul className="list-unstyled link-features-list">
												<li className="d-flex">
													<div className="feature-icon-holder mr-3 d-flex justify-cotent-center align-items-center">
														<img src={require("../../images/chart.svg")} alt="" className="img-fluid" />
													</div>
													<div className="feature-text-holder">
														<strong className="d-block">{window.i18n('sol_knwBase')}</strong>
														<p>{window.i18n('sol_eduUsers')}</p>
													</div>
												</li>
												<li className="d-flex">
													<div className="feature-icon-holder mr-3 d-flex justify-cotent-center align-items-center">
														<img src={require("../../images/api.svg")} alt="" className="img-fluid" />
													</div>
													<div className="feature-text-holder">
														<strong className="d-block">{window.i18n('sol_reliableInteg')}</strong>
														<p>{window.i18n('sol_scalSolut')}</p>
													</div>
												</li>
												<li className="d-flex">
													<div className="feature-icon-holder mr-3 d-flex justify-cotent-center align-items-center">
														<img src={require("../../images/security.svg")} alt="" className="img-fluid" />
													</div>
													<div className="feature-text-holder">
														<strong className="d-block">{window.i18n('sol_dataProte')}</strong>
														<p>{window.i18n('sol_followsInternat')}</p>
													</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							
							{/* <div className="solution-details-block">
								<div className="container">
									<div className="section-heading-block text-center">
										<h2>{window.i18n('sol_bestInClass')}</h2>
										<small className="d-block">{window.i18n('sol_isA_High')}</small>
									</div>
									<div className="solution-details-row">
										<div className="row">
											<div className="col-lg-4">
												<div className="solution-detail-block text-center section-bg-blue text-white">
													<div className="iconHolder">
														<img src={require("../../images/trust.svg")} alt="" className="img-fluid" />
													</div>
													<h3>{window.i18n('sol_knwBase')}</h3>
													<p>{window.i18n('sol_eduUsers')}</p>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="solution-detail-block text-center section-bg-blue text-white">
													<div className="iconHolder">
														<img src={require("../../images/api.svg")} alt="" className="img-fluid" />
													</div>
													<h3>{window.i18n('sol_reliableInteg')}</h3>
													<p>{window.i18n('sol_scalSolut')}</p>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="solution-detail-block text-center section-bg-blue text-white">
													<div className="iconHolder">
														<img src={require("../../images/secure.svg")} alt="" className="img-fluid" />
													</div>
													<h3>{window.i18n('sol_dataProte')}</h3>
													<p>{window.i18n('sol_followsInternat')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div> */}
						</section>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Solutions;
