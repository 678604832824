import React, { Component } from "react";
import Swal from 'sweetalert2'
import swal from 'sweetalert';
import { ENV } from '../../../config/config';
import {Redirect} from 'react-router-dom'
var CryptoJS = require("crypto-js");

class GetRegistrationCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logOutRedirect: false,

            language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
        }
        this.getRegistrationCode = this.getRegistrationCode.bind(this);
    }

    getRegistrationCode = () => {
        let email = '';
        let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        // let decryptedUserDetails = CryptoJS.AES.decrypt(user.toString(), ENV.TokSecCrpt);
        // decryptedUserDetails = JSON.parse(decryptedUserDetails.toString(CryptoJS.enc.Utf8));

        // let userId = decryptedUserDetails._id;
        let emailVal = this.props.verified;
        emailVal.forEach((obj, i) => {
            if (obj.isNotification === true) {
                email = obj.email;
            }
        })
        if (email === '') {
            Swal.fire({
                type: 'error',
                title: window.i18n('alert_bl'),
                showConfirmButton: false,
                timer: 1200
            })
            return;
        } else {
            fetch(window.APIURL + 'register-oauth-app/generate-token?lang=' + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "userId": userId,
                    "email": email,
                })
            }).then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 422) {
                    return res.json();
                } else if (res.status === 400) {
                    return res.json();
                } else {
                    return res.json();
                }
            }).then((result) => {
                if (result.sessionExpired) {
                    this.setState({
                        logOutRedirect: true
                    })
                    return;
                }


                if (result.status === true) {
                    // let data = result.member;
                    // Swal.fire("Generic Access Token", data.accessToken, "success")
                    // this.setState({
                    //     copyToken: true,
                    //     genericToken: data.accessToken
                    // })
                    // Swal.fire({ title: 'Success', text: "REGISTRATION CODE SEND TO YOUR EMAIL", customClass: "successed" })
                    swal(window.i18n('alert_ay'), window.i18n('getRegCode_regCodeTo'), 'success')
                } else if (result.status === false) {
                    swal(window.i18n('alert_a'), result.message, 'error');
                } else {
                    swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
                }
            });
        }
    }

    render() {
        const { email } = this.props;
        const { logOutRedirect } = this.state;
        if (logOutRedirect) {
            // return <Redirect to="/logout" />;
             window.location.href="/web/logout-and-clear-session"
        }

        return (
            <React.Fragment>
                < div className="action-sheet--content-wrapper button-visible" >
                    <div className="action-sheet--text">
                        {window.i18n('getRegCode_curre')}{" "}
                        <a target="_blank" href="#">
                            {window.i18n('getRegCode_oauth2')}
                        </a>{" "}
                        {window.i18n('getRegCode_draftSpec')}{" "}
                        <a target="_blank" href="#">
                            {window.i18n('global_apiDocume')}
                        </a>
                        .
                </div>
                    <div className="action-sheet--content-header">
                        {window.i18n('global_getRegCode')}
                    </div>
                    <div className="action-sheet--text">
                        {window.i18n('getRegCode_creatAnOauth')}
                        {email}, {window.i18n('getRegCode_theEmailAdd')}
                    </div>
                    <form>
                        <div
                            className="action-sheet--button-wrapper"
                            style={{
                                animation:
                                    "700ms ease 0ms 1 normal forwards running show-apply-button "
                            }}
                        >
                            <button
                                // className="button--SOLID-SECONDARY checklist-menu-item"
                                className="button--SOLID-SECONDARY"
                                // data-id="add_app"
                                type="button"
                                tabIndex="0"
                                id=""
                                onClick={this.getRegistrationCode}
                            >
                                {window.i18n('getRegCode_getRegCode')}
                            </button>
                        </div>
                    </form>
                </div >
            </React.Fragment>
        );
    }
}
export default GetRegistrationCode