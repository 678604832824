import { GET_ERRORS, EMPTY_ERRORS, BEFORE_VOUCHER_CODE, UPSERT_VOUCHER_CODE, GET_UNUSED_VOUCHER_CODE } from '../../redux/types'
import { ENV } from '../../config/config'

export const emptyError = () => {
    return {
        type: EMPTY_ERRORS
    }
}

export const beforeVoucherCode = () => {
    return {
        type: BEFORE_VOUCHER_CODE
    }
}

export const validateVoucherCode = (body) => dispatch => {
    dispatch(emptyError())
    const language = localStorage.getItem('language') ? localStorage.getItem('language') : 'en'
    fetch(ENV.url + 'voucher/validate?lang=' + language , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        // if (data.status) {
            dispatch({
                type: UPSERT_VOUCHER_CODE,
                payload: data
            })
        // } 
        // else {
        //     dispatch({
        //         type: GET_ERRORS,
        //         payload: data
        //     })
        // }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const getUnusedVoucherCode = (userId) => dispatch => {
    dispatch(emptyError())
    fetch(`${ENV.url}voucher/get?userId=${userId}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json'
        }
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: GET_UNUSED_VOUCHER_CODE,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}
