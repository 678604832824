import React, { Component } from 'react';


class CsvReport extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { csvLogs } = this.props.location.state
        return (
            <React.Fragment>
                <div className="empty-state--BITLINKS">
                    <div className="table-responsive">
                        <table class="table table-borderless reportTable">
                            <thead>
                                <tr>
                                    <th>{window.i18n('creaFcamp_url')}</th>
                                    <th>{window.i18n('cont_I6')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    csvLogs && csvLogs.length ?
                                        csvLogs.map((csvLog, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{csvLog.qlink ? csvLog.qlink : 'N/A'}</td>
                                                    <td>{csvLog.message ? csvLog.message : 'N/A'}</td>
                                                </tr>
                                            )
                                        }) :
                                        <tr>
                                            <td colSpan="5" className="text-center">{window.i18n('global_noRecordF')}</td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


export default CsvReport;