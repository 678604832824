import React, { Component } from "react";
import { Link } from "react-router-dom";
import pageBannerImage from '../../images/banner-resources.png';


class Resources extends Component {
	state = {};

	render() {
		return (
			<React.Fragment>
				<div className="main-content pl-0">
					{/* <div className="page-banner">
						<div className="container h-100 d-flex justify-content-center align-items-center">
							<div className="page-title-block py-5 text-white">
								<h1>{window.i18n('global_resourses')}</h1>
							</div>
						</div>
					</div> */}
					<div className="page-banner">
						<div className="container text-white">
							<div className="row align-items-center">
								<div className="col-md-6">
									<div className="page-banner-text">
										<h1>Hello, <span>How Can We Help</span></h1>
										<p>Neque porro quisquam esqui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.</p>
									</div>
								</div>
								<div className="col-md-6 d-none d-md-block">
									<div className="page-banner-image">
										<img className="img-fluid" src={pageBannerImage} alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="cms-content faqsHolder content-section">
						<div className="container">
							<div id="accordionExample" className="faqsBlock accordion">
								<div className="faq-type-block general-block mb-3 mb-sm-4 mb-md-5">
									<h3>General Inquiries</h3>
									<div className="card bgLightBlue">
										<div className="card-header" id="headingOne">
											<h2 className="mb-0">
												<button className="d-block btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
													{window.i18n('res_R1H')}
													<span className="toggleIcon text-center"></span>
												</button>
											</h2>
										</div>
										<div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
											<div className="blankSpacer"></div>
											<div className="card-body">
												<p>{window.i18n('res_R1P1')}</p>
												<p>{window.i18n('res_R1P2')}</p>
											</div>
										</div>
									</div>
									<div className="card bgLightBlue">
										<div className="card-header" id="headingTwo">
											<h2 className="mb-0">
												<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
													{window.i18n('res_R2H')}
													<span className="toggleIcon text-center"></span>
												</button>
											</h2>
										</div>
										<div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
											<div className="blankSpacer"></div>
											<div className="card-body">
												<p>{window.i18n('res_R2P1')}</p>
												<ol className="floin-content-o-points">
													<li>{window.i18n('res_R2L1')}</li>
													<li>{window.i18n('res_R2L2')}</li>
													<li>{window.i18n('res_R2L3')}</li>
												</ol>
												<h6><strong>{window.i18n('res_R2P2')}</strong></h6>
												<ul className="floin-content-o-points">
													<li>{window.i18n('res_R2L4')}</li>
													<li>{window.i18n('res_R2L5')}</li>
												</ul>
											</div>
										</div>
									</div>
									<div className="card bgLightBlue">
										<div className="card-header" id="headingThree">
											<h2 className="mb-0">
												<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
													{window.i18n('res_R3H')}
													<span className="toggleIcon text-center"></span>
												</button>
											</h2>
										</div>
										<div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
											<div className="blankSpacer"></div>
											<div className="card-body">
												<p>{window.i18n('res_R3P1')}</p>
												<p>{window.i18n('res_R3P2')}</p>
											</div>
										</div>
									</div>
									<div className="card bgLightBlue">
									<div className="card-header" id="headingFour">
										<h2 className="mb-0">
											<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
												{window.i18n('res_R4H')}
												<span className="toggleIcon text-center"></span>
											</button>
										</h2>
									</div>
									<div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
										<div className="blankSpacer"></div>
										<div className="card-body">
											<p>{window.i18n('res_R4P1')}</p>
											<p>{window.i18n('res_R4P2')}</p>
										</div>
									</div>
								</div>
								</div>
								<div className="faq-type-block technical-block mb-3 mb-sm-4 mb-md-5">
									<h3>Technical</h3>
									<div className="card bgLightBlue">
										<div className="card-header" id="headingFive">
											<h2 className="mb-0">
												<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
													{window.i18n('res_R5H')}
													<span className="toggleIcon text-center"></span>
												</button>
											</h2>
										</div>
										<div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
											<div className="blankSpacer"></div>
											<div className="card-body">
												<p>{window.i18n('res_R5P1')}</p>
												<p>{window.i18n('res_R5P2')}</p>
											</div>
										</div>
									</div>
									<div className="card bgLightBlue">
										<div className="card-header" id="headingSix">
											<h2 className="mb-0">
												<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
													{window.i18n('res_R6H')}
													<span className="toggleIcon text-center"></span>
												</button>
											</h2>
										</div>
										<div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
											<div className="blankSpacer"></div>
											<div className="card-body">
												<p>{window.i18n('res_R6P1')}</p>
												<p>{window.i18n('res_R6P2')}</p>
												<div className="table-responsive">
													<table className="table nedlink-table">
														<thead>
															<tr>
																<th>{window.i18n('res_R6H1')}</th>
																<th>{window.i18n('res_R6H2')}</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>{window.i18n('res_R6C1R1')}</td>
																<td>{window.i18n('res_R6C2R1')}</td>
															</tr>
															<tr>
																<td>{window.i18n('res_R6C1R2')}</td>
																<td>{window.i18n('res_R6C2R2')}</td>
															</tr>
															<tr>
																<td>{window.i18n('res_R6C1R3')}</td>
																<td>{window.i18n('res_R6C2R3')}</td>
															</tr>
															<tr>
																<td>{window.i18n('res_R6C1R4')}</td>
																<td>{window.i18n('res_R6C2R4')}</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
									<div className="card bgLightBlue">
										<div className="card-header" id="headingSeven">
											<h2 className="mb-0">
												<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
													{window.i18n('res_R7H')}
													<span className="toggleIcon text-center"></span>
												</button>
											</h2>
										</div>
										<div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
											<div className="blankSpacer"></div>
											<div className="card-body">
												<p>{window.i18n('res_R7P1')}</p>
												<ul className="floin-content-o-points">
													<li>{window.i18n('res_R7B1')}</li>
													<li>{window.i18n('res_R7B2')}</li>
													<li>{window.i18n('res_R7B3')}</li>
													<li>{window.i18n('res_R7B4')}</li>
													<li>{window.i18n('res_R7B5')}</li>
												</ul>
												<p><strong>{window.i18n('res_R7H1')}</strong> {window.i18n('res_R7P2')}</p>
											</div>
										</div>
									</div>
									<div className="card bgLightBlue">
										<div className="card-header" id="headingNine">
											<h2 className="mb-0">
												<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
													{window.i18n('res_R8H')}
													<span className="toggleIcon text-center"></span>
												</button>
											</h2>
										</div>
										<div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
											<div className="blankSpacer"></div>
											<div className="card-body">
												<p>{window.i18n('res_R8P')}</p>
											</div>
										</div>
									</div>
									<div className="card bgLightBlue">
									<div className="card-header" id="headingTen">
										<h2 className="mb-0">
											<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
												{window.i18n('res_R9H')}
												<span className="toggleIcon text-center"></span>
											</button>
										</h2>
									</div>
									<div id="collapseTen" className="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
										<div className="blankSpacer"></div>
										<div className="card-body">
											<p>{window.i18n('res_R9P')}</p>
										</div>
									</div>
								</div>
								</div>
								<div className="faq-type-block others-block mb-3 mb-sm-4 mb-md-5">
									<h3>Others</h3>
									<div className="card bgLightBlue">
										<div className="card-header" id="headingSeventeen">
											<h2 className="mb-0">
												<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
													{window.i18n('res_R10H')}
													<span className="toggleIcon text-center"></span>
												</button>
											</h2>
										</div>
										<div id="collapseSeventeen" className="collapse" aria-labelledby="headingSeventeen" data-parent="#accordionExample">
											<div className="blankSpacer"></div>
											<div className="card-body">
												<p>{window.i18n('res_R10P1')}</p>
												<h6><strong>{window.i18n('res_R10H1')}</strong></h6>
												<ol className="floin-content-o-points">
													<li>{window.i18n('res_R10B1')}</li>
													<li>{window.i18n('res_R10B2')}</li>
													<li>{window.i18n('res_R10B3')}</li>
													<li>{window.i18n('res_R10B4')}</li>
												</ol>
												<h6><strong>{window.i18n('res_R10H2')}</strong></h6>
												<ul className="floin-content-o-points">
													<li>{window.i18n('res_R10B5')}</li>
													<li>{window.i18n('res_R10B6')}</li>
													<li>{window.i18n('res_R10B7')}</li>
												</ul>
											</div>
										</div>
									</div>
									<div className="card bgLightBlue">
										<div className="card-header" id="headingEleven">
											<h2 className="mb-0">
												<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
													{window.i18n('res_R11H')}
													<span className="toggleIcon text-center"></span>
												</button>
											</h2>
										</div>
										<div id="collapseEleven" className="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample">
											<div className="blankSpacer"></div>
											<div className="card-body">
												<p>{window.i18n('res_R11P1')}</p>
												<p>{window.i18n('res_R11P2')}</p>
												<p>{window.i18n('res_R11P3')}</p>
											</div>
										</div>
									</div>
									<div className="card bgLightBlue">
										<div className="card-header" id="headingtwelve">
											<h2 className="mb-0">
												<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsetwelve" aria-expanded="false" aria-controls="collapsetwelve">
													{window.i18n('res_R12H')}
													<span className="toggleIcon text-center"></span>
												</button>
											</h2>
										</div>
										<div id="collapsetwelve" className="collapse" aria-labelledby="headingtwelve" data-parent="#accordionExample">
											<div className="blankSpacer"></div>
											<div className="card-body">
												<p>{window.i18n('res_R12P')} </p>
											</div>
										</div>
									</div>
									<div className="card bgLightBlue">
										<div className="card-header" id="headingthirteen">
											<h2 className="mb-0">
												<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsethirteen" aria-expanded="false" aria-controls="collapsethirteen">
													{window.i18n('res_R13H')}
													<span className="toggleIcon text-center"></span>
												</button>
											</h2>
										</div>
										<div id="collapsethirteen" className="collapse" aria-labelledby="headingthirteen" data-parent="#accordionExample">
											<div className="blankSpacer"></div>
											<div className="card-body">
												<p>{window.i18n('res_R13P')}</p>
											</div>
										</div>
									</div>
									<div className="card bgLightBlue">
										<div className="card-header" id="headingfourteen">
											<h2 className="mb-0">
												<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsefourteen" aria-expanded="false" aria-controls="collapsefourteen">
													{window.i18n('res_R14H')}
													<span className="toggleIcon text-center"></span>
												</button>
											</h2>
										</div>
										<div id="collapsefourteen" className="collapse" aria-labelledby="headingfourteen" data-parent="#accordionExample">
											<div className="blankSpacer"></div>
											<div className="card-body">
												<p>{window.i18n('res_R14P')}</p>
											</div>
										</div>
									</div>
									<div className="card bgLightBlue">
									<div className="card-header" id="headingfifteen">
										<h2 className="mb-0">
											<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsefifteen" aria-expanded="false" aria-controls="collapsefifteen">
												{window.i18n('res_R15H')}
												<span className="toggleIcon text-center"></span>
											</button>
										</h2>
									</div>
									<div id="collapsefifteen" className="collapse" aria-labelledby="headingfifteen" data-parent="#accordionExample">
										<div className="blankSpacer"></div>
										<div className="card-body">
											<p>{window.i18n('res_R15P')}</p>
										</div>
									</div>
								</div>
								</div>
							</div>
						</div>
					</div>
					<div className="question-from-block content-section">
						<div className="container">
							<div className="mb-md-5 mb-3 text-center">
								<h2>FLO.ink</h2>
								<h3>Still Have You Question?</h3>
								<div className="note-text d-inline-block align-top">
									<span className="d-inline-block align-top">Simply fill up the form below our support team contact with you within 24 hours</span>
								</div>
							</div>
							<form className="question-from">
								<div className="row">
									<div className="col-md-4 col-12">
										<div className="form-group">
											<input className="form-control" type="text" placeholder="Name" name="name" />
										</div>
									</div>
									<div className="col-md-4 col-12">
										<div className="form-group">
											<input className="form-control" type="email" placeholder="Email" name="email" />
										</div>
									</div>
									<div className="col-md-4 col-12">
										<div className="form-group">
											<input className="form-control" type="tel" placeholder="Contact" name="contact" />
										</div>
									</div>
									<div className="col-12">
										<div className="form-group">
											<textarea className="form-control" rows="5" cols="5" placeholder="Message / Question" name="question"></textarea>
										</div>
									</div>
									<div className="col-12 text-right">
										<buttoon className="btn btn-white-border" type="submit">Submit</buttoon>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Resources;
