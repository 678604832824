import React, { Component } from 'react';
import $ from 'jquery';
import { ENV } from './../../../config/config';
import swal from 'sweetalert';
import InfiniteScroll from 'react-infinite-scroll-component';
import ClipLoader from 'react-spinners/ClipLoader';
import { Redirect } from 'react-router-dom';
import CircleLoader from 'react-spinners/CircleLoader';
import { css } from '@emotion/core';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import LoadingOverlay from 'react-loading-overlay';
import MainLoader from '../../../shared/loaders/MainLoader'
// import $ from 'jquery';
var CryptoJS = require("crypto-js");

// const override = css`
// display: block;
// position:absolute;
// left:50%;
// top:50%;
// margin:-60px 0 0 -60px;
// border-color: blue;
// `;
const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
`;

class CustomDomains extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
            userId: '',
            mainLoader: true,
            domainsList: [],
            allowedCustomDomains: 0,
            total: 0,
            page: 1,
            limit: 0,
            pages: 0,
            hasMore: true,
            redirect: false,
            logOutRedirect: false
        };
    }

    componentDidMount() {
        if (localStorage.getItem("LoginSession")) {
            let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
            if (userId) {
                this.setState({
                    userId
                }, () => {
                    this.getDomainList();
                    this.checkUserSession(userId);
                });
            }
        }


        if (this.props.location.search) {
            var isDomainCreated = this.props.location.search.split('?created=')[1];
            if (isDomainCreated) {
                // COMMENTED TEMP.
                // $("#add_domain").show();
                this.props.history.push('/organization/domain');
                var span = document.createElement("span");
                span.innerHTML = `<h4>${window.i18n('cus_Dom_alert_a')}</h4><p><strong>${window.i18n('res_R7H1')}</strong> ${window.i18n('cus_Dom_alert_b')}</p>`;
                // swal(window.i18n('alert_ay'), span, 'success');
                swal(window.i18n('alert_ay'), window.i18n('alert_bm'), 'success')
                    .then(() => {
                        swal({
                            content: span,
                            closeOnClickOutside: false,
                        });
                    });
            }
        }

    }
    componentWillMount() {
        let subscriptionPlanDetails = localStorage.getItem("spd");
        if (!subscriptionPlanDetails) {
            localStorage.removeItem("loginToken");
            localStorage.removeItem("LoginSession");
            localStorage.removeItem("loginTime");
            window.location.href = "/web/userLogin";
            return;
        }
        let decryptedSubscriptionPlanDetails = CryptoJS.AES.decrypt(subscriptionPlanDetails.toString(), ENV.TokSecCrpt);
        decryptedSubscriptionPlanDetails = JSON.parse(decryptedSubscriptionPlanDetails.toString(CryptoJS.enc.Utf8));
        if (!parseInt(decryptedSubscriptionPlanDetails.customDomains)) {
            this.props.history.push("/organization/detail");
            return;
        }
        this.setState({ allowedCustomDomains: parseInt(decryptedSubscriptionPlanDetails.customDomains), customLinkHistory: parseInt(decryptedSubscriptionPlanDetails.customLinkHistory) });
        if (localStorage.getItem("LoginSession")) {
            let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
            this.setState({
                userId: id
            }, () => {
                this.getDomainList();
            })
        }
    }

    checkUserSession = (id) => {
        fetch(window.APIURL + "accounts/member/user-session-check?id=" + id + "&lang=" + this.state.language, {
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 403) {
                    return res.json();
                } else if (res.status === 422) {
                    return res.json();
                } else if (res.status === 404) {
                    return res.json();
                } else {
                    return res.json();
                }
            })
            .then(result => {
                if (result.status === true) {
                } else if (result.status === false) {
                    if (result.special === true) {
                        swal('Info', result.message, 'info');
                        this.notification(id);
                        localStorage.removeItem("loginToken");
                        localStorage.removeItem("LoginSession");
                        localStorage.removeItem("loginTime");
                        // this.setState({ redirect: true });
                    } else {
                        localStorage.removeItem("loginToken");
                        localStorage.removeItem("LoginSession");
                        localStorage.removeItem("loginTime");
                        this.setState({ redirect: true });
                    }
                    // swal(window.i18n('alert_a'), result.message, 'error');
                } else {
                    localStorage.removeItem("loginToken");
                    localStorage.removeItem("LoginSession");
                    localStorage.removeItem("loginTime");
                    this.setState({ redirect: true });
                    // swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
                }
            });
    }

    notification = (user) => {
        fetch(window.APIURL + "accounts/member/switch-bool?userId=" + user + "&lang=" + this.state.language, {
            method: "get",
            headers: { "Content-Type": "application/json" }
        })
            .then(response => response.json())
            .then(result => {
                if (result.status === true) {
                    this.setState({ redirect: true });
                }
            });
    }

    getDomainList() {
        let { userId } = this.state;
        this.setState({
            limit: this.state.limit + 10
        }, () => {
            fetch(window.APIURL + "custom-domain/user-custom-domains-list?userId=" + userId + "&page=" + this.state.page + '&limit=' + this.state.limit + "&lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
                method: "get",
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 403) {
                        return res.json();
                    } else if (res.status === 422) {
                        return res.json();
                    } else if (res.status === 404) {
                        return res.json();
                    } else {
                        return res.json();
                    }
                })
                .then(result => {
                    if (result.sessionExpired) {
                        this.setState({
                            logOutRedirect: true
                        })
                        return;
                    }

                    this.setState({
                        mainLoader: false
                    })
                    if (result.status === true) {
                        if (result.domains.length === result.data.total) {
                            this.setState({
                                hasMore: false
                            })
                        }
                        this.setState({
                            // hasMore: false,
                            domainsList: result.domains,
                            page: result.data.page,
                            pages: result.data.pages,
                            limit: result.data.limit,
                            total: result.data.total,
                        });
                    } else if (result.status === false) {
                        swal(window.i18n('alert_a'), result.message, 'error');
                    } else {
                        swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
                    }
                });
        })
    }

    goToPurchaseDomain() {
        $("#myModal").modal('hide');
        this.props.history.push("/organization/purchase/");
    }

    closeModal() {
        $("#myModal").modal('hide');
    }

    changeDomainStatus(domainId, status) {
        const { userId } = this.state;

        swal({
            title: window.i18n('static_TNdCJa'),
            text: window.i18n('static_hYValK'),
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((flag) => {
                if (flag) {
                    fetch(window.APIURL + "custom-domain/change-status?_id=" + domainId + "&isActive=" + status + "&lang=" + this.state.language + '&userId=' + this.state.userId + "&accessToken=" + localStorage.getItem('loginToken'), {
                        method: "get",
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                        .then(res => {
                            return res.json();
                        })
                        .then(result => {
                            if (result.sessionExpired) {
                                this.setState({
                                    logOutRedirect: true
                                })
                                return;
                            }
                            if (result.status) {
                                if (status)
                                    $("#changePlanBannerMainDiv").show();

                                // window.location.reload();
                                this.getDomainList();
                                swal(window.i18n('alert_ay'), result.message, 'success');
                            } else if (!result.status) {
                                swal(window.i18n('alert_a'), result.message, 'error');
                            } else {
                                swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
                            }
                        });
                }
            });
    }

    deleteDomain(domainId) {
        swal({
            title: window.i18n('alert_az'),
            text: window.i18n('alert_cj'),
            icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: [window.i18n('dellAcc_cancel'), window.i18n('global_yes')],
        })
            .then((willDelete) => {
                if (willDelete) {
                    let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
                    fetch(window.APIURL + "custom-domain/delete?_id=" + domainId + "&userId=" + id + "&lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
                        method: "get",
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                        .then(res => {
                            if (res.status === 200) {
                                return res.json();
                            } else if (res.status === 403) {
                                return res.json();
                            } else if (res.status === 422) {
                                return res.json();
                            } else if (res.status === 404) {
                                return res.json();
                            } else {
                                return res.json();
                            }
                        })
                        .then(result => {
                            if (result.sessionExpired) {
                                this.setState({
                                    logOutRedirect: true
                                })
                                return;
                            }
                            if (result.status === true) {
                                // this.setState({ domainsList: result.domains });
                                this.getDomainList();
                                swal(window.i18n('alert_ay'), result.message, 'success');
                            } else if (result.status === false) {
                                swal(window.i18n('alert_a'), result.message, 'error');
                            } else {
                                swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
                            }
                        });
                }
            });
    }

    openModal = () => {
        $("#myModal").modal('show');
    }

    render() {
        let { redirect } = this.state;
        const { logOutRedirect } = this.state;
        if (logOutRedirect) {
            // return <Redirect to="/logout" />;
            window.location.href = "/web/logout-and-clear-session"
        }

        if (redirect) {
            // return <Redirect to="/userLogin" />;
            window.location.href = "/web/logout-and-clear-session"
        }
        return (
            <React.Fragment>
                {/* <LoadingOverlay
                    active={this.state.mainLoader}
                    spinner
                > */}
                {
                    this.state.mainLoader && <MainLoader />
                }
                <div className="admin-section--MAIN">
                    <div className="admin-section--top">
                        <div className="mb-2 cust-dom-top-bar d-flex align-items-center flex-column flex-sm-row">
                            <h2 className="admin-section--header mt-0 flex-fill mb-0">{window.i18n('global_customDom')}</h2>
                            <a className="admin-section--cta">
                                <button className="btn btn-pink btn-add-cust-dom" id="" data-toggle="modal" onClick={this.openModal} >{window.i18n('custmDom_a')}</button>
                            </a>
                        </div>
                        {/* COMMENTED TEMP. */}
                        {/* <div className="admin-section--item">
                                    <p className="marketing-module--text">
                                        <span className="marketing-module--upsell-text">
                                            Using&nbsp;
                                    <span className="used-seats">
                                                {
                                                    " " + this.state.domainsList ? this.state.domainsList.length + " " : 0 + " "
                                                }
                                            </span>
                                    of
                                    {
                                                " " + this.state.allowedCustomDomains + " "
                                            } Custom Domains
                                </span>
                                        <br />
                                    </p>
                                </div> */}
                    </div>
                    <div className="customDomainLoaderHolder" style={{ position: 'relative' }}>
                        <InfiniteScroll
                            dataLength={this.state.total}
                            next={() => this.getDomainList()}
                            hasMore={this.state.hasMore}
                            loader={
                                // <CircleLoader
                                //     css={override}
                                //     sizeUnit={"px"}
                                //     size={120}
                                //     color={'#007bff'}
                                //     loading={this.state.hasMore}
                                // />
                                <ClipLoader
                                    css={override}
                                    sizeUnit={"px"}
                                    size={50}
                                    color={'#123abc'}
                                    loading={this.state.hasMore}
                                />
                            }
                        >
                            <div className="empty-state--BITLINKS">
                                {
                                    this.state.domainsList && this.state.domainsList.length ?
                                        this.state.domainsList.map((domain, key) => {
                                            return (
                                                <div key={key} className="avlbl-domain-row" style={{ overflow: 'hidden' }}>
                                                    <h4 className="float-left pt-2">
                                                        {domain.name}&nbsp;
                                                        <small style={{ fontSize: '14px' }}>
                                                            <span className={!domain.isVerified ? 'text-danger' : 'text-success'}>({window.i18n(!domain.isVerified ? 'global_notVerify' : 'global_verified')}, {window.i18n('global_platform')}&nbsp;{domain.from})</span>
                                                        </small>
                                                    </h4>
                                                    <span className="float-right">
                                                        {
                                                            this.state.customLinkHistory && domain.isVerified ?
                                                                <>
                                                                    {
                                                                        domain.from === 'NED.link' &&
                                                                        <Link className="btn btn-pink-outline d-inline-block align-top ml-2 mb-2" to={"/dashboard?dd=" + btoa(domain.name)}>{window.i18n('custmDom_b')}</Link>
                                                                    }
                                                                    {
                                                                        !domain.isActive ?
                                                                            <button className="btn btn-pink d-inline-block align-top ml-2 mb-2" onClick={() => this.changeDomainStatus(domain._id, true)} disabled={domain.from !== 'NED.link'}>{window.i18n('static_icrnfh')}</button>
                                                                            :
                                                                            <button className="btn btn-pink d-inline-block align-top ml-2 mb-2" onClick={() => this.changeDomainStatus(domain._id, false)} disabled={domain.from !== 'NED.link'}>{window.i18n('static_aUpM3Y')}</button>
                                                                    }
                                                                </> : ''
                                                        }

                                                        {
                                                            domain.from === 'NED.link' &&
                                                            <>
                                                                {
                                                                    domain.isVerified ?
                                                                        ''
                                                                        :
                                                                        <span className='drp-buttons'>
                                                                            <button className="btn cancelBtn" onClick={() => this.deleteDomain(domain._id)}>{window.i18n('custmDom_c')}</button>
                                                                        </span>
                                                                }
                                                            </>
                                                        }
                                                    </span>
                                                </div>
                                            )
                                        }) :
                                        <div className="empty-state--gradient-wrapper">
                                            <span className="no-bitlinks-icon" alt="no-bitlinks-icon"><i className="fas fa-link"></i></span>
                                            <h4 className="empty-state--header">{window.i18n('custmDom_d')}</h4>
                                            <a href="#" className="link-dark td_ul" data-toggle="modal" onClick={this.openModal}>{window.i18n('custmDom_e')}</a>
                                        </div>
                                }
                            </div>
                        </InfiniteScroll>
                    </div>
                </div>
                <div className="modal" id="myModal">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title">&nbsp;</div>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body text-center">
                                <div className="modal-title">{window.i18n('custmDom_f')}</div>
                                <div className="modal-text">{window.i18n('custmDom_g')}</div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button className="button--GHOST-SECONDARY checklist-menu-item" tabIndex="0" data-id="add_domain" onClick={() => this.closeModal()}>{window.i18n('custmDom_h')}</button>
                                {/* <button className="button--SOLID-SECONDARY" tabIndex="0" id="" onClick={() => this.goToPurchaseDomain()}>PURCHASE DOMAIN</button> */}
                            </div>

                        </div>
                    </div>
                </div>
                {/* </LoadingOverlay> */}
            </React.Fragment>
        )
    }
}

export default CustomDomains;