import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { getSiteSetting, beforeSiteSettings } from '../../shared/site-settings/site-settings.action';

class MainNavbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLogin: true,
			isLoader: false,
			userId: '',
			settings: {},
			path: ""
		};
	}

	componentDidMount = () => {
	}
	componentWillMount = () => {
		let location = this.props.location.pathname;
		this.setState({
			path: location
		})
		if (localStorage.getItem("loginToken")) {
			this.setState({
				showLogin: false,
				userId: JSON.parse(localStorage.getItem("LoginSession")).data._id
			}, () => {
				this.checkUserSession(this.state.userId)
			});
		}
	}

	checkUserSession = (id) => {
		fetch(window.APIURL + "accounts/member/user-session-check?id=" + id, {
			method: "get",
			headers: {
				"Content-Type": "application/json"
			}
		})
			.then(res => {
				if (res.status === 200) {
					return res.json();
				} else if (res.status === 403) {
					return res.json();
				} else if (res.status === 422) {
					return res.json();
				} else if (res.status === 404) {
					return res.json();
				} else {
					return res.json();
				}
			})
			.then(result => {
				if (result.status === true) {
				} else if (result.status === false) {
					if (result.special === true) {
						swal('Info', result.message, 'info');
						this.notification(id);
						localStorage.removeItem("loginToken");
						localStorage.removeItem("LoginSession");
						localStorage.removeItem("loginTime");
						// this.setState({ redirect: true });
					} else {
						localStorage.removeItem("loginToken");
						localStorage.removeItem("LoginSession");
						localStorage.removeItem("loginTime");
						this.setState({ redirect: true });
					}
				} else {
					localStorage.removeItem("loginToken");
					localStorage.removeItem("LoginSession");
					localStorage.removeItem("loginTime");
					this.setState({ redirect: true });
					// swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
				}
			});
	}

	notification = (user) => {
		fetch(window.APIURL + "accounts/member/switch-bool?userId=" + user + "&lang=" + this.state.language, {
			method: "get",
			headers: { "Content-Type": "application/json" }
		})
			.then(response => response.json())
			.then(result => {
				if (result.status === true) {
					this.setState({ redirect: true });
				}
			});
	}

	render() {
		let settings = this.props.settings.siteSettingsRes ? this.props.settings.siteSettingsRes.site : {}

		return (
			<React.Fragment>
				<header id="header" className={this.state.path === "/" ? "main-page-header" : "page-header"}>
				<div className="container-fluid main-padding">
						<div className="row align-items-center">
							<div className="col-6 col-lg-2">
								{settings ?
									<strong className="logo d-inline-block align-top">
										<Link to="/" className="d-inline-block align-top">
											{/* <img className="img-fluid" src={settings.siteLogo} alt={window.i18n('mainNavbar_altLogo')} /> */}
											{/* <img className="img-fluid" src={require("../../images/logo.png")} alt="logo" /> */}
											<img className="img-fluid" src={require('../../images/logo-floink-white.svg')} alt={window.i18n('mainFooter_altLogo')} />
										</Link>
									</strong>
									: ''
								}
							</div>
							<div className="col-6 col-lg-10 navigation-holder">
								<div className="banner-btns-holder left-btns-holder">
									<Link to="/userRegister" className="btn btn-transparent-white">{window.i18n('landing_signUp')}</Link>
									<Link to="/userLogin" className="btn btn-transparent-white ">{window.i18n('landing_logIn')}</Link>
								</div>
								<nav className="navbar navbar-light">
									<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
										<img className="img-fluid" src={require("../../images/bars.svg")} alt="Toggle button" />
									</button>
									<div className="collapse navbar-collapse flex-row justify-content-end" id="navbarSupportedContent">
										<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
											<span className="fa fa-times"></span>
										</button>
										<ul className="navbar-nav">
											<li className="nav-item active">
												<NavLink activeClassName="active" className="nav-link js-scroll-trigger" to='/why-nedlink'>
													{window.i18n('mainNavbar_whyNed')}
												</NavLink>
											</li>
											<li className="nav-item">
												<NavLink activeClassName="active" className="nav-link js-scroll-trigger" to='/solutions'>
													{window.i18n('global_solutions')}
												</NavLink>
											</li>
											<li className="nav-item">
												<NavLink activeClassName="active" className="nav-link js-scroll-trigger" to='/features'>
													{window.i18n('global_features')}
												</NavLink>
											</li>
											<li className="nav-item">
												<NavLink activeClassName="active" className="nav-link js-scroll-trigger" to='/pricing'>
													{window.i18n('global_pricing')}
												</NavLink>
											</li>
											<li className="nav-item">
												<NavLink activeClassName="active" className="nav-link js-scroll-trigger" to='/resources'>
													{window.i18n('global_resourses')}
												</NavLink>
											</li>
											{/* {this.state.showLogin === true ?
												<React.Fragment>
													<li className="nav-item user-link-item">
														<Link to="/userLogin" className="nav-link js-scroll-trigger btn btn-pink">LOGIN</Link>
													</li>
													<li className="nav-item user-link-item">
														<Link to="/userRegister" className="nav-link js-scroll-trigger btn btn-pink-outline">SIGNUP</Link>
													</li>
												</React.Fragment>
												:
												""
											} */}
											{/* {this.state.showLogin !== true ?
													<React.Fragment>
														<li className="nav-item colr">
															<Link to="/" className="nav-link js-scroll-trigger btn btn-pink">
																My Dashboard
															</Link>
														</li>
													</React.Fragment>
												:
												''
											} */}
										</ul>
										<div className="user-btns-holder">
											{this.state.showLogin == true ?
												<span className="d-flex flex-column">
													<Link to="/userRegister" className="nav-link js-scroll-trigger btn btn-pink-outline">{window.i18n('landing_signUp')}</Link>
													<Link to="/userLogin" className="nav-link js-scroll-trigger btn btn-pink-outline">{window.i18n('landing_logIn')}</Link>
												</span>
												:
												<Link to="/" className="nav-link js-scroll-trigger btn btn-pink-outline">
													{window.i18n('mainNavbar_myDashboard')}
												</Link>
											}
										</div>
									</div>
								</nav>
							</div>
						</div>
					</div>
				</header >
				
			</React.Fragment >
		);
	}
}

const mapDispatchToProps = {
	getSiteSetting, beforeSiteSettings
};

const mapStateToProps = state => ({
	settings: state.settings,
	header: state.header,
	errors: state.errors
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(MainNavbar));