import { GET_ERRORS, EMPTY_ERRORS, GET_SITE_SETTINGS, BEFORE_SITE_SETTINGS } from '../../redux/types';
import { ENV } from '../../config/config';

export const emptyError = () => {
    return {
        type: EMPTY_ERRORS
    }
}

export const beforeSiteSettings = () => {
    return {
        type: BEFORE_SITE_SETTINGS
    }
}

export const getSiteSetting = (language) => dispatch => {
    dispatch(emptyError());
    if (!language) language = 'en';
    fetch(`${ENV.url}site-settings/get-site-settings?lang=` + language, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: GET_SITE_SETTINGS,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}
