import React, { Component } from "react";
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterResults from "react-filter-search";
import { Redirect } from "react-router-dom";


class ManageApps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
            value: "",
            apps: [],
            activeApps: [],
            logOutRedirect: false,
            manageApplLoader: true,
            logOutRedirect: false
        };
    }

    componentDidMount = () => {
        let hash = window.location.hash;
        if (hash !== "" && hash.split("&").length > 0) {
            let splited = hash.split("&");
            let last = splited[splited.length - 1].split("=")[1];
            if (last === "manage_apps") {
                this.getAppsFunction();
                // this.check();
                this.activeAppsFunc();
            }
        }
    };


    getAppsFunction = () => {
        let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        fetch(window.APIURL + "integration/user-apps-without?userId=" + userId + "&lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'))
            .then(response => response.json())
            .then(result => {
                if (result.sessionExpired) {
                    this.setState({
                        logOutRedirect: true
                    })
                    return;
                }
                this.setState({ manageApplLoader: false, apps: result.apps });
            });
    }

    activeAppsFunc = () => {
        let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        fetch(window.APIURL + "integration/user-apps?userId=" + userId + "&lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'))
            .then(response => response.json())
            .then(result => {
                if (result.sessionExpired) {
                    this.setState({
                        logOutRedirect: true
                    })
                    return;
                }
                this.setState({ activeApps: result.apps });
            });
    }

    handleChangeApps = event => {
        const { value } = event.target;
        this.setState({ value });
    };

    addApp = () => {
        let { apps } = this.state;
        let { subscriptionDetails } = this.props;
        if (subscriptionDetails && subscriptionDetails.integrations) {
            if (apps.length < parseInt(subscriptionDetails.integrations)) {
                this.props.changeLoaderStatus(true);
                let { value } = this.state;
                let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
                this.setState({ manageApplLoader: true })
                fetch(window.APIURL + "integration/create/app?lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        appName: value,
                        userId: userId
                    })
                })
                    .then(res => {
                        if (res.status === 200) {
                            return res.json();
                        } else if (res.status === 400) {
                            return res.json();
                        } else if (res.status === 403) {
                            return res.json();
                        } else if (res.status === 422) {
                            return res.json();
                        } else if (res.status === 404) {
                            return res.json();
                        } else {
                            return res.json();
                        }
                    })
                    .then(result => {
                        if (result.sessionExpired) {
                            this.setState({
                                logOutRedirect: true
                            })
                            return;
                        }

                        this.setState({ manageApplLoader: false })
                        if (result.status === true) {
                            let createdApps = { appName: result.app.appName, _id: result.app._id };
                            this.setState({
                                apps: [...this.state.apps, createdApps],
                                value: "",
                            });
                            this.props.updateAppsFromChild(createdApps);
                            this.getAppsFunction();
                            swal(window.i18n('alert_ay'), result.message, 'success')
                            this.props.changeLoaderStatus(false);
                        } else if (result.status === false) {

                            if (result.redirection === true) {
                                if (result.special === true) {
                                    let user = JSON.parse(localStorage.getItem("LoginSession")).data._id
                                    swal('Info', result.message, 'info');
                                    this.notification(user);
                                } else {
                                    this.setState({ logOutRedirect: true });
                                    swal(window.i18n('alert_a'), result.message, 'error');
                                }
                            } else {
                                this.setState({ logOutRedirect: true });
                                swal(window.i18n('alert_a'), result.message, 'error');
                            }
                        } else {
                            swal(window.i18n('alert_a'), window.i18n('alert_c'), 'error');
                        }
                    });
            } else {
                swal(window.i18n('alert_a'), window.i18n('alert_aa'), 'error')
            }
        }
    };

    notification = (user) => {
        fetch(window.APIURL + "accounts/member/switch-bool?userId=" + user + "&lang=" + this.state.language, {
            method: "get",
            headers: { "Content-Type": "application/json" }
        })
            .then(response => response.json())
            .then(result => {
                if (result.status === true) {
                    this.setState({ logOutRedirect: true });
                }
            });
    }

    disableApp = _id => {
        Swal.fire({
            title: window.i18n('alert_az'),
            text: window.i18n('static_vD9RSo'),
            showCancelButton: true,
            confirmButtonColor: '#0a6eb4',
            cancelButtonColor: '#d33',
            confirmButtonText: window.i18n('new_alert_d')
        }).then((result) => {
            if (result.value) {
                this.props.changeLoaderStatus(true);

                // let appsBackup = this.state.apps;
                let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
                this.setState({ manageApplLoader: true })
                fetch(window.APIURL + "integration/disable?_id=" + _id + "&userId=" + userId + "&lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'))
                    .then(res => {
                        if (res.status === 200) {
                            return res.json();
                        } else if (res.status === 400) {
                            return res.json();
                        } else if (res.status === 403) {
                            return res.json();
                        } else if (res.status === 422) {
                            return res.json();
                        } else if (res.status === 404) {
                            return res.json();
                        } else {
                            return res.json();
                        }
                    })
                    .then(result => {
                        if (result.sessionExpired) {
                            this.setState({
                                logOutRedirect: true
                            })
                            return;
                        }

                        this.setState({ manageApplLoader: false })
                        if (result.status === true) {
                            swal(window.i18n('alert_ay'), result.message, 'success')
                            this.getAppsFunction();
                            this.activeAppsFunc();
                            this.props.changeLoaderStatus(false);
                        }
                        else {
                            swal(window.i18n('alert_a'), result.message, 'error');
                        }
                    });
            }
        })
    }

    enableApp = _id => {
        let { activeApps } = this.state;
        let { subscriptionDetails } = this.props;
        Swal.fire({
            title: window.i18n('alert_az'),
            text: window.i18n('static_pcAvHd'),
            showCancelButton: true,
            confirmButtonColor: '#0a6eb4',
            cancelButtonColor: '#d33',
            confirmButtonText: window.i18n('static_lYZwMQ')
        }).then((result) => {
            if (result.value) {
                if (subscriptionDetails && subscriptionDetails.integrations) {
                    if (activeApps.length < parseInt(subscriptionDetails.integrations)) {
                        this.props.changeLoaderStatus(true);
                        // let { value } = this.state;
                        let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
                        this.setState({ manageApplLoader: true })
                        // let appsBackup = this.state.apps;
                        fetch(window.APIURL + "integration/enable?_id=" + _id + "&userId=" + userId + "&lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'))
                            .then(res => {
                                if (res.status === 200) {
                                    return res.json();
                                } else if (res.status === 400) {
                                    return res.json();
                                } else if (res.status === 403) {
                                    return res.json();
                                } else if (res.status === 422) {
                                    return res.json();
                                } else if (res.status === 404) {
                                    return res.json();
                                } else {
                                    return res.json();
                                }
                            })
                            .then(result => {
                                if (result.sessionExpired) {
                                    this.setState({
                                        logOutRedirect: true
                                    })
                                    return;
                                }

                                this.setState({ manageApplLoader: false })
                                if (result.status === true) {
                                    swal(window.i18n('alert_ay'), result.message, 'success')
                                    this.getAppsFunction();
                                    this.activeAppsFunc();
                                    this.props.changeLoaderStatus(false);
                                }
                                else {
                                    swal(window.i18n('alert_a'), result.message, 'error');
                                }
                            });

                    } else {
                        swal(window.i18n('alert_a'), window.i18n('alert_aa'), 'error')
                    }
                }
            }
        })
    }

    deleteApp = _id => {

        Swal.fire({
            title: window.i18n('alert_az'),
            text: window.i18n('alert_bg'),
            showCancelButton: true,
            // customClass: "conformations",
            confirmButtonColor: '#0a6eb4',
            cancelButtonColor: '#d33',
            confirmButtonText: window.i18n('global_yesDel')
        }).then((result) => {
            if (result.value) {
                this.props.changeLoaderStatus(true);

                let appsBackup = this.state.apps;
                let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
                this.setState({ manageApplLoader: true })
                fetch(window.APIURL + "integration/delete?_id=" + _id + "&userId=" + userId + "&lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'))
                    .then(res => {
                        if (res.status === 200) {
                            return res.json();
                        } else if (res.status === 400) {
                            return res.json();
                        } else if (res.status === 403) {
                            return res.json();
                        } else if (res.status === 422) {
                            return res.json();
                        } else if (res.status === 404) {
                            return res.json();
                        } else {
                            return res.json();
                        }
                    })
                    .then(result => {
                        if (result.sessionExpired) {
                            this.setState({
                                logOutRedirect: true
                            })
                            return;
                        }

                        this.setState({ manageApplLoader: false })
                        if (result.status === true) {
                            swal(window.i18n('alert_ay'), result.message, 'success')
                            const index = appsBackup.findIndex(
                                item => item._id === _id
                            );
                            appsBackup.splice(index, 1);
                            this.props.updateAppsFromChild(appsBackup);
                            this.setState({
                                apps: appsBackup
                            });
                            this.props.changeLoaderStatus(false);

                        }
                        else {
                            swal(window.i18n('alert_a'), result.message, 'error');
                        }
                    });
            }
        })
    };

    dynamicApps = (_id, index, e) => {
        if (e.target.value === "") {
            swal(window.i18n('alert_a'), window.i18n('alert_ab'), 'error');
            return;
        }
        else {
            let apps = [...this.state.apps];
            apps[index].appName = e.target.value;
            this.setState({ apps });
            let appName = e.target.value;
            setTimeout(() => {
                fetch(window.APIURL + "integration/edit?lang=" + this.state.language + "&accessToken=" + localStorage.getItem('loginToken'), {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        _id: _id,
                        appName: appName,
                        userId: JSON.parse(localStorage.getItem("LoginSession")).data._id
                    })
                })
                    .then(res => {
                        if (res.status === 200) {
                            return res.json();
                        } else if (res.status === 400) {
                            return res.json();
                        } else if (res.status === 403) {
                            return res.json();
                        } else if (res.status === 422) {
                            return res.json();
                        } else if (res.status === 404) {
                            return res.json();
                        } else {
                            return res.json();
                        }
                    })
                    .then(result => {
                        if (result.sessionExpired) {
                            this.setState({
                                logOutRedirect: true
                            })
                            return;
                        }

                        if (result.status === false) {
                            if (result.redirection === true) {
                                this.setState({ logOutRedirect: true });
                                swal(window.i18n('alert_a'), result.message, 'error');
                            }
                            swal(window.i18n('alert_a'), result.message, 'error');
                        }
                    });
            }, 500);
        }
    };

    render() {
        const { logOutRedirect } = this.state;
        if (logOutRedirect) {
            // return <Redirect to="/logout" />;
            window.location.href = "/web/logout-and-clear-session"
        }
        return (
            <React.Fragment>
                <div
                    className="action-sheet--wrapper-MAIN data-state"
                    data-state="20"
                    style={{ display: "none " }}
                    id="manage_apps"
                >
                    <div
                        className="action-sheet--MAIN"
                        data-selected-sheet="appsList"
                        style={{
                            animation: "600ms ease 0ms 1 normal forwards running open-sheet "
                        }}
                    >
                        <div className="action-sheet--header">
                            <a>
                                {" "}
                                <span className="back_btn-icon" alt="back_btn-icon">
                                    <FontAwesomeIcon icon="chevron-left" />
                                </span>{" "}
                            </a>
                            <h4 className="action-sheet--header-text">{window.i18n('genAcc_manageApps')}</h4>
                            <span>
                                <a>
                                    <span className="close-icon" alt="close-icon" />
                                </a>
                            </span>
                        </div>
                        <div className="action-sheet--content-wrapper">
                            <div className="dynamic-checklist--MAIN">
                                <div className="search--ALTERNATE   ">
                                    <input
                                        type="search"
                                        maxLength="100"
                                        className="search--input-field"
                                        placeholder={window.i18n('mangApp_searchACrea')}
                                        value={this.state.value}
                                        onChange={this.handleChangeApps}
                                    />

                                    <FilterResults
                                        value={this.state.value}
                                        data={this.state.apps}
                                        renderResults={results => (
                                            <div>
                                                {results.length > 0 && this.state.manageApplLoader !== true ? (
                                                    results.map((el, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className="dynamic-checklist--item "
                                                            >
                                                                <div className="input-field--wrapper manageAppWrapper">
                                                                    <div className="input-field--ALTERNATE   ">
                                                                        <label className="input-field--label" />
                                                                        <div className="input-field--input-wrapper manageAppHolder d-flex">
                                                                            <input
                                                                                type="text"
                                                                                className="input-field--input flex-fill"
                                                                                maxLength="100"
                                                                                value={el.appName}
                                                                                disabled
                                                                            // onChange={event =>
                                                                            //     this.dynamicApps(
                                                                            //         el._id,
                                                                            //         index,
                                                                            //         event
                                                                            //     )
                                                                            // }
                                                                            />
                                                                            <div className="enable-disable-icon-holder text-center" style={{ width: '20px' }}>
                                                                                <span className="icon-cross" onClick={() => this.deleteApp(el._id)}> X </span>
                                                                                {
                                                                                    el.enabled ?
                                                                                        <span className="icon-cross text-success" onClick={() => this.disableApp(el._id)}> <FontAwesomeIcon icon="eye" /> </span>
                                                                                        :
                                                                                        <span className="icon-cross text-danger" onClick={() => this.enableApp(el._id)}> <FontAwesomeIcon icon="eye-slash" /> </span>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                ) :
                                                    this.state.manageApplLoader === true ?
                                                        <div className="text-center">
                                                            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                                                        </div>
                                                        :
                                                        (
                                                            <div>
                                                                {this.state.value === "" ? (
                                                                    ""
                                                                ) : (
                                                                    <div>
                                                                        <span>
                                                                            {window.i18n('mangChan_thereIsNo') + " " +
                                                                                this.state.value +
                                                                                "'"}
                                                                        </span>
                                                                        <br />
                                                                        <a className="create-suggestion" onClick={this.addApp}>
                                                                            <span className="link-no-dark td_ul"> {window.i18n('mangChan_create')} '{this.state.value}' </span>
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ManageApps;
