import React, { Component } from "react";
import { Link } from "react-router-dom";
import pageBannerImage from '../../images/banner-resources.png';

class WhyQuick extends Component {
	state = {};
	render() {
		return (
			<React.Fragment>
				<div className="main-content pl-0">
					{/* <div className="page-banner">
						<div className="container h-100 d-flex justify-content-center align-items-center">
							<div className="page-title-block py-5 text-white">
								<h1>{window.i18n('why_nedLink')}</h1>
							</div>
						</div>
					</div> */}
					<div className="page-banner">
						<div className="container text-white">
							<div className="row align-items-center">
								<div className="col-md-6">
									<div className="page-banner-text">
										<h1>{window.i18n('why_nedLink')}</h1>
										<p>Neque porro quisquam esqui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.</p>
									</div>
								</div>
								<div className="col-md-6 d-none d-md-block">
									<div className="page-banner-image">
										<img className="img-fluid" src={pageBannerImage} alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="cms-content">
						<div className="content-section">
							<div className="container">
								<div className="section-heading-block text-center">
									<h2 className="text-center">
										<span className="d-inline-block align-top">{window.i18n('why_ned')}</span>
									</h2>
									<small className="d-block">{window.i18n('why_anOver')}</small>
								</div>
								<div className="section-intro-text">
									<h2>{window.i18n('why_linkMang')}</h2>
									<p>{window.i18n('why_nedIsGreat')}</p>
								</div>
								<div className="content-articles-holder">
									<article className="content-article bgLightBlue">
										<h3>{window.i18n('why_makeLinks')}</h3>
										<p>{window.i18n('why_createCustom')}</p>
									</article>
									<article className="content-article bgLightBlue">
										<h3>{window.i18n('why_deepInsights')}</h3>
										<p>{window.i18n('why_noGuesswork')}</p>
									</article>
									<article className="content-article bgLightBlue">
										<h3>{window.i18n('why_scalableThird')}</h3>
										<p>{window.i18n('why_integYourAdv')}</p>
									</article>
									<article className="content-article bgLightBlue">
										<h3>{window.i18n('why_cyberSec')}</h3>
										<p>{window.i18n('why_createIntegrate')}</p>
									</article>
									<div className="centerBtnsHolder text-center">
										<Link to="/pricing">
											<button className="btn btn-pink">{window.i18n('global_getStarted')}</button>
										</Link>
										<Link to="/get-quote">
											<button className="btn btn-pink-outline">{window.i18n('global_getAQuote')}</button>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="content-section section-bg-grey">
							<div className="container">
								<div className="section-heading-block text-center">
									<h2 className="text-center">
										<span className="d-inline-block align-top">{window.i18n('why_toolAndApp')}</span>
									</h2>
									<small className="d-block">{window.i18n('why_workBetter')}</small>
								</div>
								<div className="section-intro-text">
									<h2>{window.i18n('why_automateYour')}</h2>
									<p>{window.i18n('why_connectNed')}</p>
								</div>
								<div className="content-articles-holder">
									<article className="content-article">
										<h3>{window.i18n('why_TeamUp')}</h3>
										<p>{window.i18n('why_connectToYour')}</p>
									</article>
									<article className="content-article">
										<h3>{window.i18n('why_createBranded')}</h3>
										<p>{window.i18n('why_apiHelps')}</p>
									</article>
									<article className="content-article">
										<h3>{window.i18n('why_enterpGrade')}</h3>
										<p>{window.i18n('why_allShortened')}</p>
									</article>
									<article className="content-article">
										<h3>{window.i18n('why_sendShort')}</h3>
										<p>{window.i18n('why_createSend')} </p>
									</article>
									<div className="centerBtnsHolder text-center">
										<Link to="/pricing">
											<button className="btn btn-pink">{window.i18n('global_getStarted')}</button>
										</Link>
										<Link to="/get-quote">
											<button className="btn btn-pink-outline">{window.i18n('global_getAQuote')}</button>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="content-section">
							<div className="container">
								<div className="section-heading-block text-center">
									<h2 className="text-center">
										<span className="d-inline-block align-top">{window.i18n('why_highFidelity')}</span>
									</h2>
									<small className="d-block">{window.i18n('why_doMore')}</small>
								</div>
								<div className="section-intro-text">
									<h2>{window.i18n('why_makeComplex')}</h2>
									<p>{window.i18n('why_businessesUnd')}</p>
								</div>
								<div className="content-articles-holder">
									<article className="content-article bgLightBlue">
										<h3>{window.i18n('why_scalable')}</h3>
										<p>{window.i18n('why_isBuiltToHelp')}</p>
									</article>
									<article className="content-article bgLightBlue">
										<h3>{window.i18n('why_reliable')}</h3>
										<p>{window.i18n('why_isEasyTo')}</p>
									</article>
									<article className="content-article bgLightBlue">
										<h3>{window.i18n('why_flexible')}</h3>
										<p>{window.i18n('why_usesSwiftAuto')}</p>
									</article>
									<article className="content-article bgLightBlue">
										<h3>{window.i18n('why_24_7_supp')}</h3>
										<p>{window.i18n('why_supportPersonal')}</p>
									</article>
									<div className="centerBtnsHolder text-center">
										<Link to="/pricing">
											<button className="btn btn-pink">{window.i18n('global_getStarted')}</button>
										</Link>
										<Link to="/get-quote">
											<button className="btn btn-pink-outline">{window.i18n('global_getAQuote')}</button>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default WhyQuick;
