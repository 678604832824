import $ from 'jquery';

export function scrollTop() {
    // window.onload = function () {
        setTimeout(() => {
            window.scrollTo({
                top: 0
            });
        });
    // }
}